<template>
  <div>
    <h1 class="visuallyhidden" ref="headerRef" tabindex="-1">
      {{ locale['pp.token.completed'] }}
    </h1>
    <div class="complete-title">
      {{ locale['pp.message.paid'] }}
    </div>
    <div class="complete-description">
      {{ locale['pp.message.action.paid'] }}
    </div>
    <div class="complete-footer">
      <app-button @onClick="goToSite">
        {{ locale['pp.message.viewcase.button'] }}
      </app-button>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import AppButton from '@/components/app-button'
import router from '../../router'
import store from '../../store'

export default {
  components: {
    AppButton
  },
  computed: {
    ...mapState({
      locale: (state) => state.mainStore.localization
    })
  },
  title() {
    return `${this.locale['pp.token.completed.pagetitle']}`
  },
  beforeRouteEnter(to, from, next) {
    const status = store.state.mainStore.details.transactionStatus
    const currentPath = `/${router.currentRoute.params.token}/${router.currentRoute.params.locale}`

    if (
      (from.name === 'PaymentProduct' ||
        from.name === 'PaymentReview' ||
        from.name === 'PaymentTerms' ||
        from.name === 'PaymentForm') &&
      to.name == 'Paid' &&
      status === 'PEND'
    ) {
      router.push(currentPath)
    } else {
      next()
    }
    if (from.path === '/') {
      let s = window.location.href
      let testpath = s.replace('completed', '').split('/')
      let path = `/${testpath[4]}/${testpath[5]}/`
      router.push(path)
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'PaymentForm') {
      location.reload()
    }
  },

  mounted() {
    this.$refs.headerRef.focus()
  },
 
  methods: {
    goToSite() {
      window.open('https://getsupport.apple.com/', '_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/custom.scss';
@import 'ac-sasskit/core';
@import 'ac-sasskit/base';
@import '@/styles/variables.scss';


.complete-title {
  text-align: center;
  @include typography(headline);
  margin-top: $app-margin-top-lg;
  margin-bottom: $app-margin-xx-xds;
  color: swatch(glyph-gray);
}

.complete-description {
  text-align: center;
  @include typography(intro);
}

.complete-footer {
  text-align: center;
  margin-top: $app-margin-lg;
}


</style>
