import instance from '@/utilities/interceptor'

export default {
  /**
   * Get Filter preference information
   * method: GET
   * end point: /api/v1/aep/filterPreferences
   */
  fetchLocalization(locale) {
    return instance.get(`messages/${locale}`)
  },

  /**
   * Get Filter preference information
   * method: GET
   * end point: /api/v1/aep/filterPreferences
   */
  getStates(payload) {
    return instance.post(`${payload.token}/states`, payload.data)
  },
  // getStatesList(data) {
  //   return instance.get(`stateCode/${data.token}/${data.countryCode}`)
  // },
  getCountryLists(data) {
    return instance.get(`/countries`)
  },

  getCountrySettings(data) {
    return instance.get(`/address/${data}`)
  },
  /**
   * Get Filter preference information
   * method: GET
   * end point: /api/v1/aep/filterPreferences
   */
  fetchDetails(locale, token) {
    return instance.get(`${token}/SummaryServ?locale=${locale}`, {
      baseURL: 'api/v3/webpay'
    })
  },

  fetchRefundDetails(token) {
    return instance.get(`/${token}/summary/refund`, {
      baseURL: 'api/v3/webpay'
    })
  }
}
