<template>
  <button
    class="button"
    :class="buttonClasses"
    :disabled="isLoading ? true : disabled"
    type="button"
    @click.prevent="onClickHandler"
  >
    <span>
      <!-- @slot Use this slot content inside button -->
      <slot />
    </span>
  </button>
</template>
<script>
import Vue from 'vue'

export default {
  name: 'app-button',
  description: 'Button',
  data() {
    return {
      color: '#0071e3'
    }
  },
  computed: {
    buttonClasses() {
      const buttonTypes = {
        primary: 'button-super',
        secondary: 'button-super button-secondary',
        fullwidth: 'button-super button-neutral',
        base: 'button-base',
        elevated: 'button-elevated',
        icon: 'button-super button--icon',
        link: 'button--link'
      }
      const buttonTypeClasses = [buttonTypes[this.type]]
      if (this.isLoading) buttonTypeClasses.push('isLoading')

      return buttonTypeClasses
    }
  },
  props: {
    type: {
      type: String,
      default: 'primary'
    },
    small: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    ariaLabel: {
      type: String,
      default: ''
    }
  },
  methods: {
    onClickHandler() {
      this.$emit('onClick')
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'ac-sasskit/core';
@import 'ac-sasskit/base';
@import 'ac-sasskit/modules/button';
@import '~@apple/vue-nectar/dist/lib/vue-nectar.min.css';
@import '@/styles/variables.scss';

.isLoading {
  // referred from https://github.pie.apple.com/aos-ui/react-forms/blob/develop/src/button/button.scss which is based on @aos/ac-forms
  $size: 24px;
  $margin: 8px;
  $padding: $size + ($margin * 2);
  padding-left: $padding;
  padding-right: $padding;
  @keyframes waitindicator-animation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  &::before {
    animation-name: waitindicator-animation;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border-radius: 50%;
    display: block;
    display: inline-block;
    margin-right: 0.3em;
    margin-top: -0.1em;
    margin-bottom: -0.1em;
    vertical-align: middle;
    position: relative;
    top: -0.1em;
    width: 24px;
    height: 24px;
    background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22rgba%280%2C0%2C0%2C0%29%22%20style%3D%22%22%3E%3Cpath%20stroke%3D%22rgb%28255%2C255%2C255%29%22%20stroke-width%3D%221.5%22%20stroke-linecap%3D%22round%22%20d%3D%22M14.997%204.583a8%208%200%201%200%204.828%205.754%22%2F%3E%3C%2Fsvg%3E);
    content: '';
    margin-left: -($size + $margin);
    margin-right: $margin;
    top: -0.04em;
  }
}

.button {
  width: 15.53em;
  font-family: $default-family;
  @include typography(body-tight);
  @include viewport(small) {
    width: 80%;
  }
  border-radius: 10px;
  white-space: normal !important;
  word-wrap: break-word;
  cursor: pointer;
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.3;
  }

  transition: 0.3s;
  &:not([disabled]):hover {
    opacity: 0.9;
  }
  &.button-super {
    color: swatch(fill);
  }
  &.button-secondary {
    background-color: swatch(fill-tertiary);
    color: $dark;
    font-weight: $app-font-weight-lg;
    &:hover {
      // border: 1px solid swatch(glyph-blue);
    }
  }
  &:focus {
    outline: 4px solid $focus-blue;
  }
  &.button--fullwidth {
    width: 50%;
    background: white !important;
    border: solid 1px swatch(glyph-gray);
    color: $dark;
    font-weight: $app-font-weight-lg;
    height: 50px;
    border-radius: 10px;
    // &:hover {
    //   border: 1px solid swatch(fill-gray-quaternary);
    // }
  }
  &:focus {
    outline: 4px solid $focus-blue;
  }
  &.button--icon {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: $app-padding-zero;
    background-color: swatch(fill);
    &:focus {
      box-shadow: 0 0 swatch(glyph-gray);
    }
  }
  &.button--link {
    color: swatch(glyph-blue);
    background-color: swatch(fill);
    margin-right: 0rem;
    width: fit-content;
    padding: $app-padding-zero;
    &:focus {
      outline: none;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}
.invisible {
  visibility: hidden;
}
.v-spinner {
  height: 0px;
}

.underline_text {
  text-decoration: underline;
}

.button-spinner {
  top: rem(10);
  position: relative;
}
</style>
