var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product section-content"},[_c('div',{staticClass:"row checkout__header"},[_c('div',{staticClass:"column large-6 small-8 checkout__header-label"},[_c('span',[_vm._v(_vm._s(_vm.locale['pp.payment.refunds.label']))])]),_c('div',{staticClass:"column large-6 small-4 checkout__header-price"},[_c('span',{staticClass:"checkout__header-tax"},[_vm._v(" "+_vm._s(_vm.locale['pp.payment.refund.amt'])+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.amount))])])]),(_vm.isVerifiedError)?_c('b-col',{staticClass:"error-message",attrs:{"cols":"12"}},[_c('div',{staticClass:"error-message__text",attrs:{"id":"text-error-focus","tabindex":"-1","role":"group","aria-label":"Notification"}},[_c('img',{staticClass:"input-field__icons",attrs:{"alt":"Error","src":require("../../assets/warning.svg")}}),_c('span',{staticClass:"error-message-refund",domProps:{"innerHTML":_vm._s(_vm.locale["pp.payment.error.bankinfo.message"])}})])]):_vm._e(),_c('div',{staticClass:"checkout__header-howtopay"},[_c('h1',{staticClass:"column large-12 small-8"},[_vm._v(" "+_vm._s(_vm.locale['pp.payment.refund.bank.info'])+" ")]),_c('span',{staticClass:"row large-12 small-12 checkout__header-text"},[_vm._v(" "+_vm._s(_vm.locale['pp.payment.refund.message'])+" ")])]),_c('div',{staticClass:"row form-group form__section--cardinfo"},[_c('div',{staticClass:"row form__section--field"},[_c('div',{staticClass:"column large-6 medium-6 small-12 form__section--field"},_vm._l((_vm.bankFieldsDetails),function(ref){
          var name = ref.name;
          var textKey = ref.textKey;
          var fieldConstraints = ref.fieldConstraints;
          var minLength = ref.minLength;
          var maxLength = ref.maxLength;
          var mandatory = ref.mandatory;
          var type = ref.type;
          var vMaskPlaceholder = ref.vMaskPlaceholder;
          var confirmationRequired = ref.confirmationRequired;
          var tooltip = ref.tooltip;
return _c('div',{key:name},[_c('app-input-base-bank-field',{attrs:{"name":name,"textKey":textKey,"fieldConstraints":fieldConstraints,"minLength":minLength,"maxLength":maxLength,"required":mandatory,"type":type,"vMaskPlaceholder":vMaskPlaceholder,"confirmationRequired":confirmationRequired,"firstInput":true,"error":_vm.error,"hasQuestionMarkIcon":tooltip},on:{"onChange":_vm.fieldChange}}),(confirmationRequired)?_c('app-input-base-bank-field',{attrs:{"name":name,"textKey":textKey,"fieldConstraints":fieldConstraints,"minLength":minLength,"maxLength":maxLength,"required":mandatory,"type":type,"vMaskPlaceholder":vMaskPlaceholder,"confirmationRequired":confirmationRequired,"secondInput":true,"error":_vm.error},on:{"onChange":_vm.fieldChange}}):_vm._e()],1)}),0)])]),_c('div',{staticClass:"row continue__review"},[_c('div',{staticClass:"column large-6 medium-6 small-12"},[_c('app-button',{attrs:{"id":"continue-payment-form","type":_vm.buttonSizeChange,"disabled":_vm.isFormEmpty,"isLoading":_vm.isFetching},on:{"onClick":_vm.nextStep}},[_vm._v(" "+_vm._s(_vm.locale['pp.payment.continuereview'])+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }