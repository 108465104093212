import router from '../router'
export default (data, { redirectToGenericErrorPageByDefault = true } = {}) => {
  const currentRoute = `/${router.currentRoute.params.token}/${router.currentRoute.params.locale}`
  if (data.status === 'CNCL') {
    router.push(`${currentRoute}/cancelled`)
  } else if (data.status === 'EXPD') {
    router.push(`${currentRoute}/expired`)
  } else if (data.status === 'PEND' && data.quote.grand_total === '0.00') {
    router.push(`${currentRoute}/error`)
  } else if (data.status === 'ERROR') {
    if (
      data.errorCode === 'CSRF_TOKEN_MISMATCH' ||
      data.errorCode === 'CSRF_TOKEN_EXPIRED' ||
      data.errorCode === 'CSRF_GUARD_EXCEPTION'
    ) {
      router.push(`${currentRoute}/autherror`)
    } else if (data.errorCode === 'TOKEN_CANCELLED_ERROR') {
      router.push(`${currentRoute}/cancelled`)
    } else if (data.errorCode === 'REFUND_CANCELLED_ERROR') {
      router.push(`${currentRoute}/refund/cancelled`)
    } else if (
      data.errorCode === 'TRANSACTION_ERROR_INVALID_TOKEN' ||
      data.errorCode === 'TRANSACTION_ERROR_SUMMARY_INVALID_TRANSACTION_STATUS'
    ) {
      router.push(`${currentRoute}/notfound`)
    } else if (
      data.errorCode === 'TRANSACTION_ERROR_SUMMARY_ALREADY_COMPLETED' ||
      data.errorCode ===
        'TRANSACTION_ERROR_SUMMARY_ALREADY_COMPLETED_BY_OTHERCHANNEL' ||
      data.errorCode === 'TRANSACTION_ERROR_PAYMENT_IS_UNDER_PROCESS' ||
      data.errorCode === 'PAYMENT_ALREADY_COMPLETED_ERROR'
    ) {
      router.push(`${currentRoute}/completed`)
    } else if (
      data.errorCode === 'TRANSACTION_ERROR_SUMMARY_STATUS_CANCELLED'
    ) {
      router.push(`${currentRoute}/cancelled`)
    } else if (
      data.errorCode === 'TRANSACTION_ERROR_TOKEN_EXPIRED' ||
      data.errorCode === 'TOKEN_EXPIRED_ERROR'
    ) {
      router.push(`${currentRoute}/expired`)
    } else if (
      [
        'TRANSACTION_ERROR_SUMMARY_AGREEMENT_ALREADY_MAPPED',
        'PAYMENT_LINK_SERVICE_INTERNAL_FAILURE',
        'TRANSACTION_ERROR_SUMMARY_CURRENCY_FORMAT',
        'TRANSACTION_ERROR_INVALID_QUOTE',
        'NO_CDM_RECORD_FOUND',
        'CONNECTION ERROR',
        'TRANSACTION_ERROR_INVALID_PRICE'
      ].includes(data.errorCode)
    ) {
      router.push(`${currentRoute}/error`)
    } else {
      if (redirectToGenericErrorPageByDefault === true) {
        router.push(`${currentRoute}/error`)
      }
    }
  } else if (data.status === 'nojs') {
    router.push(`${currentRoute}/nojs`)
  } else if (data.status === 'unsupportedbrowser') {
    router.push(`${currentRoute}/unsupportedbrowser`)
  }
}
