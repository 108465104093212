<template>
  <div class="product section-content">
    <div class="row checkout__header" />
    <h1>{{ locale['pp.account.payment.review'] }}</h1>
    <p class="product__description">
      {{ locale['pp.paymentinfo.continue'] }}
    </p>
    <div class="row product__details-nrfee">
      <div class="column large-10 small-8 product__details-nrfee">
        <p>{{ locale['pp.amount.tobepaid'] }}</p>
      </div>
      <div class="column large-2 small-4 product__details-nrfeeprice">
        <p>
          <span lang="en" dir="ltr">{{ amount }}</span>
        </p>
      </div>
      <div
        class="column large-9 large-order-1 small-12 small-order-2 product__details-name"
      >
        <div class="row product__details-id">
          <p v-if="accountType === 'SWT'" class="column large-12 small-8">
            {{ locale['pp.account.product.name.SWT'] }}
            {{ maskAccountNumber(accountNumber) }}
          </p>
          <p v-if="accountType === 'MCF'" class="column large-12 small-8">
            {{ locale['pp.account.product.name.MCF'] }}
            {{ maskAccountNumber(accountNumber) }}
          </p>
        </div>
      </div>
    </div>
    <div
      class="column large-4 small-uncentered small-12 medium-6 medium-offset-6 large-offset-8 payment__acion"
    >
      <div class="large-4 medium-8 small-12 payment__acion__container">
        <app-button id="continue-payment-product" @onClick="nextStep">
          {{ locale['pp.continue.button'] }}
        </app-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import AppButton from '@/components/app-button'
import Modal from '@marcom/ac-modal'
import KeyMap from '@marcom/ac-keyboard'
import { focusOnSelector, buttonType, maskAccountNumber } from '@/utilities/utils'

export default {
  components: {
    AppButton
  },

  title() {
    return `${this.locale['pp.summary.account.title']}`
  },

  data() {
    return {
      maskAccountNumber
    }
  },

  computed: {
    ...mapState({
      currencyCode: (state) => state.productStore.currencyCode,
      activeTerms: (state) => state.mainStore.activeTerms,
      tcUrl: (state) => state.mainStore.tcUrl,
      accountType: (state) => state.mainStore.accountType,
      accountNumber: (state) => state.mainStore.accountNumber,
      country: (state) => state.mainStore.country,
      available_cc_types: (state) => state.mainStore.available_cc_types,
      amount: (state) => state.mainStore.amount,
      text_key: (state) => state.mainStore.text_key,
      transactionStatus: (state) => state.mainStore.transactionStatus,
      locale: (state) => state.mainStore.localization
    })
  },
  methods: {
    nextStep() {
      const { token, locale } = this.$route.params
      const route = `/${token}/${locale}/terms`
      this.$router.push(route)
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'ac-sasskit/core';
@import 'ac-sasskit/base';
@import '@/styles/variables.scss';

.product.section-content,
h1 {
  @include typography(headline-reduced);
  margin-top: $app-margin-lg;
  color: swatch(glyph-gray);
  &:focus {
    outline: 0;
  }
}

.product__description {
  @include typography(body);
  color: swatch(glyph-gray);
  margin-top: $app-margin-zero !important;
  padding-top: $app-margin-x-xs;
  font-weight: $app-font-weight-xr-lg;
  &:focus {
    outline: 0;
  }
}
.row.product__details-nrfee {
  border-bottom: 1px solid swatch(fill-gray-tertiary);
}
.product__details-nrfee {
  padding: $app-padding-sm 0px;

  @include typography(eyebrow);
  padding-bottom: $app-padding-xx-m;
  padding-top: $app-margin-xx-md;
}
.product__details-nrfeeprice {
  p {
    @include direction(text-align, right, text-align, left);
    padding-top: $app-margin-xx-md;
  }
}
.payment__acion__container {
  padding-top: $app-padding-lg;
}

.product__details-id {
  @include typography(body-reduced);
  padding-bottom: $app-padding-bottom-xs;
}
</style>
