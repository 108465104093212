import Vue from 'vue'
import Router from 'vue-router'

import PaymentForm from '@/features/payment/PaymentForm'
import PaymentProduct from '@/features/payment/PaymentProduct'
import PaymentTerms from '@/features/payment/PaymentTerms'
import PaymentReview from '@/features/payment/PaymentReview'
import RefundForm from '@/features/payment/RefundForm'
import ShippingInfo from '@/features/payment/ShippingInfo'
import PurchaseOrderInfo from '@/features/payment/PurchaseOrderInfo'
import Expired from '@/features/expired/Expired'
import AppleCareRefundExpired from '@/features/appleCarerRefundExpired/AppleCareRefundExpired'
import AppleIdMismatch from '@/features/AppleIdMismatch'
import Paid from '@/features/paid/Paid'
import Cancelled from '@/features/cancelled/Cancelled'
import Cancel from '@/features/cancel/Cancel'
import Error from '@/features/error/Error'
import NotFound from '@/features/notFound/NotFound'
import AuthError from '@/features/authError/AuthError'
import UnsupportedBrowser from '@/features/unsupportedBrowser/UnsupportedBrowser'
import Redirect from '@/features/redirect/Redirect'
import Upiredirect from '@/features/upiredirect/Upiredirect'
import EWalletRedirect from '@/features/ewalletredirect/EWalletRedirect'
import Overview from '@/features/Overview'
import Maintenance from '@/features/maintenance/Maintenance'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/:token/:locale',
      name: 'Overview',
      component: Overview,
      meta: {
        title: 'Service and Support Payment - Apple Support Payment'
      }
    },
    {
      path: '/:token/:locale/terms',
      name: 'PaymentTerms',
      component: PaymentTerms,
      scrollBehavior() {
        document.getElementById('app').scrollIntoView()
      },
      meta: {
        title: 'Terms and Conditions - Apple Support Payment'
      }
    },

    {
      path: '/:token/:locale/review',
      name: 'PaymentReview',
      component: PaymentReview,
      meta: {
        title: 'Review Order - Apple Support Payment'
      }
    },
    {
      path: '/:token/:locale/billing',
      name: 'PaymentForm',
      component: PaymentForm,
      meta: {
        title: 'Payment Details - Apple Support Payment'
      }
    },
    {
      path: '/:token/:locale/purchase-order',
      name: 'PurchaseOrderInfo',
      component: PurchaseOrderInfo,
      meta: {
        title: 'Purchase Order Information - Apple Support Payment'
      }
    },
    {
      path: '/:token/:locale/shipping',
      name: 'ShippingInfo',
      component: ShippingInfo,
      meta: {
        title: 'Shipping Details - Apple Support Payment'
      }
    },
    {
      path: '/:token/:locale/refund',
      name: 'RefundForm',
      component: RefundForm,
      meta: {
        title: 'Refund Details - Apple Support Payment'
      }
    },
    {
      path: '/:token/:locale/refund/cancelled',
      name: 'AppleCareRefundExpired',
      component: AppleCareRefundExpired
    },

    {
      path: '/:token/:locale/expired',
      name: 'Expired',
      component: Expired
    },
    {
      path: '/mismatch',
      name: 'AppleIdMismatch',
      component: AppleIdMismatch
    },
    {
      path: '/:token/:locale/completed',
      name: 'Paid',
      component: Paid
    },
    {
      path: '/:token/:locale/success',
      name: 'PaymentProduct',
      component: PaymentProduct
    },
    {
      path: '/:token/:locale/cancelled',
      name: 'Cancelled',
      component: Cancelled
    },
    {
      path: '/:token/:locale/error',
      name: 'Error',
      component: Error
    },
    {
      path: '/:token/:locale/notfound',
      name: 'NotFound',
      component: NotFound
    },
    {
      path: '/:token/:locale/autherror',
      name: 'AuthError',
      component: AuthError
    },
    {
      path: '/:token/:locale/unsupportedBrowser',
      name: 'UnsupportedBrowser',
      component: UnsupportedBrowser
    },
    {
      path: '/:token/:locale/redirect',
      name: 'Redirect',
      component: Redirect
    },
    {
      path: '/:token/:locale/upiRedirect',
      name: 'Upiredirect',
      component: Upiredirect
    },
    {
      path: '/:token/:locale/e-redirect',
      name: 'EWalletRedirect',
      component: EWalletRedirect
    },
    {
      path: '/:token/:locale/maintenance',
      name: 'Maintenance',
      component: Maintenance
    },
    {
      path: '/:token/:locale/cancel',
      name: 'Cancel',
      component: Cancel
    },
    {
      path: '/mismatch',
      name: 'AppleIdMismatch',
      component: AppleIdMismatch
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})
