import { setCustomerInformationInState } from '@/features/payment/utilities/billingAddress'
import { filterCreditCards } from '@/features/payment/utilities/matchCreditCard'
import router from '@/router'
import service from '@/service/service'
import {
  COLLECT_RETURN_ADDRESS,
  EWALLETS,
  MULTIBYTE_COUNTRIES,
  TAX_FIELDS
} from '@/utilities/constants'
import redirect from '@/utilities/redirects'
import { E_WALLET_CONFIGS } from '../utilities/constants'
export const initialState = {
  status: {
    isFetching: true,
    isFetched: false,
    error: false,
    countrydata: false,
    countryfielddata: false
  },
  transaction_locale: '',
  termsLocale: '',
  localization: {},
  details: {},
  states: {},
  countrycode: '',
  countrySettings: [],
  countryFields: [],
  isCountrySettings: false,
  isCountryFields: '',
  isRtl: false,
  isUPIPaymentpend: false,
  paymentMethod: '',
  isUnderReview: false,
  isPaymentComplete: false,
  selectedCountry: 'Country',
  tokenValue: '',
  iswebpayEligibleCountry: '',
  localeCountry: '',
  isStateListAvail: false,
  isStateFetched: false,
  selectedCountryText: '',
  customerEmail: '',
  amount: '',
  accountType: '',
  accountNumber: '',
  country: '',
  available_cc_types: [],
  currencyCode: '',
  text_key: '',
  tcUrl: '',
  activeTerms: false,
  agreementDateOfPurchase: '',
  transactionStatus: '',
  refundType: '',
  productName: '',
  refundId: '',
  productFamily: '',
  first_name: '',
  firstName: '',
  transactionFlow: '',
  repairQuantity: null,
  attachmentLink: null,
  ltsaNumber: null // Read-only number displayed to the user in purchase order flow
}

const state = JSON.parse(JSON.stringify(initialState))

const mutations = {
  SET_IS_FETCHING(state, payload) {
    state.status.isFetching = payload
  },

  GET_USER_REQUEST(state, payload) {
    state.status.isFetched = false
    state.status.isFetching = true
    state.status.error = false
  },
  GET_USER_SUCCESS(state, payload) {
    state.status.isFetched = true
    state.status.isFetching = false
    state.status.error = false
  },
  GET_USER_FAILURE(state, payload) {
    state.status.isFetched = true
    state.status.isFetching = false
    state.status.error = true
  },
  GET_LOCALES_REQUEST(state, payload) {
    state.localization = {}
  },
  GET_LOCALES_SUCCESS(state, payload) {
    state.localization = { ...payload }
  },
  GET_DETAILS_REQUEST(state, payload) {
    state.details = {}
  },
  GET_DETAILS_SUCCESS(state, payload) {
    state.details = { ...payload }
    state.countrycode = payload.country_3
    state.selectedCountry = payload.country_3
    state.transaction_locale = payload.transaction_locale
    state.termsLocale = payload.tcLocale
    state.amount = payload.amount
    state.accountType = payload.accountType
    state.accountNumber = payload.accountNumber
    state.country = payload.country
    state.available_cc_types = payload.available_cc_types
    state.currencyCode = payload.currencyCode
    state.first_name = payload.first_name
    state.tcUrl = payload.tcUrl
    state.activeTerms = payload.activeTerms
    state.agreementDateOfPurchase = payload.agreementDateOfPurchase
    state.transactionStatus = payload.transactionStatus
    state.refundId = payload.refundId
    state.productFamily = payload.productFamily
    state.refundType = payload.refundType
    state.paymentTypes = payload.paymentTypes
    // PO Payment flow
    state.transactionFlow = payload.transactionFlow
    state.repairQuantity = payload.repairQuantity
    state.attachmentLink = payload.attachmentLink
    state.subTotal = payload.subTotal

    state.selectedCountryText =
      state.localization[`webpay.country.${state.countrycode}`]
    if (
      payload.country_3 === 'IND' &&
      payload.quote.total_amount.replace(/\D/g, '') < 100000
    ) {
      state.details.supportUPI = true
    } else {
      state.details.supportUPI = false
    }

    if (payload.sapAvailable === false) {
      router.push({ name: 'Maintenance' })
    }
  },
  STATES_REQUEST(state, payload) {
    state.states = {}
    state.isStateListAvail = false
    state.isStateFetched = false
  },
  STATES_SUCCESS(state, payload) {
    state.states = { ...payload }
    if (payload.stateCodes.length > 0) {
      state.isStateListAvail = true
    } else {
      state.isStateListAvail = false
    }
    state.isStateFetched = true
  },
  STATES_FAILURE(state, payload) {
    state.isStateListAvail = false
    state.isStateFetched = true
  },
  COUNTRY_SETTINGS_REQUEST(state, payload) {
    state.status.countrydata = true
    state.countrySettings = []
    state.isCountrySettings = false
    state.iswebpayEligibleCountry = ''
  },
  COUNTRY_SETTINGS_SUCCESS(state, payload) {
    state.status.countrydata = false
    state.countrySettings = [...payload.addressFields]
    state.isCountrySettings = true
    state.iswebpayEligibleCountry = payload.webpayEligibleCountry
  },
  COUNTRY_SETTINGS_FAILURE(state) {
    state.status.countrydata = false
    state.countrySettings = []
    state.isCountrySettings = false
    state.iswebpayEligibleCountry = ''
  },
  COUNTRY_FIELDS_REQUEST(state, payload) {
    state.status.countryfielddata = true
    state.countryFields = []
    state.isCountryFields = ''
    state.status.countrydata = false
  },
  COUNTRY_FIELDS_SUCCESS(state, payload) {
    state.status.countryfielddata = false
    // payload.forEach(item => {
    //   let data = {}
    //   data.text = item.descr
    //   data.value = item.country
    //   state.countryFields.push(data)
    // });

    payload.forEach((item) => {
      let data = {}
      if (state.localization[`webpay.country.${item.country}`] !== undefined) {
        data.text = state.localization[`webpay.country.${item.country}`]
        data.value = item.country
        state.countryFields.push(data)
      }
    })
    state.isCountryFields = true
    state.status.countrydata = true
  },

  COUNTRY_FIELDS_FAILURE(state) {
    state.status.countryfielddata = false
    state.countryFields = []
    state.isCountryFields = false
    state.status.countrydata = false
  },
  SET_DIRECTION(state) {
    state.isRtl = true
  },
  UPI_PAY_PENDING(state) {
    state.isUPIPaymentpend = true
  },
  SET_PAYMENT_METHOD(state, payload) {
    state.paymentMethod = payload
    state.isUnderReview = true
  },
  SET_PAYMENT_COMPLETE(state, payload) {
    state.isPaymentComplete = true
    state.customerEmail = payload.emailAddress
    state.firstName = payload.firstName
  },
  SET_VAT_AND_TAX(state, payload) {
    const emeaCountries = [
      'at',
      'li',
      'no',
      'se',
      'ch',
      'ru',
      'tr',
      'de',
      'gb',
      'be',
      'dk',
      'fi',
      'fr',
      'ie',
      'it',
      'nl',
      'es',
      'lu'
    ]
    if (emeaCountries.includes(payload.country_2.toLowerCase())) {
      state.details.region = 'EMEA'
    }
  },
  SET_COUNTRY_SELECTED(state, payload) {
    state.selectedCountry = payload
    // state.selectedCountryText = payload
    // Code change for Iconfig label change
    state.selectedCountryText = state.localization[`webpay.country.${payload}`]
  },
  GET_TOKEN_VALUE(state, payload) {
    state.tokenValue = payload.token
  },
  UPDATE_SUMMARY_DETAILS(state, payload) {
    state.details = { ...state.details, ...payload }
    state.countrycode = payload.country_3
    state.selectedCountry = payload.country_3
    state.paymentTypes = payload.paymentTypes

    if (
      payload.country_3 === 'IND' &&
      payload.quote.total_amount.replace(/\D/g, '') < 100000
    ) {
      state.details.supportUPI = true
    } else {
      state.details.supportUPI = false
    }
  },
  SET_LTSA_NUMBER(state, payload) {
    state.ltsaNumber = payload
  }
}

const actions = {
  async init({ commit, dispatch, getters }, payload) {
    if (router.currentRoute.path === '/mismatch') {
      commit('SET_IS_FETCHING', false)
      return
    }

    commit('GET_USER_REQUEST')
    try {
      if (
        router.currentRoute.params.token &&
        router.currentRoute.params.locale
      ) {
        localStorage.setItem('token', router.currentRoute.params.token)
        localStorage.setItem('locale', router.currentRoute.params.locale)
      }

      if (payload.locale && payload.token) {
        commit('GET_TOKEN_VALUE', payload)
        await dispatch('getLocales', payload)
        if (
          (router.currentRoute.name !== 'Redirect' &&
            router.currentRoute.name !== 'Upiredirect' &&
            router.currentRoute.name !== 'EWalletRedirect') ||
          ((router.currentRoute.name === 'Upiredirect' ||
            router.currentRoute.name === 'PaymentProduct') &&
            state.isUPIPaymentpend)
        ) {
          await dispatch('getDetails', payload)

          if (state.details.transactionStatus !== 'ERROR') {
            state.localeCountry = state.details.country_3

            if (
              getters.eligibleForApplePay &&
              window.ApplePaySession &&
              window.ApplePaySession.canMakePayments() === true
            ) {
              dispatch('enableApplePay')
            }
          }

          if (state.details.transactionStatus === 'PEND') {
            const requestPayload = {}
            requestPayload.token = payload.token
            const data = {}
            data.country = state.selectedCountry
            data.languageCode = state.details.language_3
            data.locale = payload.locale
            requestPayload.data = data

            await dispatch('getStates', requestPayload)
          }

          dispatch('setDirection')
        }
        const lang = payload.locale.split('_')[1]
        commit('GET_USER_SUCCESS')
      } else {
        commit('GET_USER_FAILURE')
      }
    } catch (error) {
      console.error(error)
      commit('GET_USER_FAILURE')
    }
  },
  async getLocales({ commit }, payload) {
    commit('GET_LOCALES_REQUEST')

    try {
      const response = await service.fetchLocalization(payload.locale)

      commit('GET_LOCALES_SUCCESS', response.data)
    } catch (error) {
      // commit('GET_LOCALES_FAILURE')
    }
  },

  async getDetails({ commit }, payload) {
    commit('GET_DETAILS_REQUEST')

    try {
      let response = await service.fetchDetails(payload.locale, payload.token)
      if (response.data.type === 'RFND') {
        try {
          response = await service.fetchRefundDetails(payload.token)
          // TODO: Remove these console logs later (testing logging data in IT)
        } catch (error) {
          if (
            error.response.data.errorCode === 'APPLE_ID_MISMATCH_ERROR' &&
            error.response.status === 401
          ) {
            window.location.href = '/logout'
            return
          }
          if (error.response.status === 401 && !error.response.data.errorCode) {
            window.location.href = `/${payload.token}/${payload.locale}/authenticate`
          }
        }
      }

      if (response.data.refundType || response.data.accountType) {
        response.data.quote = {
          additionalAuth: '',
          currencyCode: '',
          total_amount: '',
          tax: '',
          grand_total: '',
          parts: ''
        }
        response.data.product = {
          productName: '',
          image_url: ''
        }
      }

      if (response.data.country_2) {
        response.data.region = [
          'at',
          'li',
          'no',
          'se',
          'ch',
          'ru',
          'tr',
          'de',
          'gb',
          'be',
          'dk',
          'fi',
          'fr',
          'ie',
          'it',
          'nl',
          'es',
          'lu'
        ].includes(response.data.country_2.toLowerCase())
          ? 'EMEA'
          : ''

        if (response.data.region !== '') {
          response.data.regionEU = [
            'at',
            'no',
            'se',
            'ch',
            'de',
            'gb',
            'be',
            'dk',
            'fi',
            'fr',
            'ie',
            'it',
            'nl',
            'es'
          ].includes(response.data.country_2.toLowerCase())
            ? 'EUCOUNTRY'
            : ''
        }
        if (response.data.region !== '') {
          response.data.regionIPT = [
            'at',
            'se',
            'ch',
            'de',
            'gb',
            'dk',
            'fi',
            'fr',
            'ie',
            'it',
            'nl',
            'es'
          ].includes(response.data.country_2.toLowerCase())
            ? 'IPTEUCOUNTRY'
            : ''
        }
        if (response.data.country_2) {
          response.data.multiByteCountries = MULTIBYTE_COUNTRIES.includes(
            response.data.country_2.toLowerCase()
          )
            ? 'MultibyteCountries'
            : null
        }
      }
      // redirect
      redirect(response.data)
      commit('GET_DETAILS_SUCCESS', response.data)
      if (
        response.data.transactionStatus.toUpperCase() === 'PEND' &&
        response.data.refundType === ''
      ) {
        filterCreditCards(response.data.available_cc_types)
      }

      /**
       * set shipping info for setting the input values for the form
       */
      setCustomerInformationInState(response.data, commit)
    } catch (error) {
      redirect(error.response.data)
    }
  },
  async getStates({ commit }, payload) {
    commit('STATES_REQUEST')

    const requestPayload = {}
    requestPayload.token = state.tokenValue
    requestPayload.data = {}
    requestPayload.data.country = state.selectedCountry
    requestPayload.data.languageCode = state.details.language_3
    requestPayload.data.locale = router.currentRoute.params.locale

    try {
      const response = await service.getStates(requestPayload)
      commit('STATES_SUCCESS', response.data)
    } catch (error) {
      redirect(error.response.data)
      commit('STATES_FAILURE')
    }
  },
  async getStateList({ commit }, payload) {
    commit('STATES_REQUEST')

    const requestPayload = {}
    requestPayload.token = state.tokenValue
    requestPayload.data = {}
    requestPayload.data.country = state.selectedCountry
    requestPayload.data.languageCode = state.details.language_3
    requestPayload.data.locale = router.currentRoute.params.locale

    try {
      const response = await service.getStates(requestPayload)
      commit('STATES_SUCCESS', response.data)
    } catch (error) {
      redirect(error.response.data)
      commit('STATES_FAILURE')
    }
  },
  getStateFailure({ commit }) {
    commit('STATES_FAILURE')
  },
  async getCountrySetting({ commit }, payload) {
    commit('COUNTRY_SETTINGS_REQUEST')
    try {
      const response = await service.getCountrySettings(payload)

      if (response.data !== undefined && response.data !== '') {
        commit('COUNTRY_SETTINGS_SUCCESS', response.data)
      } else {
        commit('COUNTRY_SETTINGS_FAILURE')
      }
    } catch (error) {
      redirect(error.response.data)
      commit('COUNTRY_SETTINGS_FAILURE')
    }
  },
  async getCountryLists({ commit }, payload) {
    commit('COUNTRY_FIELDS_REQUEST')
    try {
      const response = await service.getCountryLists()
      if (response.data !== undefined && response.data !== '') {
        commit('COUNTRY_FIELDS_SUCCESS', response.data)
      } else {
        commit('COUNTRY_FIELDS_FAILURE')
      }
    } catch (error) {
      redirect(error.response.data)
      commit('COUNTRY_FIELDS_FAILURE')
    }
  },
  setDirection({ commit }) {
    if (router.currentRoute.params.locale === 'ar_AE') {
      const html = document.querySelector('html')

      html.setAttribute('dir', 'rtl')
      commit('SET_DIRECTION')
    }
  },
  setPaymentMethod({ commit }, payload) {
    commit('SET_PAYMENT_METHOD', payload)
  },
  setCountrySelected({ commit }, payload) {
    commit('SET_COUNTRY_SELECTED', payload)
  },

  setIsFetching({ commit }, status) {
    commit('SET_IS_FETCHING', status)
  }
}

const getters = {
  availableEWalletsOptions: (state) => {
    const availablePaymentTypes = state.details.paymentTypes || []
    // attach config to the available ewallet options
    return availablePaymentTypes.reduce((prev, paymentType) => {
      if (E_WALLET_CONFIGS[paymentType]) {
        return [...prev, E_WALLET_CONFIGS[paymentType]]
      }
      return prev
    }, [])
  },

  paymentTypes: (state) => {
    return state.details.paymentTypes || []
  },

  eligibleForApplePay: (_state, getters) => {
    return getters.paymentTypes.includes('AP')
  },

  eligibleForCC: (_state, getters) => {
    return getters.paymentTypes.includes('CC')
  },

  /**
   * true when requiredBillingContactFields is an empty array
   */
  showBillingAddressFormForApplePay: (state) => {
    const applePayRequestObject = state.details.applepay_payment_request
    if (applePayRequestObject) {
      const requiredBillingContactFields =
        applePayRequestObject.requiredBillingContactFields

      return (
        Array.isArray(requiredBillingContactFields) &&
        requiredBillingContactFields.length === 0
      )
    }

    return false
  },

  isEWalletPaymentMethod: (state) => {
    const paymentMethod = state.paymentMethod

    return Object.values(EWALLETS).includes(paymentMethod)
  },

  isChile: (state) => {
    return state.countrycode === 'CHL'
  },

  isIndia: (state) => {
    return state.countrycode === 'IND'
  },

  languageCode: (state) => state.details.language_3,

  isRepair: (state) => {
    return state.details.type === 'DISP'
  },

  collectShippingInfo: (state, getters) => {
    /**
     * collecting shipping info for non business customers with Repair token
     * or for Purchase order flow
     */
    if (state.details.customerInfo) {
      return (
        (state.details.customerInfo.customerType !== 'BUS' &&
          getters.isRepair) ||
        getters.isPOFlow
      )
    }
    return false
  },

  collectReturnAddress: (state, getters) => {
    return (
      COLLECT_RETURN_ADDRESS.includes(state.details.sub_type) ||
      getters.isPOFlow
    )
  },

  collectTaxDetails: (state) => {
    return typeof TAX_FIELDS[state.countrycode] !== 'undefined'
  },

  hasMultipleTaxTypes: (state, getters) => {
    return getters.collectTaxDetails && TAX_FIELDS[state.countrycode].length > 1
  },
  hasInputFieldInConfig: (state) => (field) => {
    return (
      state.countrySettings.find(({ name }) => name === field) !== undefined
    )
  },
  isPOFlow: (state) => {
    return state.transactionFlow === 'PO'
  },
  hasMultipleRepairs: (state) => {
    return state.repairQuantity > 1
  }
}

const mainStore = {
  state,
  getters,
  mutations,
  actions
}

export default mainStore
