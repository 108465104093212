import service from '@/features/payment/service/service'
import billingAddress from '@/features/payment/utilities/billingAddress'
import { matchCreditCard } from '@/features/payment/utilities/matchCreditCard'
import router from '@/router'
import { TAX_FIELDS } from '@/utilities/constants'
import redirect from '@/utilities/redirects'

const addressFieldsInitialValues = {
  first_name: null,
  last_name: null,
  street1: null,
  street2: null,
  street3: null,
  suburb: null,
  postalcode: null,
  district: null,
  city: null,
  state: null
}

const SHIPPING_ADDRESS_TYPES = ['shipping', 'return', 'tax']

export const initialState = {
  status: {
    isFetching: false,
    isFetched: false,
    error: false,
    isLoading: false,
    lastZipLookup: null,
    otherOptionChosenForCity: false
  },
  createQuote: {
    status: {
      error: false,
      isSubmitted: false,
      isLoading: false
    }
  },
  errorDetails: {
    errorCode: null,
    errorDesc: null,
    errorField: null,
    errorMessage: null
  },
  errorMessages: {},
  fields: {
    card_number: null,
    cardNumberMasked: null,
    cvv: null,
    cvvMasked: false,
    expires: null,
    email: null,
    ...addressFieldsInitialValues
  },
  citiesStatus: {
    cities: [],
    loading: false
  },
  poInfo: {
    billingAddress: {
      status: {
        isFetching: false,
        isFetched: false,
        error: false,
        isLoading: false,
        lastZipLookup: null,
        otherOptionChosenForCity: false
      },
      errorDetails: {
        errorCode: null,
        errorDesc: null,
        errorField: null,
        errorMessage: null
      },
      fields: {
        orgName: null,
        ...addressFieldsInitialValues
      },
      errorMessages: {},
      citiesStatus: {
        cities: [],
        loading: false
      },
      districtsStatus: {
        districts: [],
        loading: false
      }
    },
    poFields: {
      errorDetails: {
        errorCode: null,
        errorDesc: null,
        errorField: null,
        errorMessage: null
      },
      errorMessages: {},
      fields: {
        poNumber: null,
        poNumber_confirmation: null,
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        ltsa: null
      },
      status: {
        isFetching: false,
        isFetched: false,
        error: false,
        isLoading: false,
        lastZipLookup: null,
        otherOptionChosenForCity: false
      }
    }
  },
  shippingInfo: {
    email: null,
    phoneNumber: null,
    errorCode: null,
    errorMessageKey: null,
    shippingAddress: {
      status: {
        isFetching: false,
        isFetched: false,
        error: false,
        isLoading: false,
        lastZipLookup: null,
        otherOptionChosenForCity: false
      },
      errorDetails: {
        errorCode: null,
        errorDesc: null,
        errorField: null,
        errorMessage: null
      },
      fields: {
        orgName: null,
        ...addressFieldsInitialValues
      },
      errorMessages: {},
      citiesStatus: {
        cities: [],
        loading: false
      },
      districtsStatus: {
        districts: [],
        loading: false
      }
    },
    returnAddress: {
      status: {
        isFetching: false,
        isFetched: false,
        error: false,
        isLoading: false,
        lastZipLookup: null,
        otherOptionChosenForCity: false
      },
      errorDetails: {
        errorCode: null,
        errorDesc: null,
        errorField: null,
        errorMessage: null
      },
      errorMessages: {},
      fields: {
        orgName: null,
        ...addressFieldsInitialValues
      },
      citiesStatus: {
        cities: [],
        loading: false
      },
      districtsStatus: {
        districts: [],
        loading: false
      }
    },
    taxAddress: {
      status: {
        isFetching: false,
        isFetched: false,
        error: false,
        isLoading: false,
        lastZipLookup: null,
        otherOptionChosenForCity: false
      },
      errorDetails: {
        errorCode: null,
        errorDesc: null,
        errorField: null,
        errorMessage: null
      },
      errorMessages: {},
      fields: {
        ...addressFieldsInitialValues
      },
      citiesStatus: {
        cities: [],
        loading: false
      },
      districtsStatus: {
        districts: [],
        loading: false
      }
    }
  },
  taxDetails: {
    type: null,
    fields: {}
  },
  billingAddressSameAsShipping: true,
  taxAddressSameAsShipping: true,
  returnAddressSameAsShipping: true,
  paymentDetails: {},
  upipaymentDetails: {},
  upipaymentError: false,
  cardDetails: {},
  cardId: null,
  cardType: null,
  applePayLastFour: null,
  cvvRetry: 0,
  cardNotSupported: false,
  isDebitcard: false,
  deviceProfileURL: '',
  deviceProfilePayload: '',
  enablePayment: false,
  deviceProfilingStatus: {},
  deviceProfileExpiryMonth: '',
  deviceProfileExpiryYear: '',
  deviceProfileCvv: '',
  deviceProfilingError: false,
  billingAddress: {},
  isCancelled: false,
  isRefundVerified: false,
  clientTransactionId: '',
  zipCodeLookup: false,
  is3dsRequired: false,
  eWalletRedirectionURL: '',
  districtsStatus: {
    districts: [],
    loading: false
  },
  deviceProfileLoading: false
}

const errorMapping = {
  TRANSACTION_ERROR_PAYMENT_CC_HOLDER_FNAME: 'first_name',
  TRANSACTION_ERROR_PAYMENT_CC_HOLDER_LNAME: 'last_name',
  TRANSACTION_ERROR_PAYMENT_ADDRESS_LINE1: 'street1',
  TRANSACTION_ERROR_PAYMENT_ADDRESS_LINE1_LENGTH: 'street1',
  TRANSACTION_ERROR_PAYMENT_ADDRESS_LINE2_LENGTH: 'street2',
  TRANSACTION_ERROR_PAYMENT_ZIPCODE: 'postalcode',
  TRANSACTION_ERROR_PAYMENT_ADDRESS_ZIPCODE_LENGTH: 'postalcode',
  TRANSACTION_ERROR_PAYMENT_ADDRESS_CITY_LENGTH: 'city',
  TRANSACTION_ERROR_PAYMENT_ADDRESS_STATE: 'state',
  TRANSACTION_ERROR_PAYMENT_ADDRESS_STATE_LENGTH: 'state',
  TRANSACTION_ERROR_PAYMENT_AUTHORIZARION_CALL: 'card_error',
  TRANSACTION_ERROR_PAYMENT_CC_TYPE: 'card_error',
  TRANSACTION_ERROR_PAYMENT_CC_NUMBER: 'card_error',
  TRANSACTION_ERROR_PAYMENT_CC_EXPIRY_MONTH: 'expires',
  TRANSACTION_ERROR_PAYMENT_CC_EXPIRY_YEAR: 'expires',
  TRANSACTION_ERROR_PAYMENT_CREDIT_CARD_EXPIRED: 'expires',
  TRANSACTION_ERROR_PAYMENT_SEC_CODE: 'card_error',
  TRANSACTION_ERROR_PAYMENT_INVALID_CVV_FORMAT1: 'card_error',
  TRANSACTION_ERROR_CROSS_BORDER_VERIFICATION_FAILED: 'card_error',
  TRANSACTION_ERROR_PAYMENT_REFERRAL: 'card_error',
  TRANSACTION_ERROR_PAYMENT_INVALID_CREDIT_CARD: 'card_error',
  PAYMENT_AUTHORIZATION_FAILED_ERROR: 'card_error',
  TRANSACTION_ERROR_PAYMENT_CVV_VALIDATION: 'card_error',
  TRANSACTION_ERROR_PAYMENT_ZIPCODE_STATE: 'postalcode',
  ADDRESS_INVALID_POSTAL_CODE_FORMAT: 'postalcode',
  INVALID_POSTAL_CODE_FORMAT: 'postalcode',
  INCORRECT_CVV_ERROR: 'card_error',
  INCORRECT_CARD_NUMBER_ERROR: 'card_error',
  DECLINED_CARD_TYPE_NOT_SUPPORTED: 'card_error',
  CARD_TYPE_NOT_SUPPORTED_ERROR: 'card_error',
  INVALID_CREDIT_CARD_ERROR: 'card_error',
  PAYMENT_FAILED_REVERSAL_INITIATED_ERROR: 'card_error',
  INVALID_SUBURB_ERROR: 'suburb'
}

const errorCodeI18nKeyMapping = {
  RESTRICTED_PO_ADDRESS_ERROR:
    'webpay.shippingInfo.generalErrors.restricted_address_PO',
  RESTRICTED_ADDRESS_ERROR:
    'webpay.shippingInfo.generalErrors.restricted_address',
  DEFAULT: 'pp.message.error'
}

const state = JSON.parse(JSON.stringify(initialState))

const mutations = {
  CHANGE_TAX_TYPE(state, payload) {
    state.taxDetails = { ...initialState.taxDetails, type: payload }
  },
  INITIATE_TAX_FIELDS(state, fields) {
    state.taxDetails.fields = fields
  },
  FORM_FIELD_CHANGE(state, payload) {
    if (
      payload.name === 'city' &&
      payload.value === 'Other' &&
      payload.eventType === 'change'
    ) {
      /**
       * user has chosen 'Other' option. Reset the field value and allow text input
       */
      state.status.otherOptionChosenForCity = true
      state.fields[payload.name] = addressFieldsInitialValues.city
    } else {
      state.fields[payload.name] = payload.value
    }

    if (payload.name === 'card_number') {
      state.isDebitcard = false
      const creditCardList = matchCreditCard(payload.value)
      state.cardDetails = {}
      if (creditCardList && creditCardList.length === 1) {
        state.cardType = creditCardList[0].companyShortname
        state.cardId = creditCardList[0].id
        state.cardDetails = creditCardList[0]
      } else {
        state.cardType = null
        state.cardId = null
      }
    }
    if (state.status.isFetched) {
      Object.assign(state.status, initialState.status)
    }
  },
  SET_PO_BILLING_ADDRESS(state, payload) {
    state.poInfo.billingAddress.fields = {
      ...state.poInfo.billingAddress.fields,
      ...payload
    }
  },
  SET_SHIPPING_ADDRESS(state, payload) {
    state.shippingInfo.shippingAddress.fields = {
      ...state.shippingInfo.shippingAddress.fields,
      ...payload
    }
  },
  SET_RETURN_ADDRESS(state, payload) {
    state.shippingInfo.returnAddress.fields = {
      ...state.shippingInfo.returnAddress.fields,
      ...payload
    }
  },
  SET_CUSTOMER_EMAIL(state, payload) {
    state.shippingInfo.email = payload
    state.poInfo.poFields.fields.email = payload
  },
  SET_CUSTOMER_PHONE(state, payload) {
    state.shippingInfo.phoneNumber = payload
    state.poInfo.poFields.fields.phone = payload
  },
  SET_CUSTOMER_NAME(state, payload) {
    state.poInfo.poFields.fields.first_name = payload.firstName
    state.poInfo.poFields.fields.last_name = payload.lastName
  },
  PO_FORM_FIELD_CHANGE(state, payload) {
    const stateKey = state.poInfo[`${payload.type}`]

    stateKey.fields = { ...stateKey.fields, [payload.name]: payload.value }
  },
  SET_LTSA_NUMBER(state, payload) {
    state.poInfo.poFields.fields.ltsa = payload
  },
  SHIPPING_FORM_FIELD_CHANGE(state, payload) {
    let stateKey
    if (SHIPPING_ADDRESS_TYPES.includes(payload.type)) {
      stateKey = state.shippingInfo[`${payload.type}Address`]
    }
    if (['email', 'phoneNumber'].includes(payload.name)) {
      state.shippingInfo[payload.name] = payload.value
    } else {
      if (
        payload.name === 'city' &&
        payload.value === 'Other' &&
        payload.eventType === 'change'
      ) {
        /**
         * user has chosen 'Other' option. Reset the field value and allow text input
         */
        stateKey.status.otherOptionChosenForCity = true
        stateKey.fields = {
          ...stateKey.fields,
          [payload.name]: addressFieldsInitialValues.city
        }
      } else {
        stateKey.fields = { ...stateKey.fields, [payload.name]: payload.value }
      }
    }
    if (stateKey.status.isFetched) {
      Object.assign(stateKey.status, initialState.stateKey.status)
    }
  },
  TAX_FORM_FIELD_CHANGE(state, { name, value }) {
    state.taxDetails.fields = { ...state.taxDetails.fields, [name]: value }
  },
  PAYMENT_REQUEST(state, payload) {
    const { status } = state
    status.isFetching = true
    status.isFetched = false
    status.error = false

    state.paymentDetails = {}
  },
  SET_ZIP_CODE_LOOKUP(state, payload) {
    state.zipCodeLookup = state.fields.postalcode
  },
  DEVICE_PROFILE_AND_3DS_CHECK_REQUEST(state, payload) {
    state.status.isFetching = true
    state.is3dsRequired = false
  },
  CHECK_3DS_NOT_REQUIRED(state, payload) {
    state.status.isFetching = false
    state.is3dsRequired = false
  },
  PAYMENT_SUCCESS(state, payload) {
    const { status } = state
    status.isFetching = false
    status.isFetched = true
  },
  PAYMENT_FAILURE(state, payload) {
    const { status } = state
    status.isFetching = false
    status.isFetched = true
    status.error = true
  },

  PAYMENT_ACS_PROCESSING(state, payload) {
    state.paymentDetails = payload
  },
  E_WALLET_PAYMENT_REQUEST(state, payload) {
    state.eWalletRedirectionURL = payload
  },
  UPI_PAYMENT_REQUEST(state, payload) {
    state.upipaymentDetails = {}
    state.status.isFetching = true
    state.status.isLoading = true
  },
  UPI_PAYMENT_REDIRECTED(state, payload) {
    state.status.isFetching = false
    state.status.isLoading = false
  },
  UPI_PAYMENT_SUCCESS(state, payload) {
    state.upipaymentDetails = payload
    state.status.isLoading = false
  },
  UPI_PAYMENT_FAILURE(state, payload) {
    state.status.isFetching = false
    state.status.isLoading = false
    state.upipaymentError = true
  },
  PAYMENT_ACS_RESET(state, payload) {
    const { status } = state
    status.isFetching = false
    status.isFetched = false
    status.error = false
  },
  PROCESS_APPLEPAY_PAYMENT_REQUEST(state, payload) {
    const { status } = state
    status.isFetching = true
    status.isFetched = false
    status.error = false
    state.cardType = null
    state.applePayLastFour = null
  },
  PROCESS_APPLEPAY_PAYMENT_SUCCESS(state, payload) {
    const { status } = state
    status.isFetching = false
    status.isFetched = true
    state.cardType = 'applepay'
    state.applePayLastFour = payload.applePayLastFour
  },
  FETCH_CITY_REQUEST(state) {
    const { status, errorDetails } = state
    status.isFetched = false
    status.isFetching = false
    status.error = false
    errorDetails.errorDesc = null
    errorDetails.errorCode = null
    errorDetails.errorField = null
  },
  FETCH_CITY_SUCCESS(state, payload) {
    let addressForm
    let addressType = `${payload.type}Address`
    if (SHIPPING_ADDRESS_TYPES.includes(payload.type)) {
      /**
       * shipping/return address form
       */
      addressForm = state.shippingInfo[addressType]
    } else {
      /**
       * billing address form
       */
      addressForm = state
    }
    if (
      payload.stateCode !== '[]' &&
      payload.stateCode !== '' &&
      payload.stateCodeValid === true
    ) {
      addressForm.fields.state = payload.stateCode
    } else if (payload.stateCodeValid === false) {
      if (!this.getters.hasInputFieldInConfig('state')) {
        /**
         * reset state only if the state field doesn't exist in config (which could have beeen entered in Core that causes issues when sent to our backend)
         */
        addressForm.fields.state = addressFieldsInitialValues.state
      }
    }

    if (payload.cities) {
      addressForm.citiesStatus.cities = payload.cities
      // when selected city value doesn't exist in the response
      if (!payload.cities.includes(addressForm.fields.city)) {
        addressForm.fields.city = addressFieldsInitialValues.city
        // auto select 1st value when only one city exists in the response
        if (payload.cities.length === 1) {
          addressForm.fields.city = payload.cities[0]
        }
      }
    } else {
      if (addressForm.citiesStatus.cities.length > 0) {
        /**
         * if city was previously a select option, reset the city value (UI renders select option when citiesStatus.cities.length > 0)
         */
        addressForm.fields.city = addressFieldsInitialValues.city
      }
      addressForm.citiesStatus.cities = []
    }

    addressForm.status.otherOptionChosenForCity = false // resetting after ziplookup to show the dropdown list
    addressForm.status.lastZipLookup = payload.zipCode

    addressForm.errorDetails.errorMessage = null
  },
  FETCH_CITY_FAILURE(state, payload) {
    let addressForm
    let addressType = `${payload.type}Address`
    if (SHIPPING_ADDRESS_TYPES.includes(payload.type)) {
      addressForm = state.shippingInfo[addressType]
    } else {
      addressForm = state // billing address form
    }

    addressForm.status.error = true
    addressForm.errorDetails.errorDesc =
      payload.response.data.errorDescription || null
    addressForm.errorDetails.errorCode = payload.response.data.errorCode || null
    addressForm.errorDetails.errorField = payload.response.data.field || null
  },
  FETCH_DISTRICTS_REQUEST(state, payload = {}) {
    let addressForm
    let addressType = `${payload.type}Address`
    if (SHIPPING_ADDRESS_TYPES.includes(payload.type)) {
      addressForm = state.shippingInfo[addressType]
    } else {
      addressForm = state // billing address form
    }
    addressForm.districtsStatus.loading = true
  },
  FETCH_DEVICE_PROFILE_AND_3DS_CHECK_SUCCESS(state, payload) {
    state.is3dsRequired = true
    state.deviceProfilePayload =
      payload.deviceProfileResponse.deviceProfilePayload
    state.deviceProfileURL = payload.deviceProfileResponse.deviceProfileUrl
    state.status.isFetching = false
  },
  FETCH_DEVICE_PROFILE_AND_3DS_CHECK_FAILURE(state, payload = {}) {
    const { status } = state
    status.isFetching = false
    status.isFetched = true
    status.error = true
  },
  FETCH_DISTRICTS_SUCCESS(state, payload = {}) {
    let addressForm
    let addressType = `${payload.type}Address`
    if (SHIPPING_ADDRESS_TYPES.includes(payload.type)) {
      addressForm = state.shippingInfo[addressType]
    } else {
      addressForm = state // billing address form
    }

    addressForm.districtsStatus.loading = false
    addressForm.districtsStatus.districts = payload.districts
  },
  FETCH_DISTRICTS_FAILURE(state, payload = {}) {
    let addressForm
    let addressType = `${payload.type}Address`
    if (SHIPPING_ADDRESS_TYPES.includes(payload.type)) {
      addressForm = state.shippingInfo[addressType]
    } else {
      addressForm = state // billing address form
    }

    addressForm.districtsStatus.loading = false
  },
  UPDATE_ERROR_MESSAGE(state, payload) {
    state.errorDetails.errorMessage = payload
  },
  ADJUST_ERROR_DETAILS(state, payload) {
    state.errorDetails.errorCode = payload.errorCode
    state.errorDetails.errorDesc = payload.errorDescription
    state.errorDetails.errorField = payload.errorField
  },
  RESET_ERROR_DETAILS(state) {
    const { status } = state
    state.status.error = false
    state.errorDetails.errorCode = null
    state.errorDetails.errorDesc = null
    state.errorDetails.errorField = null
    state.errorDetails.errorMessage = null
    state.deviceProfilingError = initialState.deviceProfilingError
  },
  RESET_SHIPPING_ERROR_DETAILS(state, { type }) {
    const addressForm = state.shippingInfo[`${type}Address`]
    addressForm.status.error = false
    addressForm.errorDetails.errorCode = null
    addressForm.errorDetails.errorDesc = null
    addressForm.errorDetails.errorField = null
    addressForm.errorDetails.errorMessage = null
  },
  UPDATE_ERROR_DETAILS(state, payload) {
    state.status.error = true
    state.errorDetails.errorCode = payload.errorCode
    state.errorDetails.errorDesc = payload.errorDescription
    state.errorDetails.errorField = errorMapping[payload.errorCode]
    state.status.isFetching = false
  },
  CVV_RETRY_INCREASE(state) {
    state.cvvRetry++
  },
  CVV_RETRY_RESET(state) {
    state.cvvRetry = 0
    Object.assign(state.fields, initialState.fields)
  },
  CANCEL_TRANSACTION_REQUEST(state) {
    const { status } = state
    status.isFetching = true
    status.isFetched = false
    status.error = false
    state.isCancelled = false
  },
  CANCEL_TRANSACTION_SUCCESS(state) {
    const { status } = state
    status.isFetching = false
    status.isFetched = true
    status.error = true
    state.isCancelled = true
  },
  CANCEL_TRANSACTION_FAILURE(state) {
    const { status } = state
    status.isFetching = false
    status.isFetched = true
    status.error = true
  },
  DEFAULT_FIELD_VALUE(state) {
    Object.keys(state.fields).forEach((key) => {
      state.fields[key] = null
    })
    state.cardType = null
  },
  DEFAULT_FIELD_BILLING(state) {
    Object.keys(state.fields).forEach((key) => {
      if (key !== 'card_number' && key !== 'cvv' && key !== 'expires') {
        state.fields[key] = null
      }
    })
    state.cardType = null
  },
  CARD_NOT_SUPPORTED(state, payload) {
    state.cardNotSupported = payload
  },

  CARD_TYPE_SUCCESS(state, payload) {
    state.isDebitcard = payload.isDebit
  },
  CARD_TYPE_FAILURE(state, payload) {
    state.isDebitcard = false
  },
  CARD_NUMBER_RESET(state, payload) {
    state.fields.card_number = null
    state.fields.cvv = null
    state.fields.expires = null
  },
  DEVICE_PROFILE_FAILURE(state) {
    state.deviceProfileLoading = false
    state.deviceProfilingError = true
    state.deviceProfilingStatus.status = false
    /**
     * Assign deviceProfilingStatus.deviceProfilingReferenceId to the original value of the deviceProfilingReferenceId in the check3DSAndDeviceProfile response.
     * During the scenario of a failure we still send the deviceProfilingReferenceId and false status as part of the payment payload.
     */
    state.deviceProfilingStatus.deviceProfilingReferenceId =
      state.deviceProfilePayload.deviceProfilingReferenceId
  },
  DEVICE_PROFILE_LOADING(state) {
    state.deviceProfileLoading = true
  },
  RESET_FIELD(state, { field, type }) {
    if (SHIPPING_ADDRESS_TYPES.includes(type)) {
      let addressType = `${type}Address`
      /**
       * shipping/return address form
       */

      state.shippingInfo[addressType].fields[field] =
        initialState.shippingInfo[addressType].fields[field]
    } else {
      /**
       * billing address form
       */
      state.fields[field] = initialState.fields[field]
    }
  },
  RESET_BILLING_ADDRESS_FIELDS(state) {
    /**
     * reset only address fields
     */
    state.fields = { ...state.fields, ...addressFieldsInitialValues }
  },
  SET_BILLING_ADDRESS_FROM_SHIPPING_ADDRESS(state) {
    state.fields = {
      ...state.fields,
      ...state.shippingInfo.shippingAddress.fields,
      orgName: null // orgName is only for shipping and return addresses
    }
  },
  SET_BILLING_ADDRESS_FROM_PO_INFO(state) {
    state.billingAddress.firstname = state.poInfo.poFields.fields.first_name
    state.billingAddress.lastname = `${state.poInfo.poFields.fields.first_name} ${state.poInfo.poFields.fields.last_name}`
    state.billingAddress.name = `${state.poInfo.poFields.fields.last_name} ${state.poInfo.poFields.fields.first_name}`
    state.billingAddress.street1 = state.poInfo.billingAddress.fields.street1
    state.billingAddress.street2 = state.poInfo.billingAddress.fields.street2
    state.billingAddress.street3 = state.poInfo.billingAddress.fields.street3
    state.billingAddress.city = state.poInfo.billingAddress.fields.city
    state.billingAddress.state = state.poInfo.billingAddress.fields.state
    state.billingAddress.postalcode =
      state.poInfo.billingAddress.fields.postalcode
    state.billingAddress.district = state.poInfo.billingAddress.fields.district
    state.billingAddress.suburb = state.poInfo.billingAddress.fields.suburb
  },
  SET_RETURN_ADDRESS_FROM_SHIPPING_ADDRESS(state) {
    state.shippingInfo.returnAddress.fields = {
      ...state.shippingInfo.shippingAddress.fields
    }
  },
  TOGGLE_BILLING_ADDRESS_SAME_AS_SHIPPING(state, billingAddressSameAsShipping) {
    state.billingAddressSameAsShipping = billingAddressSameAsShipping
    state.status.lastZipLookup = null // reset last lookup as the form fields will be reset
  },
  TOGGLE_TAX_ADDRESS_SAME_AS_SHIPPING(state, taxAddressSameAsShipping) {
    state.taxAddressSameAsShipping = taxAddressSameAsShipping
  },
  TOGGLE_RETURN_ADDRESS_SAME_AS_SHIPPING(state, returnAddressSameAsShipping) {
    state.returnAddressSameAsShipping = returnAddressSameAsShipping
  },
  DEVICE_PROFILE_STATUS(state, payload) {
    state.deviceProfilingStatus.status = payload.Status
    state.deviceProfilingStatus.deviceProfilingReferenceID = payload.SessionId
    state.status.isFetching = false
    state.deviceProfileLoading = false
  },
  ENABLE_PAYMENT(state, payload) {
    state.enablePayment = false
  },
  DISABLE_PAYMENT(state, payload) {
    state.enablePayment = true
  },
  RESET_UPIPAY_STATUS(state, payload) {
    state.upipaymentError = false
  },
  SET_BILLING_ADDRESS_FIRSTNAME_LASTNAME(state, payload) {
    state.billingAddress.firstname = payload.givenName
    state.billingAddress.lastname = payload.familyName
  },
  SET_BILLING_ADDRESS(state) {
    state.billingAddress.firstname = state.fields.first_name
    state.billingAddress.lastname = `${state.fields.first_name} ${state.fields.last_name}`
    state.billingAddress.name = `${state.fields.last_name} ${state.fields.first_name}`
    state.billingAddress.street1 = state.fields.street1
    state.billingAddress.street2 = state.fields.street2
    state.billingAddress.street3 = state.fields.street3
    state.billingAddress.city = state.fields.city
    state.billingAddress.state = state.fields.state
    state.billingAddress.postalcode = state.fields.postalcode
    state.billingAddress.district = state.fields.district
    state.billingAddress.suburb = state.fields.suburb
  },
  CREATE_QUOTE_LOADING(state) {
    state.createQuote.status.isLoading = true
  },
  CREATE_QUOTE_SUCCESS(state) {
    state.createQuote.status.isLoading = false
    state.createQuote.status.isSubmitted = true
  },
  CREATE_QUOTE_ERROR(state, payload) {
    state.createQuote.status.isLoading = false
    state.createQuote.status.isSubmitted = false
    state.createQuote.status.error = payload

    const errorCode = payload.errorCode

    if (errorCode) {
      if (errorCodeI18nKeyMapping[errorCode]) {
        state.shippingInfo.errorMessageKey = errorCodeI18nKeyMapping[errorCode]
      } else if (errorCode.includes('RESTRICTED_ADDRESS_ERROR')) {
        state.shippingInfo.errorMessageKey =
          errorCodeI18nKeyMapping.RESTRICTED_ADDRESS_ERROR
      } else {
        state.shippingInfo.errorMessageKey = errorCodeI18nKeyMapping.DEFAULT
      }
      state.shippingInfo.errorCode = errorCode
    }
  },
  RESET_QUOTE_ERROR(state, payload) {
    state.shippingInfo.errorMessageKey = null
    state.shippingInfo.errorCode = null
  },
  SET_ERROR_MESSAGE_FOR_FIELD(state, payload) {
    const { type, field, message } = payload
    let errorMessages = state.errorMessages

    if (SHIPPING_ADDRESS_TYPES.includes(type)) {
      /**
       * shipping/return/tax address form
       */
      let addressType = `${type}Address`
      errorMessages = state.shippingInfo[addressType].errorMessages
    }
    errorMessages[field] = message
  },
  UNSET_ERROR_MESSAGE_FOR_FIELD(state, payload) {
    /**
     * when the error message matches the passed message for a given field, that particular error message is unset from the state
     */
    const { type, field, message } = payload
    let errorMessages = state.errorMessages

    if (SHIPPING_ADDRESS_TYPES.includes(type)) {
      /**
       * shipping/return/tax address form
       */
      let addressType = `${type}Address`
      errorMessages = state.shippingInfo[addressType].errorMessages
    }

    if (message === errorMessages[field]) {
      errorMessages[field] = undefined
    }
  },
  SET_ERROR_MESSAGE_FOR_PO_FIELD(state, payload) {
    const { type, field, message } = payload
    let errorMessages = state.errorMessages

    errorMessages = state.poInfo[type].errorMessages
    errorMessages[field] = message
  },
  UNSET_ERROR_MESSAGE_FOR_PO_FIELD(state, payload) {
    /**
     * when the error message matches the passed message for a given field, that particular error message is unset from the state
     */
    const { type, field, message } = payload
    let errorMessages = state.errorMessages

    errorMessages = state.poInfo[type].errorMessages

    if (message === errorMessages[field]) {
      errorMessages[field] = undefined
    }
  }
}

const actions = {
  formFieldChange({ commit }, payload) {
    commit('FORM_FIELD_CHANGE', payload)
    const isCardField = ['card_number', 'expires', 'cvv'].includes(payload.name)
    const currentFieldMatchesErrorField =
      payload.name === state.errorDetails.errorField ||
      (state.errorDetails.errorField === 'card_error' && isCardField)

    if (
      currentFieldMatchesErrorField ||
      typeof state.errorDetails.errorField === 'undefined'
    ) {
      commit('RESET_ERROR_DETAILS')
    }
  },
  poFormFieldChange({ commit }, payload) {
    commit('PO_FORM_FIELD_CHANGE', payload)
  },
  shippingFormFieldChange({ commit }, payload) {
    commit('SHIPPING_FORM_FIELD_CHANGE', payload)
    const currentFieldMatchesErrorField =
      payload.name ===
      state.shippingInfo[`${payload.type}Address`].errorDetails.errorField

    if (
      currentFieldMatchesErrorField ||
      typeof state.shippingInfo[`${payload.type}Address`].errorDetails
        .errorField === 'undefined'
    ) {
      commit('RESET_SHIPPING_ERROR_DETAILS', { type: payload.type })
    }
  },
  taxFormFieldChange({ commit }, change) {
    commit('TAX_FORM_FIELD_CHANGE', change)
  },

  async payment({ commit, dispatch, rootState, getters }, payload) {
    commit('PAYMENT_REQUEST')
    const countrysettings = rootState.mainStore.countrySettings
    let noState = true
    countrysettings.forEach((item) => {
      if (item.name === 'state') {
        noState = false
      }
    })
    const currentPath = `/${router.currentRoute.params.token}/${router.currentRoute.params.locale}`
    const { fields, poInfo, shippingInfo } = state

    try {
      let response
      if (getters.isPOFlow) {
        /**
         * Purchase Order Flow
         */
        const poPayload = {
          poNumber: poInfo.poFields.fields.poNumber,
          firstName: poInfo.poFields.fields.first_name,
          lastName: poInfo.poFields.fields.last_name,
          email: poInfo.poFields.fields.email,
          phone: (poInfo.poFields.fields.phone || '').match(/\d/g).join(''), // Remove phone number mask as send only the numeric characters
          shippingAddress: {
            firstName: shippingInfo.shippingAddress.fields.first_name,
            lastName: shippingInfo.shippingAddress.fields.last_name
          }
        }

        if (getters.collectReturnAddress) {
          poPayload.returnAddress = {
            firstName: shippingInfo.returnAddress.fields.first_name,
            lastName: shippingInfo.returnAddress.fields.last_name
          }
        }
        response = await service.purchaseOrderPayment(
          payload.transactionId,
          poPayload
        )
      } else {
        /**
         * Non Purchase order (cc, Apple pay & other payment methods)
         */
        const data = {
          billingAddress: billingAddress(fields, {
            locale: router.currentRoute.params.locale,
            country: rootState.mainStore.selectedCountry
          }),
          creditCardDetails: {
            ccType: state.cardId,
            ccHolderFirstName: fields.first_name,
            ccHolderLastName: fields.last_name,
            ccNumber: fields.card_number.split(' ').join(''),
            ccCvv: fields.cvv,
            ccExpiryMonth:
              rootState.mainStore.details.country_3 === 'JPN'
                ? fields.expires.replace('／', '/').split('/')[0]
                : fields.expires.split('/')[0],
            ccExpiryYear:
              rootState.mainStore.details.country_3 === 'JPN'
                ? '20' + fields.expires.replace('／', '/').split('/')[1]
                : '20' + fields.expires.split('/')[1],
            email: fields.email
          }
        }

        if (state.deviceProfileURL) {
          // Checks deviceProfileURL from 3DS Check. If device profile is successful or fails we still send the deviceProfilingStatus
          data.deviceProfilingStatus = state.deviceProfilingStatus
        }

        if (noState === true) {
          delete data.billingAddress.state
        }

        response = await service.payment(payload.transactionId, data)
      }

      if (response.data.status.toUpperCase() === 'SUCCESS') {
        if (typeof response.data.acsUrl === 'undefined') {
          commit('PAYMENT_SUCCESS', response.data)
          commit('SET_PAYMENT_COMPLETE', response.data)
        } else {
          commit('PAYMENT_ACS_PROCESSING', response.data)
        }
      } else {
        dispatch('adjustErrorDetails', response.data)
        commit('PAYMENT_FAILURE')
      }
    } catch (error) {
      dispatch('adjustErrorDetails', error.response.data)
      commit('PAYMENT_FAILURE')
    }
  },

  resetDeviceProfile({ commit }) {
    commit('RESET_DEVICE_PROFILE')
  },

  async eWalletPaymentRequest({ commit, dispatch }, payload) {
    try {
      const response = await service.paymentEWallet(
        router.currentRoute.params.token,
        payload.paymentMethod
      )
      if (response.data.redirectUrl) {
        commit('E_WALLET_PAYMENT_REQUEST', response.data.redirectUrl)
      } else {
        commit('PAYMENT_FAILURE')
      }
    } catch (error) {
      dispatch('adjustErrorDetails', error.response.data)
      commit('PAYMENT_FAILURE')
    }
  },

  eWalletPayment({ commit, dispatch }) {
    commit('PAYMENT_REQUEST')
    window.location.href = state.eWalletRedirectionURL
  },

  async verifyRefundDetails({ commit, dispatch, rootState }, payload) {
    commit('PAYMENT_REQUEST')
    const countrysettings = rootState.mainStore.countrySettings
    let noState = true
    countrysettings.forEach((item) => {
      if (item.name === 'state') {
        noState = false
      }
    })
    const currentPath = `/${router.currentRoute.params.token}/${router.currentRoute.params.locale}`
    const { fields } = state
    const data = {
      billingAddress: billingAddress(fields, {
        locale: router.currentRoute.params.locale,
        country: rootState.mainStore.selectedCountry
      }),
      creditCardDetails: {
        ccType: state.cardId,
        ccHolderFirstName: fields.first_name,
        ccHolderLastName: fields.last_name,
        ccNumber: fields.card_number.split(' ').join(''),
        ccCvv: fields.cvv,
        ccExpiryMonth:
          rootState.mainStore.details.country_3 === 'JPN'
            ? fields.expires.replace('／', '/').split('/')[0]
            : fields.expires.split('/')[0],
        ccExpiryYear:
          rootState.mainStore.details.country_3 === 'JPN'
            ? '20' + fields.expires.replace('／', '/').split('/')[1]
            : '20' + fields.expires.split('/')[1]
      }
    }

    if (state.deviceProfileURL) {
      // Checks deviceProfileURL from 3DS Check. If device profile is successful or fails we still send the deviceProfilingStatus
      data.deviceProfilingStatus = state.deviceProfilingStatus
    }

    if (noState === true) {
      delete data.billingAddress.state
    }
    try {
      const response = await service.verifyRefundDetails(
        payload.transactionId,
        data
      )

      if (response.data.status.toUpperCase() === 'SUCCESS') {
        state.isRefundVerified = true
        state.status.isFetching = false
        state.clientTransactionId = response.data.clientTransactionId
      } else {
        dispatch('adjustErrorDetails', response.data)
        // commit('PAYMENT_FAILURE')
      }
    } catch (error) {
      dispatch('adjustErrorDetails', error.response.data)
      // commit('PAYMENT_FAILURE')
    }
  },
  async submitRefundDetails({ commit, dispatch, rootState }, payload) {
    commit('PAYMENT_REQUEST')
    const countrysettings = rootState.mainStore.countrySettings
    let noState = true
    countrysettings.forEach((item) => {
      if (item.name === 'state') {
        noState = false
      }
    })
    const currentPath = `/${router.currentRoute.params.token}/${router.currentRoute.params.locale}`
    const { fields } = state
    const data = {
      billingAddress: billingAddress(fields, {
        locale: router.currentRoute.params.locale,
        country: rootState.mainStore.selectedCountry
      }),
      creditCardDetails: {
        ccType: state.cardId,
        ccHolderFirstName: fields.first_name,
        ccHolderLastName: fields.last_name,
        ccNumber: fields.card_number.split(' ').join(''),
        ccCvv: fields.cvv,
        ccExpiryMonth:
          rootState.mainStore.details.country_3 === 'JPN'
            ? fields.expires.replace('／', '/').split('/')[0]
            : fields.expires.split('/')[0],
        ccExpiryYear:
          rootState.mainStore.details.country_3 === 'JPN'
            ? '20' + fields.expires.replace('／', '/').split('/')[1]
            : '20' + fields.expires.split('/')[1]
      },
      clientTransactionId: state.clientTransactionId
    }

    if (state.deviceProfileURL) {
      // Checks deviceProfileURL from 3DS Check. If device profile is successful or fails we still send the deviceProfilingStatus
      data.deviceProfilingStatus = state.deviceProfilingStatus
    }

    if (noState === true) {
      delete data.billingAddress.state
    }
    try {
      const response = await service.submitRefundPayment(
        payload.transactionId,
        data
      )

      if (response.data.status.toUpperCase() === 'SUCCESS') {
        if (typeof response.data.acsUrl === 'undefined') {
          commit('PAYMENT_SUCCESS', response.data)
          commit('SET_PAYMENT_COMPLETE', response.data)
        } else {
          commit('PAYMENT_ACS_PROCESSING', response.data)
        }
      } else {
        dispatch('adjustErrorDetails', response.data)
        commit('PAYMENT_FAILURE')
      }
    } catch (error) {
      dispatch('adjustErrorDetails', error.response.data)
      commit('PAYMENT_FAILURE')
      console.error(error.message)
    }
  },
  /**
   *
   * @param {*} param0
   * @param {{type?: 'shipping' | 'return' | 'tax', zipCode: string, country: string, field: string}} payload
   */
  async fetchStates({ commit, dispatch, rootState }, payload) {
    let stateCodes = rootState.mainStore.states.stateCodes
    commit('FETCH_CITY_REQUEST')
    try {
      let addressForm
      let addressType = `${payload.type}Address`
      if (SHIPPING_ADDRESS_TYPES.includes(payload.type)) {
        /**
         * shipping/return address form
         */
        addressForm = state.shippingInfo[addressType]
      } else {
        /**
         * billing address form
         */
        addressForm = state
      }
      if (addressForm.status.lastZipLookup !== payload.zipCode) {
        const response = await service.getStates(payload)
        if (response.data.status.toUpperCase() === 'SUCCESS') {
          if (response.data.stateCode) {
            response.data.stateCodeValid =
              stateCodes.indexOf(response.data.stateCode) > -1
          }
          commit('FETCH_CITY_SUCCESS', {
            ...response.data,
            type: payload.type,
            zipCode: payload.zipCode
          })
        } else {
          response.data.field = payload.field

          commit('FETCH_CITY_FAILURE', { ...response.data, type: payload.type })
        }
      }
    } catch (error) {
      error.response.data.field = payload.field
      commit('FETCH_CITY_FAILURE', { ...error, type: payload.type })
    }
  },
  async fetchDistricts({ commit, dispatch, rootState }, payload) {
    commit('FETCH_DISTRICTS_REQUEST')
    try {
      const response = await service.getDistricts(payload)
      if (response.data.length) {
        commit('FETCH_DISTRICTS_SUCCESS', { districts: response.data })
        if (state.fields.district)
          state.fields.district = initialState.fields.district
        if (state.fields.city) state.fields.city = initialState.fields.city
      } else {
        commit('FETCH_DISTRICTS_FAILURE')
      }
    } catch (error) {
      commit('FETCH_DISTRICTS_FAILURE')
    }
  },
  async fetch3DSAndDeviceProfile({ commit, dispatch, rootState }, payload) {
    commit('DEVICE_PROFILE_AND_3DS_CHECK_REQUEST')
    const { token } = router.currentRoute.params
    try {
      const response = await service.check3DSAndDeviceProfile(token, payload)
      if (response.data.is3dsRequired) {
        commit('FETCH_DEVICE_PROFILE_AND_3DS_CHECK_SUCCESS', response.data)
      } else {
        commit('CHECK_3DS_NOT_REQUIRED')
      }
    } catch (error) {
      dispatch(
        'updateErrorMessage',
        rootState.mainStore.localization['pp.payment.error']
      )
      commit('FETCH_DEVICE_PROFILE_AND_3DS_CHECK_FAILURE')
    }
  },
  async fetchDistrictsForShippingInfo(
    { commit, getters, dispatch, rootState },
    payload
  ) {
    let shippingInfoAddressFormFields
    let addressType = `${payload.type}Address`
    if (SHIPPING_ADDRESS_TYPES.includes(payload.type)) {
      shippingInfoAddressFormFields = state.shippingInfo[addressType].fields
    } else {
      return
    }

    commit('FETCH_DISTRICTS_REQUEST', { type: payload.type })
    try {
      const response = await service.getDistricts(payload)
      if (response.data.length) {
        commit('FETCH_DISTRICTS_SUCCESS', {
          districts: response.data,
          type: payload.type
        })

        const enteredDistrictExistsInList = response.data.some(
          ({ district }) => {
            return district === shippingInfoAddressFormFields.district
          }
        )
        const enteredCityExistsInList = getters.getCitiesSelectOptions.some(
          ({ district }) => {
            return district === shippingInfoAddressFormFields.district
          }
        )
        /**
         * reset district and city only when the already entered values don't exist in the response list
         */
        if (
          shippingInfoAddressFormFields.district &&
          !enteredDistrictExistsInList
        )
          shippingInfoAddressFormFields.district =
            initialState.shippingInfo[addressType].fields.district
        if (shippingInfoAddressFormFields.city && !enteredCityExistsInList)
          shippingInfoAddressFormFields.city =
            initialState.shippingInfo[addressType].fields.city
      } else {
        commit('FETCH_DISTRICTS_FAILURE', {
          type: payload.type
        })
      }
    } catch (error) {
      commit('FETCH_DISTRICTS_FAILURE', {
        type: payload.type
      })
    }
  },
  updateErrorMessage({ commit }, payload) {
    commit('UPDATE_ERROR_MESSAGE', payload)
  },
  cardNotSupported({ commit }, payload) {
    commit('CARD_NOT_SUPPORTED', payload)
  },
  setZipCodeLookup({ commit }) {
    commit('SET_ZIP_CODE_LOOKUP')
  },
  setBillingAddress({ commit }) {
    commit('SET_BILLING_ADDRESS')
  },
  setBillingAddressFirstNameLastName({ commit }, payload) {
    commit('SET_BILLING_ADDRESS_FIRSTNAME_LASTNAME', payload)
  },
  setBillingAddressFromPOInfo({ commit }) {
    commit('SET_BILLING_ADDRESS_FROM_PO_INFO')
  },
  setBillingAddressFromShippingAddress({ commit }) {
    commit('SET_BILLING_ADDRESS_FROM_SHIPPING_ADDRESS')
  },
  setReturnAddressFromShippingAddress({ commit }) {
    commit('SET_RETURN_ADDRESS_FROM_SHIPPING_ADDRESS')
  },

  adjustErrorDetails({ commit, dispatch, rootState }, payload) {
    const currentPath = `/${router.currentRoute.params.token}/${router.currentRoute.params.locale}`
    commit('ADJUST_ERROR_DETAILS', payload)
    if (
      payload.errorCode === 'CSRF_TOKEN_MISMATCH' ||
      payload.errorCode === 'CSRF_TOKEN_EXPIRED' ||
      payload.errorCode === 'CSRF_GUARD_EXCEPTION'
    ) {
      router.push(currentPath + '/autherror')
    } else if (
      payload.errorCode === 'TRANSACTION_ERROR_PAYMENT_ZERO_ONE_DOLLAR' ||
      payload.errorCode === 'TRANSACTION_ERROR_PAYMENT_TRANS_TYPE' ||
      payload.errorCode === 'TRANSACTION_ERROR_PAYMENT_QUOTE_ID' ||
      payload.errorCode === 'TRANSACTION_ERROR_PAYMENT_TRANS_ID' ||
      payload.errorCode === 'TRANSACTION_ERROR_PAYMENT_FULFILLMENT_ID' ||
      payload.errorCode === 'TRANSACTION_ERROR_PAYMENT_INVALID_DISPATCH' ||
      payload.errorCode === 'TRANSACTION_ERROR_PAYMENT_AGR_CODE' ||
      payload.errorCode ===
        'TRANSACTION_ERROR_PAYMENT_AGREEMENT_ALREADY_MAPPED' ||
      payload.errorCode ===
        'TRANSACTION_ERROR_PAYMENT_AUTHORIZATION_GENERAL_ERROR' ||
      payload.errorCode === 'TRANSACTION_ERROR_GENERAL_EXCEPTION' ||
      payload.errorCode === 'TRANSACTION_ERROR_LOCALE_MANDATORY' ||
      payload.errorCode === 'CSPAYMENT_TIMED_OUT_EXCEPTION'
    ) {
      router.push(currentPath + '/error')
    } else if (
      payload.errorCode === 'TRANSACTION_ERROR_PAYMENT_ALREADY_COMPLETED' ||
      payload.errorCode === 'TRANSACTION_ERROR_SUMMARY_ALREADY_COMPLETED' ||
      payload.errorCode ===
        'TRANSACTION_ERROR_PAYMENT_ALREADY_COMPLETED_BY_OTHERCHANNEL' ||
      payload.errorCode === 'TRANSACTION_ERROR_PAYMENT_IS_UNDER_PROCESS' ||
      payload.errorCode === 'PAYMENT_ALREADY_COMPLETED_ERROR'
    ) {
      router.push(currentPath + '/completed')
    } else if (
      payload.errorCode === 'TRANSACTION_ERROR_INVALID_TOKEN' ||
      payload.errorCode === 'TRANSACTION_ERROR_INVALID_PAYMENT_STATUS'
    ) {
      router.push(currentPath + '/notfound')
    } else if (payload.errorCode === 'APPLE_ID_MISMATCH_ERROR') {
      window.location.href = '/logout'
    } else if (
      payload.errorCode === 'TRANSACTION_ERROR_SUMMARY_STATUS_CANCELLED' ||
      payload.errorCode === 'TRANSACTION_ERROR_PAYMENT_ALREADY_CANCELLED' ||
      payload.errorCode === 'TOKEN_CANCELLED_ERROR'
    ) {
      router.push(currentPath + '/cancelled')
    } else if (
      payload.errorCode === 'TRANSACTION_ERROR_TOKEN_EXPIRED' ||
      payload.errorCode === 'TOKEN_EXPIRED_ERROR'
    ) {
      router.push(currentPath + '/expired')
    } else if (payload.errorCode === 'REFUND_CANCELLED_ERROR') {
      router.push(currentPath + '/refund/cancelled')
    } else if (
      payload.errorCode === 'TRANSACTION_ERROR_PAYMENT_CC_HOLDER_FNAME' ||
      payload.errorCode === 'TRANSACTION_ERROR_PAYMENT_CC_HOLDER_LNAME' ||
      payload.errorCode === 'TRANSACTION_ERROR_PAYMENT_ADDRESS_LINE1' ||
      payload.errorCode === 'TRANSACTION_ERROR_PAYMENT_ADDRESS_LINE1_LENGTH' ||
      payload.errorCode === 'TRANSACTION_ERROR_PAYMENT_ADDRESS_LINE2_LENGTH' ||
      payload.errorCode === 'TRANSACTION_ERROR_PAYMENT_ZIPCODE' ||
      payload.errorCode ===
        'TRANSACTION_ERROR_PAYMENT_ADDRESS_ZIPCODE_LENGTH' ||
      payload.errorCode === 'TRANSACTION_ERROR_PAYMENT_ADDRESS_CITY_LENGTH' ||
      payload.errorCode === 'TRANSACTION_ERROR_PAYMENT_ADDRESS_STATE' ||
      payload.errorCode === 'TRANSACTION_ERROR_PAYMENT_ADDRESS_STATE_LENGTH' ||
      payload.errorCode === 'TRANSACTION_ERROR_PAYMENT_AUTHORIZARION_CALL' ||
      payload.errorCode === 'TRANSACTION_ERROR_PAYMENT_CC_TYPE' ||
      payload.errorCode === 'TRANSACTION_ERROR_PAYMENT_CC_NUMBER' ||
      payload.errorCode === 'TRANSACTION_ERROR_PAYMENT_CC_EXPIRY_MONTH' ||
      payload.errorCode === 'TRANSACTION_ERROR_PAYMENT_CC_EXPIRY_YEAR' ||
      payload.errorCode === 'TRANSACTION_ERROR_PAYMENT_CREDIT_CARD_EXPIRED' ||
      payload.errorCode === 'TRANSACTION_ERROR_PAYMENT_SEC_CODE' ||
      payload.errorCode === 'TRANSACTION_ERROR_PAYMENT_INVALID_CVV_FORMAT1' ||
      payload.errorCode === 'TRANSACTION_ERROR_PAYMENT_INVALID_CVV_FORMAT2'
    ) {
      dispatch(
        'updateErrorMessage',
        rootState.mainStore.localization['pp.payment.error']
      )
    } else if (payload.errorCode === 'REFUND_REQUEST_FAILED_ERROR') {
      dispatch(
        'updateErrorMessage',
        rootState.mainStore.localization['pp.payment.error.bankinfo.message']
      )
    } else if (
      // CrossborderChanges
      payload.errorCode === 'TRANSACTION_ERROR_CROSS_BORDER_VERIFICATION_FAILED'
    ) {
      dispatch(
        'updateErrorMessage',
        rootState.mainStore.localization['pp.payment.error.crossborder']
      )

      dispatch('updateErrorDetails', payload)
    } else if (payload.errorCode === 'INVALID_SUBURB_ERROR') {
      dispatch(
        'updateErrorMessage',
        rootState.mainStore.localization['pp.payment.suburb.error']
      )

      dispatch('updateErrorDetails', payload)
    } else if (
      payload.errorCode === 'TRANSACTION_ERROR_PAYMENT_ZIPCODE_STATE' ||
      payload.errorCode === 'ADDRESS_INVALID_POSTAL_CODE_FORMAT' ||
      payload.errorCode === 'INVALID_POSTAL_CODE_FORMAT' ||
      payload.errorCode === 'INCORRECT_CVV_ERROR' ||
      payload.errorCode === 'INCORRECT_CARD_NUMBER_ERROR' ||
      payload.errorCode === 'DECLINED_CARD_TYPE_NOT_SUPPORTED' ||
      payload.errorCode === 'CARD_TYPE_NOT_SUPPORTED_ERROR' ||
      payload.errorCode === 'INVALID_CREDIT_CARD_ERROR'
    ) {
      dispatch(
        'updateErrorMessage',
        rootState.mainStore.localization['pp.payment.error']
      )
      dispatch('updateErrorDetails', payload)

      // router.push(currentPath + '/billing')
    } else if (
      payload.errorCode === 'TRANSACTION_ERROR_PAYMENT_REFERRAL' ||
      payload.errorCode === 'TRANSACTION_ERROR_PAYMENT_INVALID_CREDIT_CARD' ||
      payload.errorCode === 'TRANSACTION_ERROR_PAYMENT_AUTHORIZATION_FAILED' ||
      payload.errorCode === 'PAYMENT_AUTHORIZATION_FAILED_ERROR' ||
      payload.errorCode === 'BAD_REQUEST_ERROR'
    ) {
      dispatch(
        'updateErrorMessage',
        rootState.mainStore.localization['pp.payment.error.auth.fail']
      )
      dispatch('updateErrorDetails', payload)
    } else if (
      payload.errorCode === 'PAYMENT_FAILED_REVERSAL_INITIATED_ERROR'
    ) {
      dispatch(
        'updateErrorMessage',
        rootState.mainStore.localization[
          'pp.message.payment.authorization.status.error'
        ]
      )
      dispatch('updateErrorDetails', payload)
    } else if (
      payload.errorCode === 'TRANSACTION_ERROR_PAYMENT_CVV_VALIDATION'
    ) {
      dispatch(
        'updateErrorMessage',
        rootState.mainStore.localization['pp.payment.error']
      )
      dispatch('updateErrorDetails', payload).then(() => {
        if (state.cvvRetry <= 3) {
          dispatch('cvvRetryIncrease')
        } else {
          dispatch('cvvRetryReset')
        }
      })
    } else {
      router.push(currentPath + '/error')
    }
  },
  updateErrorDetails({ commit }, payload) {
    commit('UPDATE_ERROR_DETAILS', payload)
  },
  resetErrorDetails({ commit }) {
    commit('RESET_ERROR_DETAILS')
  },
  cvvRetryIncrease({ commit }) {
    commit('CVV_RETRY_INCREASE')
  },
  cvvRetryReset({ commit, dispatch }) {
    commit('CVV_RETRY_RESET')
  },
  async cancelTransaction({ commit, rootState }) {
    commit('CANCEL_TRANSACTION_REQUEST')
    try {
      const { token, locale } = router.currentRoute.params

      await service.cancelTransaction(token)

      commit('CANCEL_TRANSACTION_SUCCESS')

      router.push(`/${token}/${locale}/cancelled`)
    } catch (error) {
      commit('CANCEL_TRANSACTION_FAILURE')
    }
  },
  paymentAcsReset({ commit }) {
    commit('PAYMENT_ACS_RESET')
  },
  async makeUPIPayment({ commit, dispatch }, payload) {
    commit('UPI_PAYMENT_REQUEST')
    try {
      const { token, locale } = router.currentRoute.params
      const response = await service.getUPIPayDetails(token)
      if (response.data.status.toUpperCase() === 'SUCCESS') {
        commit('UPI_PAYMENT_SUCCESS', response.data)
      } else {
        commit('UPI_PAYMENT_FAILURE')
      }
    } catch (error) {
      commit('UPI_PAYMENT_FAILURE')
    }
  },
  upiPaymentRedirected({ commit }) {
    commit('UPI_PAYMENT_REDIRECTED')
  },
  defaultFormfield({ commit }) {
    commit('DEFAULT_FIELD_VALUE')
  },
  defaultBillingfield({ commit }) {
    commit('DEFAULT_FIELD_BILLING')
  },
  async triggerCardtypecheck({ commit, dispatch }, payload) {
    try {
      const response = await service.getCardcheck(payload)
      if (response.data.status.toUpperCase() === 'SUCCESS') {
        commit('CARD_TYPE_SUCCESS', response.data)
      }
    } catch (error) {
      commit('CARD_TYPE_FAILURE')
    }
  },
  resetCardInfo({ commit }) {
    commit('CARD_NUMBER_RESET')
  },
  enablePayment({ commit }) {
    commit('ENABLE_PAYMENT')
  },
  disablePayment({ commit }) {
    commit('DISABLE_PAYMENT')
  },
  async triggerDeviceProfile({ commit, rootState, dispatch }) {
    commit('DEVICE_PROFILE_LOADING')
    try {
      document.getElementById('stepup').submit() // submit 3ds iframe form

      if (state.deviceProfileURL) {
        const waitForMessage = new Promise((resolve) => {
          window.addEventListener(
            'message',
            (event) => {
              let anchorElement = document.createElement('a')
              anchorElement.href = state.deviceProfileURL
              let domainURL = `https://${anchorElement.host}`
              if (event.origin === domainURL) {
                try {
                  let data = JSON.parse(event.data)

                  if (data !== undefined) {
                    dispatch('deviceProfilingStatus', data)
                    resolve(data)
                  }
                } catch (e) {
                  console.error(e)
                } finally {
                  window.removeEventListener('message', waitForMessage)
                }
              }
            },
            false
          )
        })

        const messageTimeout = new Promise((resolve) => {
          setTimeout(resolve, 10000)
        }).then(() => {
          throw new Error('DEVICE_PROFILING_TIMEOUT')
        })

        const deviceProfileData = await Promise.race([
          waitForMessage,
          messageTimeout
        ]) // resolves with message or timeout error
        return deviceProfileData
      }
    } catch (error) {
      commit('DEVICE_PROFILE_FAILURE')
      throw new Error(error)
    }
  },
  async createQuote({ commit, dispatch }, payload) {
    const { token } = router.currentRoute.params
    commit('CREATE_QUOTE_LOADING')
    try {
      const response = await service.createQuote({ token, data: payload })
      commit('CREATE_QUOTE_SUCCESS')
      commit('UPDATE_SUMMARY_DETAILS', response.data)

      if (response.data.applepay_payment_request) {
        dispatch(
          'updateApplePayRequestObject',
          response.data.applepay_payment_request
        )
      }
    } catch (e) {
      // refer https://quip-apple.com/TUdhA2X9tOEn
      commit('CREATE_QUOTE_ERROR', e.response.data)
      redirect(e.response.data, { redirectToGenericErrorPageByDefault: false })
      throw new Error(e)
    }
  },
  deviceProfilingStatus({ commit }, payload) {
    commit('DEVICE_PROFILE_STATUS', payload)
  },
  resetField({ commit }, payload) {
    commit('RESET_FIELD', payload)
  },
  resetBillingAddressFields({ commit }) {
    commit('RESET_BILLING_ADDRESS_FIELDS')
  },

  resetUpiPay({ commit }) {
    commit('RESET_UPIPAY_STATUS')
  },
  resetPayment({ commit }) {
    commit('PAYMENT_FAILURE')
  },
  changeTaxType({ commit, rootState }, payload) {
    commit('CHANGE_TAX_TYPE', payload)
    let fields = {}
    const selectedCountry = rootState.mainStore.selectedCountry
    TAX_FIELDS[selectedCountry]
      .find(({ type }) => type === payload)
      .fields.forEach(({ name }) => {
        fields[name] = null
      })
    commit('INITIATE_TAX_FIELDS', fields)
  }
}

const getters = {
  getDistrictsSelectOptions: (state) => (type) => {
    let addressForm
    if (SHIPPING_ADDRESS_TYPES.includes(type)) {
      addressForm = state.shippingInfo[`${type}Address`]
    } else {
      addressForm = state
    }

    return addressForm.districtsStatus.districts.map(({ district }) => ({
      value: district,
      text: district
    }))
  },
  getCitiesInDistrict: (state) => (type) => {
    let addressForm
    if (SHIPPING_ADDRESS_TYPES.includes(type)) {
      addressForm = state.shippingInfo[`${type}Address`]
    } else {
      addressForm = state
    }
    const selectedDistrict = addressForm.fields.district
    const districtInfo = addressForm.districtsStatus.districts.find(
      ({ district }) => district === selectedDistrict
    )
    const { cities } = districtInfo || {}
    return cities || []
  },
  getCitiesInPostalCode: (state) => (type) => {
    let addressForm
    if (SHIPPING_ADDRESS_TYPES.includes(type)) {
      addressForm = state.shippingInfo[`${type}Address`]
    } else {
      addressForm = state
    }

    const { cities } = addressForm.citiesStatus
    return cities || []
  },
  getCitiesSelectOptions: (state, getters, rootState) => (type) => {
    const citiesInDistrict = getters.getCitiesInDistrict(type).map((city) => ({
      value: city,
      text: city
    }))
    const citiesInPostalCode = getters
      .getCitiesInPostalCode(type)
      .map((city) => ({
        value: city,
        text: city
      }))

    let selectOptions = []
    if (citiesInDistrict.length > 0) {
      selectOptions = citiesInDistrict
    } else if (citiesInPostalCode.length > 0) {
      selectOptions = citiesInPostalCode
    }
    if (selectOptions.length > 0) {
      selectOptions.push({
        value: 'Other',
        text: rootState.mainStore.localization['webpay.input.options.other']
      })
    }
    return selectOptions
  },
  collectTaxAddress: (state, _getters, rootState) => {
    return (
      (state.taxDetails.type &&
        TAX_FIELDS[rootState.mainStore.selectedCountry].find(
          ({ type: taxType }) => taxType === state.taxDetails.type
        ).collectTaxAddress) ||
      false
    )
  }
}

const productStore = {
  state,
  getters,
  mutations,
  actions
}

export default productStore
