import { setCustomerInformationInState } from '@/features/payment/utilities/billingAddress'
import service from '@/features/upiredirect/service/upiredirectPageService'
import router from '@/router'

const timeout = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const initialState = {
  status: {
    isFetching: true,
    isFetched: false,
    error: false,
    errorMessage: false,
    eWalletErrorMessage: false
  },
  eWalletRedirect: false,
  showed: false
}

const state = JSON.parse(JSON.stringify(initialState))

const mutations = {
  CHECK_STATUS_EWALLET_REQUEST(state, payload) {
    Object.assign(state.status, initialState.status)
    state.status.isFetching = true
    state.showed = true
  },
  CHECK_STATUS_EWALLET_FAILURE(state, payload) {
    state.status.isFetching = false
    state.status.isFetched = true
    state.status.error = true
    state.status.errorMessage = true
    state.status.eWalletErrorMessage = true
  },
  CHECK_EWALLET_STATUS(state, payload) {
    state.showed = true
    state.eWalletRedirect = true
    state.status.eWalletErrorMessage = true
  },
  CHECK_QR_STATUS(state, payload) {
    state.showed = true
    state.eWalletRedirect = true
    state.status.eWalletErrorMessage = true
  },
  RESET_EWALLET_STATUS(state) {
    state.showed = true
    state.eWalletRedirect = false
    state.status.eWalletErrorMessage = false
  }
}

const actions = {
  async checkEWalletStatus({ commit, dispatch }, payload) {
    const delay = 5000
    let timer = 0
    let status
    let supportReversal
    let responseStatus
    commit('CHECK_STATUS_EWALLET_REQUEST')

    try {
      while (status !== 'COMP') {
        timer = timer + delay
        const response = await service.getStatus(payload)
        status = response.data.transactionStatus
        responseStatus = response.data.status
        supportReversal = response.data.supportReversal

        if (status === 'CNCL') {
          router.push(`/${payload.token}/${payload.locale}/cancelled`)
          break
        } else if (status === 'COMP') {
          commit('SET_PAYMENT_COMPLETE', response.data)
          commit('CHECK_STATUS_EWALLET_SUCCESS', response.data)
          setCustomerInformationInState(response.data, commit)

          router.push(`/${payload.token}/${payload.locale}/review`)
          break
        }
        if (timer < 15000 && responseStatus !== 'ERROR') {
          await timeout(delay)
        } else if (timer > 15000) {
          if (status === 'PEND' && responseStatus !== 'ERROR') {
            commit('CHECK_STATUS_EWALLET_FAILURE')

            break
          } else if (responseStatus === 'ERROR') {
            commit('CHECK_STATUS_EWALLET_FAILURE', supportReversal)

            break
          }

          break
        }
      }
    } catch (error) {
      supportReversal = error.response.data.supportReversal
      commit('CHECK_STATUS_EWALLET_FAILURE', supportReversal)
    }
  },
  resetEWallet({ commit }) {
    commit('RESET_EWALLET_STATUS')
  }
}

const eWalletRedirectPageStore = {
  state,
  mutations,
  actions
}

export default eWalletRedirectPageStore
