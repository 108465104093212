import { render, staticRenderFns } from "./CheckMarkIcon.vue?vue&type=template&id=590c1126&scoped=true"
import script from "./CheckMarkIcon.vue?vue&type=script&lang=js"
export * from "./CheckMarkIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "590c1126",
  null
  
)

export default component.exports