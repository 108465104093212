<template>
  <div class="terms section-content">
    <!-- Terms & Conditions Title -->
    <div class="terms__heading">
      <h1 v-if="(subType === 'ACP' && region === 'EUCOUNTRY') || accountType">
        {{ locale['pp.terms.heading.acp.EU'] }}
      </h1>
      <h1 v-else-if="isRefundType">{{ locale['pp.terms.heading.refund'] }}</h1>

      <h1 v-else>{{ locale['pp.terms.heading'] }}</h1>
    </div>
    <!-- Terms & Conditions Info  -->
    <!-- <div class="terms_content">
       <span v-html="html"></span>
    </div> -->
    <!-- Terms and Conditions info -->
    <div ref="termsRef">
      <p
        v-if="isRefundType"
        class="terms__content"
        v-html="locale[`pp.terms.highlights.${textKey}`]"
      />
      <p
        v-else-if="accountType"
        class="terms__content"
        v-html="locale[`pp.terms.highlights.accounts.SWT`]"
      />
      <p
        v-else-if="transtype !== 'CRU'"
        class="terms__content"
        v-html="insertPoTermsListItem(locale[`pp.terms.highlights.${productInfo.ordertype}`])"
      />
      <p
        v-else
        class="terms__content"
        v-html="insertPoTermsListItem(locale[`pp.terms.highlights.aex`])"
      />
    </div>
    <!--Date of Purchase-->
    <div v-if="refundType === 'AGR'" class="terms-link">
      <p>
        {{ locale['pp.terms.highlights.refund.dateofpurchase'] }}
        {{ caculateDate }}
      </p>
    </div>
    <!-- Terms Link -->
    <div v-if="tcUrl" class="terms-link" id="terms-link">
      <a :href="tcUrl" class="more" target="_blank"
        ><span>{{ locale['pp.terms.heading'] }}</span>
        <span class="a11y">{{ locale['pp.link.window'] }}</span>
      </a>
    </div>
    <!-- Accept Terms -->
    <div class="terms-accept">
      <div class="term-accept-content" v-if="activeTerms">
        <input
          v-model="termsStatus"
          class="terms-accept__checkbox"
          type="checkbox"
          name="acceptTerms"
          id="acceptTerms"
          :required="true"
          :value="true"
        />
        <label
          class="terms-accept__label"
          id="acceptTermsLabel"
          for="acceptTerms"
        >
          <p v-if="subType === 'ACP' && region === 'EUCOUNTRY'">
            {{ locale['pp.product.feature.read'] }}
          </p>
          <p v-else>
            {{ locale['pp.terms.accept.button'] }}
          </p>
        </label>
      </div>

      <div class="row">
        <p
          v-if="subType === 'ACP' && region === 'EUCOUNTRY'"
          class="terms__content-cancel"
          v-html="locale[`pp.terms.highlights.acp.cancel`]"
        />
        <p
          v-else-if="accountType"
          class="terms__content-cancel"
          v-html="locale[`pp.terms.highlights.accounts.cancel`]"
        />
        <p
          v-else-if="!isRefundType"
          class="terms__content-cancel"
          v-html="locale[`pp.terms.highlights.default`]"
        />
      </div>
    </div>
    <div class="row terms-actions">
      <div class="column large-6 small-12">
        <div class="visually-hidden" aria-live="polite">
          <span v-if="countrydata || countryfielddata">
            {{ locale['pp.message.loading'] }}
          </span>
        </div>
        <app-button
          id="continue-payment-terms"
          :type="buttonSizeChange"
          @onClick="nextStep"
          :isLoading="countrydata || countryfielddata"
          :disabled="isButtonDisabled"
        >
          {{ locale['pp.continue.button'] }}
        </app-button>
      </div>
      <span v-if="accountType" class="terms__content-notice">
        {{ locale['pp.terms.highlights.pastdue.notice'] }}
      </span>
    </div>
    <!-- Confirm Cancellation  -->
    <div id="confirmModal">
      <h2 class="confirmModal__heading">
        {{ locale['pp.cancel.confirm.heading'] }}
      </h2>
      <p class="confirmModal__content">
        {{ locale['pp.cancel.confirm.message'] }}
      </p>
      <p class="confirmModal__content">
        {{ locale['pp.cancel.confirm.message2'] }}
      </p>
      <div class="confirmModal__action">
        <app-button
          id="cancel-confirm-no-terms-and-conditions"
          @onClick="hideModal"
          type="base"
        >
          {{ locale['pp.cancel.confirm.no.button'] }}
        </app-button>
        <app-button
          id="cancel-confirm-yes-terms-and-conditions"
          app-button
          data-modal-close
          type="link"
          @onClick="cancelTransaction"
        >
          <div class="underline_text">
            {{ locale['pp.cancel.confirm.yes.button'] }}
          </div>
        </app-button>
      </div>
    </div>
    <!-- Failure Modal -->
    <div id="confirmModal-fail">
      <h2 class="termsFailureModal__heading">
        {{ locale['pp.message.error'] }}
      </h2>
      <p class="termsFailureModal__content">
        {{ locale['pp.terms.failure'] }}
      </p>
      <div class="termsFailureModal__action">
        <app-button
          id="terms-failure"
          data-modal-close
          @onClick="closetermsFailureModal"
          type="elevated"
        >
          {{ locale['pp.continue.button'] }}
        </app-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import AppButton from '@/components/app-button'
import Modal from '@marcom/ac-modal'
import KeyMap from '@marcom/ac-keyboard'
import { focusOnSelector, buttonType } from '@/utilities/utils'

export default {
  components: {
    AppButton
  },

  title() {
    if (this.isRefundType) {
      return `${this.locale['pp.terms.refund.pagetitle']}`
    } else {
      return `${this.locale['pp.terms.heading']}`
    }
  },
  data() {
    return {
      modal: '',
      failmodal: '',
      isModalClose: true,
      token: '',
      localeval: '',
      windowWidth: 0
    }
  },
  computed: {
    ...mapState({
      locale: (state) => state.mainStore.localization,
      accountType: (state) => state.mainStore.accountType,
      tcUrl: (state) => state.mainStore.details.tcUrl,
      activeTerms: (state) => state.mainStore.details.activeTerms,
      agreementDateOfPurchase: (state) =>
        state.mainStore.details.agreementDateOfPurchase,
      countrycode: (state) => state.mainStore.countrycode,
      html: (state) => state.termsStore.html,
      isFetching: (state) => state.termsStore.status.isFetching,
      isFetched: (state) => state.termsStore.status.isFetched,
      error: (state) => state.termsStore.status.error,
      isCountrySettings: (state) => state.mainStore.isCountrySettings,
      status: (state) => state.termsStore.termStatus,
      productInfo: (state) => state.productStore,
      transtype: (state) => state.mainStore.details.type,
      countrydata: (state) => state.mainStore.status.countrydata,
      countryfielddata: (state) => state.mainStore.status.countryfielddata,
      category: (state) => state.mainStore.details.category,
      refundType: (state) => state.mainStore.details.refundType,
      region: (state) => state.mainStore.details.regionEU,
      subType: (state) => state.mainStore.details.sub_type,
      isRefundType: (state) => state.mainStore.refundType,
      textKey: (state) => state.mainStore.details.text_key,
      bankFieldsDetails: (state) => state.refundStore.bankFieldsDetails,
      transaction_locale: (state) => state.mainStore.transaction_locale,
      termsLocale: (state) => state.mainStore.termsLocale
    }),
    ...mapGetters(['collectShippingInfo', 'isPOFlow']),
    isButtonDisabled() {
      if (this.activeTerms !== true) {
        return false
      } else {
        return !this.status
      }
    },
    buttonSizeChange() {
      return buttonType(this.windowWidth)
    },
    caculateDate() {
      const date = new Date(Number(this.agreementDateOfPurchase))
      return date.toLocaleDateString()
    },
    termsStatus: {
      get() {
        return this.status
      },
      set(value) {
        this.$emit('toggleAgree', value)
        this.$store.dispatch('setTermStatus', value)
      }
    }
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener('resize', this.getWindowWidth)
      //Init
      this.getWindowWidth()
    }),
      // Confirm Cancel Modal
      focusOnSelector('h1')
    this.token = this.$route.params.token
    this.localeval = this.$route.params.locale
    this.failmodal = Modal.createStandardModal()
    this.modal = Modal.createStandardModal()
    this.failmodal.modalElement.setAttribute(
      'aria-label',
      this.locale['pp.message.error']
    )
    this.modal.modalElement.setAttribute(
      'aria-label',
      this.locale['pp.cancel.confirm.heading']
    )
    const termsel = document.getElementById('confirmModal-fail')
    const el = document.getElementById('confirmModal')
    this.failmodal.appendContent(termsel)
    this.failmodal.modalElement.removeAttribute('data-modal-close')
    this.failmodal.modalElement
      .getElementsByTagName('div')[0]
      .getElementsByTagName('div')[0]
      .removeAttribute('data-modal-close')
    this.failmodal.modalElement
      .getElementsByTagName('div')[0]
      .getElementsByTagName('div')[0]
      .getElementsByTagName('div')[0]
      .removeAttribute('data-modal-close')
    this.failmodal.modalElement.getElementsByTagName('button')[1].remove()
    this.modal.appendContent(el)
    const link = document.querySelector('.terms__content-cancel a')

    if (link) {
      link.addEventListener('click', this.showModal)
      link.setAttribute('id', 'confirmCancel')
      link.setAttribute('role', 'button')
      link.addEventListener('keyup', (event) => {
        if (event.code === 'Space') {
          this.modal.open()
        }
      })
    }
    let LIlinklength
    const ULlink = document.querySelector('.terms__content ul')
    ULlink.setAttribute('role', 'list')
    LIlinklength = ULlink.getElementsByTagName('li').length
    for (let i = 0; i < LIlinklength; i++) {
      ULlink.getElementsByTagName('li')[i].setAttribute('role', 'listitem')
    }

    if (this.error === true) {
      this.failmodal.open()
    }
    if (this.termsLocale !== this.transaction_locale) {
      // adds extra bullet point to list of terms when terms locale is not the same as transaction locale
      try {
        let termsLocalLanguage = document.createElement('li')
        termsLocalLanguage.appendChild(
          document.createTextNode(
            this.locale['pp.terms.highlights.locale.exception']
          )
        )
        this.$refs.termsRef.querySelector('ul').appendChild(termsLocalLanguage)
      } catch (error) {
        console.error(error)
      }
    }
  },
  beforeDestroy() {
    const link = document.querySelector('.terms__content-cancel a')
    if (link) {
      link.removeEventListener('click', this.showModal)
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth)
  },
  methods: {
    showModal(e) {
      e.preventDefault()
      this.modal.open()
    },
    getWindowWidth(event) {
      this.windowWidth = document.documentElement.clientWidth
    },
    insertPoTermsListItem(poTermsList) {
      // Return PoTermsList unchanged if not POFlow
      if (!this.isPOFlow) {
        return poTermsList
      }

      const termHighlightsList = document.createElement('div')
      termHighlightsList.innerHTML = poTermsList
      const ulElement = termHighlightsList.querySelector('ul')

      // If no <ul> is found, return poTermsList unchanged
      if (!ulElement) {
        return poTermsList
      }
      // Create and insert new PO list item
      const poNewListItem = document.createElement('li')
      poNewListItem.innerHTML = this.locale['pp.terms.highlights.po.additional']
      ulElement.appendChild(poNewListItem)

      return termHighlightsList.innerHTML
    },

    hideModal() {
      this.modal.close()
    },
    cancelTransaction() {
      this.modal.close()
      this.$store.dispatch('cancelTransaction')
    },
    closetermsFailureModal() {
      this.failmodal.close()
      const { token, locale } = this.$route.params
      this.$router.push(`/${token}/${locale}`)
      this.$store.dispatch('init', this.$route.params)
    },
    nextStep() {
      if (!this.isRefundType) {
        this.$store.dispatch('getCountrySetting', this.countrycode).then(() => {
          this.reRoute()
        })
      } else this.reRoute()
    },
    changeTermsStatus(value) {
      this.status = value
    },
    reRoute() {
      const { token, locale } = this.$route.params
      if (this.refundType) {
        const route = `/${token}/${locale}/refund`
        this.$router.push(route)
        if (this.bankFieldsDetails.length === 0) {
          this.$store.dispatch('getBankFieldsValue', this.countrycode)
        }
      } else {
        let paymentInfoPage = '/billing'
        if (this.isPOFlow) {
          paymentInfoPage = `/purchase-order`
        }
        const nextPagePath = this.collectShippingInfo
          ? '/shipping'
          : paymentInfoPage
        const route = `/${token}/${locale}${nextPagePath}`
        this.$router.push(route)
        this.$store.dispatch('defaultFormfield')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'ac-sasskit/core';
@import 'ac-sasskit/base';
@import '@/styles/variables.scss';

.terms {
  color: swatch(glyph-gray);
  text-align: left;
}

.terms__heading,
h1 {
  @include direction(text-align, left, text-align, right);
  margin-top: $app-margin-xxx-lg;
  color: swatch(glyph-gray);
  @include typography(headline-reduced);

  @include viewport(medium) {
    margin-top: $app-padding-lg;
  }
  //   font-weight: $app-font-weight-xxx-lg;
  &:focus {
    outline: 0;
  }
}

.terms-link {
  margin-bottom: $app-margin-xx-xs;
}

.terms__content {
  color: swatch(glyph-gray);
  @include typography(eyebrow);
  margin-top: $app-margin-lg;
  margin-bottom: $app-margin-xx-xs;
  @include direction(text-align, left, text-align, right);
  // li {
  //   @include direction(margin-left, 0, margin-right, 13px);
  // }
}

.terms-accept,
.terms-actions {
  @include direction(text-align, left, text-align, right);
}
.terms-accept__label {
  color: swatch(glyph-gray);
  @include typography(body);
  @include direction(
    padding-left,
    $app-margin-xxx-xs,
    padding-right,
    $app-margin-xxx-xs
  );
  margin-bottom: $app-margin-xx-xs;
}
.term-accept-content {
  display: flex;
  flex-direction: row;
  align-items: first baseline;
}
.terms__content-cancel {
  color: swatch(glyph-gray);
  @include typography(body);
  @include direction(text-align, left, text-align, right);
}

.terms__content-cancel /deep/ a {
  text-decoration: underline;
}
.terms__content-notice {
  padding-top: $app-padding-sm;
  padding-left: $app-padding-zero;
  @include typography(body);
  @include direction(text-align, left, text-align, right);
}

.terms-actions {
  margin-top: $app-margin-lg !important;
  @include viewport(small) {
    margin-top: $app-padding-bottom-xs !important;
  }
}
.confirmModal__heading {
  text-align: center;
  @include typography(headline-reduced);
  padding-bottom: $app-padding-xxxx-sm;
  @include viewport(small) {
    padding-bottom: $app-margin-xxxx-sm;
  }
}
.confirmModal__content {
  @include direction(text-align, center, text-align, center);
  @include typography(body-tight);
  margin-top: 0 !important;
  padding-top: $app-padding-zero;
  p {
    margin: $app-margin-zero;
  }
}

.confirmModal__action {
  margin-top: $app-margin-xxxx-sm;
  text-align: -webkit-center;
  .button {
    display: block;
    margin-top: $app-margin-x-xss;
    margin-right: 0;
    @include viewport(small) {
      width: 70%;
    }
  }
}

.termsFailureModal__heading {
  text-align: center;
  @include typography(headline-reduced);
  padding-bottom: $app-padding-xxxx-sm;
  @include viewport(small) {
    padding-bottom: $app-margin-xxxx-sm;
  }
}
.termsFailureModal__content {
  @include direction(text-align, center, text-align, center);
  padding-top: $app-padding-zero;
  margin-top: 0 !important;
  @include typography(body-tight);
  p {
    margin: $app-margin-zero;
  }
}

.termsFailureModal__action {
  margin-top: $app-margin-xxxx-sm;
  text-align: -webkit-center;
  .button {
    display: block;
    margin-top: $app-margin-x-xss;
    @include viewport(small) {
      width: 70%;
    }
  }
}
.row .terms-actions {
  padding-left: 0 !important;
}
.terms-actions .button {
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
}
.displayNone,
.modal-close {
  @include direction(left, $app-padding-xxxx-md, right, $app-padding-xxxx-md);
  display: none;
}

.more::after {
  @include direction(transform, unset, transform, rotate(180deg));
}
</style>
