<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 250 25"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <g transform="matrix(1 0 0 1 25 100) scale(2)">
      <path
        :fill="color"
        d="M 46.582 8.64258 C 49.5117 8.64258 51.8066 7.4707 53.418 5.07812 L 99.1211 -65.4297 C 100.293 -67.1875 100.781 -68.7988 100.781 -70.3125 C 100.781 -74.3164 97.8516 -77.1973 93.75 -77.1973 C 90.918 -77.1973 89.209 -76.2207 87.5 -73.4863 L 46.3867 -8.44727 L 25.3906 -34.9121 C 23.7305 -36.9141 22.0215 -37.8418 19.5801 -37.8418 C 15.4297 -37.8418 12.4512 -34.9121 12.4512 -30.8594 C 12.4512 -29.1016 13.0371 -27.4414 14.5508 -25.6836 L 39.7949 5.32227 C 41.6992 7.61719 43.7988 8.64258 46.582 8.64258 Z"
      />
    </g>
  </svg>
</template>
<script>
import Vue from 'vue'

export default {
  name: 'CheckMarkIcon',
  description: 'Check Mark Icon',
  props: {
    width: {
      type: [Number, String],
      default: null
      // note: 'title for select'
    },
    height: {
      type: [Number, String],
      default: null
      // note: 'title for select'
    },
    color: {
      type: String,
      default: null
      // note: 'title for select'
    }
  }
}
</script>
<style lang="scss" scoped></style>
