import service from '@/features/upiredirect/service/upiredirectPageService'
import { setCustomerInformationInState } from '@/features/payment/utilities/billingAddress'
import router from '@/router'

const timeout = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const initialState = {
  status: {
    isFetching: true,
    isFetched: false,
    error: false,
    errorMessage: false,
    upierrorMessage: false
  },
  upiredirect: false,
  showed: false,
  isPendingQR: false,
  isPendingVPA: false,
  upiModal: false
}

const state = JSON.parse(JSON.stringify(initialState))

const mutations = {
  CHECK_STATUS_UPIPAYMENT_REQUEST(state, payload) {
    Object.assign(state.status, initialState.status)
    state.status.isFetching = true
    state.showed = true
  },
  CHECK_STATUS_UPIPAYMENT_FAILURE(state, payload) {
    state.status.isFetching = false
    state.status.isFetched = true
    state.status.error = true
    state.status.errorMessage = true
    state.status.upierrorMessage = true
    state.upiModal = true
  },
  CHECK_UPI_STATUS(state, payload) {
    state.showed = true
    state.upiredirect = true
    state.status.upierrorMessage = true
    state.isPendingVPA = true
    state.upiModal = true
  },
  CHECK_QR_STATUS(state, payload) {
    state.showed = true
    state.upiredirect = true
    state.status.upierrorMessage = true
    state.isPendingQR = true
    state.upiModal = true
  },
  RESET_UPI_STATUS(state) {
    state.showed = true
    state.upiredirect = false
    state.status.upierrorMessage = false
    state.isPendingVPA = false
    state.isPendingQR = false
    state.upiModal = false
  },
  UPI_PAY_FAILURE(state) {
    state.upiModal = true
  }
}

const actions = {
  async checkUPIPaymentStatus({ commit, dispatch }, payload) {
    const delay = 5000
    let timer = 0
    let status
    let supportReversal
    let isPendingQR
    let isPendingVPA
    let responseStatus
    commit('CHECK_STATUS_UPIPAYMENT_REQUEST')

    try {
      while (status !== 'COMP') {
        timer = timer + delay
        const response = await service.getStatus(payload)
        status = response.data.transactionStatus
        responseStatus = response.data.status
        supportReversal = response.data.supportReversal

        if (response.data.isPendingQR) {
          isPendingQR = response.data.isPendingQR
        }
        if (response.data.isPendingVPA) {
          isPendingVPA = response.data.isPendingVPA
        }

        if (status === 'CNCL') {
          router.push(`/${payload.token}/${payload.locale}/cancelled`)
          break
        } else if (status === 'COMP') {
          commit('SET_PAYMENT_COMPLETE', response.data)
          commit('CHECK_STATUS_UPIPAYMENT_SUCCESS', response.data)
          setCustomerInformationInState(response.data, commit)

          router.push(`/${payload.token}/${payload.locale}/review`)
          break
        }
        if (timer < 15000 && responseStatus !== 'ERROR') {
          await timeout(delay)
        } else if (timer > 15000) {
          if (status === 'PEND' && responseStatus !== 'ERROR') {
            if (isPendingQR) {
              commit('CHECK_QR_STATUS')
            } else {
              commit('CHECK_UPI_STATUS')
            }
            commit('UPI_PAY_PENDING')
            commit('UPI_PAYMENT_FAILURE')
            commit('UPI_PAY_FAILURE')
            const data = {}
            router.push(`/${payload.token}/${payload.locale}`)
            data.token = payload.token
            data.locale = payload.locale
            dispatch('init', data)
            // router.push(`/${payload.token}/${payload.locale}/review`)
            break
          } else if (responseStatus === 'ERROR') {
            if (isPendingQR) {
              commit('CHECK_QR_STATUS')
            } else {
              commit('CHECK_UPI_STATUS')
            }
            commit('UPI_PAY_PENDING')
            commit('UPI_PAYMENT_FAILURE')
            commit('UPI_PAY_FAILURE')
            commit('CHECK_STATUS_UPIPAYMENT_FAILURE', supportReversal)

            // router.push(`/${payload.token}/${payload.locale}/review`)

            break
          }
          // commit('CHECK_STATUS_UPIPAYMENT_FAILURE', supportReversal)
          break
        }
      }
    } catch (error) {
      supportReversal = error.response.data.supportReversal
      if (error.response.data.isPendingQR) {
        isPendingQR = error.response.data.isPendingQR
      }
      if (error.response.data.isPendingQR) {
        commit('CHECK_QR_STATUS')
      } else {
        commit('CHECK_UPI_STATUS')
      }
      commit('UPI_PAY_PENDING')
      commit('UPI_PAYMENT_FAILURE')
      commit('CHECK_STATUS_UPIPAYMENT_FAILURE', supportReversal)
      // const data = {}
      // router.push(`/${payload.token}/${payload.locale}`)
      // data.token = payload.token
      // data.locale = payload.locale
      // dispatch('init', data)
      // commit('CHECK_STATUS_PAYMENT_FAILURE', supportReversal)
    }
  },
  resetUpi({ commit }) {
    commit('RESET_UPI_STATUS')
  }
}

const getters = {}

const upiredirectPageStore = {
  state,
  getters,
  mutations,
  actions
}

export default upiredirectPageStore
