export default (state, additionalData) => {
  return {
    firstName: state.first_name,
    lastName: state.last_name,
    locale: additionalData.locale,
    street1: state.street1 || undefined,
    street2: state.street2 || undefined,
    street3: state.street3 || undefined,
    street4: state.street4 || undefined,
    suburb: state.suburb || undefined,
    postalCode: state.postalcode || undefined,
    city: state.city || undefined,
    country: additionalData.country || undefined,
    state: state.state || undefined,
    district: state.district || undefined,
    orgName: additionalData.orgName || undefined
  }
}

/**
 * Used to map the address fields received from the API to our local state
 */
export function apiAddressFieldsToLocalStateMapping(fields, additionData) {
  const { firstName, lastName, postalCode, ...rest } = fields || {}
  return {
    ...rest,
    first_name: firstName,
    last_name: lastName,
    postalcode: postalCode
  }
}

export function setCustomerInformationInState(apiResponse, commit) {
  /**
   * set customer information in state to make them available to be displayed to the user
   */
  const { ltsaNumber, customerInfo } = apiResponse
  if (ltsaNumber) {
    commit('SET_LTSA_NUMBER', ltsaNumber)
  }
  if (customerInfo) {
    const {
      firstName,
      lastName,
      emailId,
      phoneNumber,
      returnAddress,
      shippingAddress,
      billingAddress
    } = customerInfo

    commit(
      'SET_SHIPPING_ADDRESS',
      apiAddressFieldsToLocalStateMapping(shippingAddress)
    )

    if (returnAddress) {
      commit(
        'SET_RETURN_ADDRESS',
        apiAddressFieldsToLocalStateMapping(returnAddress)
      )
    }

    if (billingAddress) {
      commit(
        'SET_PO_BILLING_ADDRESS',
        apiAddressFieldsToLocalStateMapping(billingAddress)
      )
    }

    commit('SET_CUSTOMER_NAME', { firstName, lastName })
    commit('SET_CUSTOMER_EMAIL', emailId)
    commit('SET_CUSTOMER_PHONE', phoneNumber)
  }
}
