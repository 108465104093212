<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <path
      fill-rule="evenodd"
      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
    />
  </svg>
</template>
<script lang="ts">
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
export default {
  name: 'ChevronDownIcon',
  description: 'Chevron Down Icon',
  props: {
    width: {
      type: [Number, String],
      default: null
      // note: 'title for select'
    },
    height: {
      type: [Number, String],
      default: null
      // note: 'title for select'
    },
    color: {
      type: String,
      default: null
      // note: 'title for select'
    }
  }
}
</script>
<style lang="scss" scoped></style>
