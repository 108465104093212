export const initialState = {
  id: null,
  price: null,
  productName: null,
  customerFullName: null,
  imageUrl: null,
  currencyCode: null,
  parts: [],
  additionalAuth: null,
  tax: null,
  subTotal: null,
  shippingPrice: null,
  grandTotal: null,
  ordertype: null,
  transactionType: null,
  paymentAcsDetails: {
    name: null,
    ccLastFourDigits: null,
    street1: null,
    city: null,
    postalcode: null,
    type: null,
    state: null
  },
  successPayment: false,
  isACSProcessing: false,
  isUPIProcessing: false,
  isEWalletProcessing: false
}

const state = JSON.parse(JSON.stringify(initialState))

const mutations = {
  GET_DETAILS_REQUEST(state, payload) {
    Object.assign(state, initialState)
  },
  GET_DETAILS_SUCCESS(state, payload) {
    if (payload.transactionStatus === 'PEND') {
      state.price = payload.quote.total_amount
      state.subTotal = payload.quote.subTotal
      state.shippingPrice = payload.quote.shippingPrice
      state.id = payload.id
      state.productName = payload.product.productName
      state.transactionType = payload.type
      state.imageUrl = payload.product.image_url
      state.currencyCode = payload.quote.currencyCode
      if (payload.quote.parts !== undefined) {
        state.parts = [...payload.quote.parts]
      }
      if (payload.quote.additional_authorization !== undefined) {
        state.additionalAuth = payload.quote.additional_authorization
        state.grandTotal = payload.quote.grand_total
      }
      state.tax = payload.quote.tax
      state.ordertype = payload.text_key
    }
  },
  CHECK_STATUS_PAYMENT_SUCCESS(state, payload) {
    state.isACSProcessing = true
    state.successPayment = true
    state.price = payload.quote.total_amount
    state.subTotal = payload.quote.subTotal
    state.shippingPrice = payload.quote.shippingPrice
    state.id = payload.id
    state.productName = payload.product.productName
    state.imageUrl = payload.product.image_url
    state.currencyCode = payload.quote.currencyCode
    if (payload.quote.parts !== undefined) {
      state.parts = [...payload.quote.parts]
    }
    if (payload.quote.additional_authorization !== undefined) {
      state.additionalAuth = payload.quote.additional_authorization
      state.grandTotal = payload.quote.grand_total
    }
    state.tax = payload.quote.tax
    state.ordertype = payload.text_key
    state.paymentAcsDetails.name = payload.paymentDetails.ccHolderName
    state.paymentAcsDetails.ccLastFourDigits =
      payload.paymentDetails.ccLastFourDigits
    state.paymentAcsDetails.type = payload.paymentDetails.ccType
    state.paymentAcsDetails.street1 = payload.billingAddress.street1
    state.paymentAcsDetails.street2 = payload.billingAddress.street2
    state.paymentAcsDetails.street3 = payload.billingAddress.street3
    state.paymentAcsDetails.city = payload.billingAddress.city
    state.paymentAcsDetails.postalcode = payload.billingAddress.postalCode
    state.paymentAcsDetails.state = payload.billingAddress.state
  },
  CHECK_STATUS_UPIPAYMENT_SUCCESS(state, payload) {
    state.isUPIProcessing = true
    state.price = payload.quote.total_amount
    state.subTotal = payload.quote.subTotal
    state.shippingPrice = payload.quote.shippingPrice
    state.id = payload.id

    state.customerFullName = payload.fullName
    state.productName = payload.product.productName
    state.imageUrl = payload.product.image_url
    state.currencyCode = payload.quote.currencyCode
    if (payload.quote.parts !== undefined) {
      state.parts = [...payload.quote.parts]
    }
    if (payload.quote.additional_authorization !== undefined) {
      state.additionalAuth = payload.quote.additional_authorization
      state.grandTotal = payload.quote.grand_total
    }
    state.tax = payload.quote.tax
    state.ordertype = payload.text_key
    state.paymentAcsDetails.type = payload.paymentMethod
  },
  CHECK_STATUS_EWALLET_SUCCESS(state, payload) {
    state.isEWalletProcessing = true
    state.price = payload.quote.total_amount
    state.subTotal = payload.quote.subTotal
    state.shippingPrice = payload.quote.shippingPrice
    state.id = payload.id

    state.customerFullName = payload.fullName
    state.productName = payload.product.productName
    state.imageUrl = payload.product.image_url
    state.currencyCode = payload.quote.currencyCode
    if (payload.quote.parts !== undefined) {
      state.parts = [...payload.quote.parts]
    }
    if (payload.quote.additional_authorization !== undefined) {
      state.additionalAuth = payload.quote.additional_authorization
      state.grandTotal = payload.quote.grand_total
    }
    state.tax = payload.quote.tax
    state.ordertype = payload.text_key
    state.paymentAcsDetails.type = payload.paymentMethod
  }
}

const actions = {}

const getters = {}

const productStore = {
  state,
  getters,
  mutations,
  actions
}

export default productStore
