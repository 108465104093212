let creditCards = [
  {
    id: '01',
    pattern: '^4',
    companyName: 'Visa',
    companyShortname: 'visa',
    cardNumberGrouping: [4, 4, 4, 4],
    cardNumberLength: 16,
    expirationFormat: 'MM/YY',
    validateLuhn: true,
    cvcLength: 3,
    layout: {
      number: 'front',
      exp: 'front',
      name: 'front',
      cvc: 'back'
    }
  },
  {
    id: '02',
    pattern: '^(5[1-5]|22[2-9][0-9]|2[3-7])',
    companyName: 'MasterCard',
    companyShortname: 'mastercard',
    cardNumberGrouping: [4, 4, 4, 4],
    cardNumberLength: 16,
    expirationFormat: 'MM/YY',
    validateLuhn: true,
    cvcLength: 3,
    layout: {
      number: 'front',
      exp: 'front',
      name: 'front',
      cvc: 'back'
    }
  },
  {
    id: '03',
    pattern: '^(36|38|30[0-5])',
    companyName: 'Diners Club',
    companyShortname: 'diners',
    cardNumberGrouping: [4, 6, 4],
    cardNumberLength: 14,
    expirationFormat: 'MM/YY',
    validateLuhn: true,
    cvcLength: 3,
    layout: {
      number: 'front',
      exp: 'front',
      name: 'front',
      cvc: 'back'
    }
  },
  {
    id: '04',
    pattern: '^3[47]',
    companyName: 'American Express',
    companyShortname: 'amex',
    cardNumberGrouping: [4, 6, 5],
    cardNumberLength: 15,
    expirationFormat: 'MM/YY',
    validateLuhn: true,
    cvcLength: 4,
    layout: {
      number: 'front',
      exp: 'front',
      name: 'front',
      cvc: 'front'
    }
  },
  {
    id: '05',
    pattern: '^(6011|65[0-14-9]|64[4-9]|622)',
    companyName: 'Discover',
    companyShortname: 'discover',
    cardNumberGrouping: [4, 4, 4, 4],
    cardNumberLength: 16,
    expirationFormat: 'MM/YY',
    validateLuhn: true,
    cvcLength: 3,
    layout: {
      number: 'front',
      exp: 'front',
      name: 'front',
      cvc: 'back'
    }
  },
  {
    id: '06',
    pattern: '^35',
    companyName: 'JCB',
    companyShortname: 'jcb',
    cardNumberGrouping: [4, 4, 4, 4],
    cardNumberLength: 16,
    expirationFormat: 'MM/YY',
    validateLuhn: true,
    cvcLength: 3,
    layout: {
      number: 'front',
      exp: 'front',
      name: 'front',
      cvc: 'back'
    }
  },
  {
    id: '07',
    pattern: '^(220[0-4]|67|356|623|629)',
    companyName: 'MNP',
    companyShortname: 'mnp',
    cardNumberGrouping: [5, 5, 5, 4],
    cardNumberLength: 19,
    expirationFormat: 'MM/YY',
    validateLuhn: true,
    cvcLength: 3,
    layout: {
      number: 'front',
      exp: 'front',
      name: 'front',
      cvc: 'back'
    }
  },
  {
    id: '08',
    pattern: '^(508[5-9]|60[6-8]|65[2-3])',
    companyName: 'Rupay',
    companyShortname: 'rupay',
    cardNumberGrouping: [4, 4, 4, 4],
    cardNumberLength: 16,
    expirationFormat: 'MM/YY',
    validateLuhn: true,
    cvcLength: 3,
    layout: {
      number: 'front',
      exp: 'front',
      name: 'front',
      cvc: 'back'
    }
  },
  {
    id: '09',
    pattern:
      '^(620|62[4-6]|621[0-7]|6218[0-2]|6218[4-7]|6218|6220|6222|622018|623|624|625|626|6270|6272|6276|6277[0-7][0-9]|62778|62779|628[2-9]|6291|6292|81|816[4-9]|8170|8172)',
    companyName: 'UnionPay',
    companyShortname: 'unionpay',
    cardNumberGrouping: [4, 4, 4, 7],
    cardNumberLength: 19,
    expirationFormat: 'MM/YY',
    validateLuhn: true,
    cvcLength: 3,
    layout: {
      number: 'front',
      exp: 'front',
      name: 'front',
      cvc: 'back'
    }
  }
]
export const creditCardIds = creditCards

export const filterCreditCards = (cardIdsArray) => {
  creditCards = creditCards.filter((card) => {
    return cardIdsArray.indexOf(card.id) !== -1
  })
}

export const matchCreditCard = (value) => {
  if (value) {
    return creditCards.filter((card) => {
      const cardPattern = new RegExp(card.pattern)

      return value.match(cardPattern)
    })
  }
}

export const checkCreditCard = (value) => {
  if (value) {
    return creditCards.filter((card) => {
      return value.match(card.id)
    })
  }
}
