export const focusOnSelector = (selector, delay = 500, scrollToTop = true) => {
  if (scrollToTop) {
    window.scrollTo(0, 0)
  }
  const el = document.querySelector(selector)
  if (selector.indexOf('input') === -1) {
    el.tabIndex = -1
  }
  setTimeout(() => {
    if (el) {
      el.focus()
    }
  }, delay)
}

const smallWidth = 735
const largeWidth = 1069
export const buttonType = (windowWidth) => {
  if (windowWidth < smallWidth) {
    return 'primary'
  }
  if (windowWidth >= smallWidth && windowWidth < largeWidth) {
    return 'elevated'
  }
  if (windowWidth > largeWidth) {
    return 'primary'
  }
}

// polishInput checks that the amount of bytes does not exceed the max length.
export const byteCount = (userInput) => {
  return encodeURI(userInput).split(/%..|./).length - 1
}
export const polishInput = (userInput, maxLength, cardNumberMasked) => {
  if (cardNumberMasked !== null) {
    return userInput
  }
  if (!userInput || !maxLength) return ''

  let i = 2
  while (i < maxLength) {
    const size = byteCount(userInput.slice(0, i + 1))
    if (size > maxLength) break
    i++
  }
  return userInput.slice(0, i)
}

export function maskAccountNumber(accountNumber) {
  return '*'.repeat(accountNumber.length - 4) + accountNumber.slice(-4)
}

export function isPOBoxAddress(address = '') {
  /**
   * In the near future, we need to get this logic/config from the backend
   * maybe from the addressfields config or a separate API
   */
  const regex = `^ *(((box|bin)[-. /]?\\d+))|(.*(^|\\s)p[ .]? ?(o|0)[-. /]? *-?((box|bin)|b|(#|num)?\\d+))|
        ^Apdo$|^box$|^boks$|^CEDEX$|^correos$|^Packstation$|^boxadresser$|^post office$|
        (p(ost)? *(o(ff(ice)?)?)? *\\d+)|((box|bin)) *(number|num|#)? *\\d+|
        (p(ost)? *(o(ff(ice)?)?)? *((box|bin)|b)? *\\d+)|(p *-?\\/?(o)? *-?box)|
        ^C[.\\s]*C[.]?$|^P[.\\s]*L[.]?$|^P[.\\s]*B[.]?$|^B[.\\s]*P[.]?$|
        ^C[.\\s]*P[.]?$|^PF[.]?$`

  const isPOBox = new RegExp(regex, 'ig').test(address)
  return isPOBox
}

/**
 * returns true when special characters or emojis exist
 */
export function containsSpecialCharacters(value) {
  const containsInvalidCharacters = /[~`^{}/!“”"%&'()*+:;<=>?_$£¥€\\\\|]/.test(
    value
  )

  // example: 😂, Ref: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Regular_expressions/Unicode_character_class_escape, https://tc39.es/ecma262/multipage/text-processing.html#prod-CharacterClassEscape
  const containsEmojis = /\p{Extended_Pictographic}/u.test(value)

  return containsInvalidCharacters || containsEmojis
}

export function objectContainsTruthyValues(object) {
  return Object.keys(object).some((fieldName) => {
    return !!object[fieldName]
  })
}
