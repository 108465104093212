import instance from '@/utilities/interceptor'

export default {
  /**
   * Payment
   * method: put
   * end point: /api/v1/webpay/{transactionId}/payment
   */
  payment(transactionId, data) {
    return instance.put(`${transactionId}/payment`, data)
  },
  purchaseOrderPayment(transactionId, data) {
    return instance.put(`${transactionId}/payment/po`, data)
  },
  /**
   * Payment Applepay
   * method: put
   * end point: /api/v1/webpay/{token}/PaymentForApplePay
   */
  paymentApplepay(token, data) {
    return instance.put(`${token}/PaymentForApplePay`, data)
  },
  paymentEWallet(token, paymentMethod) {
    /**
     * /api/v1/webpay/{token}/payment/ewallet
     * response:
     * { redirectUrl }
     */
    return instance.post(`${token}/payment/ewallet`, {
      walletType: paymentMethod
    })
  },
  getBankFields(countrycode) {
    return instance.get(`/bank/${countrycode}`)
  },
  /**
   * Payment india
   * method: put
   * end point: /api/v1/webpay/{transactionId}/paymentIndia
   */
  // paymentIndia(transactionId, data) {
  //   return instance.put(`${transactionId}/paymentIndia`, data)
  // },
  /**
   * Get States - used for zip validation and get the states list if available
   * method: get
   * end point: /api/v1/webpay/zip/validate/{zipCode}/{country}
   */

  getStates(data) {
    return instance.get(`state/${data.zipCode}/${data.country}`)
  },

  /**
   * Get districts and their corresponding cities
   * endpoint: api/v1/webpay/district/{country}/{statecode}?languageCode={languageCode}
   * sample: api/v1/webpay/district/CHL/AG?languageCode=ENG, response: [{"district":"Coihaique","cities":["Coyhaique"]},{"district":"Cochrane","cities":["Cochrane"]}]
   */
  getDistricts({ country, stateCode, languageCode }) {
    return instance.get(
      `district/${country}/${stateCode}?languageCode=${languageCode}`
    )
  },
  /**
   * Cancel transaction
   * method: put
   * end point: /api/v1/webpay/{dispatchId}/status
   */
  cancelTransaction(token) {
    return instance.put(`${token}/status`, { status: 'cancelled' })
  },
  /**
   * Get applepay session
   * method: put
   * end point: /api/v1/webpay/merchantValidation
   */
  getApplepaySession(data) {
    return instance.put('/merchantValidation', data)
  },
  /**
   * Get Terms and Conditions
   * method: GET
   * end point: '/api/v1/webpay/fetchTC?category={categorydata}&token={token}&locale={locale}'
   */
  /**
   * Get Card Enquiry
   * method: post
   * end point: /api/v1/webpay/cardtype-enquiry
   */
  getCardcheck(data) {
    return instance.post(`/cardtype-enquiry`, data)
  },
  // Look into  the name of this-- "account" !== refund
  verifyRefundDetails(transactionId, data) {
    return instance.post(
      `${transactionId}/payment/account?operation=validate`,
      data
    )
  },
  /**
   * Get 3DS and Device Profiling
   * Returns if is3dsRequired and Device Profiling data
   * https://quip-apple.com/T4y4AYbMXa97
   */
  check3DSAndDeviceProfile(token, data) {
    return instance.post(`${token}/3ds/check`, data)
  },

  verifyACRT(payload, data) {
    console.log(payload, data)
    return instance.post(`${payload}/payment/refund?operation=validate`, data)
  },
  submitACRT(transactionId, data) {
    return instance.post(
      `${transactionId}/payment/refund?operation=submit`,
      data
    )
  },
  submitRefundPayment(transactionId, data) {
    return instance.post(
      `${transactionId}/payment/account?operation=submit`,
      data
    )
  },

  getUPIPayDetails(data) {
    return instance.put(`${data}/upiPayment`, '')
  },

  createQuote(payload) {
    return instance.post(`/${payload.token}/repair/quote`, payload.data, {
      baseURL: 'api/v3/webpay'
    })
  }
}
