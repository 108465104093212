<template>
  <div>
    <div class="title_heading">
      <h1>{{ locale['pp.message.error'] }}</h1>
    </div>
    <p class="title_heading-description">
      {{ locale['pp.message.action.error'] }}
    </p>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import mainStore from '@/store/mainStore'
import AppButton from '@/components/app-button'
import { focusOnSelector } from '@/utilities/utils'

export default {
  components: {
    AppButton
  },
  computed: {
    ...mapState({
      locale: (state) => state.mainStore.localization,
      transactionStatus: (state) => state.mainStore.details.transactionStatus
    })
  },
  mounted() {
    focusOnSelector('h1')
  },
  title() {
    return `${this.locale['pp.error.pagetitle']}`
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (
        to.name === 'Error' &&
        (from.name === null || from.name === 'Error') &&
        mainStore.state.details.Status !== 'ERROR'
      ) {
        const { token, locale } = vm.$route.params
        vm.$router.push(`/${token}/${locale}`)
      }
    })
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/custom.scss';
@import 'ac-sasskit/core';
@import 'ac-sasskit/base';
@import '@/styles/variables.scss';

.title_heading,
h1 {
  text-align: center;
  @include typography(headline);
  padding-top: $app-margin-top-lg;
  color: swatch(glyph-gray);
  &:focus {
    outline: 0;
  }
}

.title_heading-description {
  text-align: center;
  @include typography(intro);
  color: swatch(glyph-gray);
  margin-top: 0px !important;
  padding-top: $app-margin-xx-xds;
  // font-weight: $app-font-weight-xr-lg;
  &:focus {
    outline: 0;
  }
}
</style>
