<template>
  <div class="checkout section-content">
    <div v-if="!isLoaded" class="visually-hidden" aria-live="polite">
      <span> {{ locale['pp.message.loaded'] }} </span>
    </div>
    <div class="row checkout__header">
      <div class="column large-6 small-4 checkout__header-label">
        <span>{{ locale['pp.payment.checkout'] }}</span>
      </div>
      <div class="column large-6 small-8 checkout__header-price">
        <span class="checkout__header-text">
          <span v-if="isLoanAccount">
            {{ locale['pp.amount.summary'] }} {{ amount }}
          </span>
          <span v-if="!isLoanAccount && !isRefundType">
            {{
              isPOFlow
                ? locale['pp.payment.ordersummary']
                : locale['pp.summary.shipping.total']
            }}
            {{
              productInfo.parts.length > 0
                ? productInfo.price
                : productInfo.additionalAuth.non_return_amount
            }}</span
          >
        </span>
      </div>
    </div>
    <div class="checkout__header">
      <h1>{{ locale['page.purchase_order.heading'] }}</h1>
    </div>
    <!-- Error Message-->
    <b-col
      v-if="errorMessageKey"
      ref="errorMessageText"
      role="region"
      aria-label="Payment error"
      aria-live="polite"
      tabindex="-1"
      cols="12"
      class="error-message"
    >
      <div class="error-message__text">
        <img
          alt="Error"
          class="input-field__icons"
          src="../../assets/warning.svg"
        />
        <span
          aria-hidden="true"
          class="error-message-form"
          v-html="locale[errorMessageKey]"
        ></span>
      </div>
    </b-col>

    <!-- Payment Selection  -->
    <div class="row payment__selection">
      <div class="column large-6 medium-6 small-12 form-selector-group">
        <!-- Billing Summary -->
        <!-- Credit Card Section - Changed CVV type to password-->
        <div id="creditcard__section">
          <b-card>
            <div
              id="creditcard__section-details"
              class="creditcard__section-details"
            >
              <template>
                <div class="row">
                  <fieldset class="column large-12 shipping_address__container">
                    <div
                      v-if="
                        isCountrySettings === false || isStateFetched === false
                      "
                      class="button-spinner"
                    >
                      <app-moon-loader color="black" size="25px" />
                    </div>
                    <template
                      v-if="
                        isCountrySettings === true &&
                        isStateFetched === true &&
                        selectedCountry !== 'country'
                      "
                    >
                      <div
                        v-for="{
                          component,
                          eventHandlers,
                          key,
                          inputProps,
                          layoutConfig: { endOfSection }
                        } in getPOInputs()"
                        :key="key"
                        :class="{
                          [`creditcard__section--field`]: true
                        }"
                      >
                        <component
                          :is="component"
                          v-bind="inputProps"
                          v-on="eventHandlers"
                        ></component>

                        <div v-if="endOfSection" class="hr"></div>
                      </div>

                      <!-- email & phone -->
                      <!-- Commented out for now. Will be added back with Griffin integration -->
                      <!-- <app-input-new
                          name="email"
                          :readonly="isLoading"
                          :required="true"
                          :characterCountEnabled="true"
                          :error="
                            $v.shippingInfo['email']
                              ? $v.shippingInfo['email'].$invalid
                              : false
                          "
                          :placeholder="locale['pp.summary.shipping.email']"
                          :fieldValue="shippingInfo['email']"
                          :multiByteMessage="
                            locale[MULTIBYTE_ERROR_MESSAGE['email']]
                          "
                          :multiByteCountries="multiByteCountries"
                          type="text"
                          @onBlur="validateDetailsField"
                          @onChange="fieldChange()($event)"
                          @onInputApply="onKeyupfield"
                        />
                        <p class="font-body-tight">
                          {{ locale['pp.summary.shipping.email.status'] }}
                        </p>
                        <app-input-new
                          name="phoneNumber"
                          :readonly="isLoading"
                          :required="true"
                          :characterCountEnabled="true"
                          :error="
                            $v.shippingInfo['phoneNumber']
                              ? $v.shippingInfo['phoneNumber'].$invalid
                              : false
                          "
                          :placeholder="locale['pp.summary.shipping.phone']"
                          :fieldValue="shippingInfo[`phoneNumber`]"
                          :multiByteMessage="
                            locale[MULTIBYTE_ERROR_MESSAGE['phoneNumber']]
                          "
                          :multiByteCountries="multiByteCountries"
                          type="text"
                          @onBlur="validateDetailsField"
                          @onChange="fieldChange()($event)"
                          @onInputApply="onKeyupfield"
                        />
                        <div class="smsOptIn">
                          <input
                            v-model="smsUpdatesOptIn"
                            type="checkbox"
                            name="smsUpdatesOptIn"
                            id="smsUpdatesOptIn"
                            :required="true"
                            :value="true"
                          />
                          <label
                            class="checkbox__label"
                            id="smsUpdatesOptInLabel"
                            for="smsUpdatesOptIn"
                          >
                            <p>{{ locale['pp.summary.shipping.sms.message'] }}</p>
                          </label>
                        </div>
                        -->
                    </template>
                  </fieldset>
                  <!-- Billing Address Section -->
                  <template>
                    <fieldset class="column large-12">
                      <legend class="creditcard__section-addressheading">
                        {{ locale['pp.payment.address.heading'] }}
                      </legend>

                      <template>
                        <div
                          v-if="
                            isCountrySettings === false ||
                            isStateFetched === false
                          "
                          class="button-spinner"
                        >
                          <app-moon-loader color="black" size="25px" />
                        </div>
                        <template
                          v-if="
                            isCountrySettings === true &&
                            isStateFetched === true &&
                            selectedCountry !== 'country'
                          "
                        >
                          <div
                            v-for="{
                              component,
                              eventHandlers,
                              key,
                              inputProps
                            } in getAddressInputs('billing')"
                            :key="key"
                            :class="{
                              [`creditcard__section--field`]: true
                            }"
                          >
                            <component
                              :is="component"
                              v-bind="inputProps"
                              v-on="eventHandlers"
                            ></component>
                          </div>

                          <app-select
                            :autocomplete="AUTOCOMPLETE_ATTRIBUTES['country']"
                            class="creditcard__section--field"
                            v-if="productInfo.transactionType !== 'AGR'"
                            name="country"
                            :label="locale['pp.address.country']"
                            :options="[{ text: selectedCountryText }]"
                            :selected="selectedCountryText"
                            :error="$v.country ? $v.country.$error : false"
                            @onKeyup="onKeyupfield"
                            @onBlur="validateDetailsField"
                            :disabled="true"
                          />
                        </template>
                      </template>
                    </fieldset>
                  </template>
                </div>
              </template>
            </div>
          </b-card>
        </div>
      </div>
    </div>
    <!-- Continue Buttton -->
    <div class="row continue__review">
      <div class="column large-6 medium-6 small-12">
        <app-button
          id="continue-payment-form"
          :type="buttonSizeChange"
          :disabled="!isFormValid"
          :isLoading="isLoading"
          @onClick="nextStep"
        >
          {{ locale['pp.payment.continuereview'] }}
        </app-button>
      </div>
    </div>

    <div
      id="loading-text"
      role="alert"
      class="sr-only"
      aria-live="polite"
    ></div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import AppButton from '@/components/app-button'
import AppInputNew from '@/components/app-input-new'
import AppSelect from '@/components/app-select'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import router from '../../router'
import store from '../../store'
import { required } from 'vuelidate/lib/validators'
import validationMixin from '@/mixins/validationMixin'
import Modal from '@marcom/ac-modal'
import {
  focusOnSelector,
  buttonType,
  isPOBoxAddress,
  containsSpecialCharacters
} from '@/utilities/utils'
import { TAX_FIELDS, USA_PHONE_NUMBER_MASK } from '@/utilities/constants'
import { ARIA_LIVE_TIME_OUT_DELAY } from '@/utilities/timeout'
import { creditCardIds } from './utilities/matchCreditCard'
import ExternalPaymentRadioButton from '@/components/ExternalPaymentRadioButton'
import {
  AUTOCOMPLETE_ATTRIBUTES,
  MULTIBYTE_ERROR_MESSAGE
} from '@/utilities/constants'
import addressStateToPayloadMapping from './utilities/billingAddress'

const PO_FIELDS = [
  {
    key: 'form.purchase_order.purchase_order',
    name: 'poNumber',
    required: true,
    regex: '',
    type: 'string',
    length: '30',
    columns: 6
  },
  {
    key: 'form.purchase_order.confirm_purchase_order',
    name: 'poNumber_confirmation',
    required: true,
    regex: '',
    type: 'string',
    columns: 6,
    length: '30',
    endOfSection: true
  },
  {
    key: 'address.first_name',
    name: 'first_name',
    required: true,
    length: '17',
    regex: '',
    type: 'string',
    columns: 6
  },
  {
    key: 'address.last_name',
    name: 'last_name',
    required: true,
    length: '17',
    regex: '',
    type: 'string',
    columns: 6
  },
  {
    key: 'pp.summary.shipping.email',
    name: 'email',
    required: true,
    regex: '',
    type: 'string',
    length: '30',
    columns: 6
  },
  {
    key: 'pp.summary.shipping.phone',
    name: 'phone',
    required: true,
    regex: '',
    type: 'string',
    columns: 6,
    length: '30',
    endOfSection: true
  },
  {
    key: 'form.purchase_order.LTSA_numbers',
    name: 'ltsa',
    required: true,
    regex: '',
    type: 'string',
    length: '30',
    columns: 6
  }
]

export default {
  components: {
    AppButton,
    AppInputNew,
    AppSelect,
    appMoonLoader: MoonLoader,
    ExternalPaymentRadioButton,
    AppInputNew
  },

  title() {
    return `${this.locale['pp.payment.checkout']}`
  },

  data() {
    return {
      showed: '',
      isPaymentSelected: true,
      isModalClose: true,
      isLoaded: false,
      visible: false,
      windowWidth: 0,
      shouldZipCodeLookUp: false, // tells us if backend validation is needed for this country
      isZipCodeRequired: false, // tells us if zipcode is required for this country
      isLoanAccount: false,
      usesSelectInput: ['state', 'district', 'city'],
      inlineFields: ['city', 'postalcode'],
      isNextStepProcessing: false,
      ARIA_LIVE_TIME_OUT_DELAY,
      MULTIBYTE_ERROR_MESSAGE,
      AUTOCOMPLETE_ATTRIBUTES,
      smsUpdatesOptIn: false,
      TAX_FIELDS
    }
  },
  computed: {
    ...mapState({
      locale: (state) => state.mainStore.localization,
      productInfo: (state) => state.productStore,
      amount: (state) => state.mainStore.amount,
      accountType: (state) => state.mainStore.accountType,
      states: (state) => state.mainStore.states,
      countrySettings: (state) => state.mainStore.countrySettings,
      countryFields: (state) => state.mainStore.countryFields,
      productDetails: (state) => state.productStore,
      shippingInfo: (state) => state.formStore.shippingInfo,
      poInfo: (state) => state.formStore.poInfo,
      taxDetails: (state) => state.formStore.taxDetails,
      isLoading: (state) => state.formStore.createQuote.status.isLoading,
      isFetched: (state) => state.formStore.status.isFetched,
      error: (state) => state.formStore.status.error,
      country: (state) => state.mainStore.details.country_3,
      errorMessageKey: (state) => state.formStore.shippingInfo.errorMessageKey,
      errorField: (state) => state.formStore.errorDetails.errorField,
      isRtl: (state) => state.mainStore.isRtl,
      additionalAuth: (state) => state.productStore.additionalAuth,
      region: (state) => state.mainStore.details.region,
      type: (state) => state.mainStore.details.acceptance_types,
      detailsInfo: (state) => state.mainStore.details,
      isCountrySettings: (state) => state.mainStore.isCountrySettings,
      isCountryFields: (state) => state.mainStore.isCountryFields,
      countrycode: (state) => state.mainStore.countrycode,
      selectCountryOptions: (state) => state.mainStore.countryFields,
      termsChecked: (state) => state.termsStore.termStatus,
      selectedCountry: (state) => state.mainStore.selectedCountry,
      selectedCountryText: (state) => state.mainStore.selectedCountryText,
      localeCountry: (state) => state.mainStore.localeCountry,
      tokenValue: (state) => state.mainStore.tokenValue,
      iswebpayEligibleCountry: (state) =>
        state.mainStore.iswebpayEligibleCountry,
      isStateListAvail: (state) => state.mainStore.isStateListAvail,
      isStateFetched: (state) => state.mainStore.isStateFetched,
      isRefundType: (state) => state.mainStore.refundType,
      isRefundVerified: (state) => state.formStore.isRefundVerified,
      text_key: (state) => state.mainStore.details.text_key,
      zipCodeLookup: (state) => state.formStore.zipCodeLookup,
      multiByteCountries: (state) => state.mainStore.details.multiByteCountries,
      returnAddressSameAsShipping: (state) =>
        state.formStore.returnAddressSameAsShipping
    }),
    ...mapGetters(['collectReturnAddress', 'isPOFlow']),

    selectOptions() {
      const states = this.states['stateCodes']
      const textArray = this.states['stateDescriptions']
      let formatedOptions = [
        {
          value: null,
          text: this.locale['address.state']
        }
      ]
      states.map((item, index) => {
        formatedOptions.push({
          value: item,
          text: textArray[index]
        })
      })
      return formatedOptions
    },

    buttonSizeChange() {
      return buttonType(this.windowWidth)
    },
    isFormValid() {
      const isPOValid = !this.$v.poInfo.poFields.$invalid

      if (isPOValid && !this.isNextStepProcessing) {
        return true
      }
      return false
    },
    taxAddressSameAsShipping: {
      get() {
        return this.$store.state.formStore.taxAddressSameAsShipping
      },
      set(value) {
        this.$store.commit('TOGGLE_TAX_ADDRESS_SAME_AS_SHIPPING', value)
      }
    },
    returnAddressSameAsShipping: {
      get() {
        return this.$store.state.formStore.returnAddressSameAsShipping
      },
      set(value) {
        this.$store.commit('TOGGLE_RETURN_ADDRESS_SAME_AS_SHIPPING', value)
      }
    }
  },
  mixins: [validationMixin],
  validations() {
    const checkpostalcode = (value) => {
      if (this.selectedCountry === 'JPN') {
        let postCodeVal = ''
        if (value !== null) {
          postCodeVal = value.replace('−', '-').replace('ー', '-')
        }

        if (value !== null) {
          if (postCodeVal.slice(3, 4) !== '-') {
            return false
          } else {
            return true
          }
        } else {
          return true
        }
      }
    }
    const checkEmail = (value) => {
      /**
       * invalid email validation (same as the one used by Core)
       */
      const regex =
        /^[\w!#$%&‘*+/=?{|}~^-]+(?:\.[\w!#$%&'*+/=?{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,63}$/

      // standalone validator ideally should not assume a field is required
      if (value === '') {
        return true
      }
      return regex.test(value)
    }

    // const checkPhone = (value) => {
    //   const regex = /^\d+$/ // numeric only

    //   // standalone validator ideally should not assume a field is required
    //   if (value === '') {
    //     return true
    //   }
    //   return regex.test(value)
    // }

    const validateStreetAddress = (type, field) => (value) => {
      const isValid = !isPOBoxAddress(value)
      const message = this.locale['pp.payment.error.highlight.noPoBoxAddress']

      if (!isValid) {
        this.$store.commit('SET_ERROR_MESSAGE_FOR_PO_FIELD', {
          type,
          field,
          message
        })
      } else {
        this.$store.commit('UNSET_ERROR_MESSAGE_FOR_PO_FIELD', {
          type,
          field,
          message
        }) // unsets the error message
      }
      return isValid
    }

    const specialCharacterCheck = (type, field) => (value) => {
      const isValid = !containsSpecialCharacters(value)
      const message =
        this.locale['pp.payment.error.highlight.noSpecialCharacters']

      if (!isValid) {
        this.$store.commit('SET_ERROR_MESSAGE_FOR_PO_FIELD', {
          type,
          field,
          message
        })
      } else {
        this.$store.commit('UNSET_ERROR_MESSAGE_FOR_PO_FIELD', {
          type,
          field,
          message
        }) // unsets the error message
      }
      return isValid
    }

    let settings = {
      poInfo: {
        billingAddress: {
          fields: {}
        },
        poFields: {
          fields: {
            email: {
              required,
              checkEmail
            },
            phone: {
              required
              //   checkPhone
            }
          }
        }
        // Commented out for now. Will be added back with Griffin integration
        // email: {
        //   required,
        //   checkEmail
        // },
        // phoneNumber: {
        //   required
        // }
      },
      country: {},
      taxDetails: {
        fields: {}
      }
    }

    // dynamic validation

    PO_FIELDS.map((field) => {
      const { name } = field
      settings.poInfo.poFields.fields[name] =
        settings.poInfo.poFields.fields[name] || {}
      if (field.required) {
        settings.poInfo.poFields.fields[name] = {
          ...settings.poInfo.poFields.fields[name],
          required
        }
      }
      if (name === 'poNumber_confirmation') {
        settings.poInfo.poFields.fields[name] = {
          ...settings.poInfo.poFields.fields[name],
          required,
          poConfirmation: (value) => {
            if (value === this.poInfo.poFields.fields['poNumber']) {
              return true
            }
            return false
          }
        }
      }
    })

    this.countrySettings.forEach((item) => {
      // adds field to settings if field does not exist in settings

      if (!settings.poInfo.billingAddress.fields[item.name]) {
        settings.poInfo.billingAddress.fields[item.name] = {}
      }
      if (item.required) {
        if (item.name === 'postalcode' && this.selectedCountry === 'JPN') {
          settings.poInfo.billingAddress.fields[item.name] = {
            required,
            checkpostalcode
          }
          if (this.collectReturnAddress) {
            settings.poInfo.returnAddress.fields[item.name] = {
              required,
              checkpostalcode
            }
          }
          settings.poInfo.taxAddress.fields[item.name] = {
            required,
            checkpostalcode
          }

          this.shouldZipCodeLookUp = true
        } else {
          settings.poInfo.billingAddress.fields[item.name] = {
            required,
            ...settings.poInfo.billingAddress.fields[item.name]
          }
        }
      }
    })
    settings.country = { required }

    const poFieldsSettings = settings.poInfo.poFields.fields
    /**
     * attach special character validation for all address fields
     */
    Object.keys(poFieldsSettings).forEach((field) => {
      if (!['email', 'phone'].includes(field)) {
        poFieldsSettings[field] = {
          ...poFieldsSettings[field],
          specialCharacterCheck: specialCharacterCheck('poFields', field)
        }
      }
    })

    return settings
  },
  created: function () {
    const that = this
    setTimeout(function () {
      that.isLoaded = true
    }, ARIA_LIVE_TIME_OUT_DELAY)
    this.checkAccountType()
  },
  watch: {
    errorMessageKey(val) {
      if (val) {
        this.$nextTick(() => {
          // DOM is now updated
          const errorCol = this.$refs.errorMessageText
          errorCol.focus()
          errorCol.scrollIntoView()
        })
      }
    },
    selectedCountry(oldval, val) {
      if (oldval !== val) {
      }
    },
    isCountrySettings: function (val, oldVal) {
      if (val === false) {
        var node = document.createElement('span')
        var textnode = document.createTextNode('loading address fields')
        node.appendChild(textnode)
        document.getElementById('loading-text').appendChild(node)
      } else {
        var list = document.getElementById('loading-text')
        list.removeChild(list.childNodes[0])
      }
    },
    isStateFetched: function (val, oldVal) {
      if (val === false) {
        var node = document.createElement('span')
        var textnode = document.createTextNode('loading address fields')
        node.appendChild(textnode)
        document.getElementById('loading-text').appendChild(node)
      } else {
        var list = document.getElementById('loading-text')
        list.removeChild(list.childNodes[0])
      }
    }
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener('resize', this.getWindowWidth)

      //Init
      this.getWindowWidth()
    }),
      focusOnSelector('h1')
    if (this.errorField === '') {
      this.$store.dispatch('defaultFormfield')
    }

    if (
      this.$route.name === 'ShippingInfo' &&
      this.type === 'Active' &&
      !this.termsChecked
    ) {
      const { token, locale } = this.$route.params

      this.$router.push(`/${token}/${locale}/terms`)
    } else if (this.isCountrySettings === false) {
      this.$store.dispatch('getCountrySetting', this.countrycode).then(() => {
        if (!this.isCountrySettings) {
          this.failmodal.open()
        }
      })
    }
    this.isModalClose = false
    if (this.productInfo.transactionType !== 'AGR') {
      this.$store.dispatch('setCountrySelected', this.country)
      this.$store.dispatch('getCountrySetting', this.country)
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth)
  },
  beforeRouteLeave(to, _from, next) {
    if (!this.isFormValid && to.name === 'PaymentReview') {
      this.$v.$touch()
      next(false)
    } else {
      next()
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // from.name is null when refreshing, on non-refresh we recieve a name.
      // prevents user from coming into /purchase-order when not coming from shipping page or review page.
      if (vm.isPOFlow) {
        if (
          (from.name !== 'PaymentTerms' &&
            from.name !== 'ShippingInfo' &&
            from.name !== 'PaymentReview') ||
          from.name === null
        ) {
          const { token, locale } = vm.$route.params
          const route = `/#/${token}/${locale}`
          location.href = route
          location.reload()
        }
      }
    })
  },
  methods: {
    checkAccountType() {
      if (this.accountType) {
        this.visible = true
        this.isLoanAccount = true
        this.isPaymentSelected = true
        this.paymentMethod = 'cc'
      } else {
        this.visible = false
        this.isLoanAccount = false
      }
    },
    getWindowWidth(event) {
      this.windowWidth = document.documentElement.clientWidth
    },

    getPOInputs() {
      if (this.countrySettings.length) {
        let inputs = {}

        let newSettings = PO_FIELDS

        newSettings.map((field) => {
          const { key, name, length, required, ziplookup, endOfSection } = field
          inputs[name] = {
            inputProps: {
              name
            },
            eventHandlers: {},
            layoutConfig: {
              endOfSection
            }
          }
          const textInputProps = {
            component: AppInputNew,
            inputProps: {
              ...inputs[name].inputProps,
              autocomplete: AUTOCOMPLETE_ATTRIBUTES[name],
              maxLength: Number(length),
              readonly: this.isLoading,
              required,
              characterCountEnabled: true,
              lookup: ziplookup,
              error:
                this.poInfo.poFields.errorDetails.errorField === name
                  ? true
                  : this.$v.poInfo.poFields.fields[name]
                  ? this.$v.poInfo.poFields.fields[name].$error
                  : false,

              validationMessage: this.poInfo.poFields.errorMessages[name],
              placeholder: this.locale[key],
              fieldValue: this.poInfo.poFields.fields[name],
              multiByteMessage: this.locale[MULTIBYTE_ERROR_MESSAGE[name]],
              multiByteCountries: this.multiByteCountries,
              displayPOBoxDisclaimer: name === 'street1' ? true : false,
              type: 'text'
            },
            eventHandlers: {
              onBlur: this.validateDetailsField('poFields'),
              onChange: this.fieldChange('poFields'),
              onInputApply: this.onKeyupfield('poFields')
            }
          }

          /**
           * text input
           */
          inputs[name] = { ...inputs[name], ...textInputProps }

          /**
           * making certain fields readonly/disabled for billing address
           */
          if (['ltsa'].includes(name)) {
            inputs[name].inputProps = {
              ...inputs[name].inputProps,
              readonly: true,
              disabled: inputs[name].component === AppSelect ? true : undefined // readonly will not work for select element as per HTML spec
            }
          }
          if (name === 'phone') {
            inputs[name].inputProps = {
              ...inputs[name].inputProps,
              mask: USA_PHONE_NUMBER_MASK // TODO: for future reference. If PO is expanded to multiple countries, get this mask from API
            }
          }
        })
        return inputs
      }
    },
    getAddressInputs(type) {
      if (this.countrySettings.length) {
        let inputs = {}
        const fieldSettings = []

        let newSettings = []

        this.countrySettings.map((field, index) => {
          const { name } = field

          if (name !== 'first_name' && name !== 'last_name') {
            // Exclude first name, last name for PO
            fieldSettings.push(field)
          }
          if (index === 0) {
            /**
             * inserting business name field after names (assuming the first 2 inputs are first and last, or last and first) for shipping address
             */
            fieldSettings.push({
              key: 'pp.summary.po.business',
              name: 'orgName',
              required: false,
              length: '30'
            })
          }
        })

        if (type !== 'PO') {
          newSettings = fieldSettings
        }

        newSettings.map((field) => {
          const { key, name, length, required, ziplookup, endOfSection } = field
          if (this.poInfo[`${type}Address`].fields[name]) {
            inputs[name] = {
              inputProps: {
                name
              },
              eventHandlers: {},
              layoutConfig: {
                endOfSection
              }
            }
            const textInputProps = {
              component: AppInputNew,
              inputProps: {
                ...inputs[name].inputProps,
                autocomplete: AUTOCOMPLETE_ATTRIBUTES[name],
                maxLength: Number(length),
                readonly: this.isLoading,
                required,
                characterCountEnabled: true,
                lookup: ziplookup,
                error:
                  this.poInfo[`${type}Address`].errorDetails.errorField === name
                    ? true
                    : false,
                validationMessage:
                  this.poInfo[`${type}Address`].errorMessages[name],
                placeholder: this.locale[key],
                fieldValue: this.poInfo[`${type}Address`].fields[name],
                multiByteMessage: this.locale[MULTIBYTE_ERROR_MESSAGE[name]],
                multiByteCountries: this.multiByteCountries,
                displayPOBoxDisclaimer: false,
                type: 'text'
              },
              eventHandlers: {
                // Commented out since they'll be read-only
                //   onBlur: this.validateDetailsField(`${type}Address`),
                //   onChange: this.fieldChange(`${type}Address`),
                //   onInputApply: this.onKeyupfield(`${type}Address`)
              }
            }
            /**
             * text input
             */
            inputs[name] = { ...inputs[name], ...textInputProps }

            /**
             * making all fields readonly/disabled for billing address
             */
            //   if (type === 'shipping' && ['state', 'postalcode'].includes(name)) {
            inputs[name].inputProps = {
              ...inputs[name].inputProps,
              readonly: true,
              disabled: inputs[name].component === AppSelect ? true : undefined // readonly will not work for select element as per HTML spec
            }
            //   }

            /**
             * remove postalcode field if no value is entered by the Advisor in Core when the field is optional
             */
            if (type === 'shipping' && name === 'postalcode') {
              if (!this.poInfo[`${type}Address`].fields[name] && !required) {
                delete inputs[name]
              }
            }
          } else {
          }
        })
        return inputs
      }
    },

    async nextStep(value) {
      try {
        const { token, locale } = this.$route.params
        const route = `/${token}/${locale}/review`

        this.$store.commit('SET_PAYMENT_METHOD', 'PO')
        this.$store.dispatch('setBillingAddressFromPOInfo')

        this.$router.push(route)
      } catch (e) {
        console.error(e)
      } finally {
      }
    },

    fieldChange(type) {
      return (change) => {
        this.setflag = false
        this.isDoublebyte = false
        if (this.selectedCountry === 'JPN' && change.name === 'postalcode') {
          change.value = change.value.replace('−', '-').replace('ー', '-')
        }
        const eventType = event.type
        this.$store
          .dispatch('poFormFieldChange', {
            type,
            ...change,
            eventType
          })
          .then(() => {
            /**
             * reset error so that validation error isn't shown to the user before they could type anything
             * does not reset the error when user types in 'Other', only resets when user uses dropdown 'Other'
             */
            if (change.value === 'Other' && eventType === 'change') {
              this.$v.poInfo[`${type}`].fields[change.name].$reset()
            }
          })
      }
    },

    taxFormFieldChange(change) {
      this.$store.dispatch('taxFormFieldChange', change)
    },
    taxFieldOnBlur(change) {
      this.$v.taxDetails.fields[change.name].$touch()
    },

    validateField(data) {
      this.isDoublebyte = false
      this.setflag = false
      this.$v[data.name].$touch()
    },
    validateDetailsField(type) {
      return (data) => {
        this.isDoublebyte = false
        this.setflag = false
        const addressfields = `${type}`
        if (this.$v.poInfo[addressfields].fields[data.name]) {
          this.$v.poInfo[addressfields].fields[data.name].$touch()
          if (data.name === 'postalcode' && this.shouldZipCodeLookUp) {
            if (data.value === '') {
              return
            }
            if (this.selectedCountry === 'JPN') {
              let postCodeVal = ''

              if (this.poInfo[addressfields].fields.postalcode !== null) {
                postCodeVal = this.poInfo[addressfields].fields.postalcode
                  .replace('−', '-')
                  .replace('ー', '-')
              }
              if (
                postCodeVal.indexOf('-') === -1 &&
                this.poInfo[addressfields].fields.postalcode !== null
              ) {
                var postCodeVal1 = postCodeVal.slice(0, 3)
                var postCodeVal2 = postCodeVal.slice(3, 7)
                postCodeVal = `${postCodeVal1}-${postCodeVal2}`
              }

              if (
                postCodeVal.indexOf('-') !== -1 &&
                this.poInfo[addressfields].fields.postalcode !== null
              ) {
                if (this.poInfo[addressfields].fields.postalcode !== null) {
                  if (data.value === this.zipCodeLookup) {
                    return
                  }
                  this.$store
                    .dispatch('fetchStates', {
                      zipCode: postCodeVal,
                      country: this.selectedCountry,
                      field: data.name,
                      type
                    })
                    .then(() => {
                      if (this.errorField !== 'postalcode') {
                        this.$store.dispatch('setZipCodeLookup')
                      }
                    })
                }
              }
            } else {
              if (data.value === this.zipCodeLookup) {
                return
              }
              this.$store
                .dispatch('fetchStates', {
                  zipCode: data.value,
                  country: this.selectedCountry,
                  field: data.name,
                  type
                })
                .then(() => {
                  if (this.errorField !== 'postalcode') {
                    this.$store.dispatch('setZipCodeLookup')
                  }
                })
            }
          }
        }
      }
    },
    onKeyupfield(type) {
      return (data) => {
        if (isit.doubleByte(data) && this.setflag === false) {
          this.isDoublebyte = false
          this.setflag = true
        } else {
          this.isDoublebyte = true
          this.setflag = false
        }

        if (
          this.$v.$invalid === false &&
          this.error === false &&
          this.detailsInfo.country_3 === 'JPN'
        ) {
          this.isDoublebyte = true
        }
      }
    },
    selectTaxType(event) {
      if (event.target.value !== this.taxDetails.type) {
        this.$store.dispatch('changeTaxType', event.target.value)
        /**
         * reset Tax form error when Tax type changes
         * Some tax type share similar fields causing duplicate errors to show up for multiple tax types
         */
        this.$v.taxDetails.$reset()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'ac-sasskit/core';
@import 'ac-sasskit/base';
@import 'ac-forms/core';
@import 'ac-forms/base';
@import 'ac-forms/modules/form-icons';
@import 'ac-forms/modules/form-textbox';
@import '@/styles/variables.scss';
@import 'ac-forms/modules/form-tooltip';
@import 'ac-forms/modules/form-selector';
@import 'ac-forms/modules/form-alert';
@import 'ac-forms/modules/form-checkbox';

.tax__section-addressheading {
  margin: $app-margin-xxxx-sm 0;
  font-weight: 600;
  width: fit-content;
}

.form-tooltip-info-override {
  width: 15rem;
  margin-bottom: 10px;
  bottom: 18px;
  right: 0px;
}

.form-tooltip-after-override {
  margin-left: 0.5rem;
}

.form-tooltip-content-override {
  padding: 0.75rem 1.0625rem 0.8125rem;
}

.form-alert {
  padding: 0.75rem 1rem; // overriding because of corrupt padding values for this class
  p /deep/ a {
    text-decoration: underline;
  }
}

.checkbox__container {
  white-space: nowrap;
  display: flex;
  align-items: baseline;
  margin-bottom: $app-margin-xx-xs;
}

.checkbox__label {
  color: swatch(glyph-gray);
  @include typography(body);
  @include direction(
    padding-left,
    $app-margin-xxx-xs,
    padding-right,
    $app-margin-xxx-xs
  );
  white-space: normal;
}

.smsOptIn {
  display: flex;
  align-items: baseline;
}

.shipping_address__container {
  padding-bottom: 14px;
}

.inline-field {
  display: inline-block;
}

.inline-field + .inline-field {
  padding-left: 1rem;
}

.checkout {
  color: swatch(glyph-gray);
  text-align: left;
}

.payment__selection {
  @include direction(margin-left, 0px, margin-right, 0px);
  @include viewport(large) {
    border-bottom: 1px solid swatch(fill-gray-tertiary);
  }
  @include viewport(small) {
    border-bottom: none;
  }
}
h1:focus {
  outline: none;
}
.checkout__header-label {
  h1 {
    margin-block-start: 0;
    margin-block-end: 0;
    @include typography(eyebrow);
  }
  @include typography(eyebrow);
  padding-bottom: $app-margin-xxx-sm;
  border-bottom: 1px solid swatch(fill-gray-tertiary);
}

.checkout__header-price {
  padding-top: $app-padding-x-sm;
  @include typography(body-reduced);
  color: black;
  float: right;
  text-align: right;
  border-bottom: 1px solid swatch(fill-gray-tertiary);
}
.checkout__header-price:lang(ar-AE) {
  padding-top: $app-padding-x-sm;
  @include typography(body-reduced);
  color: black;
  float: right;
  text-align: left;
}

.checkout__header-text {
  color: black;
}

.checkout__header,
h2 {
  margin-top: $app-margin-lg;
  @include typography(headline-reduced);
  @include viewport(medium) {
    @include typography(eyebrow-super);
  }
  color: swatch(glyph-gray);
}
h2:focus {
  outline: none;
}
.checkout_button {
  padding-top: $app-margin-xx-xs;
  padding-bottom: $app-margin-xx-xs;
}
.form-label-small {
  margin-top: 4px;
  letter-spacing: unset;
  @include typography(body);
  @include viewport(medium) {
    @include typography(caption);
  }
  @include viewport(small) {
    @include typography(caption);
  }
}
.checkout_button--creditline1 {
  @include typography(body);
  font-weight: 600;
  color: swatch(glyph-gray);
}
.checkout_button--creditline2 {
  @include typography(body-reduced);
  color: swatch(glyph-gray);
}

.card {
  border: none;
}
.card-body {
  margin-bottom: -50px;
  padding: 0;
  @include viewport(small) {
    border-bottom: none;
    margin-bottom: -50px !important;
  }
  @include viewport(large) {
    margin-bottom: -50px;
  }
  @include viewport(medium) {
    margin-bottom: -40px;
  }
}

.creditcard__section-details {
  margin-top: $app-margin-x-sm;
  margin-bottom: $app-margin-lg;
}

.creditcard__section-label {
  @include typography(body);
  color: swatch(glyph-gray);
  margin-bottom: $app-margin-xx-xs;
}

.body__text {
  @include typography(body);
  color: swatch(glyph-gray);
  margin-bottom: $app-margin-xx-xs;
}

.creditcard__section--field {
  padding-bottom: 0;
}

.creditcard__section-addressheading {
  display: block;
  margin: $app-margin-xxxx-sm 0;
  @include typography(body);
  font-weight: 600;
  color: swatch(glyph-gray);
}

.form-selector-group {
  margin-bottom: 36px;
  margin-top: 0px;
  @include viewport(small) {
    margin-bottom: $app-padding-xxxx-lg;
  }
}
.form-selector .form-selector-label {
  margin-bottom: $app-margin-xx-xs;
}

.form-selector-label .form-selector-left-col {
  @include direction(text-align, left, text-align, right);
  @include direction(padding-left, 0, padding-right, 0);
  position: relative;
  align-self: center;
}

.form-selector-label {
  min-height: 4.88235rem;
  @include viewport(medium) {
    min-height: 4.77rem;
  }
}
.form-selector {
  @include direction(padding-right, 0px, padding-left, 0px);
}
.form-selector-input {
  padding: $app-padding-xxx-sm;
}
.form-tooltip-content {
  width: auto;
  height: auto;
}
.continue__review {
  padding-top: $app-margin-lg;
  @include viewport(small) {
    padding-top: 0px;
  }

  @include direction(padding-right, 10px, padding-left, 0px);
  @include direction(text-align, right, text-align, left);
}
.row {
  padding-left: 0px;
  padding-right: 0px;
}

.continue__review .button {
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
}

.termsFailureModal__heading {
  text-align: center;
  @include typography(headline-reduced);
  padding-bottom: $app-padding-xxxx-sm;
  @include viewport(small) {
    padding-bottom: $app-margin-xxxx-sm;
  }
}
.termsFailureModal__content {
  @include direction(text-align, center, text-align, center);
  @include typography(body-tight);
  padding-top: $app-padding-zero;
  margin-top: 0 !important;
  p {
    margin: 0px;
  }
}

.termsFailureModal__action {
  margin-top: $app-margin-xxxx-sm;
  text-align: -webkit-center;
  .button {
    display: block;
    margin-top: $app-margin-xxxx-xs;
    @include viewport(small) {
      width: 70%;
    }
  }
}
.form-textbox-righticon {
  @include direction(text-align, right, text-align, left);
}
.displayNone,
.modal-close {
  display: none;
  @include direction(left, 24px, right, 24px);
}
.column {
  @include direction(padding-right, 0px, padding-left, 0px);
}

img {
  width: 70px;
  height: 28px;
  display: block;
}

.button-spinner {
  top: rem(10);
  position: relative;
  text-align: -webkit-center;
}

#loading-text {
  display: none;
}

.error-message {
  margin: $app-margin-xx-xds 0;
  border-color: rgba(227, 0, 0, 0.4);
  background: #fff2f4;
  border-radius: 5px;
  border: 1px solid swatch(glyph-red);
}
.error-message-form {
  padding-left: $app-padding-xx-sm;
  vertical-align: middle;
}
.error-message__text {
  display: flex;
  color: #e30000;
  font-weight: $app-font-weight-lg;
  font-size: 0.9375em;
  padding: $app-margin-xxx-sm $app-padding-x-sm $app-padding-xx-m
    $app-padding-xx-m;
  img {
    width: 14px;
    height: 20px;
  }
}
.confirmModal__action {
  margin-top: $app-margin-xxxx-sm;
  text-align: -webkit-center;
  .button {
    display: block;
    margin-top: $app-margin-x-xss;
    @include viewport(small) {
      width: 70%;
    }
  }
}

.hr {
  border-bottom: 1px solid #d2d2d7;
  margin: 28px 0;
}
</style>
