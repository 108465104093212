<template>
  <div class="product section-content">
    <div class="row checkout__header">
      <div class="column large-6 small-8 checkout__header-label">
        <span>{{ locale['pp.payment.refunds.label'] }}</span>
      </div>
      <div class="column large-6 small-4 checkout__header-price">
        <span class="checkout__header-tax">
          {{ locale['pp.payment.refund.amt'] }}
        </span>
        <span> {{ amount }}</span>
      </div>
    </div>
    <b-col v-if="isVerifiedError" cols="12" class="error-message">
      <div
        class="error-message__text"
        id="text-error-focus"
        tabindex="-1"
        role="group"
        aria-label="Notification"
      >
        <img
          alt="Error"
          class="input-field__icons"
          src="../../assets/warning.svg"
        />
        <span
          class="error-message-refund"
          v-html="locale[`pp.payment.error.bankinfo.message`]"
        ></span>
      </div>
    </b-col>
    <div class="checkout__header-howtopay">
      <h1 class="column large-12 small-8">
        {{ locale['pp.payment.refund.bank.info'] }}
      </h1>
      <span class="row large-12 small-12 checkout__header-text">
        {{ locale['pp.payment.refund.message'] }}
      </span>
    </div>

    <div class="row form-group form__section--cardinfo">
      <div class="row form__section--field">
        <div class="column large-6 medium-6 small-12 form__section--field">
          <div
            v-for="{
              name,
              textKey,
              fieldConstraints,
              minLength,
              maxLength,
              mandatory,
              type,
              vMaskPlaceholder,
              confirmationRequired,
              tooltip
            } in bankFieldsDetails"
            :key="name"
          >
            <app-input-base-bank-field
              @onChange="fieldChange"
              :name="name"
              :textKey="textKey"
              :fieldConstraints="fieldConstraints"
              :minLength="minLength"
              :maxLength="maxLength"
              :required="mandatory"
              :type="type"
              :vMaskPlaceholder="vMaskPlaceholder"
              :confirmationRequired="confirmationRequired"
              :firstInput="true"
              :error="error"
              :hasQuestionMarkIcon="tooltip"
            />
            <app-input-base-bank-field
              @onChange="fieldChange"
              v-if="confirmationRequired"
              :name="name"
              :textKey="textKey"
              :fieldConstraints="fieldConstraints"
              :minLength="minLength"
              :maxLength="maxLength"
              :required="mandatory"
              :type="type"
              :vMaskPlaceholder="vMaskPlaceholder"
              :confirmationRequired="confirmationRequired"
              :secondInput="true"
              :error="error"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row continue__review">
      <div class="column large-6 medium-6 small-12">
        <app-button
          id="continue-payment-form"
          :type="buttonSizeChange"
          :disabled="isFormEmpty"
          :isLoading="isFetching"
          @onClick="nextStep"
        >
          {{ locale['pp.payment.continuereview'] }}
        </app-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import AppButton from '@/components/app-button'
import AppSelect from '@/components/app-select'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import router from '../../router'
import store from '../../store'
import Modal from '@marcom/ac-modal'
import { focusOnSelector, buttonType } from '@/utilities/utils'
import { ARIA_LIVE_TIME_OUT_DELAY } from '@/utilities/timeout'
import AppInputBaseBankField from '../../components/app-input-base-bank-field.vue'

export default {
  components: {
    AppButton,
    AppSelect,
    appMoonLoader: MoonLoader,
    AppInputBaseBankField
  },

  title() {
    if (this.isRefundType) {
      return `${this.locale['pp.payment.refund.pagetitle']}`
    } else {
      return `${this.locale['pp.payment.checkout']}`
    }
  },

  data() {
    return {
      showed: '',
      isPaymentSelected: true,
      paymentMethod: '',
      debitModal: '',
      isModalClose: true,
      isLoaded: false,
      isCCOpen: false,
      visible: false,
      windowWidth: 0,
      isLoanAccount: false,
      isFieldEmpty: true
    }
  },
  computed: {
    ...mapState({
      locale: (state) => state.mainStore.localization,
      amount: (state) => state.mainStore.amount,
      accountType: (state) => state.mainStore.accountType,
      isRefundType: (state) => state.mainStore.refundType,
      isFetching: (state) => state.refundStore.status.isFetching,
      error: (state) => state.refundStore.status.error,
      cardType: (state) => state.formStore.cardType,
      country: (state) => state.refundStore.country,
      errorMessage: (state) => state.formStore.errorDetails.errorMessage,
      errorField: (state) => state.refundStore.errorDetails.errorField,
      isRtl: (state) => state.mainStore.isRtl,
      type: (state) => state.mainStore.details.acceptance_types,
      termsChecked: (state) => state.termsStore.termStatus,
      tokenValue: (state) => state.mainStore.tokenValue,
      isStateFetched: (state) => state.mainStore.isStateFetched,
      bankFieldsDetails: (state) => state.refundStore.bankFieldsDetails,
      fields: (state) => state.refundStore.bankClientInfo,
      bankClientInfoConfirmationRequiredList: (state) =>
        state.refundStore.bankClientInfoConfirmationRequiredList,
      isFormEmpty: (state) => state.refundStore.isFormEmpty,
      isVerifiedError: (state) => state.refundStore.isVerifiedError,
      isVerified: (state) => state.refundStore.isVerified,
      countrycode: (state) => state.mainStore.countrycode
    }),

    buttonSizeChange() {
      return buttonType(this.windowWidth)
    },
    fullwidthButton() {
      if (this.$route.params.locale === 'ru_RU') {
        return true
      } else {
        return false
      }
    },
    fullwidthButtonnor() {
      if (this.$route.params.locale === 'no_NO') {
        return true
      } else {
        return false
      }
    },
    fullwidthButtonpor() {
      if (this.$route.params.locale === 'pt_PT') {
        return true
      } else {
        return false
      }
    },
    isDisabledForm() {
      if (this.error || this.isFormEmpty) {
        return true
      } else return false
    }
  },
  created: function () {
    const that = this
    setTimeout(function () {
      that.isLoaded = true
    }, ARIA_LIVE_TIME_OUT_DELAY)
    window.addEventListener(
      'message',
      (event) => {
        let urlHack = document.createElement('a')
        urlHack.href = this.deviceProfileURL
        let domainURL = `https://${urlHack.host}`
        if (event.origin === domainURL) {
          let data = JSON.parse(event.data)
          if (data !== undefined) {
            this.$store.dispatch('deviceProfilingStatus', data)
          }
        }
      },
      false
    )
    this.checkAccountType()
  },
  watch: {
    isVerifiedError() {
      if (this.errorMessage.length > 0) {
        focusOnSelector('error-message__text')
      }
    },
    selectedCountry(oldval, val) {
      if (oldval !== val) {
      }
    },
    isStateFetched: function (val, oldVal) {
      if (this.paymentMethod === 'cc') {
        if (val === false) {
          var node = document.createElement('span')
          var textnode = document.createTextNode('loading address fields')
          node.appendChild(textnode)
          document.getElementById('loading-text').appendChild(node)
        } else {
          var list = document.getElementById('loading-text')
          list.removeChild(list.childNodes[0])
        }
      }
    }
  },
  mounted() {
    if (this.bankFieldsDetails.length === 0) {
      this.$store.dispatch('getBankFieldsValue', this.countrycode)
    }
    this.$nextTick(function () {
      window.addEventListener('resize', this.getWindowWidth)

      //Init
      this.getWindowWidth()
    }),
      focusOnSelector('h1')
    if (this.errorField === '') {
      this.$store.dispatch('defaultFormfield')
    }
    this.failmodal = Modal.createStandardModal()
    this.failmodal.modalElement.setAttribute(
      'aria-label',
      this.locale['pp.message.error']
    )
    const failel = document.getElementById('confirmModal-fail')
    this.failmodal.appendContent(failel)
    this.failmodal.modalElement.removeAttribute('data-modal-close')
    this.failmodal.modalElement
      .getElementsByTagName('div')[0]
      .getElementsByTagName('div')[0]
      .removeAttribute('data-modal-close')
    this.failmodal.modalElement
      .getElementsByTagName('div')[0]
      .getElementsByTagName('div')[0]
      .getElementsByTagName('div')[0]
      .removeAttribute('data-modal-close')
    this.failmodal.modalElement.getElementsByTagName('button')[1].remove()
    this.debitModal = Modal.createStandardModal()
    this.debitModal.modalElement.setAttribute('aria-label', 'Debit Card Modal')
    const el = document.getElementById('debit_Modal')
    this.debitModal.appendContent(el)

    this.debitModal.modalElement.removeAttribute('data-modal-close')

    this.debitModal.modalElement
      .getElementsByTagName('div')[0]
      .getElementsByTagName('div')[0]
      .removeAttribute('data-modal-close')
    this.debitModal.modalElement
      .getElementsByTagName('div')[0]
      .getElementsByTagName('div')[0]
      .getElementsByTagName('div')[0]
      .removeAttribute('data-modal-close')

    if (
      this.$route.name === 'PaymentForm' &&
      this.type === 'Active' &&
      !this.termsChecked
    ) {
      const { token, locale } = this.$route.params

      this.$router.push(`/${token}/${locale}/terms`)
    }
    this.isModalClose = false
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth)
  },
  methods: {
    checkAccountType() {
      if (this.accountType) {
        this.visible = true
        this.isLoanAccount = true
        this.isPaymentSelected = true
        this.paymentMethod = 'cc'
      } else {
        this.visible = false
        this.isLoanAccount = false
      }
    },
    getWindowWidth(event) {
      this.windowWidth = document.documentElement.clientWidth
    },
    nextStep(value) {
      // should be validated, need to change
      const { token, locale } = this.$route.params
      this.$store
        .dispatch('verifyACRTDetails', {
          transactionId: token,
          locale: locale
        })
        .then(() => {
          if (this.isVerified === true) {
            const route = `/${token}/${locale}/review`
            this.$router.push(route)
          }
        })
        .then(() => {
          if (this.isVerified === false) {
            document.getElementById('text-error-focus').focus()
          }
        })
    },
    fieldChange(change) {
      this.$store.dispatch('updateBankClientInfo', change)
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'ac-sasskit/core';
@import 'ac-sasskit/base';
@import 'ac-forms/core';
@import 'ac-forms/base';
@import 'ac-forms/modules/form-icons';
@import 'ac-forms/modules/form-textbox';
@import '@/styles/variables.scss';
@import 'ac-forms/modules/form-tooltip';
@import 'ac-forms/modules/form-selector';

.checkout {
  color: swatch(glyph-gray);
  text-align: left;
  @include viewport(small) {
    padding-top: $app-padding-xxx-sm;
  }
  @include viewport(medium) {
    padding-top: $app-padding-xxx-sm;
  }
}

.payment__selection {
  @include direction(margin-left, 0px, margin-right, 0px);
  @include viewport(large) {
    border-bottom: 1px solid swatch(fill-gray-tertiary);
  }
  @include viewport(small) {
    border-bottom: none;
  }
}
.checkout__header {
  @include typography(eyebrow);
  color: swatch(glyph-gray);
  margin-top: $app-margin-xx-xs;
  padding-bottom: $app-margin-xxx-sm;
  border-bottom: 1px solid swatch(fill-gray-tertiary);
}
h1:focus {
  outline: none;
}
.checkout__header-label {
  @include typography(eyebrow);
  color: swatch(glyph-gray);
}

.checkout__header-price {
  padding-top: $app-padding-x-sm;
  @include typography(body-reduced);
  color: black;
  float: right;
  text-align: right;
}
.checkout__header-price:lang(ar-AE) {
  padding-top: $app-padding-x-sm;
  @include typography(body-reduced);
  color: black;
  float: right;
  text-align: left;
}

.checkout__header-text {
  @include typography(body-reduced);
  padding-top: $app-padding-sm;
}
.checkout__header-tax {
  @include viewport(small) {
    display: none;
  }
}

.checkout__header-howtopay,
h2 {
  margin-top: $app-margin-lg;
  margin-bottom: 20px;
  @include typography(headline-reduced);
  @include viewport(medium) {
    @include typography(eyebrow-super);
  }
  color: swatch(glyph-gray);
}
h2:focus {
  outline: none;
}
.checkout_button {
  padding-top: $app-margin-xx-xs;
  padding-bottom: $app-margin-xx-xs;
}
.form-label-small {
  margin-top: 4px;
  letter-spacing: unset;
  @include typography(body);
  @include viewport(medium) {
    @include typography(caption);
  }
  @include viewport(small) {
    @include typography(caption);
  }
}
.checkout_button--creditline1 {
  @include typography(body);
  font-weight: 600;
  color: swatch(glyph-gray);
}
.checkout_button--creditline2 {
  @include typography(body-reduced);
  color: swatch(glyph-gray);
}

.card {
  border: none;
}
.card-body {
  margin-bottom: -50px;
  padding: 0;
  @include viewport(small) {
    border-bottom: none;
    margin-bottom: -50px !important;
  }
  @include viewport(large) {
    margin-bottom: -50px;
  }
  @include viewport(medium) {
    margin-bottom: -40px;
  }
}

.form__section-details {
  margin-top: $app-margin-x-sm;
  margin-bottom: $app-margin-lg;
}

.form__section-label {
  @include typography(body);
  color: swatch(glyph-gray);
  margin-bottom: $app-margin-xx-xs;
}
.form__section--cardinfo {
  padding-bottom: $app-padding-lg;
  border-bottom: 1px solid swatch(fill-gray-tertiary);
  @include viewport(small) {
    padding-bottom: $app-margin-xx-md;
  }
}

.form__section--field {
  padding-bottom: 0;
}

.form__section--expire {
  @include viewport(small) {
    padding-bottom: 0;
  }
}
.form__section--expireARE {
  @include viewport(small) {
    padding-bottom: $app-margin-xx-xs;
  }
  padding-left: 10px !important;
}
.form__section--cvvARE {
  @include viewport(small) {
    padding-left: 0px;
  }
  @include viewport(medium) {
    margin-right: $app-padding-x-sm;
  }
  @include viewport(large) {
    padding-right: $app-margin-xx-xs;
  }
}
.form__section-addressheading {
  display: block;
  margin: $app-margin-xxxx-sm 0;
  @include typography(body);
  font-weight: 600;
  color: swatch(glyph-gray);
}

.form-selector-group {
  margin-bottom: 36px;
  margin-top: 0px;
  @include viewport(small) {
    margin-bottom: $app-padding-xxxx-lg;
  }
}
.form-selector .form-selector-label {
  margin-bottom: $app-margin-xx-xs;
}
.form-selector-label .form-selector-left-col {
  position: relative;
  align-self: center;
  text-align: left;
  padding-left: 0;
}
.form-selector-label {
  min-height: 4.88235rem;
  @include viewport(medium) {
    min-height: 4.77rem;
  }
}
.form-selector {
  @include direction(padding-right, 0px, padding-left, 0px);
}
.form-selector-input {
  padding: $app-padding-xxx-sm;
}
.form-tooltip-content {
  width: auto;
  height: auto;
}
.continue__review {
  padding-top: $app-margin-lg;

  @include direction(padding-right, 10px, padding-left, 0px);
  @include direction(text-align, right, text-align, left);
}
.row {
  padding-left: 0px;
  padding-right: 0px;
}

.debitModal-close__icons {
  width: 10px;
  height: 10px;
  margin-bottom: 3px;
}
.debitModal__heading {
  text-align: center;
  @include typography(headline-reduced);
  padding-bottom: $app-padding-sm;
  @include viewport(small) {
    padding-bottom: $app-margin-xxxx-sm;
  }
}
.debitModal__content {
  @include direction(text-align, center, text-align, center);
  @include typography(body-tight);
  padding-top: $app-padding-zero;
  margin-top: 0 !important;
}

.continue__review .button {
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
}

.debitModal__action {
  margin-top: $app-margin-xxxx-sm;
  text-align: -webkit-center;

  .button {
    display: block;
    margin-top: $app-margin-x-xss;
    @include viewport(small) {
      width: 70%;
    }
  }
  .button--link {
    margin-left: $app-margin-x-xss;
    margin-right: $app-margin-x-xss;
  }
}

.termsFailureModal__heading {
  text-align: center;
  @include typography(headline-reduced);
  padding-bottom: $app-padding-xxxx-sm;
  @include viewport(small) {
    padding-bottom: $app-margin-xxxx-sm;
  }
}
.termsFailureModal__content {
  @include direction(text-align, center, text-align, center);
  @include typography(body-tight);
  padding-top: $app-padding-zero;
  margin-top: 0 !important;
  p {
    margin: 0px;
  }
}

.termsFailureModal__action {
  margin-top: $app-margin-xxxx-sm;
  text-align: -webkit-center;
  .button {
    display: block;
    margin-top: $app-margin-xxxx-xs;
    @include viewport(small) {
      width: 70%;
    }
  }
}
.form-textbox-righticon {
  @include direction(text-align, right, text-align, left);
}
.displayNone,
.modal-close {
  display: none;
  @include direction(left, 24px, right, 24px);
}
.column {
  @include direction(padding-right, 0px, padding-left, 0px);
}

.form__section--cvv {
  @include viewport(small) {
    padding-left: 0px;
  }
  @media only screen and (min-width: 750px) {
    padding-left: $app-padding-x-sm;
  }
  @include viewport(large) {
    padding-left: $app-margin-xx-xs;
  }
}
.button-spinner {
  top: rem(10);
  position: relative;
  text-align: -webkit-center;
}

#loading-text {
  display: none;
}

.debit_info {
  position: absolute;
  top: $app-margin-bottom-mmd;
  right: $app-right-mg;
  @include typography(caption);
  @include viewport(medium) {
    position: absolute;
    top: $app-margin-bottom-mmd;
    right: $app-right-mg;
    @include typography(caption);
  }
  @include viewport(small) {
    position: relative;
    top: $app-top-xxxx-xs;
    padding-top: $app-margin-xx-md;
    right: 0px;
    @include typography(caption);
  }
}
.error-message {
  margin: $app-margin-xx-xds 0;
  border-color: rgba(227, 0, 0, 0.4);
  background: #fff2f4;
  border-radius: 8px;
  border: 1px solid swatch(glyph-red);
  outline: none;
  &:focus {
    outline: 0;
    outline-offset: 1px;
    border-color: swatch(fill-gray-tertiary);
    box-shadow: none;
  }
}
.error-message-refund {
  padding-left: $app-padding-xx-sm;
  vertical-align: middle;
}
.error-message__text {
  font-weight: $app-font-weight-lg;
  font-size: 15px;
  padding: $app-margin-xxx-sm $app-padding-x-sm $app-padding-xx-m
    $app-padding-xx-m;
  outline: 0;
  &:focus {
    outline: 4px solid $focus-blue;
  }
}
.confirmModal__action {
  margin-top: $app-margin-xxxx-sm;
  text-align: -webkit-center;
  .button {
    display: block;
    margin-top: $app-margin-x-xss;
    @include viewport(small) {
      width: 70%;
    }
  }
}
</style>
