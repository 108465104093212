import service from '@/features/redirect/service/redirectPageService'
import { setCustomerInformationInState } from '@/features/payment/utilities/billingAddress'
import router from '@/router'

const timeout = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const initialState = {
  status: {
    isFetching: true,
    isFetched: false,
    error: false,
    errorMessage: null
  }
}

const state = JSON.parse(JSON.stringify(initialState))

const mutations = {
  CHECK_STATUS_PAYMENT_REQUEST(state, payload) {
    Object.assign(state.status, initialState.status)
    state.status.isFetching = true
  },
  CHECK_STATUS_PAYMENT_FAILURE(state, payload) {
    state.status.isFetching = false
    state.status.isFetched = true
    state.status.error = true
    state.status.errorMessage = payload
  }
}

const actions = {
  async checkPaymentStatus({ commit, dispatch }, payload) {
    const delay = 5000
    let timer = 0
    let status
    let supportReversal
    commit('CHECK_STATUS_PAYMENT_REQUEST')

    try {
      while (status !== 'COMP') {
        timer = timer + delay
        const response = await service.getStatus(payload)

        status = response.data.transactionStatus
        supportReversal = response.data.supportReversal

        if (status === 'CNCL') {
          router.push(`/${payload.token}/${payload.locale}/cancelled`)
          break
        } else if (status === 'COMP') {
          commit('SET_PAYMENT_COMPLETE', response.data)
          commit('CHECK_STATUS_PAYMENT_SUCCESS', response.data)
          commit('SET_VAT_AND_TAX', response.data)
          setCustomerInformationInState(response.data, commit)

          router.push(`/${payload.token}/${payload.locale}/review`)
          break
        }

        if (timer < 30000 && status !== 'ERROR') {
          await timeout(delay)
        } else if (timer > 30000) {
          commit('CHECK_STATUS_PAYMENT_FAILURE', supportReversal)
          break
        }
      }
    } catch (error) {
      supportReversal = error.response.data.supportReversal
      commit('CHECK_STATUS_PAYMENT_FAILURE', supportReversal)
    }
  }
}

const getters = {}

const redirectPageStore = {
  state,
  getters,
  mutations,
  actions
}

export default redirectPageStore
