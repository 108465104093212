import service from '@/features/payment/service/service'

export const initialState = {
  bankFieldsDetails: [],
  bankClientInfo: {},
  bankClientInfoConfirmationRequiredList: {},
  country: '',
  isVerified: false,
  status: {
    isFetching: false,
    isFetched: false,
    error: false,
    isLoading: false
  },
  isFormEmpty: true,
  errorDetails: {
    errorCode: null,
    errorDesc: null,
    errorField: {},
    errorMessage: null
  },
  isVerifiedError: false,
  bankName: '',
  errorFieldsArray: [],
  isACRT: false,
  emailAddress: '',
  isSubmitted: false
}
const state = JSON.parse(JSON.stringify(initialState))

const mutations = {
  GET_BANK_FIELDS_VALUE(state, payload) {
    state.country = payload.country
    state.isACRT = true
    state.bankFieldsDetails = payload.bankFields
    state.bankFieldsDetails.forEach((field) => {
      field.minLength =
        field.fieldConstraints[field.fieldConstraints.length - 1].minLength

      field.maxLength =
        field.fieldConstraints[field.fieldConstraints.length - 1].maxLength

      field.defaultMinMaxLength = true
    })
    payload.bankFields.forEach((field) => {
      state.bankClientInfo[field.name] = ''
      if (field.confirmationRequired) {
        state.bankClientInfoConfirmationRequiredList[field.name] = {
          firstInputOfCR: '',
          secondInputOfCR: '',
          areBothInputsEqual: true,
          copyOfInput: '',
          inputObfuscated: '',
          minLength:
            field.fieldConstraints[field.fieldConstraints.length - 1].minLength
        }
      }
    })
    state.status.isFetching = false
  },
  VERIFY_ACRT_DETAILS(state, payload) {
    state.isVerified = true
    if (Object.keys(state.bankClientInfoConfirmationRequiredList).length > 0) {
      for (const duplicateInput in state.bankClientInfoConfirmationRequiredList) {
        state.bankClientInfoConfirmationRequiredList[
          duplicateInput
        ].copyOfInput = state.bankClientInfo[duplicateInput]
        state.bankClientInfoConfirmationRequiredList[
          duplicateInput
        ].inputObfuscated = '•'.repeat(
          state.bankClientInfo[duplicateInput].length
        )
      }
    }

    state.bankName = payload.bankName
    state.isVerifiedError = false
  },
  VERIFY_ERROR(state, payload) {
    state.isVerified = false
    state.isVerifiedError = true
    if (payload.errorCode === 'BAD_REQUEST_ERROR') {
      Object.keys(state.bankClientInfo).forEach((name) => {
        if (payload.errorDescription.includes(name)) {
          if (!state.errorFieldsArray.includes(name)) {
            state.errorFieldsArray.push(name)
          }
        }
      })
    }
  },
  RESET_OBFUSCATION(state, payload) {
    state.bankClientInfoConfirmationRequiredList[payload].inputObfuscated = ''
    state.bankClientInfo[payload] = ''
    state.bankClientInfoConfirmationRequiredList[payload].firstInputOfCR = ''
    state.bankClientInfoConfirmationRequiredList[payload].secondInputOfCR = ''
  },
  SUBMIT_ACRT_DETAILS(state, payload) {
    state.isSubmitted = true
    state.emailAddress = payload.emailAddress
  },
  COPY_SET(state) {
    for (const duplicateInput in state.bankClientInfoConfirmationRequiredList) {
      state.bankClientInfo[duplicateInput] =
        state.bankClientInfoConfirmationRequiredList[duplicateInput].copyOfInput
    }
  },

  ON_BLUR_CHECK(state, payload) {
    ///  Determines if input is too short
    if (payload.valueLength < payload.minLength && !payload.isFirstInputAndCR) {
      state.status.error = true
      if (!Object.keys(state.errorDetails.errorField).includes(payload.name)) {
        state.errorDetails.errorField[payload.name] = true
      }
    } else {
      delete state.errorDetails.errorField[payload.name]
    }
    // Determines if confirmation required both inputs match
    if (
      payload.confirmationRequired &&
      // state.bankClientInfoConfirmationRequiredList[payload.name].secondInputOfCR !== ''
      state.bankClientInfoConfirmationRequiredList[payload.name]
        .secondInputOfCR !== ''
    ) {
      if (
        state.bankClientInfoConfirmationRequiredList[payload.name]
          .firstInputOfCR ===
        state.bankClientInfoConfirmationRequiredList[payload.name]
          .secondInputOfCR
      ) {
        state.bankClientInfoConfirmationRequiredList[
          payload.name
        ].areBothInputsEqual = false
        delete state.errorDetails.errorField[payload.name]
        if (payload.valueLength < payload.minLength) {
          state.status.error = true
          if (
            !Object.keys(state.errorDetails.errorField).includes(payload.name)
          ) {
            state.errorDetails.errorField[payload.name] = true
          }
        }
      }
      if (
        state.bankClientInfoConfirmationRequiredList[payload.name]
          .firstInputOfCR !==
        state.bankClientInfoConfirmationRequiredList[payload.name]
          .secondInputOfCR
      ) {
        state.bankClientInfoConfirmationRequiredList[
          payload.name
        ].areBothInputsEqual = true
        state.status.error = true
        if (
          !Object.keys(state.errorDetails.errorField).includes(payload.name)
        ) {
          state.errorDetails.errorField[payload.name] = true
        }
      }
      ///  Determines if input is too short after checking for confirmation required matching
      if (payload.valueLength < payload.minLength) {
        state.status.error = true
        if (
          !Object.keys(state.errorDetails.errorField).includes(payload.name)
        ) {
          state.errorDetails.errorField[payload.name] = true
        }
      }
    }

    // Determines if all fields are filled out by user
    state.isFormEmpty = false
    for (let property in state.bankClientInfo) {
      if (state.bankClientInfo[property] === '') {
        state.isFormEmpty = true
      }
    }
    // Determines if all fields that are confirmation required are filled out by user and matching
    for (let property in state.bankClientInfoConfirmationRequiredList) {
      if (
        state.bankClientInfoConfirmationRequiredList[property]
          .areBothInputsEqual === true
      ) {
        state.isFormEmpty = true
      }
    }

    if (Object.keys(state.errorDetails.errorField).length > 0) {
      state.status.error = true
    } else state.status.error = false
    if (state.status.error) {
      state.isFormEmpty = true
    }
    state.errorFieldsArray = Object.keys(state.errorDetails.errorField)
  },
  UPDATE_BANK_CLIENT_INFO(state, payload) {
    state.errorDetails.errorMessage = null
    state.errorDetails.errorDesc = null
    state.errorDetails.errorCode = null
    if (payload.confirmationRequired) {
      if (payload.first) {
        state.bankClientInfoConfirmationRequiredList[
          payload.name
        ].firstInputOfCR = payload.value
      }
      if (payload.second) {
        state.bankClientInfoConfirmationRequiredList[
          payload.name
        ].secondInputOfCR = payload.value
      }
    }

    state.bankClientInfo[payload.name] = payload.value
    // Determines if all fields are filled out by user
    state.isFormEmpty = false
    for (let property in state.bankClientInfo) {
      if (state.bankClientInfo[property] === '') {
        state.isFormEmpty = true
      }
    }

    // checks all confirmation required/ duplicate forms are empty
    for (let property in state.bankClientInfoConfirmationRequiredList) {
      if (
        state.bankClientInfoConfirmationRequiredList[property]
          .firstInputOfCR === '' ||
        state.bankClientInfoConfirmationRequiredList[property]
          .secondInputOfCR === ''
      ) {
        state.isFormEmpty = true
      }
    }

    // checks if any confirmation required/ duplicate forms are not equal
    for (let property in state.bankClientInfoConfirmationRequiredList) {
      if (
        state.bankClientInfoConfirmationRequiredList[property]
          .firstInputOfCR !==
        state.bankClientInfoConfirmationRequiredList[property].secondInputOfCR
      ) {
        state.isFormEmpty = true
      }
    }
  }
}

const actions = {
  async getBankFieldsValue({ commit }, payload) {
    const response = await service.getBankFields(payload)
    commit('GET_BANK_FIELDS_VALUE', response.data)
  },
  async verifyACRTDetails({ commit, dispatch }, payload) {
    for (const duplicateInput in state.bankClientInfoConfirmationRequiredList) {
      if (
        state.bankClientInfoConfirmationRequiredList[duplicateInput]
          .inputObfuscated.length > 1
      ) {
        commit('COPY_SET')
      }
    }

    try {
      const response = await service.verifyACRT(
        payload.transactionId,
        state.bankClientInfo
      )

      if (response.data.status === 'SUCCESS') {
        commit('VERIFY_ACRT_DETAILS', response.data)
        dispatch('resetErrorDetails')
      } else {
        dispatch('adjustErrorDetails', response.data)
        commit('VERIFY_ERROR', response.data)
      }
    } catch (error) {
      dispatch('adjustErrorDetails', error.response.data)
      commit('VERIFY_ERROR', error.response.data)
    }
  },
  async submitACRTDetails({ commit, dispatch }, payload) {
    try {
      const response = await service.submitACRT(
        payload.transactionId,
        state.bankClientInfo
      )
      if (response.data.status === 'SUCCESS') {
        commit('SET_PAYMENT_COMPLETE', response.data)
      } else {
        dispatch('adjustErrorDetails', response.data)
        commit('VERIFY_ERROR', response.data)
      }
    } catch (error) {
      dispatch('adjustErrorDetails', error.response.data)
      commit('VERIFY_ERROR', error.response.data)
    }
  },
  resetObfuscation({ commit }, payload) {
    commit('RESET_OBFUSCATION', payload)
  },
  updateBankClientInfo({ commit }, payload) {
    commit('UPDATE_BANK_CLIENT_INFO', payload)
  },
  onBlurCheck({ commit }, payload) {
    commit('ON_BLUR_CHECK', payload)
  }
}

const getters = {}

const refundStore = {
  state,
  getters,
  mutations,
  actions
}

export default refundStore
