<template>
  <div class="product section-content">
    <div class="row checkout__header" />
    <h1>{{ locale['pp.summary.acrt.refund'] }}</h1>
    <p class="product__description">
      {{ locale['pp.summary.acrt.bankinfo'] }}
    </p>
    <div class="row product__details-nrfee">
      <div class="column large-10 medium-8 small-7 product__details-nrfee">
        <p>
          {{ locale[`pp.refund.type.${refundType}`] }}
          {{ locale['pp.summary.refund.title'] }}
        </p>
      </div>
      <div class="column large-2 medium-4 small-5 product__details-nrfeeprice">
        <p>
          <span lang="en" dir="ltr">{{ amount }}</span>
        </p>
        <p
          class="column large-12 large-order-2 small-12 small-order-1 textprice"
        >
          {{ locale['pp.summary.refund.taxstatement'] }}
        </p>
      </div>
      <div
        class="column large-9 large-order-1 medium-12 small-12 small-order-2 product__details-name"
      >
        <div class="row product__details-id">
          <p v-if="productName" class="column large-12 small-9">
            <span lang="en" dir="ltr">
              {{ locale['pp.summary.refund.product'] }}
              {{ productName }}
            </span>
          </p>
          <p class="column large-12 small-8">
            <span lang="en" dir="ltr">
              {{ locale[`pp.summary.refund.id.${refundType}`] }}
              {{ refundId }}</span
            >
          </p>
          <p class="column large-12 small-8">
            <span lang="en" dir="ltr">
              {{ locale['pp.summary.refund.caseid'] }} {{ caseId }}
            </span>
          </p>
        </div>
      </div>
    </div>

    <div
      class="column large-4 small-uncentered small-12 medium-6 medium-offset-8 large-offset-8 payment__acion"
    >
      <div class="large-4 medium-8 small-12 payment__acion__container">
        <app-button
          id="continue-payment-product"
          @onClick="nextStep"
        >
          {{ locale['pp.continue.button'] }}
        </app-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import AppButton from '@/components/app-button'
import Modal from '@marcom/ac-modal'
import KeyMap from '@marcom/ac-keyboard'
import { focusOnSelector, buttonType } from '@/utilities/utils'

export default {
  components: {
    AppButton
  },

  title() {
    return `${this.locale['pp.summary.refund.pagetitle']}`
  },

  computed: {
    ...mapState({
      caseId: (state) => state.mainStore.details.caseId,
      productName: (state) => state.mainStore.details.productName,
      refundId: (state) => state.mainStore.details.refundId,
      caseId: (state) => state.mainStore.details.caseId,
      refundType: (state) => state.mainStore.details.refundType,
      currencyCode: (state) => state.mainStore.currencyCode,
      amount: (state) => state.mainStore.amount,
      transactionStatus: (state) => state.mainStore.transactionStatus,
      locale: (state) => state.mainStore.localization
    })
  },
  methods: {
    nextStep() {
      const { token, locale } = this.$route.params
      const route = `/${token}/${locale}/terms`
      this.$router.push(route)
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'ac-sasskit/core';
@import 'ac-sasskit/base';
@import '@/styles/variables.scss';

.product.section-content,
h1 {
  @include typography(headline-reduced);
  margin-top: $app-margin-lg;
  color: swatch(glyph-gray);
  &:focus {
    outline: 0;
  }
}
.button {
  @include viewport(medium) {
    width: 80%;
  }
  @media only screen and (min-width: 750px) and (max-device-width: 900px) {
    width: 160%;
    transform: translate(-80px);
  }
}
.product__description {
  @include typography(body);
  padding-bottom: 30px;
  border-bottom: 1px solid swatch(fill-gray-tertiary);
  color: swatch(glyph-gray);
  margin-top: $app-margin-zero !important;
  padding-top: $app-margin-x-xs;
  font-weight: $app-font-weight-xr-lg;
  &:focus {
    outline: 0;
  }
  @include viewport(small) {
    padding-top: 40px;
  }
}
.row.product__details-nrfee {
  border-bottom: 1px solid swatch(fill-gray-tertiary);
}
.product__details-nrfee {
  padding: $app-padding-sm 0px;
  padding-top: 10px;
  @include typography(eyebrow);
}
.product__details-nrfeeprice {
  p {
    @include direction(text-align, right, text-align, left);
    padding-top: 15px;
  }
}
.payment__acion__container {
  padding-top: $app-padding-lg;
}

.product__details-id {
  @include typography(body-reduced);
  padding-bottom: $app-padding-bottom-xs;
}

.product__details-partrow {
  @include typography(eyebrow);
  padding-top: 20px;
}
.product__details-partprice {
  @include typography(eyebrow);
}
.product__details-id {
  padding-bottom: 5px;
}
.textprice {
  @include typography(caption);
  @include direction(text-align, right, text-align, left);
  @include viewport(medium) {
    padding-left: 0px;
  }
  @include viewport(small) {
    padding-left: 0px;
    @include direction(text-align, left, text-align, right);
    padding-bottom: 10px;
  }
  margin-top: -12px;
  padding-left: 10px;
}
</style>
