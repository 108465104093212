import router from '@/router'
import service from '@/features/payment/service/service'

export const initialState = {
  status: {
    isFetching: false,
    isFetched: false,
    error: false
  },
  html: null,
  termStatus: false
}

const state = JSON.parse(JSON.stringify(initialState))

const mutations = {
  SET_TERM_STATUS(state, payload) {
    state.termStatus = payload
  }
}

const actions = {
  setTermStatus({ commit }, payload) {
    commit('SET_TERM_STATUS', payload)
  }
}

const getters = {}

const termsStore = {
  state,
  getters,
  mutations,
  actions
}

export default termsStore
