import service from '@/features/payment/service/service'
import router from '@/router'

export const initialState = {
  status: {
    isPending: false,
    isFinished: false,
    error: false
  },
  details: {
    paymentRequestObj: {},
    isEnabled: false
  },
  validateMerchantStatus: {
    isChecking: false,
    isChecked: false,
    error: false
  },
  validateMerchantData: {},
  applepayProcessStatus: {
    isPending: false,
    isDone: false,
    error: false
  },
  applepayProcessData: {}
}

const state = JSON.parse(JSON.stringify(initialState))

const mutations = {
  MAKE_APPLEPAY_PAYMENT_START(state, payload) {
    state.status.isPending = true
    state.status.isFinished = false
    state.status.error = false
  },
  MAKE_APPLEPAY_PAYMENT_SUCCESS(state, payload) {
    state.status.isPending = false
    state.status.isFinished = true
    state.status.error = false
  },
  MAKE_APPLEPAY_PAYMENT_FAILURE(state, payload) {
    state.status.isPending = false
    state.status.isFinished = true
    state.status.error = true
  },
  ENABLE_APPLE_PAY(state, payload) {
    state.details.isEnabled = true
    state.details.paymentRequestObj = payload
  },
  UPDATE_APPLE_PAY_REQUEST_OBJECT(state, payload) {
    state.details.paymentRequestObj = {
      ...state.details.paymentRequestObj,
      ...payload
    }
  },
  APPLEPAY_VALIDATE_MERCHANT_REQUEST(state, payload) {
    state.validateMerchantStatus.isChecking = true
    state.validateMerchantStatus.isChecked = false
    state.validateMerchantStatus.error = false

    state.validateMerchantData = {}
  },
  APPLEPAY_VALIDATE_MERCHANT_SUCCESS(state, payload) {
    state.validateMerchantStatus.isChecking = false
    state.validateMerchantStatus.isChecked = true
    state.validateMerchantStatus.error = false
    state.validateMerchantData = payload
  },
  APPLEPAY_VALIDATE_MERCHANT_FAILURE(state, payload) {
    state.validateMerchantStatus.isChecking = false
    state.validateMerchantStatus.isChecked = true
    state.validateMerchantStatus.error = true
  },
  PROCESS_APPLEPAY_PAYMENT_REQUEST(state, payload) {
    state.applepayProcessStatus.isPending = true
    state.applepayProcessStatus.isDone = false
    state.applepayProcessStatus.error = false

    state.applepayProcessData = {}
  },
  PROCESS_APPLEPAY_PAYMENT_SUCCESS(state, payload) {
    state.applepayProcessStatus.isPending = false
    state.applepayProcessStatus.isDone = true
    state.applepayProcessStatus.error = false

    state.applepayProcessData = payload
  },
  PROCESS_APPLEPAY_PAYMENT_FAILURE(state, payload) {
    state.applepayProcessStatus.isPending = false
    state.applepayProcessStatus.isDone = true
    state.applepayProcessStatus.error = true

    if (payload) {
      state.applepayProcessData = payload
    }
  }
}

const localizeApplePayRequest = (
  applePayRequestObject,
  localization,
  details
) => {
  let reqObj = JSON.stringify(applePayRequestObject)

  reqObj = JSON.stringify(applePayRequestObject).replace(
    'Estimated Tax',
    localization['pp.payment.quote.tax']
  )

  if (details.text_key !== 'acplus' && details.region === 'EMEA') {
    reqObj = JSON.stringify(applePayRequestObject).replace(
      'Estimated Tax',
      localization['pp.payment.quote.vat']
    )
  }

  reqObj = reqObj.replace(
    'Order Total',
    localization['pp.payment.quote.order-total']
  )
  reqObj = reqObj.replace(
    'Non-return fee',
    localization['pp.payment.quote.non-return-fee']
  )
  reqObj = reqObj.replace(
    'Non-return tax',
    localization['pp.payment.quote.non-return-tax']
  )

  return JSON.parse(reqObj)
}

const actions = {
  enableApplePay({ commit, rootState }) {
    const { details } = rootState.mainStore
    const { localization } = rootState.mainStore

    commit(
      'ENABLE_APPLE_PAY',
      localizeApplePayRequest(
        details.applepay_payment_request,
        localization,
        details
      )
    )
  },
  updateApplePayRequestObject({ commit, rootState }, applePayRequestObject) {
    const { details } = rootState.mainStore
    const { localization } = rootState.mainStore

    commit(
      'UPDATE_APPLE_PAY_REQUEST_OBJECT',
      localizeApplePayRequest(applePayRequestObject, localization, details)
    )
  },
  makeApplepayPaymentStart({ commit }) {
    commit('MAKE_APPLEPAY_PAYMENT_START')
  },
  makeApplepayPaymentSuccess({ commit }) {
    commit('MAKE_APPLEPAY_PAYMENT_SUCCESS')
  },
  makeApplepayPaymentFailure({ commit }) {
    commit('MAKE_APPLEPAY_PAYMENT_FAILURE')
  },
  async validateMerchant({ commit }, payload) {
    commit('APPLEPAY_VALIDATE_MERCHANT_REQUEST')

    try {
      const validationUrl = payload.validationURL

      const response = await service.getApplepaySession({ validationUrl })

      commit('APPLEPAY_VALIDATE_MERCHANT_SUCCESS', response.data)
    } catch (error) {
      commit('APPLEPAY_VALIDATE_MERCHANT_FAILURE')
      commit('MAKE_APPLEPAY_PAYMENT_FAULURE')
    }
  },

  async processApplepayPayment({ commit }, payload) {
    commit('PROCESS_APPLEPAY_PAYMENT_REQUEST')
    try {
      const response = await service.paymentApplepay(
        router.currentRoute.params.token,
        payload
      )
      if (response.data.status.toUpperCase() === 'ERROR') {
        commit('PROCESS_APPLEPAY_PAYMENT_FAILURE', response.data)
      } else {
        commit('PROCESS_APPLEPAY_PAYMENT_SUCCESS', response.data)
        commit('SET_PAYMENT_COMPLETE', response.data)
      }
    } catch (error) {
      commit('PROCESS_APPLEPAY_PAYMENT_FAILURE', error.response.data)
    }
  }
}

const getters = {}

const applepayStore = {
  state,
  getters,
  mutations,
  actions
}

export default applepayStore
