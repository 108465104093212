import instance from '@/utilities/interceptor'

export default {
  /**
   * Get transaction status
   * method: GET
   * end point: /api/v1/webpay/{token}/Status?locale={locale}
   */
  getStatus(data) {
    return instance.get(`${data.token}/Status?locale=${data.locale}`, {
      baseURL: 'api/v2/webpay'
    })
  }
}
