<template>
  <div id="app" :aria-hidden="false">
    <app-global-loader v-if="isFetching" />
    <b-container role="main" v-else-if="!isFetching && !error">
      <router-view />
    </b-container>
    <div id="global-footer"></div>
  </div>
</template>
<script>
import AppButton from '@/components/app-button'
import AppGlobalLoader from '@/components/app-global-loader'
import service from '@/service/service'
import { mapState } from 'vuex'

export default {
  components: {
    AppButton,
    AppGlobalLoader
  },

  async created() {
    this.$store.dispatch('init', this.$route.params)

    const html = document.querySelector('html')

    if (this.$route.params.locale !== undefined) {
      html.setAttribute('lang', this.getHtmlLangAttribute())
    }
  },

  mounted: function () {
    let headerScript = document.createElement('script')
    headerScript.setAttribute(
      'src',
      `https://km.support.apple.com/includes/albert/v2/${this.getLocale()}/header.js`
    )

    let footerScript = document.createElement('script')
    footerScript.setAttribute(
      'src',
      `https://km.support.apple.com/includes/albert/v2/${this.getLocale()}/footer.js`
    )

    headerScript.addEventListener('load', () => {
      if (createGlobalHeader && typeof (createGlobalHeader === 'function')) {
        createGlobalHeader({
          disableOfferBanner: true
        })
      }
    })

    footerScript.addEventListener('load', () => {
      if (createGlobalFooter && typeof createGlobalFooter === 'function') {
        createGlobalFooter({
          containerId: 'global-footer',
          footerLocaleLink: ''
        })
      }
    })

    document.head.appendChild(headerScript)
    document.head.appendChild(footerScript)
  },
  computed: {
    ...mapState({
      locale: (state) => state.mainStore.localization,
      isFetching: (state) => state.mainStore.status.isFetching,
      error: (state) => state.mainStore.status.error
    }),

    fallbackPageTitle() {
      // TODO: This should be changed to use JavaScript nullish coalescing operator (??) once we upgrade the Node version in WebPay
      // Example: `return this.locale['pp.page_title'] ?? 'Apple Support Payment'`
      return this.locale['pp.page_title']
        ? this.locale['pp.page_title']
        : 'Apple Support Payment'
    }
  },
  data() {
    return {
      fieldText: null
    }
  },
  watch: {
    $route: {
      handler: function (to, from) {
        document.title = to.meta.title || this.fallbackPageTitle
      },
      immediate: true
    }
  },

  methods: {
    onChange(value) {
      this.fieldText = value
    },

    getLocale() {
      const localeRegex = /^[a-z]{2}_[A-Z]{2}$/
      const fallbackLocale = 'en_US'

      if (this.$route.params.locale) {
        return localeRegex.test(this.$route.params.locale)
          ? this.$route.params.locale
          : fallbackLocale
      }

      if (localStorage.getItem('locale')) {
        return localeRegex.test(localStorage.getItem('locale'))
          ? localStorage.getItem('locale')
          : fallbackLocale
      }

      return fallbackLocale
    },

    getHtmlLangAttribute() {
      if (this.$route.params.locale) {
        return localStorage.getItem('locale').replace('_', '-')
      }

      if (localStorage.getItem('locale')) {
        return localStorage.getItem('locale').replace('_', '-')
      }

      return 'en-US'
    }
  }
}
</script>

<style lang="scss">
@import 'ac-sasskit/core';
@import 'ac-sasskit/base';
@import 'ac-modal';
@import '@/styles/variables.scss';

html[dir='rtl'] {
  * {
    text-align: right;
    direction: rtl;
  }
}

body {
  font: initial !important;
}

html,
body {
  height: 100%;
  position: relative;
}

#global-footer {
  margin-top: auto;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 44px);
  @include viewport(small) {
    min-height: calc(100% - 48px);
  }
  font-size: 17px;
  font-weight: $app-font-weight-sm;
  font-family: 'SF Pro Text', 'SF Pro Icons', 'Helvetica Neue', 'Helvetica',
    'Arial', sans-serif;
  .container {
    position: relative;
    padding-bottom: 100px;
    @include viewport(medium) {
      padding-bottom: 80px;
    }
    @include viewport(small) {
      padding-bottom: 80px;
    }
    @media (min-width: 1200px) {
      max-width: 1000px;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
    line-height: 1;
  }
}

.font-body-tight {
  @include typography(body-tight);
}
.font-body-reduced {
  @include typography(body-reduced);
}

.terms__content {
  margin-top: $app-padding-xxx-sm;
  li {
    margin: 0px 0px 0px 0.625rem;
    line-height: 1.1em;
    list-style-type: disc;
    @include typography(body);
    @include direction(margin-left, $app-margin-xx-md, margin-right, 0);
  }
  p {
    margin-bottom: $app-padding-xxx-sm;
  }
  a {
    color: swatch(glyph-blue);
    font-weight: $app-font-weight-lg;
    &:focus {
      text-decoration: underline;
    }
  }
  a:focus {
    text-decoration: underline;
  }
  ul {
    @include viewport(small) {
      margin: 14px 0px 14px 7px;
    }
    margin: $app-margin-x-sm 0 $app-margin-x-sm $app-margin-xxx-xs;
  }
}

.form-textbox-input.focused,
.form-textbox-input:focus {
  outline: none !important;
  box-shadow: 0 0 0 4px rgba(0, 125, 250, 0.6) !important;
  -webkit-box-shadow: 0 0 0 4px rgba(0, 125, 250, 0.6) !important;
}

.button:focus {
  outline: none !important;
}

.terms__content-cancel {
  margin-top: $app-padding-xxx-sm;

  p {
    margin-bottom: $app-padding-xxx-sm;
  }
  a {
    font-weight: $app-font-weight-lg;
    color: swatch(glyph-blue);
    &:focus {
      text-decoration: underline;
    }
  }
  a:focus {
    text-decoration: underline;
  }
}

.terms-link,
#legal-styles {
  @include typography(body);
  a:focus {
    text-decoration: underline;
  }
}

button.btn-link {
  padding-left: 0;
  padding-right: 0;
}

.content-padding {
  padding: $app-margin-xxx-lg !important;
  @include viewport(mediun) {
    padding: $app-margin-xxx-lg $app-padding-right-md !important;
  }
  @include viewport(small) {
    padding: $app-margin-xxx-lg 8.33% !important;
  }
}
.section-content {
  margin-top: $app-padding-sm;
  margin-left: auto;
  margin-right: auto;
  width: 980px;
}
@media only screen and (min-width: 1441px) {
  .section-content {
    margin-left: auto;
    margin-right: auto;
    width: 980px;
  }
}
@media only screen and (max-width: 1068px) {
  .section-content {
    margin-left: auto;
    margin-right: auto;
    width: 692px;
  }
}
@media only screen and (max-width: 734px) {
  .section-content {
    margin-left: auto;
    margin-right: auto;
    width: 87.5%;
  }
}

.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
.align-center {
  text-align: center;
}
.a11y {
  position: absolute;
  padding: 0;
  margin: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(0px 0px 99.9% 99.9%);
}
.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(0px 0px 99.9% 99.9%);
}
.link-group {
  position: relative;
  .icon-right {
    position: absolute;
    right: 0;
    top: rem(-8);
    cursor: pointer;
    + a {
      padding-right: $app-padding-md;
      cursor: pointer;
    }
  }
  .icon-left {
    position: absolute;
    left: rem(-10);
    top: rem(-12);
    cursor: pointer;
    + a {
      padding-left: $app-padding-md;
      cursor: pointer;
    }
  }
}

.footer,
.overlay {
  height: 50px;
}

.row {
  margin: 0 !important;
}

.modal-open {
  display: block !important;
  z-index: 11000;
}

.modal-content {
  border: none !important;
}

// .modal-close {
//   @include icon(close, after);
// }

.modal-close::after {
  content: '';
  width: 30px;
  height: 32px;
  background: url('./assets/cancel.png') no-repeat;
  background-size: 30px;
  opacity: 0.4;
}
.button-secondary {
  margin-left: $app-margin-md;
  @include viewport(small) {
    margin-left: $app-margin-zero;
  }
}
.button-super {
  margin-right: $app-margin-md;
  @include viewport(small) {
    margin-right: $app-margin-zero;
    margin-bottom: $app-margin-md;
  }
}

.flex-1 {
  flex: 1;
}

.width-fit-content {
  width: fit-content !important;
}

.error-message__text {
  img {
    height: 14px;
    width: 14px;
  }
}
</style>
