import Vue from 'vue'
import Vuex from 'vuex'

import mainStore from '@/store/mainStore'
import productStore from '@/features/payment/store/productStore'
import formStore from '@/features/payment/store/formStore'
import applepayStore from '@/features/payment/store/applepayStore'
import termsStore from '@/features/payment/store/termsStore'
import redirectPageStore from '@/features/redirect/store/redirectPageStore'
import upiredirectPageStore from '@/features/upiredirect/store/upiredirectPageStore'
import eWalletRedirectPageStore from '@/features/ewalletredirect/store/eWalletRedirectPageStore'
import refundStore from '@/features/payment/store/refundStore'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    mainStore,
    productStore,
    formStore,
    termsStore,
    applepayStore,
    redirectPageStore,
    upiredirectPageStore,
    eWalletRedirectPageStore,
    refundStore
  }
})
