<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 233.30078125 159.98291015625"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <g
      transform="matrix(1 0 0 1 80.32224609374998 115.221435546875) scale(1.45)"
    >
      <path
        :fill="color"
        d="M 58.5449 14.5508 C 85.791 14.5508 108.35 -8.05664 108.35 -35.2539 C 108.35 -62.5 85.7422 -85.0586 58.4961 -85.0586 C 31.2988 -85.0586 8.74023 -62.5 8.74023 -35.2539 C 8.74023 -8.05664 31.3477 14.5508 58.5449 14.5508 Z M 58.5449 6.25 C 35.498 6.25 17.0898 -12.207 17.0898 -35.2539 C 17.0898 -58.3008 35.4492 -76.7578 58.4961 -76.7578 C 81.543 -76.7578 100 -58.3008 100.049 -35.2539 C 100.098 -12.207 81.5918 6.25 58.5449 6.25 Z M 58.4961 -26.416 C 60.8398 -26.416 62.207 -27.7344 62.2559 -30.2734 L 62.9395 -56.0547 C 62.9883 -58.5449 61.0352 -60.4004 58.4473 -60.4004 C 55.8105 -60.4004 53.9551 -58.5938 54.0039 -56.1035 L 54.6387 -30.2734 C 54.6875 -27.7832 56.0547 -26.416 58.4961 -26.416 Z M 58.4961 -10.5469 C 61.3281 -10.5469 63.7695 -12.793 63.7695 -15.625 C 63.7695 -18.5059 61.377 -20.7031 58.4961 -20.7031 C 55.6152 -20.7031 53.2227 -18.457 53.2227 -15.625 C 53.2227 -12.8418 55.6641 -10.5469 58.4961 -10.5469 Z"
      />
    </g>
  </svg>
</template>
<script>
import Vue from 'vue'
export default {
  name: 'ErrorCircleIcon',
  description: 'Error Circle Icon',
  props: {
    width: {
      type: [Number, String],
      default: null
      // note: 'title for select'
    },
    height: {
      type: [Number, String],
      default: null
      // note: 'title for select'
    },
    color: {
      type: String,
      default: null
      // note: 'title for select'
    }
  }
}
</script>
<style lang="scss" scoped></style>
