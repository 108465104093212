<template>
  <div class="global-loader">
    <app-moon-loader color="black" size="25px" />
  </div>
</template>
<script>
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
export default {
  name: 'app-global-loader',
  description: 'Global loader',
  components: {
    appMoonLoader: MoonLoader
  },
  props: {
    /**
     * Removes opacity from overlay
     */
    opaque: {
      type: Boolean
    }
  }
}
</script>
<style lang="scss" scoped>
.global-loader {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.global-loader {
  background-color: #ffffff;
}
</style>
