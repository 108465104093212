<template>
  <div class="product section-content">
    <div v-if="!isPaymentComplete" class="row checkout__header">
      <div
        v-if="!isRefundType"
        class="column large-12 small-12 checkout__header-label"
      >
        <span>{{ locale['pp.payment.checkout'] }}</span>
      </div>
      <div
        v-if="isRefundType"
        class="column large-12 small-12 checkout__header-label"
      >
        <span>{{ locale['pp.payment.refunds.label'] }}</span>
      </div>
    </div>
    <!--Heading in thank you page -->
    <div v-if="isPaymentComplete" class="product__heading-thanks">
      <h1
        v-if="isACSProcessing"
        ref="headerRef"
        tabindex="-1"
        class="centerText"
      >
        {{ locale['pp.message.complete'] }} {{ paymentAcsDetails.name }}.
      </h1>
      <h1
        v-else-if="isUPIProcessing || isEWalletProcessing"
        ref="headerRef"
        tabindex="-1"
        class="centerText"
      >
        {{ locale['pp.message.complete'] }} {{ customerFullName }}.
      </h1>
      <h1 v-else ref="headerRef" tabindex="-1" class="centerText">
        {{ locale['pp.message.complete'] }} {{ billingAddress.firstname }}.
      </h1>

      <p v-if="!isLoanAccount" class="product__heading-confirmation">
        {{ locale['pp.message.action.email'] }} {{ maskEmail(customerEmail) }}
      </p>
      <p v-if="!isLoanAccount" class="product__heading-orderno">
        {{ locale['pp.message.action.order-no'] }} {{ isPOFlow? caseId: productInfo.id}}
      </p>
      <p v-if="isLoanAccount" class="product__heading-confirmation">
        {{ locale['pp.message.action.nucleus.email'] }}
      </p>
    </div>
    <!--Heading in review page -->
    <div v-if="!isPaymentComplete" class="product__heading">
      <h1 ref="headerRef" tabindex="-1">
        <span role="text" aria-labelledby="1 2"
          ><span v-if="!isRefundType && !isLoanAccount" id="1"
            >{{ locale['pp.summary.heading.your-order'] }}
          </span>
          <br /><span id="2">{{ locale['pp.summary.heading.make-sure'] }}</span>
        </span>
      </h1>
    </div>
    <!-- Error Message -->
    <b-col
      role="region"
      aria-live="polite"
      cols="12"
      v-if="isVerifiedError || errorMessage !== null"
      class="error-message"
    >
      <div
        ref="errorMessages"
        aria-label="Payment error"
        tabindex="-1"
        class="error-message__text"
      >
        <img
          alt="Error"
          class="input-field__icons"
          src="../../assets/warning.svg"
        />
        <span class="error-message-refund" v-html="errorMessage"></span>
      </div>
    </b-col>
    <b-col
      role="region"
      cols="12"
      v-else-if="isPendingQR"
      class="error-message"
    >
      <div
        ref="errorMessages"
        aria-label="Payment error"
        tabindex="-1"
        class="error-message__text"
      >
        {{ locale['pp.payment.error.qrcode.fail'] }}
      </div>
    </b-col>
    <b-col
      role="region"
      cols="12"
      v-else-if="isPendingVPA || upipaymentError || this.error"
      class="error-message"
    >
      <div
        ref="errorMessages"
        aria-label="Payment error"
        tabindex="-1"
        class="error-message__text"
      >
        {{ locale['pp.payment.error.upi.fail'] }}
      </div>
    </b-col>
    <b-col
      role="region"
      cols="12"
      v-else-if="applepayProcessError"
      class="error-message"
    >
      <div
        ref="errorMessages"
        aria-label="Payment error"
        tabindex="-1"
        class="error-message__text"
      >
        {{ locale['pp.payment.error.applepay.fail'] }}
      </div>
    </b-col>
    <!--Product Summary-->
    <div class="product__summary">
      <!--Product Details with Price-->
      <div class="row product__details">
        <h2
          v-if="!isLoanAccount && !isRefundType"
          class="column large-3 small-3 medium-3 product__details-image_left"
        >
          <img
            :src="productInfo.imageUrl"
            v-bind:alt="productInfo.productName"
          />
        </h2>
        <div
          v-if="isLoanAccount && !isRefundType"
          class="column large-10 small-8 product__details-nrfee_nuc"
        >
          <p>{{ locale['pp.amount.tobepaid'] }}</p>
        </div>

        <div
          v-bind:class="[
            this.isLoanAccount || isRefundType
              ? 'row large-12 medium-12 small-12  product__details-parts'
              : 'row large-9 medium-9 small-9  product__details-parts'
          ]"
        >
          <div
            v-if="!isLoanAccount"
            class="column large-8 small-12 product__details-partrow"
          >
            <span lang="en">{{
              locale[`pp.summary.heading.${productInfo.ordertype}`]
            }}</span>
          </div>

          <div
            v-if="isRefundType"
            class="column large-10 medium-8 small-7 product__details-nrfee"
          >
            <p>{{ locale['pp.review.refund.amount'] }}</p>
          </div>
          <div
            v-if="isRefundType"
            class="column large-2 medium-4 small-5 product__details-nrfeeprice"
          >
            <p>
              <span lang="en" dir="ltr">{{ amount }}</span>
            </p>
            <p
              class="column large-12 large-order-2 small-12 small-order-1 textprice"
            >
              {{ locale['pp.summary.refund.taxstatement'] }}
            </p>
          </div>
          <div v-if="isRefundType" class="row product__details-id">
            <p v-if="productName" class="column large-12 small-9">
              <span lang="en" dir="ltr">
                {{ locale['pp.summary.refund.product'] }}
                {{ productName }}
              </span>
            </p>
            <p class="column large-12 small-8">
              <span lang="en" dir="ltr">
                {{ locale[`pp.summary.refund.id.${refundType}`] }}
                {{ refundId }}</span
              >
            </p>
            <p class="column large-12 small-8">
              <span lang="en" dir="ltr">
                {{ locale['pp.summary.refund.caseid'] }} {{ caseId }}
              </span>
            </p>
          </div>

          <div
            v-if="isLoanAccount"
            class="column large-8 medium-6 small-6 product__details-partrow_nuc"
          >
            <p>
              {{ locale[`pp.account.product.name.${accountType}`] }}
              {{ maskAccountNumber(accountNumber) }}
            </p>
          </div>
          <div
            v-if="isLoanAccount"
            class="column large-4 medium-6 small-6 product__details-partprice_nuc"
          >
            {{ amount }}
          </div>
          <div
            v-if="!isLoanAccount && !isRefundType"
            class="column large-4 small-12 product__details-partprice"
          >
            {{
              productInfo.parts.length > 0
                ? productInfo.price
                : productInfo.additionalAuth.non_return_amount
            }}
          </div>
          <div class="row">
            <div v-if="!accountType && !isRefundType" class="row">
              <div
                v-if="subType === 'ACP' && regionIPT === 'IPTEUCOUNTRY'"
                class="column large-3 large-order-2 small-12 small-order-1 textprice"
              >
                {{ locale['pp.acp.ipt'] }}
              </div>
              <div
                v-if="!isLoanAccount && !isRefundType"
                class="column large-9 large-order-1 small-12 small-order-2 product__details-name"
              >
                <div>
                  {{ locale['pp.summary.product'] }}
                  <span lang="en" dir="ltr">{{ productInfo.productName }}</span>
                </div>
                <div v-if="isPOFlow">
                  <p class="column flex-1">
                    {{ locale[`pp.summary.caseId`] }}
                    <span lang="en" dir="ltr">{{ this.caseId }}</span>
                  </p>
                </div>
                <div v-else>
                  <p class="column flex-1">
                    {{ locale[`pp.summary.id.${productInfo.ordertype}`] }}
                    <span lang="en" dir="ltr">{{ productInfo.id }}</span>
                  </p>
                </div>
                <div>
                  <p v-if="isPOFlow && hasMultipleRepairs">
                    {{ locale[`pp.summary.repairQuantity.label`] }}
                    <span lang="en" dir="ltr">{{ repairQuantity }}</span>
                  </p>
                </div>
                <p
                  v-if="isPOFlow && hasMultipleRepairs && attachmentLink"
                  class="product__details-id row__top-padding"
                >
                  <a
                    lang="en"
                    dir="ltr"
                    :href="attachmentLink"
                    target="_blank"
                    >{{ locale[`pp.summary.attachmentLink.label`] }}</a
                  >
                </p>
              </div>
            </div>
          </div>

          <!-- Parts -->
          <!-- 
              Note:
              No need to show for PO flow with Multiple repairs
            -->
          <div
            v-if="productInfo.parts.length > 0 && !hasMultipleRepairs"
            class="row product__details-partrow"
          >
            <div
              class="row product__details-partitem"
              v-for="(part, index) in productInfo.parts"
              :key="`part.name${index}`"
            >
              <div class="column large-9 small-12 product__details-partname">
                <p>
                  <span lang="en" dir="ltr">{{ part.name }}</span>
                </p>
              </div>
              <div
                class="column large-3 small-12 product__details-partprice-sub"
              >
                <p>
                  <span lang="en" dir="ltr">{{ part.amount }}</span>
                </p>
              </div>
            </div>
          </div>
          <!-- Authorization Info -->
          <div
            v-if="
              !isUnderReview &&
              !isPaymentComplete &&
              productInfo.additionalAuth !== null &&
              productInfo.additionalAuth !== undefined
            "
            class="product__details-authinfo"
          >
            <p
              v-if="productInfo.ordertype == '40a'"
              class="product__details-authdesc"
              v-html="locale[`p.payment.auth.${productInfo.ordertype}`]"
            />
            <p
              v-else-if="productInfo.ordertype == '40b'"
              class="product__details-authdesc"
            >
              {{ locale[`p.payment.auth.${productInfo.ordertype}`] }}
            </p>
            <p v-else class="product__details-authdesc">
              {{ locale[`pp.payment.auth.${productInfo.ordertype}`] }}
            </p>
          </div>
          <!-- Non Return Fee -->
          <div
            class="row product__details-addfee"
            v-if="
              productInfo.additionalAuth !== null &&
              productInfo.additionalAuth !== undefined
            "
          >
            <div class="row product__details-nrfee">
              <div class="column large-8 small-12 product__details-partname">
                <p>
                  <span lang="en" dir="ltr">{{
                    locale['pp.payment.quote.non-return-fee']
                  }}</span>
                </p>
              </div>
              <div
                class="column large-4 small-12 product__details-nonreturnprice"
              >
                <p>
                  <span lang="en" dir="ltr">
                    {{ additionalAuth.non_return_fee }}</span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Review Section: Payment Details for RefundType -->

      <div v-if="isRefundType" class="row row-logical product__detail-info">
        <div class="column large-3 small-12 payment__detail-label">
          <div class="payment__detail-cardlabel">
            <h2>{{ locale['pp.review.refund.bank.info'] }}</h2>
          </div>

          <div class="payment__detail-link button_change_dir">
            <a
              id="payment-review-change"
              v-if="isRefundType"
              :href="changeRefund"
              class="payment_method_button"
              aria-label="Change payment details"
              >{{ locale['pp.summary.change.button'] }}</a
            >
          </div>
        </div>
        <div
          v-if="isRefundType"
          class="column large-5 small-12 payment__detail-cardinfo"
        >
          <div v-if="bankClientInfo" class="payment__detail-cardlabel">
            <span>{{ locale['pp.review.refund.act.holder'] }}</span>
            <p class="address-title__info">
              {{ this.bankClientInfo.accountHolderName }}
            </p>
          </div>
          <div v-if="bankClientInfo" class="payment__detail-cardlabel">
            {{ locale['pp.review.refund.bank.name'] }}
            <p class="address-title__info">{{ this.bankName }}</p>
          </div>
        </div>

        <div class="column large-4 small-12 payment__detail-address">
          <div
            v-if="bankClientInfo.bankKey"
            class="payment__detail-addresslabel"
          >
            <span>{{ locale['pp.payment.refund.bank.bank_key'] }} </span>
            <p class="address-title__info">
              {{ obfuscateNumber(this.bankClientInfo.bankKey, 4) }}
            </p>
          </div>
          <div
            v-if="bankClientInfo.bankAccount"
            class="payment__detail-addresslabel"
          >
            <span>{{ locale['pp.payment.refund.bank.bank_account'] }}</span>
            <p class="address-title__info">
              {{ obfuscateNumber(this.bankClientInfo.bankAccount, 4) }}
            </p>
          </div>
          <div v-if="bankClientInfo.iban" class="payment__detail-addresslabel">
            <span>{{ locale['pp.payment.refund.bank.iban'] }}</span>
            <p class="address-title__info">
              {{ obfuscateNumber(this.bankClientInfo.iban, 4) }}
            </p>
          </div>

          <div
            v-if="bankClientInfo.controlKey"
            class="payment__detail-addresslabel"
          >
            <span>{{ locale['pp.payment.refund.bank.control_key'] }}</span>
            <p class="address-title__info">
              {{ getControlValue(this.bankClientInfo.controlKey) }}
            </p>
          </div>
        </div>
      </div>
      <!-- Review Section: Shipping Details -->
      <!-- if shipping info exists in state when the user is in review or success page (review page with success content) -->
      <template
        v-if="
          (collectShippingInfo || isPaymentComplete) &&
          shippingInfo.shippingAddress.fields &&
          objectContainsTruthyValues(shippingInfo.shippingAddress.fields)
        "
      >
        <div
          v-if="isUnderReview || isPaymentComplete"
          class="row row-logical product__detail-info"
        >
          <div class="column large-3 small-12 payment__detail-label">
            <div class="payment__detail-cardlabel">
              <h2>{{ locale['pp.review.shipping.details'] }}</h2>
            </div>

            <div class="payment__detail-link button_change_dir">
              <router-link
                id="payment-review-change"
                v-if="!isPaymentComplete"
                class="payment_method_button"
                aria-label="Change Shipping details"
                to="shipping"
              >
                {{ locale['pp.summary.change.button'] }}
              </router-link>
            </div>
          </div>

          <!-- Review Section: Shipping Details: Shipping Address -->
          <div class="column large-5 small-12 payment__detail-address">
            <div class="payment__detail-addresslabel">
              {{ locale['pp.review.shipping.address'] }}
            </div>
            <div class="payment__detail-addressinfo">
              <span class="address-title__info">
                {{ shippingInfo.shippingAddress.fields.first_name }}
                {{ shippingInfo.shippingAddress.fields.last_name }}
              </span>
              <span class="address-title__info">
                {{ shippingInfo.shippingAddress.fields.orgName }}
              </span>
              <span class="address-title__info">
                {{ shippingInfo.shippingAddress.fields.street1 }}
              </span>
              <span class="address-title__info">
                {{ shippingInfo.shippingAddress.fields.street2 }}
              </span>
              <span class="address-title__info">
                {{ shippingInfo.shippingAddress.fields.street3 }}
              </span>
              <span class="address-title__info">
                {{
                  hasInputFieldInConfig('suburb')
                    ? shippingInfo.shippingAddress.fields.suburb || ''
                    : ''
                }}
              </span>
              <span class="address-title__info">
                {{ shippingInfo.shippingAddress.fields.city }}
                {{
                  hasInputFieldInConfig('state')
                    ? stateDescriptions[
                        stateCodes.indexOf(
                          shippingInfo.shippingAddress.fields.state
                        )
                      ] || ''
                    : ''
                }}
                {{ shippingInfo.shippingAddress.fields.postalcode }}</span
              >
            </div>
          </div>
          <!-- if shipping info exists in state when the user is in review or success page (review page with success content) -->
          <div
            v-if="
              (collectReturnAddress || isPaymentComplete) &&
              shippingInfo.returnAddress.fields &&
              objectContainsTruthyValues(shippingInfo.returnAddress.fields)
            "
            class="column large-4 small-12 payment__detail-address"
          >
            <div class="payment__detail-addresslabel">
              {{ locale['pp.review.return.address'] }}
            </div>
            <div class="payment__detail-addressinfo">
              <span class="address-title__info">
                {{ shippingInfo.returnAddress.fields.first_name }}
                {{ shippingInfo.returnAddress.fields.last_name }}
              </span>
              <span class="address-title__info">
                {{ shippingInfo.returnAddress.fields.orgName }}
              </span>
              <span class="address-title__info">
                {{ shippingInfo.returnAddress.fields.street1 }}
              </span>
              <span class="address-title__info">
                {{ shippingInfo.returnAddress.fields.street2 }}
              </span>
              <span class="address-title__info">
                {{ shippingInfo.returnAddress.fields.street3 }}
              </span>
              <span class="address-title__info">
                {{
                  hasInputFieldInConfig('suburb')
                    ? shippingInfo.returnAddress.fields.suburb || ''
                    : ''
                }}
              </span>
              <span class="address-title__info">
                {{ shippingInfo.returnAddress.fields.city }}
                {{
                  hasInputFieldInConfig('state')
                    ? stateDescriptions[
                        stateCodes.indexOf(
                          shippingInfo.returnAddress.fields.state
                        )
                      ] || ''
                    : ''
                }}
                {{ shippingInfo.returnAddress.fields.postalcode }}</span
              >
            </div>
          </div>
        </div>
      </template>
      <!-- Review Section: Payment Details -->
      <div
        v-if="isUnderReview || isPaymentComplete"
        class="row row-logical product__detail-info"
      >
        <div class="column large-3 small-12 payment__detail-label">
          <div class="payment__detail-cardlabel">
            <h2>{{ locale['pp.summary.paymentdetail'] }}</h2>
          </div>

          <div class="payment__detail-link button_change_dir">
            <router-link
              v-if="!isPaymentComplete"
              id="payment-review-change"
              class="payment_method_button"
              aria-label="Change payment details"
              :to="paymentMethod === 'PO' ? 'purchase-order' : 'billing'"
            >
              {{ locale['pp.summary.change.button'] }}
            </router-link>
          </div>
        </div>
        <div
          v-if="paymentMethod === 'upipay' || isUPIProcessing"
          class="column large-5 small-12 payment__detail-cardinfo"
        >
          <div aria-live="polite">
            <div v-if="isPaymentComplete" class="payment__detail-cardlabel">
              {{ locale['pp.summary.paid-with'] }}
            </div>
            <div v-else class="payment__detail-cardlabel">
              {{ locale['pp.summary.pay-with'] }}
            </div>

            <span class="payment__detail-paytype">UPI</span>
          </div>
        </div>
        <div
          v-if="isEWalletSelected || isEWalletProcessing"
          class="column large-5 small-12 payment__detail-cardinfo"
        >
          <div aria-live="polite">
            <div v-if="isPaymentComplete" class="payment__detail-cardlabel">
              {{ locale['pp.summary.paid-with'] }}
            </div>
            <div v-else class="payment__detail-cardlabel">
              {{ locale['pp.summary.pay-with'] }}
            </div>
            <div class="payment__detail-paytype" :class="eWalletTypeImage">
              <span class="visually-hidden">{{
                locale[
                  `pp.payment.ewallet.${
                    paymentMethod || paymentAcsDetails.type.toLowerCase()
                  }`
                ]
              }}</span>
            </div>
          </div>
        </div>
        <div
          v-else-if="paymentMethod === 'applepay'"
          class="column large-5 small-12 payment__detail-cardinfo"
        >
          <div aria-live="polite">
            <div v-if="isPaymentComplete" class="payment__detail-cardlabel">
              {{ locale['pp.summary.paid-with'] }}
            </div>
            <div v-else class="payment__detail-cardlabel">
              {{ locale['pp.summary.pay-with'] }}
            </div>
            <span class="payment__detail-chosenpaytype"
              ><img src="../../images/applepay.png" alt="Apple Pay"
            /></span>
          </div>
        </div>
        <div
          v-else-if="paymentMethod === 'PO'"
          class="column large-5 small-12 payment__detail-cardinfo"
        >
          <div aria-live="polite">
            <div class="payment__detail-cardlabel">
              <p>{{ locale['form.purchase_order.purchase_order'] }}</p>
            </div>

            <div class="method-details">
              <div class="method-details__number">
                <span lang="en" dir="ltr">{{
                  poInfo.poFields.fields.poNumber
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="paymentMethod !== 'upipay'"
          class="column large-5 small-12 payment__detail-cardinfo"
        >
          <div aria-live="polite">
            <div v-if="isPaymentComplete" class="payment__detail-cardlabel">
              <p>{{ locale['pp.summary.paid-with'] }}</p>
            </div>
            <div v-else class="payment__detail-cardlabel">
              <p>{{ locale['pp.summary.pay-with'] }}</p>
            </div>
            <div class="method-details">
              <div
                v-if="!isACSProcessing"
                class="method-details__type"
                :class="
                  cardType ? `method-details__type--${cardType}` : undefined
                "
              >
                <span class="visually-hidden">{{ cardType }}</span>
              </div>
              <div v-else class="method-details__type" :class="cardTypeImage">
                <span class="visually-hidden">{{
                  paymentAcsDetails.type
                }}</span>
              </div>
              <div class="method-details__number">
                <span lang="en" dir="ltr">...{{ lastFour }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- Review Section: Payment Details: Billing Address -->
        <div
          v-if="displayAddressDetails"
          class="column large-4 small-12 payment__detail-address"
        >
          <div class="payment__detail-addresslabel">
            {{ locale['pp.payment.address.heading'] }}
          </div>
          <div v-if="isACSProcessing" class="payment__detail-addressinfo">
            <span class="address-title__info">
              {{ paymentAcsDetails.name }}
            </span>
            <span class="address-title__info">
              {{ paymentAcsDetails.street1 }}
            </span>
            <span class="address-title__info">
              {{ paymentAcsDetails.street2 }}
            </span>
            <span class="address-title__info">
              {{ paymentAcsDetails.street3 }}
            </span>
            <span class="address-title__info">
              {{
                hasInputFieldInConfig('suburb')
                  ? paymentAcsDetails.suburb || ''
                  : ''
              }}
            </span>
            <span class="address-title__info">
              {{ paymentAcsDetails.city }}
              {{
                hasInputFieldInConfig('state')
                  ? paymentAcsDetails.state || ''
                  : ''
              }}
              {{ paymentAcsDetails.postalcode }}</span
            >
          </div>
          <div v-else class="payment__detail-addressinfo">
            <span class="address-title__info">
              {{ billingAddress.lastname }}</span
            >
            <span class="address-title__info">
              {{ billingAddress.street1 }}
            </span>
            <span class="address-title__info">
              {{ billingAddress.street2 }}
            </span>
            <span class="address-title__info">
              {{ billingAddress.street3 }}
            </span>
            <span class="address-title__info">
              {{
                hasInputFieldInConfig('suburb')
                  ? billingAddress.suburb || ''
                  : ''
              }}
            </span>
            <span class="address-title__info">
              {{ billingAddress.city }}
              {{
                hasInputFieldInConfig('state')
                  ? stateDescriptions[
                      stateCodes.indexOf(billingAddress.state)
                    ] || ''
                  : ''
              }}
              {{ billingAddress.postalcode }}</span
            >
          </div>
        </div>
      </div>
      <!-- Product Subtotal, tax, NRP price info -->
      <div v-if="!isLoanAccount" class="row payment__pricing">
        <div class="column large-3 medium-12 small-12">
          <h2 class="row" id="product__details-yourtotal" v-if="isUnderReview">
            {{ locale['pp.payment.quote.your-total'] }}
          </h2>
        </div>
        <div class="column large-9 medium-12 small-12">
          <div class="row product__details-nonreturnitem">
            <div v-if="!isRefundType" class="row product__details-nrsubtotal">
              <div
                v-if="isPOFlow === true && productInfo.subTotal"
                class="row product__details-nrfee"
              >
                <div class="column flex-1 product__details-nrfee">
                  <p>
                    {{ locale['pp.payment.quote.sub-total'] }}
                  </p>
                </div>
                <div
                  class="column width-fit-content product__details-nrfeeprice"
                >
                  <p>
                    <span lang="en" dir="ltr">{{ productInfo.subTotal }}</span>
                  </p>
                </div>
              </div>
              <div
                v-if="isPOFlow === true && productInfo.shippingPrice"
                class="row product__details-nrfee"
              >
                <div class="column flex-1 product__details-nrfee">
                  <p>{{ locale['pp.summary.shipping.label'] }}</p>
                </div>
                <div
                  class="column width-fit-content product__details-nrfeeprice"
                >
                  <p>
                    <span lang="en" dir="ltr">{{
                      productInfo.shippingPrice
                    }}</span>
                  </p>
                </div>
              </div>
              <!-- Tax Details -->
              <div
                v-if="productInfo.parts.length > 0"
                class="product__details-additional"
              >
                <div class="row product__details-nrfee">
                  <div class="column flex-1 product__details-nrfeeitem">
                    <p>
                      {{ taxLabel }}
                    </p>
                  </div>
                  <div
                    class="column width-fit-content product__details-nrfeeprice"
                  >
                    <p>
                      <span lang="en" dir="ltr">{{ productInfo.tax }}</span>
                    </p>
                  </div>
                </div>
                <!-- Subtotal Details  -->
                <div
                  v-if="
                    productDetails.additionalAuth !== null &&
                    productDetails.additionalAuth !== undefined
                  "
                  class="row product__details-nrfee"
                >
                  <div class="column flex-1 product__details-nrfee">
                    <p>
                      {{ locale['pp.payment.quote.sub-total'] }}
                    </p>
                  </div>
                  <div class="column large-4 product__details-nrfeeprice">
                    <p>
                      <span lang="en" dir="ltr">{{ productInfo.price }}</span>
                    </p>
                  </div>
                </div>
              </div>

              <div
                class="row product__details-subtotal"
                v-if="
                  productInfo.additionalAuth !== null &&
                  productInfo.additionalAuth !== undefined
                "
              >
                <!-- NRP Tax -->
                <div div class="row product__details-nrfee">
                  <div class="column flex-1 product__details-nrfeetax">
                    <p>
                      {{ locale['pp.payment.quote.non-return-tax'] }}
                    </p>
                  </div>
                  <div
                    class="column width-fit-content product__details-nrfeeprice"
                  >
                    <p>
                      <span lang="en" dir="ltr">{{
                        additionalAuth.non_return_tax
                      }}</span>
                    </p>
                  </div>
                </div>
                <!-- Authorisation Amount -->
                <div class="row product__details-nrfee">
                  <div class="column flex-1 product__details-nrfee">
                    <p>{{ locale['pp.payment.quote.auth-total'] }}</p>
                  </div>
                  <div
                    class="column width-fit-content product__details-nrfeeprice"
                  >
                    <p>
                      <span lang="en" dir="ltr">{{
                        additionalAuth.non_return_amount
                      }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- Order Total  -->
            <div
              class="row product__details-total"
              v-if="
                productInfo.parts.length > 0 &&
                productInfo.additionalAuth !== undefined &&
                productInfo.additionalAuth !== null
              "
            >
              <div class="column flex-1 product__details-ordertotal">
                <p>
                  {{ locale['pp.payment.quote.order-total'] }}
                </p>
              </div>

              <div
                class="column width-fit-content product__details-ordertotalprice"
              >
                <p>
                  <span lang="en" dir="ltr">{{ productInfo.grandTotal }}</span>
                </p>
              </div>
            </div>
            <div
              class="row product__details-total"
              v-if="
                productInfo.additionalAuth !== undefined &&
                productInfo.parts.length === 0 &&
                productInfo.additionalAuth !== null
              "
            >
              <div class="column flex-1 product__details-ordertotal">
                <p>
                  {{ locale['pp.payment.quote.order-total'] }}
                </p>
              </div>
              <div
                class="column width-fit-content product__details-ordertotalprice"
              >
                <p>
                  <span lang="en" dir="ltr">{{
                    productInfo.additionalAuth.non_return_amount
                  }}</span>
                </p>
              </div>
            </div>
            <div
              class="row product__details-total"
              v-if="
                (productInfo.additionalAuth === undefined ||
                  productInfo.additionalAuth === null) &&
                productInfo.parts.length > 0
              "
            >
              <div class="column flex-1 product__details-ordertotal">
                <p>
                  {{ locale['pp.payment.quote.order-total'] }}
                </p>
              </div>
              <div
                class="column width-fit-content product__details-ordertotalprice"
              >
                <p>
                  <span lang="en" dir="ltr">{{ productInfo.price }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- </div>  -->
      </div>
    </div>
    <!-- Button Section -->
    <transition name="fade">
      <div class="row button_change_dir">
        <!-- Button for Print -->
        <div class="column large-centered" v-if="isPaymentComplete">
          <div class="payment__acion__print">
            <app-button id="print-button" primary @onClick="print">
              {{ locale['pp.print.button'] }}
            </app-button>
          </div>
        </div>
        <!-- Button in Product -->
        <div
          v-else
          class="column large-4 small-uncentered small-12 medium-6 medium-offset-6 large-offset-8 payment__acion_reviewpage"
        >
          <div class="payment__acion__container">
            <div class="visually-hidden" aria-live="polite">
              <span v-if="isFetching">
                {{ locale['pp.message.loading'] }}
              </span>
            </div>
            <app-button
              id="payment-submit-button"
              v-if="!isUnderReview"
              :type="buttonSizeChange"
              :isLoading="isFetching"
              @onClick="nextStep"
            >
              {{
                isRefundType
                  ? locale['pp.review.refund.submit.button']
                  : locale['pp.continue.button']
              }}
            </app-button>
            <!-- Button ins Review -->
            <app-button
              id="make-payment-applepay"
              v-else-if="paymentMethod === 'applepay'"
              :disabled="error || enablePayment"
              :type="buttonSizeChange"
              :ariaLabel="locale['pp.summary.ApplePay.button']"
              @onClick="makeApplepayPayment"
            >
              {{ locale['pp.payment.continue-with'] }}
              <img height="20px" alt="" src="../../images/applelogo.png" />
            </app-button>
            <app-button
              id="make-payment-upi"
              v-else-if="paymentMethod === 'upipay'"
              secondary
              :disabled="upipaymentError"
              :isLoading="isFetching"
              @onClick="showExternalPayment"
            >
              {{ locale['pp.makepayment.button'] }}
            </app-button>
            <app-button
              id="make-payment-ewallet"
              v-else-if="isEWalletPaymentMethod"
              secondary
              :isLoading="isFetching"
              :disabled="isContinueButtonDisabled"
              @onClick="showExternalPayment"
            >
              {{ locale['pp.makepayment.button'] }}
            </app-button>
            <app-button
              id="make-payment"
              v-else
              :disabled="error"
              :isLoading="isFetching"
              @onClick="payment"
            >
              {{ locale['pp.makepayment.button'] }}
            </app-button>
          </div>
          <div
            v-if="!isUnderReview && !isRefundType"
            class="payment__acion__container"
          >
            <app-button
              id="payment-review-cancel"
              :type="buttonSizeChange"
              class="button-secondary"
              :isLoading="isFetching"
              @onClick="showModal"
            >
              {{ locale['pp.cancel.button'] }}
            </app-button>
          </div>
        </div>
      </div>
    </transition>
    <!-- Confirm Cancellation Modal -->

    <div id="confirmModal" :class="{ displayNone: isModalClose }">
      <h2 class="confirmModal__heading">
        {{ locale['pp.cancel.confirm.heading'] }}
      </h2>
      <p class="confirmModal__content">
        {{ locale['pp.cancel.confirm.message'] }}
      </p>
      <p class="confirmModal__content">
        {{ locale['pp.cancel.confirm.message2'] }}
      </p>
      <div class="confirmModal__action">
        <app-button
          id="review-confirm-modal-yes"
          @onClick="cancelTransaction"
          type="elevated"
          :isLoading="isFetching"
        >
          <div class="underline_text">
            {{ locale['pp.cancel.confirm.yes.button'] }}
          </div>
        </app-button>
        <app-button
          id="review-confirm-modal-no"
          type="link"
          @onClick="hideModal"
        >
          {{ locale['pp.cancel.confirm.no.button'] }}
        </app-button>
        <!-- <button type="button" class="modal-close"><img src= '../../assets/cancel.png' alt="Cancel" /></button> -->
        <!-- <img src= '../../assets/cancel.png' alt="Cancel" /> -->
      </div>
    </div>
    <!-- External Payment Lookup Modal -->
    <div
      id="confirmModal-externalPayment"
      :class="{ displayNone: isModalClose }"
    >
      <h2 class="externalPayment__heading">
        {{
          locale[
            `pp.message.payment.authorization.status.popup.${paymentMethod.toLowerCase()}`
          ]
        }}
      </h2>
      <p class="externalPayment__content">
        {{
          locale[
            `pp.message.payment.authorization.status.title.${paymentMethod.toLowerCase()}`
          ]
        }}
      </p>
      <div class="externalPayment__action">
        <app-button
          id="review-bank-modal-yes"
          @onClick="submitPaymentExternal"
          type="elevated"
        >
          {{ locale['pp.staycontinue.button'] }}
        </app-button>
        <app-button
          id="review-bank-modal-no"
          type="link"
          @onClick="hideExternalPayment"
        >
          <div class="underline_text">
            {{ locale['pp.applepay.back'] }}
          </div>
        </app-button>
      </div>
    </div>
    <!-- 3DS Redirect form -->
    <template v-if="isAcsPaymentHidenFormEnabled">
      <form :action="paymentDetails.acsUrl" method="POST" id="redirectForm">
        <template v-for="(value, key) in paymentDetails.payload">
          <input type="hidden" :key="key" :name="key" :value="value" />
        </template>
      </form>
    </template>
    <!-- UPI Redirect form -->
    <template v-if="upipaymentDetails.apsUrl">
      <form
        :action="upipaymentDetails.apsUrl"
        method="POST"
        id="redirectupiForm"
      >
        <template
          v-for="(value, key) in upipaymentDetails.paymentResponseParameters"
        >
          <input type="hidden" :key="key" :name="key" :value="value" />
        </template>
      </form>
    </template>
    <div
      id="loading-text"
      role="alert"
      class="sr-only"
      aria-live="polite"
    ></div>
  </div>
</template>
<script>
import AppButton from '@/components/app-button'
import { EWALLETS } from '@/utilities/constants'
import {
  buttonType,
  focusOnSelector,
  maskAccountNumber,
  objectContainsTruthyValues
} from '@/utilities/utils'
import Modal from '@marcom/ac-modal'
import { mapGetters, mapState } from 'vuex'
import router from '../../router'
import store from '../../store'

export default {
  components: {
    AppButton
  },
  title() {
    if (this.isRefundType) {
      return `${this.locale['pp.review.refund.pagetitle']}`
    } else {
      return `${this.productInfo.productName} - ${this.locale['pp.page_title']}`
    }
  },
  data() {
    return {
      modal: '',
      modallookup: '',
      view: 'product',
      isUPIPaymentHidenFormEnabled: false,
      isAcsPaymentHidenFormEnabled: false,
      isModalClose: true,
      windowWidth: 0,
      isLoanAccount: false,
      maskAccountNumber,
      isContinueButtonDisabled: false,
      EWALLETS,
      objectContainsTruthyValues
    }
  },
  computed: {
    ...mapState({
      locale: (state) => state.mainStore.localization,
      amount: (state) => state.mainStore.amount,
      accountType: (state) => state.mainStore.accountType,
      accountNumber: (state) => state.mainStore.accountNumber,
      countrycode: (state) => state.mainStore.countrycode,
      productDetails: (state) => state.mainStore.details,
      productInfo: (state) => state.productStore,
      upipaymentError: (state) => state.formStore.upipaymentError,
      isPendingQR: (state) => state.upiredirectPageStore.isPendingQR,
      isPendingVPA: (state) => state.upiredirectPageStore.isPendingVPA,
      additionalAuth: (state) => state.productStore.additionalAuth,
      isUnderReview: (state) => state.mainStore.isUnderReview,
      isPaymentComplete: (state) => state.mainStore.isPaymentComplete,
      paymentDetails: (state) => state.formStore.paymentDetails,
      subType: (state) => state.mainStore.details.sub_type,
      paymentMethod: (state) => state.mainStore.paymentMethod,
      isFetching: (state) => state.formStore.status.isFetching,
      cardType: (state) => state.formStore.cardType,
      paymentAcsDetails: (state) => state.productStore.paymentAcsDetails,
      card_number: (state) => state.formStore.fields.card_number,
      errorMessage: (state) => state.formStore.errorDetails.errorMessage,
      error: (state) => state.formStore.status.error,
      enablePayment: (state) => state.formStore.enablePayment,
      poInfo: (state) => state.formStore.poInfo,
      isAppleEnabled: (state) => state.applepayStore.details.isEnabled,
      validateMerchantChecking: (state) =>
        state.applepayStore.validateMerchantStatus.isChecking,
      validateMerchantChecked: (state) =>
        state.applepayStore.validateMerchantStatus.isChecked,
      validateMerchantError: (state) =>
        state.applepayStore.validateMerchantStatus.error,
      validateMerchantResponse: (state) =>
        state.applepayStore.validateMerchantData,
      paymentRequestObj: (state) =>
        state.applepayStore.details.paymentRequestObj,
      applepayProcessPending: (state) =>
        state.applepayStore.applepayProcessStatus.isPending,
      applepayProcessDone: (state) =>
        state.applepayStore.applepayProcessStatus.isDone,
      applepayProcessError: (state) =>
        state.applepayStore.applepayProcessStatus.error,
      applepayProcessResponse: (state) =>
        state.applepayStore.applepayProcessData,
      upipaymentDetails: (state) => state.formStore.upipaymentDetails,
      applePayLastFour: (state) => state.formStore.applePayLastFour,
      billingAddress: (state) => state.formStore.billingAddress,
      stateDescriptions: (state) => state.mainStore.states.stateDescriptions,
      stateCodes: (state) => state.mainStore.states.stateCodes,
      isACSProcessing: (state) => state.productStore.isACSProcessing,
      customerEmail: (state) => state.mainStore.customerEmail,
      isUPIProcessing: (state) => state.productStore.isUPIProcessing,
      isEWalletProcessing: (state) => state.productStore.isEWalletProcessing,
      customerFullName: (state) => state.productStore.customerFullName,
      isRefundType: (state) => state.mainStore.refundType,
      refundType: (state) => state.mainStore.details.refundType,
      bankClientInfo: (state) => state.refundStore.bankClientInfo,
      caseId: (state) => state.mainStore.details.caseId,
      productName: (state) => state.mainStore.details.productName,
      refundId: (state) => state.mainStore.details.refundId,
      currencyCode: (state) => state.mainStore.currencyCode,
      isRefundVerified: (state) => state.formStore.isRefundVerified,
      regionIPT: (state) => state.mainStore.details.regionIPT,
      region: (state) => state.mainStore.details.region,
      bankName: (state) => state.refundStore.bankName,
      isVerified: (state) => state.refundStore.isVerified,
      isACRT: (state) => state.refundStore.isACRT,
      isPaymentComplete: (state) => state.mainStore.isPaymentComplete,
      isVerifiedError: (state) => state.refundStore.isVerifiedError,
      text_key: (state) => state.mainStore.details.text_key,
      selectedCountryText: (state) => state.mainStore.selectedCountryText,
      countryCode2: (state) => state.mainStore.details.country_2,
      shippingInfo: (state) => state.formStore.shippingInfo,
      attachmentLink: (state) => state.mainStore.attachmentLink,
      repairQuantity: (state) => state.mainStore.repairQuantity
    }),
    ...mapGetters([
      'isEWalletPaymentMethod',
      'isChile',
      'showBillingAddressFormForApplePay',
      'collectReturnAddress',
      'collectShippingInfo',
      'hasInputFieldInConfig',
      'hasMultipleRepairs',
      'isPOFlow'
    ]),
    changeRefund() {
      const { token, locale } = this.$route.params
      return `#/${token}/${locale}/refund`
    },
    buttonSizeChange() {
      return buttonType(this.windowWidth)
    },
    cardTypeImage() {
      return this.paymentAcsDetails.type !== undefined
        ? `method-details__type--${this.paymentAcsDetails.type.toLowerCase()}`
        : null
    },
    eWalletTypeImage() {
      const paymentMethod = this.paymentMethod || this.paymentAcsDetails.type
      return paymentMethod !== undefined
        ? `payment__detail-paytype--${paymentMethod.toLowerCase()}`
        : null
    },
    isEWalletSelected() {
      return Object.values(EWALLETS).includes(this.paymentMethod)
    },
    displayAddressDetails() {
      return (
        this.paymentMethod !== 'upipay' &&
        !this.isUPIProcessing &&
        !this.isEWalletProcessing &&
        this.paymentMethod !== 'applepay' &&
        !store.getters.isEWalletPaymentMethod
      )
    },

    taxLabel() {
      if (this.productInfo.tax !== undefined) {
        return this.region === 'EMEA' &&
          this.text_key != 'acplus' &&
          this.text_key != 'acp.dmg' &&
          this.text_key != 'acp.tnl'
          ? this.locale['pp.payment.quote.vat']
          : this.locale['pp.payment.quote.tax']
      }
    },
    lastFour() {
      let cardNumber = ''
      if (!this.isACSProcessing) {
        cardNumber = this.card_number
      } else {
        cardNumber = this.paymentAcsDetails.ccLastFourDigits
      }

      return this.applePayLastFour
        ? this.applePayLastFour
        : this.isUPIProcessing
        ? ''
        : cardNumber.replace(/ +/g, '').slice(-4)
    }
  },
  created: function () {
    this.checkAccountType()
  },
  watch: {
    errorMessage(val, oldVal) {
      if (document.querySelector('.error-message')) {
        document.querySelector('.error-message').focus()
      }
    },
    isLoading: function (val, oldVal) {
      if (val === true) {
        let node = document.createElement('span')
        let textnode = document.createTextNode('loading')
        node.appendChild(textnode)
        document.getElementById('loading-text').appendChild(node)
      } else {
        let list = document.getElementById('loading-text')
        list.removeChild(list.childNodes[0])
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const status = store.state.mainStore.details.transactionStatus
      const { token, locale } = vm.$route.params
      const summaryPagePath = `/#/${token}/${locale}`
      if (
        (from.name === 'PaymentProduct' ||
          from.name === 'PaymentTerms' ||
          from.name === 'PaymentReview' ||
          from.name === 'PaymentForm') &&
        to.name == 'Expired' &&
        status === 'PEND'
      ) {
        router.push(summaryPagePath)
      } else {
        next()
      }

      if (
        from.name === 'PaymentForm' ||
        from.name === 'RefundForm' ||
        from.name === 'PurchaseOrderInfo' ||
        vm.isPaymentComplete // so that the redirect to /review page works after payment completion
      ) {
        /**
         * if user is coming from the billing, refund form, or Purchase order page
         * Do nothing, allow them to view the Review page
         */
      } else {
        location.href = summaryPagePath
        location.reload()
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    if (
      to.params.token !== from.params.token ||
      to.params.locale !== from.params.locale
    ) {
      location.reload()
    }

    if (this.isPaymentComplete) {
      const currentPath = `/${router.currentRoute.params.token}/${router.currentRoute.params.locale}`
      // if payment is complete allow user to move forward to success page, if the path is not to success we do not allow the user to move to a different page.
      if (to.path === `${currentPath}/success`) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  beforeRouteUpdate(to, from, next) {
    location.reload()
  },
  mounted() {
    if (this.error) {
      this.$refs.errorMessages.focus()
    } else {
      this.$refs.headerRef.focus()
    }

    if (document.querySelector('.error-message')) {
      document.querySelector('.error-message').focus()
    }
    ;(this.modal = Modal.createStandardModal()),
      this.$nextTick(function () {
        window.addEventListener('resize', this.getWindowWidth)
        //Init
        this.getWindowWidth()
      }),
      this.modal.modalElement.setAttribute(
        'aria-label',
        this.locale['pp.cancel.confirm.heading']
      )
    const el = document.getElementById('confirmModal')
    this.modal.appendContent(el)

    this.modallookup = Modal.createStandardModal()
    this.modallookup.modalElement.setAttribute(
      'aria-label',
      this.locale['pp.message.payment.authorization.status.popup.title']
    )
    const bankel = document.getElementById('confirmModal-externalPayment')
    this.modallookup.appendContent(bankel)
    // this.modallookup.modalElement.getElementsByTagName('button')[1].remove()
    this.modallookup.modalElement.removeAttribute('data-modal-close')

    if (
      this.$route.name === 'Payment' &&
      this.type === 'Active' &&
      !this.termsChecked
    ) {
      const { token, locale } = this.$route.params

      this.$router.push(`/${token}/${locale}/terms`)
    }
    this.isContinueButtonDisabled = false
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth)
  },
  methods: {
    obfuscateNumber(numberToObfuscate, numberDisplayCount) {
      if (numberToObfuscate) {
        return '•••' + numberToObfuscate.slice(-numberDisplayCount)
      }
    },
    getControlValue(selection) {
      const controlKeyDic = {
        '01': this.locale['pp.payment.refund.bank.saving.account'],
        '02': this.locale['pp.payment.refund.bank.checking.account']
      }
      return controlKeyDic[selection]
    },

    checkAccountType() {
      if (this.accountType) {
        this.isLoanAccount = true
      } else {
        this.isLoanAccount = false
      }
    },

    getWindowWidth(event) {
      this.windowWidth = document.documentElement.clientWidth
    },
    hideExternalPayment() {
      this.isAcsPaymentHidenFormEnabled = false
      this.$store.dispatch('paymentAcsReset').then(() => {
        if (!this.isFetching) {
          this.modallookup.close()
          this.isModalClose = true
          document.getElementsByClassName('button')[1].focus()
        }
      })
      // document.getElementsByClassName('button')[1].focus()
    },
    showModal() {
      this.modal.open()
      document.getElementById('confirmModal').focus()
      this.isModalClose = false

      // this.modal.open()
    },
    nextStep() {
      if (this.isPendingQR || this.isPendingVPA) {
        this.$store.dispatch('resetUpi')
      }
      if (this.upipaymentError) {
        this.$store.dispatch('resetUpiPay')
      }
      if (this.isACRT && this.isVerified) {
        const { token, locale } = this.$route.params
        this.$store
          .dispatch('submitACRTDetails', {
            transactionId: token,
            locale: locale
          })
          .then(() => {
            if (this.isPaymentComplete) {
              const route = `/${token}/${locale}/success`
              this.$router.push(route)
            }
          })
      }
      if (!this.isRefundType) {
        const { token, locale } = this.$route.params
        const route = `/${token}/${locale}/success`
        this.$router.push(route)
      }
    },
    hideModal() {
      this.modal.close()
    },
    cancelTransaction() {
      this.modal.close()
      this.$store.dispatch('cancelTransaction')
    },
    payment() {
      const self = this
      if (this.isRefundVerified) {
        this.$store
          .dispatch('submitRefundDetails', {
            transactionId: this.$route.params.token,
            locale: this.$route.params.locale
          })
          .then((data) => {
            focusOnSelector('h1')
            if (
              self.paymentDetails &&
              self.paymentDetails.status &&
              self.paymentDetails.status.toUpperCase() === 'SUCCESS' &&
              typeof self.paymentDetails.acsUrl !== 'undefined'
            ) {
              self.showExternalPayment()
              self.isAcsPaymentHidenFormEnabled = true
            }
          })
      } else {
        this.$store
          .dispatch('payment', {
            transactionId: this.$route.params.token,
            locale: this.$route.params.locale
          })
          .then((data) => {
            focusOnSelector('h1')
            if (
              self.paymentDetails &&
              self.paymentDetails.status &&
              self.paymentDetails.status.toUpperCase() === 'SUCCESS' &&
              typeof self.paymentDetails.acsUrl !== 'undefined'
            ) {
              self.showExternalPayment()
              self.isAcsPaymentHidenFormEnabled = true
            }
          })
      }
    },

    async showExternalPayment() {
      this.isContinueButtonDisabled = true
      if (this.isEWalletPaymentMethod) {
        await this.$store
          .dispatch('eWalletPaymentRequest', {
            paymentMethod: this.paymentMethod
          })
          .then(() => {
            if (!this.error) {
              this.modallookup.open()
              document.getElementById('confirmModal-externalPayment').focus()
              this.isModalClose = false
            }
          })
      } else {
        this.modallookup.open()
        document.getElementById('confirmModal-externalPayment').focus()
        this.isModalClose = false
      }
      this.isContinueButtonDisabled = false
    },

    submitPaymentExternal() {
      if (this.isEWalletPaymentMethod) {
        this.$store.dispatch('eWalletPayment', {
          paymentMethod: this.paymentMethod
        })
      } else if (this.paymentMethod === 'upipay') {
        this.makeupipayment()
      } else {
        document.getElementById('redirectForm').submit()
        this.isAcsPaymentHidenFormEnabled = false
      }
    },
    makeupipayment() {
      const self = this
      this.$store.dispatch('makeUPIPayment').then(() => {
        if (
          self.upipaymentDetails.apsUrl &&
          self.upipaymentDetails.upi_session
        ) {
          this.createCookie(
            'upi_session',
            this.upipaymentDetails.upi_session,
            20
          )
          self.isUPIPaymentHidenFormEnabled = true
          document.getElementById('redirectupiForm').submit()
          self.isUPIPaymentHidenFormEnabled = false
        } else {
          document.querySelector('.upierror-message').focus()
        }
      })
    },
    createCookie(name, value, minutes) {
      let expires = ''
      if (minutes) {
        let date = new Date()
        date.setTime(date.getTime() + minutes * 60 * 1000)
        expires = '; expires=' + date.toGMTString()
      } else {
        expires = ''
      }
      document.cookie =
        name + '=' + value + expires + ';domain=.apple.com; path=/'
    },
    delete_cookie(name) {
      document.cookie =
        name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=.apple.com'
    },
    makeApplepayPayment() {
      // this.$store.dispatch('makeApplepayPayment')
      const currentRoute = `/${this.$route.params.token}/${this.$route.params.locale}`
      let session
      this.$store.dispatch('makeApplepayPaymentStart')
      try {
        /* Pass Apple Pay Version , Request Object to ApplePaySession */
        session = new ApplePaySession(2, this.paymentRequestObj)
      } catch (err) {
        // $scope.states.step = 0
        this.$router.push(`${currentRoute}/error`)
      }

      /* handler for merchant validation */
      session.onvalidatemerchant = (event) => {
        this.$store.dispatch('validateMerchant', event).then(() => {
          if (this.validateMerchantChecked && this.validateMerchantError) {
            session.abort()
            this.$router.push(`${currentRoute}/error`)
          }
          if (
            this.validateMerchantResponse.signature !== null &&
            this.validateMerchantResponse.signature !== undefined &&
            this.validateMerchantResponse.signature !== ''
          ) {
            try {
              session.completeMerchantValidation(this.validateMerchantResponse)
            } catch (err) {
              session.completeMerchantValidation(this.validateMerchantResponse)

              this.$router.push(`${currentRoute}/error`)
            }
          } else {
            session.completeMerchantValidation(this.validateMerchantResponse)
            this.$router.push(`${currentRoute}/error`)
          }
        })
      }
      session.onpaymentauthorized = (event) => {
        // For Non-requiredBillingContactFields countries we recieve the first and last name from the apple pay session and store them to state
        if (!this.showBillingAddressFormForApplePay) {
          this.$store.dispatch(
            'setBillingAddressFirstNameLastName',
            event.payment.billingContact
          )
        }
        // the encrypted Apple Pay token--Pass it to WebPay server
        let paymentData = event.payment.token
        let billingData = {}
        // For Non-showBillingAddressFormForApplePay countries we recieve the billingContact data from the applepay sesion
        // For requiredBillingContactFields we use the billingAddress to insert billing details into billingContact before processing applepay payments
        if (!this.showBillingAddressFormForApplePay) {
          billingData = event.payment.billingContact
          paymentData.billingContact = billingData
        } else {
          const addressLinesNotFiltered = [
            this.billingAddress.street1,
            this.billingAddress.street2
          ]
          const addressLines = addressLinesNotFiltered.filter(
            (value) => value !== null && value !== ''
          )
          ;(billingData.addressLines = addressLines),
            (billingData.administrativeArea = this.billingAddress.state),
            (billingData.country = this.selectedCountryText),
            (billingData.countryCode = this.countryCode2),
            (billingData.familyName = this.billingAddress.lastname),
            (billingData.givenName = this.billingAddress.firstname),
            (billingData.locality = this.billingAddress.city),
            (billingData.postalCode = this.billingAddress.postalcode),
            (billingData.subLocality = this.billingAddress.district)
          paymentData.billingContact = billingData
        }
        /* Validating empty billing Contact */

        if (
          billingData.addressLines.length === 0 ||
          billingData.addressLines === undefined ||
          billingData.addressLines === null ||
          billingData.addressLines === ''
        ) {
          session.completePayment(
            window.ApplePaySession.STATUS_INVALID_BILLING_POSTAL_ADDRESS
          )
        } else if (
          billingData.locality === '' ||
          billingData.locality === undefined ||
          billingData.locality === null
        ) {
          session.completePayment(
            window.ApplePaySession.STATUS_INVALID_BILLING_POSTAL_ADDRESS
          )
        } else if (
          billingData.country === '' ||
          billingData.country === undefined ||
          billingData.country === null
        ) {
          session.completePayment(
            window.ApplePaySession.STATUS_INVALID_BILLING_POSTAL_ADDRESS
          )
        } else if (
          billingData.postalCode === '' ||
          billingData.postalCode === undefined ||
          billingData.postalCode === null
        ) {
          // postalCode
          session.completePayment(
            window.ApplePaySession.STATUS_INVALID_BILLING_POSTAL_ADDRESS
          )
        } else {
          this.$store
            .dispatch('processApplepayPayment', paymentData)
            .then((res) => {
              if (
                this.applepayProcessResponse.status.toUpperCase() === 'SUCCESS'
              ) {
                session.completePayment(window.ApplePaySession.STATUS_SUCCESS)
              } else if (
                this.applepayProcessResponse.status.toUpperCase() === 'ERROR'
              ) {
                // window.ApplePaySession.STATUS_FAILURE closes Apple Pay overlay and displays error banner on current review page to user.
                if (
                  this.applepayProcessResponse.errorCode ===
                    'CSRF_TOKEN_MISMATCH' ||
                  this.applepayProcessResponse.errorCode ===
                    'CSRF_TOKEN_EXPIRED' ||
                  this.applepayProcessResponse.errorCode ===
                    'CSRF_GUARD_EXCEPTION' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_ZERO_ONE_DOLLAR' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_TRANS_TYPE' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_QUOTE_ID' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_TRANS_ID' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_AUTHORIZATION_FAILED' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_FULFILLMENT_ID' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_INVALID_DISPATCH' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_AGR_CODE' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_AGREEMENT_ALREADY_MAPPED' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_AUTHORIZATION_GENERAL_ERROR' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_GENERAL_EXCEPTION' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_LOCALE_MANDATORY' ||
                  this.applepayProcessResponse.errorCode ===
                    'CSPAYMENT_TIMED_OUT_EXCEPTION' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_REFERRAL' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_INVALID_CREDIT_CARD' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_CVV_VALIDATION'
                ) {
                  session.completePayment(window.ApplePaySession.STATUS_FAILURE)
                  // Once Apple Pay overlay recieves STATUS_FAILURE it displays payment failure to user and closes. Afterwards we focus the user to the error on current payment review page after overlay closure.
                  setTimeout(() => {
                    this.$refs.errorMessages.focus()
                  }, 2000)
                } else if (
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_ALREADY_COMPLETED' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_ALREADY_COMPLETED_BY_OTHERCHANNEL' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_IS_UNDER_PROCESS'
                ) {
                  session.completePayment(window.ApplePaySession.STATUS_FAILURE)
                  this.$router.push(currentRoute + '/completed')
                } else if (
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_INVALID_TOKEN' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_INVALID_PAYMENT_STATUS'
                ) {
                  session.completePayment(window.ApplePaySession.STATUS_FAILURE)
                  this.$router.push(currentRoute + '/notfound')
                } else if (
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_SUMMARY_STATUS_CANCELLED' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_ALREADY_CANCELLED'
                ) {
                  session.completePayment(window.ApplePaySession.STATUS_FAILURE)
                  this.$router.push(currentRoute + '/cancelled')
                } else if (
                  this.applepayProcessResponse.errorCode ===
                  'TRANSACTION_ERROR_TOKEN_EXPIRED'
                ) {
                  session.completePayment(window.ApplePaySession.STATUS_FAILURE)
                  this.$router.push(currentRoute + '/expired')
                } else if (
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_CC_HOLDER_FNAME' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_CC_HOLDER_LNAME' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_ADDRESS_LINE1' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_ADDRESS_LINE1_LENGTH' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_ADDRESS_LINE2_LENGTH' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_ZIPCODE' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_ADDRESS_ZIPCODE_LENGTH' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_ADDRESS_CITY_LENGTH' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_ADDRESS_STATE' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_ADDRESS_STATE_LENGTH' ||
                  this.applepayProcessResponse.errorCode ===
                    'INVALID_SUBURB_ERROR' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_AUTHORIZARION_CALL' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_CC_TYPE' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_CC_NUMBER' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_CC_EXPIRY_MONTH' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_CC_EXPIRY_YEAR' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_CREDIT_CARD_EXPIRED' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_SEC_CODE' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_INVALID_CVV_FORMAT1' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_INVALID_CVV_FORMAT2' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_ILLEGAL_CHARACTER' ||
                  this.applepayProcessResponse.errorCode ===
                    'TRANSACTION_ERROR_PAYMENT_ZIPCODE_STATE'
                ) {
                  // ApplePaySession.STATUS_INVALID_BILLING_POSTAL_ADDRESS shows user the error message in overlay 'Update Billing Address'
                  session.completePayment(
                    window.ApplePaySession.STATUS_INVALID_BILLING_POSTAL_ADDRESS
                  )
                } else {
                  session.completePayment(window.ApplePaySession.STATUS_FAILURE)
                  setTimeout(() => {
                    this.$refs.errorMessages.focus()
                  }, 2000)
                }
              } else if (
                // else if is for error catching scenario where applepayProcessResponse does not have an error status
                this.applepayProcessDone &&
                this.applepayProcessError
              ) {
                session.completePayment(window.ApplePaySession.STATUS_FAILURE)
                this.$router.push(currentRoute + '/error')
              }
            })
        }

        /* End of Apple pay payment */
      }

      /* On cancel */
      session.oncancel = (event) => {
        /* Session cancelled */
      }

      session.begin()

      session.onshippingmethodselected = (event) => {
        const newTotal = this.paymentRequestObj.total
        const newLineItems = this.paymentRequestObj.lineItems
        session.completeShippingMethodSelection(status, newTotal, newLineItems)
      }

      session.onpaymentmethodselected = (event) => {
        const newTotal = this.paymentRequestObj.total
        const newLineItems = this.paymentRequestObj.lineItems
        session.completePaymentMethodSelection(newTotal, newLineItems)
      }
    },
    print() {
      window.print()
    },
    maskEmail(email) {
      if (email !== '' && email !== undefined) {
        let split = email.split('@')
        return (
          email.substr(0, 1) +
          new Array(split[0].length - 1).fill('x').join('') +
          '@' +
          split[1]
        )
      } else {
        return null
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'ac-sasskit/core';
@import 'ac-sasskit/base';
@import '@/styles/variables.scss';

.payment_method_button {
  margin: 1.25rem 1.25rem 0 0;
  padding: 0;
  @include typography(body-tight);
}

.product {
  color: swatch(glyph-gray);
}
.product__heading,
h1 {
  @include typography(headline-reduced);
  margin-top: $app-margin-lg;
  color: swatch(glyph-gray);
  &:focus {
    outline: 0;
  }
}

.product__description {
  @include typography(body);
  color: swatch(glyph-gray);
  margin-top: $app-margin-zero !important;
  padding-top: $app-margin-x-xs;
  font-weight: $app-font-weight-xr-lg;
  &:focus {
    outline: 0;
  }
}

.product__detail-info {
  padding: $app-margin-xx-sm 0;
  border-bottom: 1px solid swatch(fill-gray-tertiary);
  @include viewport(small) {
    padding: $app-padding-bottom-xs 0 $app-padding-sm 0;
  }
}

.product__heading-thanks {
  padding-top: $app-padding-lg;
  color: swatch(glyph-gray);
  @include typography(headline);
  text-align: center;
  margin: $app-margin-xx-xds 0;
}

.product__heading-confirmation {
  color: swatch(glyph-gray);
  @include typography(intro);
  text-align: center;
}
.product__heading-orderno {
  color: swatch(glyph-gray);
  text-align: center;
  @include typography(intro);
}

.payment__detail-label {
  h2 {
    @include typography(eyebrow);
  }
  padding-top: 0;
  margin-top: 0;
  color: swatch(glyph-gray);
  @include typography(eyebrow);
  @include viewport(small) {
    padding-bottom: $app-padding-sm;
  }
}
.payment__detail-link {
  @include typography(body);
  &:focus {
    outline: 4px solid $focus-blue;
    outline-offset: 1px;
  }
}
.payment__detail-cardinfo {
  @include viewport(small) {
    padding-bottom: $app-padding-sm;
  }
}
.payment__detail-cardlabel {
  color: swatch(glyph-gray);
  @include typography(body);
  @include font-weight(semibold);
  padding-bottom: $app-padding-xx-sm;
}
.payment__detail-address {
  @include viewport(small) {
    padding-bottom: $app-padding-sm;
  }
}

.payment__detail-addresslabel {
  color: swatch(glyph-gray);
  @include typography(body);
  @include font-weight(semibold);
  padding-bottom: $app-padding-xx-sm;
}
.payment__detail-addressinfo {
  span {
    display: block;
    @include typography(body);
    padding-bottom: 0;
  }
}

.product__summary {
  @include direction(margin-right, 0px, margin-left, 0px);
  color: swatch(glyph-gray);
  margin-top: $app-margin-lg;
  margin-bottom: 0;
  @include viewport(small) {
    margin-top: $app-margin-bottom-mmd;
  }
}
.textprice {
  @include typography(caption);
  @include direction(text-align, right, text-align, left);
  @include viewport(medium) {
    margin-top: 0px;
    padding-left: 0px;
  }
  @include viewport(small) {
    margin-top: 0px;
    padding-left: 0px;
    @include direction(text-align, left, text-align, right);
    padding-top: 7px;
    padding-bottom: 10px;
  }
  padding-left: 10px;
}
.product__details {
  border-bottom: 1px solid swatch(fill-gray-tertiary);
  @include direction(float, left, float, right);
  padding-bottom: 50px;
  @include viewport(small) {
    padding-bottom: 0;
  }
}
.product__details-image_left {
  text-align: center;
  img {
    width: $app-image-width-lg;
    height: $app-image-width-lg;
  }
  @include viewport(medium) {
    img {
      width: $app-image-width-md;
      height: $app-image-width-md;
    }
  }
  @include viewport(small) {
    img {
      width: 54px;
      height: 54px;
      //   padding-bottom: $app-margin-xx-xs;
    }
  }
}

.product__details-parts {
  @include typography(eyebrow);
  color: swatch(glyph-gray);
  @include viewport(small) {
    padding-bottom: 30px;
  }
}

.product__details-partprice_nuc {
  text-align: right;
}
.product__details-partprice-sub {
  color: swatch(glyph-gray);
  @include direction(text-align, right, text-align, left);
  @include typography(body);
  @include font-weight(semibold);
  p {
    @include direction(text-align, right, text-align, left);
    @include viewport(small) {
      @include direction(text-align, left, text-align, right);
    }
    padding-top: 0;
  }
}
.product_details-info {
  @include typography(body);
  padding: $app-margin-lg 0;
  border-bottom: 1px solid swatch(fill-gray-tertiary);
}

.product__details-name {
  @include typography(body-reduced);
  @include viewport(small) {
    padding-top: $app-padding-xx-sm;
  }
  @include viewport(medium) {
    padding-top: $app-padding-xx-sm;
  }
  > :last-child {
    padding-bottom: $app-padding-sm;
  }
}

.row__top-padding {
  padding-top: $app-padding-sm;
}

.product__details-id {
  @include typography(body-reduced);
  padding-bottom: $app-padding-sm;
}

.product__details-partrow {
  // border-bottom: 1px solid swatch(fill-gray-tertiary);
}
.product__details-partrow_nuc {
  @include typography(body);
}
.product__details-partitem {
  padding: $app-padding-sm 0px;
  border-top: 1px solid swatch(fill-gray-tertiary);
}

.product__details-partname {
  @include typography(body);
  font-weight: $app-font-weight-xr-lg;
}
.product__details-nonreturnprice {
  @include typography(body);
  font-weight: $app-font-weight-xr-lg;
  @include direction(text-align, right, text-align, left);
  @include viewport(small) {
    @include direction(text-align, left, text-align, right);
  }
}
.product__details-partprice {
  @include direction(text-align, right, text-align, left);
  @include typography(intro-elevated-reduced);
  font-weight: $app-font-weight-xr-lg;
  p {
    @include direction(text-align, right, text-align, left);
  }
  @include viewport(small) {
    @include direction(text-align, left, text-align, right);
  }
}
.product__details-partprice-sub {
  color: swatch(glyph-gray);
  @include direction(text-align, right, text-align, left);
  @include typography(body);
  @include font-weight(semibold);
  @include viewport(small) {
    @include direction(text-align, left, text-align, right);
    @include typography(body-reduced);
    @include font-weight(semibold);
  }
}
.product__details-authinfo {
  padding: 14px 0 $app-padding-sm 0;
  border-bottom: 1px solid swatch(fill-gray-tertiary);
  // border-top: 1px solid swatch(fill-gray-tertiary);
  padding-top: 0;
}

.method-details__number {
  @include typography(body);
  @include direction(
    padding-left,
    $app-padding-x-sm,
    padding-right,
    $app-padding-x-sm
  );
}

.product_auth-heading {
  color: swatch(glyph-gray);
  @include typography(eyebrow);
  padding-bottom: $app-padding-xx-sm;
}

.product__details-authdesc {
  text-align: justify;
  @include typography(body-reduced);
}

.payment__pricing {
  color: swatch(glyph-gray);
  @include typography(body);
  padding-top: $app-margin-xx-sm;
  @include viewport(small) {
    padding-top: $app-margin-xx-xs;
  }
}
.product__details-nonreturnitem {
  @include typography(body);
}
#product__details-yourtotal {
  color: swatch(glyph-gray);
  @include typography(eyebrow);
  @include viewport(small) {
    padding-top: $app-padding-xxxx-lg;
    padding-bottom: $app-padding-xx-xs;
  }
}

.product__details-yourtotal h2 {
  padding: none !important;
  line-height: 0 !important ;
}

.product__details-subtotal {
  @include typography(body);
}
.product__details-addfee {
  padding-top: $app-padding-sm;
  padding-bottom: $app-margin-bottom-sm;
  @include typography(eyebrow);
  @include viewport(small) {
    padding-bottom: 0px;
  }
}

.product__details-nrsubtotal {
  padding-bottom: $app-padding-x-sm;
  border-bottom: 1px solid swatch(fill-gray-tertiary);
}
.product__details-nrfee {
  padding-bottom: $app-padding-x-sm;
}
.product__details-nrfee_nuc {
  padding: $app-padding-sm 0px;
  @include typography(eyebrow);
  padding-bottom: 10px;
  padding-top: 30px;
}
.row {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.product__details-additional {
  width: 100%;
  padding: 0 !important;
}

.product__details-nrfeeprice {
  p {
    @include direction(text-align, right, text-align, left);
    padding-top: 0;
  }
}
.product__details-nrfeetax {
  p {
    padding-bottom: 0;
  }
}
.product__details-total {
  @include typography(eyebrow);
  padding-top: $app-padding-sm;
}
.product__details-ordertotalprice {
  p {
    @include direction(text-align, right, text-align, left);
  }
}

.payment__acion_reviewpage {
  @include direction(
    margin-right,
    $app-margin-zero,
    margin-left,
    $app-margin-zero
  );
  @include direction(text-align, right, text-align, left);
  margin-top: 32px;
  @include viewport(small) {
    padding-top: $app-margin-xx-xys;
  }
}

.payment__acion__container {
  @include direction(text-align, right, text-align, left);
  padding-bottom: 20px;
  img {
    width: $app-image-width-xm;
    height: $app-image-height-xm;
  }
}

.confirmModal__heading {
  text-align: center;
  @include typography(headline-reduced);
  padding-bottom: $app-padding-xxxx-sm;
}
.confirmModal__content {
  @include direction(text-align, center, text-align, center);
  @include typography(body-tight);
  margin-top: 0 !important;
  padding-top: $app-padding-zero;
  p {
    margin: $app-margin-zero;
  }
}

.confirmModal__action {
  margin-top: $app-margin-xxxx-sm;
  text-align: -webkit-center;
  .button {
    display: block;
    margin-top: $app-margin-x-xss;
    @include viewport(small) {
      width: 70%;
    }
  }
  .button--link {
    margin-left: $app-margin-x-xss;
    margin-right: $app-margin-x-xss;
  }
}

.externalPayment__heading {
  text-align: center;
  @include typography(headline-reduced);
  padding-bottom: $app-padding-xxxx-sm;
  @include viewport(small) {
    padding-bottom: $app-margin-xxxx-sm;
  }
}
.externalPayment__content {
  @include direction(text-align, center, text-align, center);
  @include typography(body-tight);
  margin-top: 0 !important;
  padding-top: $app-padding-zero;
  p {
    margin: $app-margin-zero;
  }
}

.checkout__header-label {
  h1 {
    margin-block-start: 0;
    margin-block-end: 0;
    @include typography(eyebrow);
  }
  @include typography(eyebrow);
  border-bottom: 1px solid #d2d2d7;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgb(210, 210, 215);
  padding-bottom: $app-margin-xxx-sm;
  color: swatch(glyph-gray);
}
.address-title__info {
  @include typography(body-tight);
}

.externalPayment__action {
  margin-top: $app-margin-xxxx-sm;
  text-align: -webkit-center;
  .button {
    display: block;
    margin-top: $app-margin-x-xss;
    @include viewport(small) {
      width: 70%;
    }
  }
}

.method-details {
  @include typography(body-tight);
  display: flex;
  align-items: center;
}
.method-title__text {
  font-size: 17px;
  color: #333;
  font-weight: $app-font-weight-x-lg;
}
.method-details__type {
  background-size: $app-payment-image-width $app-payment-image-height;
  width: $app-payment-image-width;
  height: $app-payment-size-height;

  &.method-details__type--visa {
    background-image: url(../../images/visa.png);
  }

  &.method-details__type--mnp {
    background-image: url(../../images/mnp.png);
    background-size: 41px 29px;
  }

  &.method-details__type--rupay {
    background-image: url(../../images/rupay.png);
    border-radius: 2px;
    background-color: white;
  }

  &.method-details__type--mastercard {
    background-image: url(../../images/mastercard.png);
  }
  &.method-details__type--unionpay {
    background-image: url(../../images/unionpay.png);
  }

  &.method-details__type--amex {
    background-image: url(../../images/amex.png);
  }

  &.method-details__type--discover {
    background-image: url(../../images/discover.png);
  }

  &.method-details__type--jcb {
    background-image: url(../../images/jcb.png);
  }

  &.method-details__type--diners {
    background-image: url(../../images/diners.png);
  }

  &.method-details__type--maestro {
    background-image: url(../../images/maestro.png);
  }

  &.method-details__type--applepay {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
    border: 0.5px solid black;
    display: inline-block;
    background-size: 80% 60% !important;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 4px;
    padding: 0px;
    box-sizing: border-box;
    min-width: 40px;
    min-height: 30px;
    max-height: 64px;
  }
}
.payment__detail-paytype {
  width: 100%;
  height: 45px;
  background-size: contain;
  background-repeat: no-repeat;

  &.payment__detail-paytype--momo {
    background-image: url(../../images/momo-logo.png);
  }
  &.payment__detail-paytype--shopee {
    background-image: url(../../images/shopee-logo.png);
  }
  &.payment__detail-paytype--atm {
    background-image: url(../../images/atm-logo.png);
  }
  &.payment__detail-paytype--tng {
    background-image: url(../../images/tng-logo.png);
  }
  &.payment__detail-paytype--banktransfer {
    background-image: url(../../images/banktransfer-logo.png);
  }
}
.error-message {
  margin: $app-margin-xx-xds 0;
  border-color: rgba(227, 0, 0, 0.4);
  background: #fff2f4;
  border-radius: 5px;
  border: 1px solid swatch(glyph-red);
  outline: none;
}
.error-message__text {
  color: #e30000;
  font-weight: $app-font-weight-lg;
  font-size: 0.9375em;
  padding: $app-margin-xxx-sm $app-padding-x-sm $app-padding-xx-m
    $app-padding-xx-m;
  outline: 0;
}
.error-message-refund {
  padding-left: $app-padding-xx-sm;
  vertical-align: middle;
}
.payment__acion__print {
  text-align: center;
  margin-top: $app-margin-ms-lg;
}

.confirmModal-externalPayment-close {
  position: absolute;
  top: $app-margin-xxx-sm;
  left: $app-margin-x-xss;
  @include direction(left, $app-margin-x-xss, right, auto);
}

.centerText {
  text-align: center;
}

.confirmModal-externalPayment-close__icons {
  width: $app-icon-width-xm;
  height: $app-icon-height-xm;
  margin-bottom: $app-padding-x-xs;
}

.confirmModal-externalPayment__header {
  text-align: center;
  @include typography(headline-reduced);
  padding-bottom: $app-padding-sm;
}

.confirmModal-externalPayment__body {
  text-align: left;
  @include typography(body-tight);
}

.confirmModal-externalPayment__footer {
  margin-top: $app-margin-lg;
  text-align: -webkit-center;
  .button {
    display: block;
    margin-bottom: $app-padding-xx-sm;
    width: 40%;
    @include viewport(small) {
      width: 90%;
    }
  }
}

.payment__detail-paytype {
  // font-weight: 600;
  @include typography(body);
  img {
    display: block;
    width: $app-image-width-md;
    height: $app-image-height-md;
    border: 1px solid black;
    border-radius: 5px;
    padding: $app-padding-xx-ssm;
  }
}
.button_change_dir {
  direction: ltr;
}
.payment__detail-chosenpaytype {
  @include typography(body);
  img {
    display: block;
    width: auto;
    height: 40px;
    border: 1px solid black;
    border-radius: 5px;
    padding: $app-padding-xx-ssm;
  }
}
.displayNone,
.modal-close {
  display: none;
}

.modal-close::after {
  padding-left: 0px !important;
  content: none !important;
}
#make-payment-applepay {
  background-color: swatch(fill-gray);
}
</style>
