<template>
<div class="dropdown">
  <div class="form-dropdown" :class="{ 'is-error': error }">
    <select
      :class="[
        this.selected
          ? 'form-dropdown-select'
          : 'form-dropdown-select form-dropdown-selectnone'
      ]"
      :autocomplete="autocomplete"
      :id="name"
      :name="name"
      :aria-label="name"
      :aria-invalid="error"
      :aria-required="true"
      :aria-describedby="error ? `${name}_error` : null"
      v-model="isSelected"
      :disabled="disabled"
      :required="required"
      @blur="blur"
    >
      <option v-if="!this.isSelected" disabled selected>
        {{ label }}
      </option>
      <option v-for="item in options" :key="item.text" :value="item.text">
        {{ item.text }}
      </option>
    </select>
    <span class="form-dropdown-chevron" aria-hidden="true">
      <span class="a11y">{{
        locale['cryptozen.dropdown.dropdownButton']
      }}</span>
    </span>
    <span
      class="form-dropdown-label"
      :id="`${name}_label`"
      aria-hidden="true"
      >{{ label }}</span
    >
    <div aria-live="polite" :id="`${name}_error`">
      <div v-if="error" class="error-container row">
        <div class="form-message-wrapper">
          <span class="form-message">
            {{
              locale[`pp.payment.error.highlight.${this.name.replace('-', '')}`]
            }}</span
          >
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Vue from 'vue'

export default {
  name: 'app-select',
  description: 'select Field',
  components: {},
  props: {
    label: {
      type: String,
      default: null
    },
    error: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      default: []
    },
    name: {
      type: String,
      default: ''
    },
    selected: {
      type: String,
      default: ''
    },
    selectMessage: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  mounted() {},
  computed: {
    errorMsg() {
      return this.errorMessage
        ? this.errorMessage.replace('{}', this.label)
        : ''
    },
    isSelected: {
      get() {
        const target = this.options.find(
          (option) =>
            option.value === this.selected || option.text === this.selected
        )
        return target ? target.text : null
      },
      set(value) {
        const target = this.options.find((option) => option.text === value)
        const payload = { name: this.name, value: target.value }
        this.$emit('onChange', payload)
      }
    },
    ...mapState({
      locale: (state) => state.mainStore.localization
    })
  },
  methods: {
    blur() {
      const payload = { name: this.name }
      this.$emit('onBlur', payload)
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'ac-sasskit/core';
@import 'ac-sasskit/base';
@import 'ac-forms/core';
@import 'ac-forms/base';
@import 'ac-forms/modules/form-icons';
@import 'ac-forms/modules/form-dropdown';
@import '@/styles/variables.scss';
.dropdown {
  width: 100%;
  margin: 0 auto;
  .dropdown-label {
    margin-bottom: $app-margin-sm;
  }

  .form-dropdown-label {
    @include direction(left, 1rem, right, 1rem);
    @include direction(text-align, unset, text-align, right);
  }
  .dropdown-label__text {
    font-weight: $app-font-weight-x-lg;
    max-width: 100%;
    font-size: $app-font-size-reg;
    line-height: $app-line-height-lg;
    color: $darkgrey;
  }
  .form-dropdown-chevron {
    top: 12px;
    bottom: 30px;
    @include direction(text-align, unset, text-align, left);
    @include direction(left, unset, right, 1rem);
  }
  @include viewport(small) {
    max-width: 100%;
  }
}

.form-dropdown-label {
  @include direction(left, 1rem, right, 1rem);
  @include direction(text-align, unset, text-align, right);
}

.form-dropdown-chevron {
  @include direction(text-align, unset, text-align, left);
  @include direction(left, unset, right, 1rem);
}

.dropdown-label__text {
  font-weight: $app-font-weight-x-lg;
  max-width: 100%;
  font-size: $app-font-size-reg;
  line-height: $app-line-height-lg;
  color: $darkgrey;
}

.form-message-wrapper {
  content: '';
  margin-top: 8px !important;
  margin-bottom: 12px;
  @include direction(padding-left, 0, padding-right, 0);
  img {
    width: 12px;
    height: 12px;
    margin-top: 2px;
  }
  @include direction(padding-left, 0, padding-right, 0);
}

.form-message {
  @include direction(padding-right, unset, padding-left, $app-padding-x-xs);
}

.form-dropdown-select {
  margin: 0;
  margin-bottom: 14px;
  @include direction(padding-right, unset, padding-left, 1rem);
}

option {
  display: block;
  white-space: pre;
  min-height: 1.2em;
  padding: 0 rem(2) rem(1);
}

.form-message {
  @include direction(
    padding-left,
    $app-padding-x-xs,
    padding-right,
    $app-padding-x-xs
  );
}
</style>
