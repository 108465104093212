export const MULTIBYTE_COUNTRIES = ['jp', 'kr', 'vn', 'hk', 'tw']

export const MULTIBYTE_ERROR_MESSAGE = {
  first_name: `pp.payment.error.message.name`,
  last_name: `pp.payment.error.message.name`,
  street1: `pp.payment.error.message.street`,
  street2: `pp.payment.error.message.street`,
  city: `pp.payment.error.message.city`
}

export const MAX_BYTE = 30
export const NAME_MAX_BYTE = 17

export const EWALLETS = {
  ATM_EWALLET: 'ATM',
  MOMO_EWALLET: 'MOMO',
  SHOPEE_EWALLET: 'SHOPEE',
  TNG_EWALLET: 'TNG',
  BANKTRANSFER_EWALLET: 'BANKTRANSFER'
}

export const CARD_TYPES_NOT_3DS_SUPPORTED = ['rupay']

export const PAYMENT_TYPES = {
  CREDIT_CARD: 'cc',
  APPLE_PAY: 'applepay',
  ATM: 'ATM',
  MOMO: 'MOMO',
  SHOPEE: 'SHOPEE',
  TOUCH_N_GO: 'TNG',
  BANK_TRANSFER: 'BANKTRANSFER',
  UPI: 'upipay'
}

export const CREDIT_CARD_TYPES = {
  VISA: 'visa',
  MASTERCARD: 'mastercard',
  DINERS_CLUB: 'diners',
  AMERICAN_EXPRESS: 'amex',
  DISCOVER: 'discover',
  JCB: 'jcb',
  MNP: 'mnp',
  RUPAY: 'rupay',
  UNION_PAY: 'unionpay'
}

export const E_WALLET_CONFIGS = {
  [EWALLETS.ATM_EWALLET]: {
    type: 'ATM',
    title: 'ATM',
    styleClass: 'image-atmpay',
    logoImage: 'atm-logo.png'
  },
  [EWALLETS.MOMO_EWALLET]: {
    type: 'MOMO',
    title: 'Momo',
    styleClass: 'image-momo',
    logoImage: 'momo-logo.png'
  },
  [EWALLETS.SHOPEE_EWALLET]: {
    type: 'SHOPEE',
    title: 'ShopeePay',
    styleClass: 'image-shoppepay',
    logoImage: 'shopee-logo.png'
  },
  [EWALLETS.TNG_EWALLET]: {
    type: 'TNG',
    title: 'TouchNGo',
    styleClass: 'image-tng',
    logoImage: 'tng-logo.png'
  },
  [EWALLETS.BANKTRANSFER_EWALLET]: {
    type: 'BANKTRANSFER',
    title: 'OnlineBanking',
    styleClass: 'image-banktransfer',
    logoImage: 'banktransfer-logo.png'
  }
}

export const AUTOCOMPLETE_ATTRIBUTES = {
  first_name: 'given-name',
  last_name: 'family-name',
  street1: 'address-line1',
  street2: 'address-line2',
  street3: 'address-line3',
  postalcode: 'postal-code',
  city: 'city',
  card_number: 'cc-number',
  expires: 'cc-exp',
  cvv: 'cc-csc',
  country: 'country-name',
  state: 'address-level1'
}

/**
 * transaction subTypes for which return address needs to be collected
 */
export const COLLECT_RETURN_ADDRESS = ['ROR', 'M-IN', 'RORB', 'MIB']

/**
 * Tax details
 * isValid - fn for validation
 * transformInput - fn for transforming the values as the user types in - Sample: (value) => value.toUpperCase() capitalizes characters as the user types in
 * normalizeValue - fn for normalizing the entire value before sending to the API - Sample: (value) => value.replaceAll(' ', '') removes spaces
 */
export const TAX_FIELDS = {
  BRA: [
    {
      type: 'CPF',
      fields: [
        {
          name: 'cpf',
          mask: '###########',
          i18n: 'pp.summary.tax.cpf',
          required: true,
          isValid: (value) => /([0-9]){11}/.test(value),
          maxLength: 11
        }
      ]
    },
    {
      type: 'CNPJ',
      fields: [
        {
          name: 'cnpjNumber',
          mask: '##############',
          i18n: 'pp.summary.tax.cnpj',
          required: true,
          isValid: (value) => /([0-9]){14}/.test(value),
          maxLength: 14
        },
        {
          name: 'stateTaxId',
          i18n: 'pp.summary.tax.cnpj.state', // State Tax number
          required: true
        },
        {
          name: 'municipalTaxId',
          i18n: 'pp.summary.tax.cnpj.municipal', // Municipal Tax number
          required: true
        }
      ]
    }
  ],
  CHL: [
    {
      type: 'BOLETA',
      fields: [
        {
          name: 'taxId', // RUT ID
          default: '66666666-6',
          mask: '########-#',
          i18n: 'pp.summary.tax.boleta.taxId',
          isValid: (value) => {
            const regexValid = /([0-9]){8}-([0-9]){1}/.test(value)
            const isStringEmpty =
              value === null ||
              (typeof value === 'string' && value.trim() === '')
            return isStringEmpty || regexValid
          }
        }
      ]
    },
    {
      type: 'FACTURA',
      collectTaxAddress: true,
      fields: [
        {
          name: 'taxId', // RUT ID
          mask: '########-#',
          i18n: 'pp.summary.tax.factura.taxId.label',
          required: true,
          isValid: (value) => /([0-9]){8}-([0-9]){1}/.test(value)
        },
        {
          name: 'registeredName',
          maxLength: 100,
          i18n: 'pp.summary.tax.factura.registeredName.label',
          required: true
        },
        {
          name: 'economicActivityId',
          maxLength: 40,
          i18n: 'pp.summary.tax.factura.giro.label', // Giro
          required: true
        }
      ]
    }
  ],
  ITA: [
    {
      type: null,
      fields: [
        {
          name: 'id', // Codice Fiscale
          mask: 'AAAAAANNNNNNNNNA',
          i18n: 'pp.summary.tax.italy',
          maxLength: 16,
          isValid: (value) => {
            const regexValid =
              /^[A-Za-z]{6}(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{9}[A-Za-z]{1}$/g.test(
                value
              ) // sample: LRNNRC76T05G478Z
            const isStringEmpty =
              value === null ||
              (typeof value === 'string' && value.trim() === '')
            return isStringEmpty || regexValid
          },
          transformInput: (value) => value.toUpperCase()
        }
      ]
    }
  ]
}

export const USA_PHONE_NUMBER_MASK = '(###) ###-####'
