<template>
  <div class="checkout section-content">
    <div v-if="!isLoaded" class="visually-hidden" aria-live="polite">
      <span> {{ locale['pp.message.loaded'] }} </span>
    </div>
    <div class="row checkout__header">
      <div class="column large-6 small-4 checkout__header-label">
        <span>{{ locale['pp.payment.checkout'] }}</span>
      </div>
      <div class="column large-6 small-8 checkout__header-price">
        <span class="checkout__header-text">
          <span v-if="isLoanAccount">
            {{ locale['pp.amount.summary'] }} {{ amount }}
          </span>
          <span v-if="!isLoanAccount && !isRefundType">
            {{ locale['pp.summary.shipping.total'] }}
            {{
              productInfo.parts.length > 0
                ? productInfo.price
                : productInfo.additionalAuth.non_return_amount
            }}</span
          >
        </span>
      </div>
    </div>
    <div class="checkout__header-howtopay">
      <h1>{{ locale['pp.payment.review'] }}</h1>
    </div>
    <!-- Error Message-->
    <b-col
      v-if="deviceProfilingError || errorMessage"
      ref="errorMessageText"
      role="region"
      aria-label="Payment error"
      aria-live="polite"
      tabindex="-1"
      cols="12"
      class="error-message"
    >
      <div class="error-message__text">
        <img
          alt="Error"
          class="input-field__icons"
          src="../../assets/warning.svg"
        />
        <span
          aria-hidden="true"
          class="error-message-form"
          v-html="locale['pp.payment.error']"
        ></span>
      </div>
    </b-col>
    <!-- Iframe Request for 3DS  -->
    <iframe
      v-if="this.deviceProfileURL"
      id="myframe"
      name="output_frame"
      src=""
      height="1"
      width="1"
    >
      <form
        id="stepup"
        target="output_frame"
        method="POST"
        :action="this.deviceProfileURL"
      >
        <input
          type="hidden"
          name="JWT"
          :value="this.deviceProfilePayload.JWT"
        />
      </form>
    </iframe>

    <!-- Payment Selection  -->
    <div class="row payment__selection">
      <div class="column large-6 medium-6 small-12 form-selector-group">
        <div v-if="isApplePayEnabled" class="form-selector">
          <input
            v-on:click="checkRadio('applepay', $event)"
            id="forms-applepay"
            class="form-selector-input"
            name="paymentType"
            type="radio"
            value="applepay"
          />
          <label for="forms-applepay" class="form-selector-label">
            <span class="row">
              <span class="form-selector-left-col column large-12">
                <img src="../../images/applepay.png" alt="Apple Pay" />
              </span>
            </span>
          </label>
        </div>
        <div v-if="displayCCPaymentType">
          <div class="form-selector">
            <input
              v-on:click="checkRadio('cc', $event)"
              id="forms-creditcard"
              aria-controls="creditcard__section"
              class="form-selector-input"
              name="paymentType"
              type="radio"
              value="Credit Card Payment"
              v-on:keydown.space="checkRadio('cc', $event)"
              v-on:keydown.space.self.prevent
            />
            <label
              id="cc-label"
              for="forms-creditcard"
              class="form-selector-label"
            >
              <span class="row">
                <span class="form-selector-left-col">
                  <span class="form-selector-title">{{
                    locale['pp.payment.ccpayment']
                  }}</span>
                  <span class="form-label-small">{{ creditCardTypeList }}</span>
                </span>
              </span>
            </label>
          </div>
        </div>
        <!-- Billing Summary -->
        <!-- Credit Card Section - Changed CVV type to password-->
        <b-collapse id="creditcard__section" v-model="visible">
          <b-card>
            <div
              id="creditcard__section-details"
              class="creditcard__section-details"
            >
              <div
                v-if="paymentMethod !== 'applepay'"
                class="creditcard__section-label"
              >
                <span v-if="isLoanAccount">
                  {{ locale['pp.payment.debitcard'] }}
                </span>
                <span v-if="!isLoanAccount">
                  {{ locale['pp.payment.cardinfo'] }}
                </span>
              </div>
              <div
                v-if="paymentMethod !== 'applepay'"
                class="row form-group creditcard__section--cardinfo"
              >
                <div class="row creditcard__section--field">
                  <div class="column large-12">
                    <app-input-new
                      :autocomplete="AUTOCOMPLETE_ATTRIBUTES['card_number']"
                      name="card_number"
                      type="text"
                      hasRightIcon
                      :mask="ccMask"
                      :cardNumberMasked="cardNumberMasked"
                      :required="true"
                      :aria-required="true"
                      :aria-invalid="error"
                      :readonly="isFetching"
                      :fieldValue="isCCMasked"
                      :placeholder="
                        this.isLoanAccount === true
                          ? locale['pp.payment.dbcnumber']
                          : locale['pp.card_number.label']
                      "
                      :error="$v.card_number.$error"
                      :maxLength="determinemaxLength"
                      @onChange="fieldChange"
                      @onBlur="validateField"
                      :isLoanAccount="isLoanAccount"
                      @onInputApply="onKeyupfield"
                    />
                  </div>
                </div>
                <div class="row creditcard__section--field">
                  <div
                    :class="
                      this.isRtl === true
                        ? 'creditcard__section--expireARE column large-8 small-12'
                        : 'creditcard__section--expire column large-8 small-12'
                    "
                  >
                    <app-input-new
                      :autocomplete="AUTOCOMPLETE_ATTRIBUTES['expires']"
                      name="expires"
                      :readonly="isFetching"
                      mask="##/##"
                      required
                      :error="$v.expires.$error"
                      :placeholder="labelText"
                      :fieldValue="fields.expires"
                      :maxLength="5"
                      type="text"
                      @onChange="fieldChange"
                      @onBlur="validateField"
                      @onInputApply="onKeyupfield"
                    />
                  </div>
                  <div
                    :class="
                      this.isRtl === true
                        ? 'creditcard__section--cvvARE column large-4 small-6'
                        : 'creditcard__section--cvv column large-4 small-6'
                    "
                  >
                    <app-input-new
                      :autocomplete="AUTOCOMPLETE_ATTRIBUTES['cvv']"
                      name="cvv"
                      :readonly="isFetching"
                      :isCVV="true"
                      :required="true"
                      :aria-required="true"
                      :error="$v.cvv.$error"
                      :placeholder="locale['pp.payment.cvv']"
                      :maxLength="cardDetails.cvcLength || 3"
                      :fieldValue="fields.cvv"
                      :type="cvvMasked ? 'password' : 'text'"
                      mask="####"
                      @onInputApply="onKeyupfield"
                      @onChange="fieldChange"
                      @onBlur="validateField"
                    />
                  </div>
                  <div
                    class="large-8 medium-12 small-12 debit_info"
                    aria-hidden="true"
                  >
                    <span v-if="isLoanAccount" id="debitCardMessage">
                      {{ locale['pp.debitcard.message'] }}
                    </span>
                  </div>
                  <div class="hr"></div>
                  <div
                    :class="
                      this.isRtl === true
                        ? 'creditcard__section--emailARE column large-12 small-12 new-section'
                        : 'creditcard__section--email column large-12 small-12 new-section'
                    "
                  >
                    <app-input-new
                      name="email"
                      :placeholder="
                        locale['pp.summary.billing.cardHolderEmail']
                      "
                      :required="true"
                      :characterCountEnabled="true"
                      type="text"
                      :fieldValue="fields.email"
                      :aria-required="true"
                      :error="$v.fields.email.$error"
                      @onInputApply="onKeyupfield"
                      @onChange="fieldChange"
                      @onBlur="validateDetailsField"
                    />
                  </div>
                </div>
              </div>
              <template>
                <div class="row">
                  <fieldset class="column large-12">
                    <legend class="creditcard__section-addressheading">
                      {{ locale['pp.payment.address.heading'] }}
                    </legend>
                    <!-- Display shipping address option if available from previous page -->
                    <template v-if="collectShippingInfo">
                      <div class="checkbox-container">
                        <input
                          v-model="billingAddressSameAsShipping"
                          type="checkbox"
                          name="billingAddressSameAsShipping"
                          id="billingAddressSameAsShipping"
                          :required="true"
                          :value="true"
                        />
                        <label
                          class="checkbox__label"
                          id="billingAddressSameAsShippingLabel"
                          for="billingAddressSameAsShipping"
                          ><p>
                            {{ locale['pp.payment.address.billingisshipping'] }}
                          </p></label
                        >
                      </div>
                    </template>
                    <template v-if="!showBillingAddressForm">
                      <div class="payment__form-displayAddressinfo">
                        <span class="address-title__info">
                          {{ shippingInfo.shippingAddress.fields.first_name }}
                          {{ shippingInfo.shippingAddress.fields.last_name }}
                        </span>
                        <span class="address-title__info">
                          {{ shippingInfo.shippingAddress.fields.street1 }}
                        </span>
                        <span class="address-title__info">
                          {{ shippingInfo.shippingAddress.fields.street2 }}
                        </span>
                        <span class="address-title__info">
                          {{ shippingInfo.shippingAddress.fields.street3 }}
                        </span>
                        <span>
                          {{
                            hasInputFieldInConfig('suburb')
                              ? shippingInfo.shippingAddress.fields.suburb || ''
                              : ''
                          }}
                        </span>
                        <span class="address-title__info">
                          {{ shippingInfo.shippingAddress.fields.city
                          }}{{
                            hasInputFieldInConfig('state') &&
                            this.states.stateDescriptions[
                              this.states.stateCodes.indexOf(
                                shippingInfo.shippingAddress.fields.state
                              )
                            ]
                              ? `, ${
                                  this.states.stateDescriptions[
                                    this.states.stateCodes.indexOf(
                                      shippingInfo.shippingAddress.fields.state
                                    )
                                  ]
                                }` || ''
                              : ''
                          }}
                          {{
                            shippingInfo.shippingAddress.fields.postalcode
                          }}</span
                        >
                      </div>
                    </template>
                    <template v-if="showBillingAddressForm">
                      <app-select
                        :autocomplete="AUTOCOMPLETE_ATTRIBUTES['country']"
                        class="creditcard__section--field"
                        v-if="productInfo.transactionType !== 'AGR'"
                        name="country"
                        :label="locale['pp.address.country']"
                        :options="[{ text: selectedCountryText }]"
                        :selected="selectedCountryText"
                        :error="$v.country ? $v.country.$error : false"
                        @onKeyup="onKeyupfield"
                        @onBlur="validateDetailsField"
                        :disabled="true"
                      />
                      <div
                        v-if="
                          (isCountrySettings === false ||
                            isStateFetched === false) &&
                          productInfo.transactionType !== 'AGR'
                        "
                        class="button-spinner"
                      >
                        <app-moon-loader color="black" size="25px" />
                      </div>
                      <div
                        v-if="
                          productInfo.transactionType === 'AGR' ||
                          (isCountrySettings === true &&
                            isStateFetched === true &&
                            productInfo.transactionType !== 'AGR' &&
                            selectedCountry !== 'country')
                        "
                        v-for="field in countrySettings"
                        :key="field.key"
                        :class="`creditcard__section--field`"
                      >
                        <!-- The state input field is explicitly being excluded because state is a drop-down and not a text-input -->
                        <app-input-new
                          :autocomplete="AUTOCOMPLETE_ATTRIBUTES[field.name]"
                          v-if="!usesSelectInput.includes(field.name)"
                          :name="field.name"
                          :maxLength="Number(field.length)"
                          :readonly="isFetching"
                          :required="field.required"
                          :characterCountEnabled="true"
                          :lookup="field.ziplookup"
                          :error="
                            errorField === field.name
                              ? true
                              : $v.fields[field.name]
                              ? $v.fields[field.name].$error
                              : false
                          "
                          :placeholder="locale[field.key]"
                          :fieldValue="fields[field.name]"
                          :multiByteMessage="
                            locale[MULTIBYTE_ERROR_MESSAGE[field.name]]
                          "
                          :multiByteCountries="multiByteCountries"
                          type="text"
                          :validationMessage="errorMessages[field.name]"
                          @onBlur="validateDetailsField"
                          @onChange="fieldChange"
                          @onInputApply="onKeyupfield"
                        />

                        <app-select
                          :autocomplete="AUTOCOMPLETE_ATTRIBUTES[field.name]"
                          v-if="showSelectInput(field.name)"
                          :name="field.name"
                          :options="getSelectOptions(field.name)"
                          :readonly="isFetching"
                          :label="locale[field.key]"
                          :selected="fields[field.name]"
                          :error="
                            $v.fields[field.name]
                              ? $v.fields[field.name].$error
                              : false
                          "
                          @onBlur="validateDetailsField"
                          @onKeyup="onKeyupfield"
                          @onChange="fieldChange"
                        />

                        <app-input-new
                          :autocomplete="AUTOCOMPLETE_ATTRIBUTES[field.name]"
                          v-if="showAlternativeTextInput(field.name)"
                          :name="field.name"
                          :maxLength="Number(field.length)"
                          :readonly="isFetching"
                          required
                          :error="
                            errorField === field.name
                              ? true
                              : $v.fields[field.name]
                              ? $v.fields[field.name].$error
                              : false
                          "
                          :placeholder="locale[field.key]"
                          :fieldValue="fields[field.name]"
                          type="text"
                          :validationMessage="errorMessages[field.name]"
                          @onBlur="validateDetailsField"
                          @onChange="fieldChange"
                          @onInputApply="onKeyupfield"
                        />
                      </div>
                    </template>
                  </fieldset>
                </div>
              </template>
            </div>
          </b-card>
        </b-collapse>
        <!-- eWallet options -->
        <div>
          <ExternalPaymentRadioButton
            v-for="eWalletOption in availableEWalletsOptions"
            v-bind:key="eWalletOption.type"
            :type="eWalletOption.type"
            :title="
              locale[`pp.payment.ewallet.${eWalletOption.title.toLowerCase()}`]
            "
            :styleClass="eWalletOption.styleClass"
            :logoImage="eWalletOption.logoImage"
            @onClick="checkRadio"
          >
            <template #image>
              <div class="form-selector">
                <img
                  :class="eWalletOption.styleClass"
                  :src="require('@/images/' + eWalletOption.logoImage)"
                  :alt="eWalletOption.type"
                />
              </div>
            </template>
            <template #default> </template>
          </ExternalPaymentRadioButton>
        </div>

        <div
          v-if="supportUPI"
          class="form-selector form-selector-twocol-threeline"
        >
          <input
            v-on:click="checkRadio('upipay', $event)"
            id="forms-finish2"
            class="form-selector-input"
            name="paymentType"
            type="radio"
            value="upi"
            :disabled="
              (!$v.$invalid && this.paymentMethod === 'cc') || isUPIdisabled
            "
          />
          <label for="forms-finish2" class="form-selector-label">
            <span class="row">
              <span class="form-selector-left-col column large-6">
                <span class="form-selector-title">UPI</span>
                <span class="form-label-small">UPI Payment Gateway</span>
              </span>
            </span>
          </label>
        </div>
      </div>
    </div>
    <!-- Continue Buttton -->
    <div class="row continue__review">
      <div class="column large-6 medium-6 small-12">
        <app-button
          id="continue-payment-form"
          :type="buttonSizeChange"
          :disabled="isDisabledForm"
          :isLoading="isFetching"
          @onClick="nextStep"
        >
          {{ locale['pp.payment.continuereview'] }}
        </app-button>
      </div>
    </div>
    <!-- Debit Check Modal -->
    <div id="debit_Modal" :class="{ displayNone: isModalClose }">
      <h2 class="debitModal__content">
        {{ locale['DebitAEXAlert'] }}
      </h2>
      <p class="debitModal__content">
        {{ locale['pp.cancel.confirm.message2'] }}
      </p>
      <div class="debitModal__action">
        <app-button type="elevated" @onClick="hideDebitModal">
          {{ locale['pp.continue.button'] }}
        </app-button>
        <app-button type="link" @onClick="clearCardInfo">
          {{ locale['pp.applepay.back'] }}
        </app-button>
      </div>
    </div>
    <!-- Failure Modal -->
    <div id="confirmModal-fail">
      <h2 class="termsFailureModal__heading">
        {{ locale['pp.message.error'] }}
      </h2>
      <p class="termsFailureModal__content">
        {{ locale['pp.terms.failure'] }}
      </p>
      <div class="termsFailureModal__action">
        <app-button
          data-modal-close
          @onClick="closetermsFailureModal"
          type="elevated"
        >
          {{ locale['pp.continue.button'] }}
        </app-button>
      </div>
    </div>
    <div
      id="loading-text"
      role="alert"
      class="sr-only"
      aria-live="polite"
    ></div>
  </div>
</template>
<script>
import ExternalPaymentRadioButton from '@/components/ExternalPaymentRadioButton'
import AppButton from '@/components/app-button'
import AppInputNew from '@/components/app-input-new'
import AppSelect from '@/components/app-select'
import validationMixin from '@/mixins/validationMixin'
import {
  AUTOCOMPLETE_ATTRIBUTES,
  CREDIT_CARD_TYPES,
  MULTIBYTE_ERROR_MESSAGE,
  PAYMENT_TYPES
} from '@/utilities/constants'
import { ARIA_LIVE_TIME_OUT_DELAY } from '@/utilities/timeout'
import {
  buttonType,
  containsSpecialCharacters,
  focusOnSelector
} from '@/utilities/utils'
import Modal from '@marcom/ac-modal'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import { mapGetters, mapState } from 'vuex'
import store from '../../store'
import { creditCardIds } from './utilities/matchCreditCard'

export default {
  components: {
    AppButton,
    AppInputNew,
    AppSelect,
    appMoonLoader: MoonLoader,
    ExternalPaymentRadioButton
  },

  title() {
    return `${this.locale['pp.payment.checkout']}`
  },

  data() {
    return {
      showed: '',
      isPaymentSelected: true,
      paymentMethod: '',
      isUPIdisabled: false,
      debitModal: '',
      isModalClose: true,
      isLoaded: false,
      isCCOpen: false,
      visible: false,
      windowWidth: 0,
      shouldZipCodeLookUp: false, // tells us if backend validation is needed for this country
      isZipCodeRequired: false, // tells us if zipcode is required for this country
      isLoanAccount: false,
      usesSelectInput: ['state', 'district', 'city'],
      eWalletSupportedCountries: [],
      isNextStepProcessing: false,
      ARIA_LIVE_TIME_OUT_DELAY,
      MULTIBYTE_ERROR_MESSAGE,
      eWalletSupportedCountries: [],
      AUTOCOMPLETE_ATTRIBUTES
    }
  },
  computed: {
    ...mapState({
      locale: (state) => state.mainStore.localization,
      productInfo: (state) => state.productStore,
      amount: (state) => state.mainStore.amount,
      accountType: (state) => state.mainStore.accountType,
      billingAddress: (state) => state.formStore.billingAddress,
      errorMessages: (state) => state.formStore.errorMessages,
      states: (state) => state.mainStore.states,
      countrySettings: (state) => state.mainStore.countrySettings,
      countryFields: (state) => state.mainStore.countryFields,
      productDetails: (state) => state.productStore,
      card_number: (state) => state.formStore.fields.card_number,
      cardNumberMasked: (state) => state.formStore.fields.cardNumberMasked,
      cvv: (state) => state.formStore.fields.cvv,
      cvvMasked: (state) => state.formStore.fields.cvvMasked,
      expires: (state) => state.formStore.fields.expires,
      expires_month: (state) => state.formStore.fields.expires_month,
      expires_year: (state) => state.formStore.fields.expires_year,
      fields: (state) => state.formStore.fields,
      billingFormStatus: (state) => state.formStore.status,
      isFetching: (state) => state.formStore.status.isFetching,
      isFetched: (state) => state.formStore.status.isFetched,
      error: (state) => state.formStore.status.error,
      cardType: (state) => state.formStore.cardType,
      country: (state) => state.mainStore.details.country_3,
      errorMessage: (state) => state.formStore.errorDetails.errorMessage,
      errorField: (state) => state.formStore.errorDetails.errorField,
      isApplePayEnabled: (state) => state.applepayStore.details.isEnabled,
      isRtl: (state) => state.mainStore.isRtl,
      validateMerchantChecking: (state) =>
        state.applepayStore.validateMerchantStatus.isChecking,
      validateMerchantChecked: (state) =>
        state.applepayStore.validateMerchantStatus.isChecked,
      validateMerchantError: (state) =>
        state.applepayStore.validateMerchantStatus.error,
      validateMerchantResponse: (state) =>
        state.applepayStore.validateMerchantData,
      paymentRequestObj: (state) =>
        state.applepayStore.details.paymentRequestObj,
      applepayProcessPending: (state) =>
        state.applepayStore.applepayProcessStatus.isPending,
      applepayProcessDone: (state) =>
        state.applepayStore.applepayProcessStatus.isDone,
      applepayProcessError: (state) =>
        state.applepayStore.applepayProcessStatus.error,
      applepayProcessResponse: (state) =>
        state.applepayStore.applepayProcessData,
      paymentDetails: (state) => state.formStore.paymentDetails,
      cardDetails: (state) => state.formStore.cardDetails,
      applePayLastFour: (state) => state.formStore.applePayLastFour,
      additionalAuth: (state) => state.productStore.additionalAuth,
      region: (state) => state.mainStore.details.region,
      type: (state) => state.mainStore.details.acceptance_types,
      available_cc_types: (state) => state.mainStore.details.available_cc_types,
      support3DSecure: (state) => state.mainStore.details.support3DSecure,
      detailsInfo: (state) => state.mainStore.details,
      isDebitcard: (state) => state.formStore.isDebitcard,
      deviceProfileURL: (state) => state.formStore.deviceProfileURL,
      deviceProfilePayload: (state) => state.formStore.deviceProfilePayload,
      deviceProfilingError: (state) => state.formStore.deviceProfilingError,
      enablePayment: (state) => state.formStore.enablePayment,
      upipaymentDetails: (state) => state.formStore.upipaymentDetails,
      supportUPI: (state) => state.mainStore.details.supportUPI,
      isCountrySettings: (state) => state.mainStore.isCountrySettings,
      isCountryFields: (state) => state.mainStore.isCountryFields,
      countrycode: (state) => state.mainStore.countrycode,
      selectCountryOptions: (state) => state.mainStore.countryFields,
      termsChecked: (state) => state.termsStore.termStatus,
      selectedCountry: (state) => state.mainStore.selectedCountry,
      selectedCountryText: (state) => state.mainStore.selectedCountryText,
      localeCountry: (state) => state.mainStore.localeCountry,
      tokenValue: (state) => state.mainStore.tokenValue,
      iswebpayEligibleCountry: (state) =>
        state.mainStore.iswebpayEligibleCountry,
      isStateListAvail: (state) => state.mainStore.isStateListAvail,
      isStateFetched: (state) => state.mainStore.isStateFetched,
      isRefundType: (state) => state.mainStore.refundType,
      isRefundVerified: (state) => state.formStore.isRefundVerified,
      text_key: (state) => state.mainStore.details.text_key,
      zipCodeLookup: (state) => state.formStore.zipCodeLookup,
      isCreditCardDisabled: (state) => state.mainStore.details.disableCC,
      deviceProfileExpiryMonth: (state) =>
        state.formStore.deviceProfileExpiryMonth,
      deviceProfileExpiryYear: (state) =>
        state.formStore.deviceProfileExpiryYear,
      deviceProfilingStatus: (state) => state.formStore.deviceProfilingStatus,
      deviceProfileCvv: (state) => state.formStore.deviceProfileCvv,
      multiByteCountries: (state) => state.mainStore.details.multiByteCountries,
      deviceProfileLoading: (state) => state.formStore.deviceProfileLoading,
      shippingInfo: (state) => state.formStore.shippingInfo,
      is3dsRequired: (state) => state.formStore.is3dsRequired
    }),
    ...mapGetters([
      'availableEWalletsOptions',
      'eligibleForCC',
      'isChile',
      'isIndia',
      'languageCode',
      'getDistrictsSelectOptions',
      'getCitiesInDistrict',
      'getCitiesSelectOptions',
      'collectShippingInfo',
      'showBillingAddressFormForApplePay',
      'hasInputFieldInConfig'
    ]),
    creditCardTypeList() {
      const creditCardsOptions = this.available_cc_types.map((ele) => {
        return creditCardIds[Number(ele) - 1].companyName === 'MasterCard'
          ? 'Mastercard'
          : creditCardIds[Number(ele) - 1].companyName
      })
      /*
       * The conditional code block below is commented out because we do not currently support Apple Card detection/identification.
       * We are in the process of working with various teams in order to add this functionality in a future release.
       * Once we integrate the ability to detect/identify Apple Cards, the code below should be re-added (and this comment should be removed).
       *
       * if (this.country === 'USA') {
       *   return `Apple Card, ${creditCardsOptions.join(', ')}`
       * }
       */
      return creditCardsOptions.join(', ')
    },
    displayCCPaymentType() {
      return (
        !this.isLoanAccount &&
        !this.isRefundType &&
        !this.isCreditCardDisabled &&
        this.eligibleForCC
      )
    },
    ccMask() {
      return this.cardDetails.cardNumberGrouping
        ? this.cardDetails.cardNumberGrouping
            .map((num) => 'X'.repeat(num))
            .join(' ')
        : '#### #### #### ####'
    },
    isCCMasked() {
      if (this.fields.cardNumberMasked === null) {
        return this.fields.card_number
      }
      if (this.fields.cardNumberMasked !== null) {
        return this.fields.cardNumberMasked
      }
    },
    selectOptions() {
      const states = this.states['stateCodes']
      const textArray = this.states['stateDescriptions']
      let formatedOptions = [
        {
          value: null,
          text: this.locale['address.state']
        }
      ]
      states.map((item, index) => {
        formatedOptions.push({
          value: item,
          text: textArray[index]
        })
      })
      return formatedOptions
    },
    determineMaxLength() {
      if (Object.keys(this.cardDetails).length === 0) {
        return 19
      } else {
        return (
          this.cardDetails.cardNumberGrouping.reduce(
            (previousCardGroup, currentCardGroup) =>
              previousCardGroup + currentCardGroup,
            0
          ) +
          this.cardDetails.cardNumberGrouping.length -
          1
        )
      }
    },
    renderForm() {
      if (this.isApplePayEnabled) {
        if (this.view === 'cc') {
          return !this.isFetched || (this.isFetched && this.error)
        } else {
          return false
        }
      } else if (this.supportUPI && this.isUPIEnabled) {
        if (this.view === 'cc') {
          return !this.isFetched || (this.isFetched && this.error)
        } else {
          return false
        }
      } else {
        return !this.isFetched || (this.isFetched && this.error)
      }
    },
    formOffset() {
      if (this.isRtl) {
        return 0
      } else {
        return this.renderForm ? '0' : '6'
      }
    },
    buttonSizeChange() {
      return buttonType(this.windowWidth)
    },
    isApplePayBillingFormValid() {
      // this.$v.fields.$invalid checks for any required fields that are billing inputs, but excludes credit card inputs
      return (this.paymentMethod === 'applepay' &&
        this.showBillingAddressFormForApplePay &&
        this.$v.fields.$invalid) ||
        (this.paymentMethod === 'applepay' &&
          this.showBillingAddressFormForApplePay &&
          this.error)
        ? true
        : false
    },
    isDisabledForm() {
      if (
        (this.$v.$invalid && this.paymentMethod === 'cc') ||
        this.isApplePayBillingFormValid ||
        (this.error && this.paymentMethod !== 'applepay') ||
        (this.isPaymentSelected && this.paymentMethod !== 'cc') ||
        this.deviceProfileLoading ||
        this.isNextStepProcessing
      ) {
        return true
      }
    },
    lastFour() {
      return this.applePayLastFour
        ? this.applePayLastFour
        : this.supportUPI && this.isUPIEnabled
        ? ''
        : this.card_number.replace(/ +/g, '').slice(-4)
    },
    taxLabel() {
      if (this.productDetails.tax !== undefined) {
        return this.region === 'EMEA' &&
          this.text_key != 'acplus' &&
          this.text_key != 'acp.dmg' &&
          this.text_key != 'acp.tnl'
          ? this.locale['pp.payment.quote.vat']
          : this.locale['pp.payment.quote.tax']
      }
    },
    labelText() {
      if (this.$route.params.locale === 'ar_AE') {
        return `${this.locale['pp.card_exp.label']} ${this.locale['pp.payment.info.yy']}/${this.locale['pp.payment.info.mm']}`
      } else {
        return `${this.locale['pp.card_exp.label']} ${this.locale['pp.payment.info.mm']}/${this.locale['pp.payment.info.yy']}`
      }
    },
    fullwidthButton() {
      if (this.$route.params.locale === 'ru_RU') {
        return true
      } else {
        return false
      }
    },
    fullwidthButtonnor() {
      if (this.$route.params.locale === 'no_NO') {
        return true
      } else {
        return false
      }
    },
    fullwidthButtonpor() {
      if (this.$route.params.locale === 'pt_PT') {
        return true
      } else {
        return false
      }
    },
    billingAddressSameAsShipping: {
      get() {
        return this.$store.state.formStore.billingAddressSameAsShipping
      },
      set(value) {
        this.$store.commit('TOGGLE_BILLING_ADDRESS_SAME_AS_SHIPPING', value)
      }
    },
    showBillingAddressForm() {
      const showShippingSummary =
        this.collectShippingInfo && this.billingAddressSameAsShipping

      return !showShippingSummary
    }
  },
  mixins: [validationMixin],
  validations() {
    const checkCardId = () => {
      let validCard = true
      validCard = this.available_cc_types.includes(this.cardDetails.id)
      if (this.fields.card_number === '') {
        this.$store.dispatch('cardNotSupported', false)
      }
      if (validCard == false && this.fields.card_number) {
        this.$store.dispatch('cardNotSupported', true)
      }

      return (this.cardDetails && this.cardDetails.id && validCard) || false
    }

    const checkExpires = () => {
      if (this.fields.expires !== null) {
        let expiryMonth
        let expiryYear
        if (this.detailsInfo.country_3 !== 'JPN') {
          expiryMonth = this.getExpiryMonth(this.fields.expires)
          expiryYear = this.getExpiryYear(this.fields.expires)
        } else {
          expiryMonth = this.fields.expires.replace('／', '/').split('/')[0]
          expiryYear = this.fields.expires.replace('／', '/').split('/')[1]
        }

        if (
          !/^\d+$/.test(expiryMonth) ||
          !/^\d+$/.test(expiryYear) ||
          expiryMonth.length != 2 ||
          expiryYear.length != 2
        ) {
          return false
        }

        const currentYear = new Date().getFullYear().toString().substr(-2)

        const currentMonth = new Date().getMonth().toString()

        if (
          Number(expiryMonth) > 12 ||
          Number(expiryMonth) === 0 ||
          Number(expiryYear) < Number(currentYear) ||
          (Number(expiryYear) === Number(currentYear) &&
            Number(expiryMonth) < Number(currentMonth) + 1)
        ) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    }
    const checkpostalcode = () => {
      if (this.selectedCountry === 'JPN') {
        let postCodeVal = ''
        if (this.fields.postalcode !== null) {
          postCodeVal = this.fields.postalcode
            .replace('−', '-')
            .replace('ー', '-')
        }

        if (this.fields.postalcode !== null) {
          if (postCodeVal.slice(3, 4) !== '-') {
            return false
          } else {
            return true
          }
        } else {
          return true
        }
      }
    }

    const specialCharacterCheck = (type, field) => (value) => {
      const isValid = !containsSpecialCharacters(value)
      const message =
        this.locale['pp.payment.error.highlight.noSpecialCharacters']

      if (!isValid) {
        this.$store.commit('SET_ERROR_MESSAGE_FOR_FIELD', {
          type,
          field,
          message
        })
      } else {
        this.$store.commit('UNSET_ERROR_MESSAGE_FOR_FIELD', {
          type,
          field,
          message
        })
      }
      return isValid
    }

    const checkEmail = (value) => {
      /**
       * invalid validation (same as the one used by Core)
       */
      const regex =
        /^[\w!#$%&‘*+/=?{|}~^-]+(?:\.[\w!#$%&'*+/=?{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,63}$/

      // standalone validator ideally should not assume a field is required
      if (value === '') {
        return true
      }
      return regex.test(value)
    }

    let settings = {
      card_number: {
        required,
        minLength: minLength(19),
        checkCardId
      },
      cvv: {
        required,
        minLength: minLength(this.cardDetails.cvcLength || 3),
        maxLength: maxLength(this.cardDetails.cvcLength || 3)
      },
      expires: {
        required,
        minLength: minLength(5),
        checkExpires
      },
      fields: {
        email: {
          required,
          checkEmail
        }
      },
      country: {}
    }
    // dynamic validation
    if (this.cardDetails.cardNumberGrouping) {
      let allowedLeng = 0

      this.cardDetails.cardNumberGrouping.forEach((leng) => {
        allowedLeng = allowedLeng + Number(leng)
      })

      allowedLeng = allowedLeng + this.cardDetails.cardNumberGrouping.length - 1

      if (this.cardDetails.id == '07' || this.cardDetails.id == '09') {
        allowedLeng = 19
      }
      settings.card_number.minLength = minLength(allowedLeng)
    }
    this.countrySettings.forEach((item) => {
      settings.fields[item.name] = {}
      if (item.required) {
        if (item.name === 'postalcode' && this.selectedCountry === 'JPN') {
          settings.fields[item.name] = { required, checkpostalcode }
          this.shouldZipCodeLookUp = true
        } else {
          settings.fields[item.name] = { required }
        }
      }
      if (item.name === 'postalcode') {
        this.shouldZipCodeLookUp = item.zipLookup
        if (item.required) this.isZipCodeRequired = true
      }
    })
    settings.country = { required }

    const billingAddressFieldsSettings = settings.fields

    /**
     * attach special character validation for all address fields
     */
    Object.keys(billingAddressFieldsSettings).forEach((field) => {
      if (field !== 'email') {
        billingAddressFieldsSettings[field] = {
          ...billingAddressFieldsSettings[field],
          specialCharacterCheck: specialCharacterCheck('billing', field)
        }
      }
    })
    return settings
  },
  created: function () {
    const that = this
    setTimeout(function () {
      that.isLoaded = true
    }, ARIA_LIVE_TIME_OUT_DELAY)
    this.checkAccountType()
  },
  watch: {
    billingAddressSameAsShipping(val) {
      /**
       * reset the billing address values to default if 'same as shipping' checkbox is unchecked
       */
      if (val === false) {
        this.$store.commit('RESET_BILLING_ADDRESS_FIELDS')
      } else if (val === true) {
        this.$store.commit('RESET_ERROR_DETAILS')
        this.$store.dispatch('setBillingAddressFromShippingAddress')
      }
    },

    deviceProfilingError(val) {
      if (val === true) {
        this.$nextTick(() => {
          // DOM is now updated
          const errorCol = this.$refs.errorMessageText
          errorCol.focus()
          errorCol.scrollIntoView()
        })
      }
    },

    errorMessage(val) {
      if (val === true) {
        this.$nextTick(() => {
          // DOM is now updated
          const errorCol = this.$refs.errorMessageText
          errorCol.focus()
          errorCol.scrollIntoView()
        })
      }
    },
    selectedCountry(oldval, val) {
      if (oldval !== val) {
      }
    },
    isCountrySettings: function (val, oldVal) {
      if (this.paymentMethod === 'cc') {
        if (val === false) {
          var node = document.createElement('span')
          var textnode = document.createTextNode('loading address fields')
          node.appendChild(textnode)
          document.getElementById('loading-text').appendChild(node)
        } else {
          var list = document.getElementById('loading-text')
          list.removeChild(list.childNodes[0])
        }
      }
    },
    isStateFetched: function (val, oldVal) {
      if (this.paymentMethod === 'cc') {
        if (val === false) {
          var node = document.createElement('span')
          var textnode = document.createTextNode('loading address fields')
          node.appendChild(textnode)
          document.getElementById('loading-text').appendChild(node)
        } else {
          var list = document.getElementById('loading-text')
          list.removeChild(list.childNodes[0])
        }
      }
    }
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener('resize', this.getWindowWidth)

      //Init
      this.getWindowWidth()
    }),
      focusOnSelector('h1')
    if (this.errorField === '') {
      this.$store.dispatch('defaultFormfield')
    }
    this.failmodal = Modal.createStandardModal()
    this.failmodal.modalElement.setAttribute(
      'aria-label',
      this.locale['pp.message.error']
    )
    const failel = document.getElementById('confirmModal-fail')
    this.failmodal.appendContent(failel)
    this.failmodal.modalElement.removeAttribute('data-modal-close')
    this.failmodal.modalElement
      .getElementsByTagName('div')[0]
      .getElementsByTagName('div')[0]
      .removeAttribute('data-modal-close')
    this.failmodal.modalElement
      .getElementsByTagName('div')[0]
      .getElementsByTagName('div')[0]
      .getElementsByTagName('div')[0]
      .removeAttribute('data-modal-close')
    this.failmodal.modalElement.getElementsByTagName('button')[1].remove()
    this.debitModal = Modal.createStandardModal()
    this.debitModal.modalElement.setAttribute('aria-label', 'Debit Card Modal')
    const el = document.getElementById('debit_Modal')
    this.debitModal.appendContent(el)

    this.debitModal.modalElement.removeAttribute('data-modal-close')

    this.debitModal.modalElement
      .getElementsByTagName('div')[0]
      .getElementsByTagName('div')[0]
      .removeAttribute('data-modal-close')
    this.debitModal.modalElement
      .getElementsByTagName('div')[0]
      .getElementsByTagName('div')[0]
      .getElementsByTagName('div')[0]
      .removeAttribute('data-modal-close')

    if (
      this.$route.name === 'PaymentForm' &&
      this.type === 'Active' &&
      !this.termsChecked
    ) {
      const { token, locale } = this.$route.params

      this.$router.push(`/${token}/${locale}/terms`)
    } else if (this.isCountrySettings === false) {
      this.$store.dispatch('getCountrySetting', this.countrycode).then(() => {
        if (!this.isCountrySettings) {
          this.failmodal.open()
        }
      })
    }
    this.isModalClose = false
    if (this.productInfo.transactionType !== 'AGR') {
      this.$store.dispatch('setCountrySelected', this.country)
      this.$store.dispatch('getCountrySetting', this.country)
    }
    if (this.isFetching) {
      this.$store.dispatch('upiPaymentRedirected')
    }

    if (
      this.collectShippingInfo &&
      Object.keys(this.billingAddress).length === 0
    ) {
      /**
       * set billing address values from the shipping address when the billing address is empty
       */
      this.$store.dispatch('setBillingAddressFromShippingAddress')
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth)
  },
  beforeRouteLeave(to, _from, next) {
    if (
      this.isDisabledForm && // when form is invalid
      this.isNextStepProcessing === false && // when form is not being processed
      to.name === 'PaymentReview'
    ) {
      // when the user tries to navigate to payment review with Browser forward button
      this.$v.fields.$touch()
      next(false)
    } else {
      next()
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // from.name is null when refreshing, on non-refresh we recieve a name.
      // prevents user from coming into /billing when not coming from shipping page or review page.
      if (vm.collectShippingInfo) {
        if (
          (from.name !== 'ShippingInfo' && from.name !== 'PaymentReview') ||
          from.name === null
        ) {
          const { token, locale } = vm.$route.params
          const route = `/#/${token}/${locale}`
          location.href = route
          location.reload()
        }
      }
    })
  },
  methods: {
    checkAccountType() {
      if (this.accountType) {
        this.visible = true
        this.isLoanAccount = true
        this.isPaymentSelected = true
        this.paymentMethod = 'cc'
      } else {
        this.visible = false
        this.isLoanAccount = false
      }
    },
    getExpiryMonth(date) {
      return date.split('/')[0]
    },
    getExpiryYear(date) {
      return date.split('/')[1]
    },
    conjoinCardNumber(creditCardNumber) {
      return creditCardNumber.split(' ').join('')
    },
    getWindowWidth(event) {
      this.windowWidth = document.documentElement.clientWidth
    },
    obfuscateCreditCard() {
      if (this.fields.card_number !== null) {
        this.fields.cardNumberMasked =
          this.fields.card_number.slice(0, -5).replace(/[0-9]/g, '•') +
          this.fields.card_number.slice(-5)
      }
    },
    checkRadio(value, event) {
      /*
       * The purpose of introducing this early function exit is to resolve a problem arising from Safari's autofill.
       * The issue involves an unintended activation of the Credit Card radio button, consequently leading to the collapse of the credit card dropdown.
       * This, in turn, results in the automatic credit card details from autofill being concealed.
       * By incorporating this code snippet, we guarantee that the radio button selection occurs exclusively in response to user interaction, as opposed to autofill actions.
       *
       * This Safari autofill issue should be researched more, as this is a stop-gap fix. See this Radar for more details: rdar://113935495
       */
      if (!event.isTrusted) {
        return
      }

      this.isPaymentSelected = false
      this.paymentMethod = value
      if (value === 'applepay' && this.visible === true) {
        this.isUPIdisabled = false
        this.visible = false
      }
      if (
        value === 'applepay' &&
        this.showBillingAddressFormForApplePay &&
        this.isApplePayEnabled
      ) {
        this.visible = true
        document.getElementById('forms-applepay').focus()
      }
      if (value === 'cc' && !this.isLoanAccount) {
        if (this.showBillingAddressFormForApplePay && this.isApplePayEnabled) {
          this.visible = true
        } else {
          this.visible = !this.visible
        }
        document.getElementById('cc-label').focus()

        this.isPaymentSelected = true
        if (
          document.getElementById('creditcard__section').className ===
          'collapse'
        ) {
          this.isUPIdisabled = true
        } else {
          this.isUPIdisabled = false
        }
      }
    },
    clearCardInfo() {
      this.debitModal.close()
      this.isModalClose = true
      this.$store.dispatch('resetCardInfo')
    },
    hideDebitModal() {
      this.debitModal.close()
      this.isModalClose = true
    },
    showSelectInput(fieldName) {
      switch (fieldName) {
        case 'state':
          return (
            (this.isStateListAvail === true &&
              this.productInfo.transactionType !== 'AGR') ||
            this.productInfo.transactionType === 'AGR'
          )
        case 'district':
          return this.getDistrictsSelectOptions().length
        case 'city': {
          if (this.billingFormStatus.otherOptionChosenForCity) {
            /**
             * user has chosen 'Other' option. Don't show Select input
             */
            return false
          }
          return this.getCitiesSelectOptions().length
        }
        default:
          return false
      }
    },
    /**
     * for displaying text input instead of a select when select options are not available
     */
    showAlternativeTextInput(fieldName) {
      if (!this.usesSelectInput.includes(fieldName)) return false

      switch (fieldName) {
        case 'state':
          return (
            this.isStateListAvail !== true &&
            this.productInfo.transactionType !== 'AGR'
          )
        case 'district':
          return !this.getDistrictsSelectOptions().length
        case 'city': {
          if (this.billingFormStatus.otherOptionChosenForCity) {
            /**
             * user has chosen 'Other' option. Show text input
             */
            return true
          }
          return !this.getCitiesSelectOptions().length
        }
        default:
          return false
      }
    },
    getSelectOptions(fieldName) {
      switch (fieldName) {
        case 'state':
          return this.selectOptions
        case 'district':
          return this.getDistrictsSelectOptions()
        case 'city':
          return this.getCitiesSelectOptions()
        default:
          return []
      }
    },
    async nextStep(value) {
      this.isNextStepProcessing = true
      try {
        const isZipCodeRequiredOrHasValue =
          this.isZipCodeRequired || this.fields.postalcode
        const needsZipCodeValidation =
          isZipCodeRequiredOrHasValue && this.shouldZipCodeLookUp

        if (this.collectShippingInfo && this.billingAddressSameAsShipping) {
          /**
           * set billing address values from the shipping address
           */
          this.$store.dispatch('setBillingAddressFromShippingAddress')
        }

        /**
         * this.zipCodeLookup !== this.fields.postalcode is there to ensure that validation
         * is done when the entered zipcode is different from the one which is already validated
         */
        if (
          this.zipCodeLookup !== this.fields.postalcode &&
          needsZipCodeValidation &&
          this.isUPIdisabled
        ) {
          this.$store
            .dispatch('fetchStates', {
              zipCode: this.fields.postalcode,
              country: this.selectedCountry,
              field: 'postalcode'
            })
            .then(async () => {
              if (this.error) {
                return
              }
              this.fields.cvvMasked = true
              this.$store.dispatch('setPaymentMethod', this.paymentMethod)
              this.$store.dispatch('setBillingAddress')

              this.$store.dispatch('resetErrorDetails')
              const { token, locale } = this.$route.params
              /**
               * Check if 3DS is supported, check if card type is Rupay, Rupay card network does not support 3DS,
               * and check if payment type is credit card as 3DS is only used for credit cards.
               */
              if (
                this.support3DSecure &&
                this.cardType !== CREDIT_CARD_TYPES['RUPAY'] &&
                this.paymentMethod === PAYMENT_TYPES['CREDIT_CARD']
              ) {
                await this.request3DSAndDeviceProfile()
              }
              if (this.error) {
                return
              }
              if (this.is3dsRequired) {
                await this.$store.dispatch('triggerDeviceProfile')
              }

              if (this.isLoanAccount) {
                this.$store
                  .dispatch('verifyRefundDetails', {
                    transactionId: token,
                    locale: locale
                  })
                  .then((data) => {
                    if (this.isRefundVerified) {
                      this.obfuscateCreditCard()
                      const route = `/${token}/${locale}/review`
                      this.$router.push(route)
                    }
                  })
              } else {
                this.obfuscateCreditCard()
                const route = `/${token}/${locale}/review`
                this.$router.push(route)
              }
            })
        } else {
          this.fields.cvvMasked = true
          this.$store.dispatch('setPaymentMethod', this.paymentMethod)
          this.$store.dispatch('setBillingAddress')

          this.$store.dispatch('resetErrorDetails')
          const { token, locale } = this.$route.params
          /**
           * Check if 3DS is supported, check if card type is Rupay, Rupay card network does not support 3DS,
           * and check if payment type is credit card as 3DS is only used for credit cards.
           */
          if (
            this.support3DSecure &&
            this.cardType !== CREDIT_CARD_TYPES['RUPAY'] &&
            this.paymentMethod === PAYMENT_TYPES['CREDIT_CARD']
          ) {
            await this.request3DSAndDeviceProfile()
          }
          if (this.error) {
            return
          }
          if (this.is3dsRequired) {
            await this.$store.dispatch('triggerDeviceProfile')
          }

          if (this.isLoanAccount) {
            // const self = this
            this.$store
              .dispatch('verifyRefundDetails', {
                transactionId: token,
                locale: locale
              })
              .then((data) => {
                if (this.isRefundVerified) {
                  this.obfuscateCreditCard()
                  const route = `/${token}/${locale}/review`
                  this.$router.push(route)
                }
              })
          } else {
            this.obfuscateCreditCard()
            const route = `/${token}/${locale}/review`
            this.$router.push(route)
          }
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.isNextStepProcessing = false
      }
    },
    async request3DSAndDeviceProfile() {
      const fieldsSource =
        this.billingAddressSameAsShipping && this.collectShippingInfo
          ? this.shippingInfo.shippingAddress.fields
          : this.fields

      const billingAddress = {
        firstName: fieldsSource.first_name,
        lastName: fieldsSource.last_name,
        street1: fieldsSource.street1,
        street2: fieldsSource.street2,
        postalCode: fieldsSource.postalcode,
        city: fieldsSource.city,
        country: this.country,
        state: fieldsSource.state
      }

      const creditCardDetails = {
        ccType: this.cardDetails.id,
        ccHolderFirstName: fieldsSource.first_name,
        ccHolderLastName: fieldsSource.last_name,
        ccNumber: this.conjoinCardNumber(this.fields.card_number),
        ccCvv: this.fields.cvv,
        ccExpiryMonth: this.getExpiryMonth(this.fields.expires),
        ccExpiryYear: `20${this.getExpiryYear(this.fields.expires)}`
      }

      await this.$store.dispatch('fetch3DSAndDeviceProfile', {
        billingAddress,
        creditCardDetails
      })
    },
    closetermsFailureModal() {
      this.failmodal.close()
      const { token, locale } = this.$route.params
      this.$router.push(`/${token}/${locale}`)
      this.$store.dispatch('init', this.$route.params)
    },
    fieldChange(change) {
      if (change.name === 'cvv' && this.fields.cvvMasked) {
        this.fields.cvv = null
        this.fields.cvvMasked = false
        return this.fields.cvv
      }
      if (
        change.name === 'card_number' &&
        this.fields.cardNumberMasked !== null
      ) {
        ;(this.fields.cardNumberMasked = null), (this.fields.card_number = null)
      }

      if (this.isChile) {
        if (change.name === 'state') {
          this.$store.dispatch('fetchDistricts', {
            country: this.selectedCountry,
            stateCode: change.value,
            languageCode: this.languageCode
          })
        }

        if (change.name === 'district') {
          /**
           * city is populated based on district (in Chile), so resetting
           */
          this.$store.dispatch('resetField', { field: 'city' })
        }
      }

      let self = this
      if (
        this.productInfo.transactionType !== 'AGR' &&
        change.name === 'country'
      ) {
        this.$v.$reset()
        this.$store.dispatch('defaultBillingfield')
        this.$store.dispatch('setCountrySelected', change.value)
        this.$store.dispatch('getCountrySetting', change.value).then(() => {
          if (!self.isCountryFields) {
            self.failmodal.open()
          }
          let isState = false

          self.countrySettings.forEach((item) => {
            for (var key in item) {
              if (item.hasOwnProperty(key)) {
                if (item[key] === 'address.state') {
                  isState = true
                }
              }
            }
          })
          if (!isState) {
            this.$store.dispatch('getStateFailure')
          }

          if (!self.iswebpayEligibleCountry) {
            this.$store.dispatch('getStateFailure')
          }

          if (self.isCountryFields && isState && self.iswebpayEligibleCountry) {
            this.$store.dispatch('getStateList')
          }
        })
      } else {
        this.setflag = false
        this.isDoublebyte = false
        if (this.selectedCountry === 'JPN' && change.name === 'postalcode') {
          change.value = change.value.replace('−', '-').replace('ー', '-')
        }
        // prevents error if event does not occur on a browser such as safari's autofill
        let eventType
        if (event) {
          eventType = event.type
          change.eventType = eventType
        }

        this.$store.dispatch('formFieldChange', change).then(() => {
          /**
           * reset error so that validation error isn't shown to the user before they could type anything
           * does not reset the error when user types in 'Other', only resets when user uses dropdown 'Other'
           */
          if (change.value === 'Other' && eventType === 'change') {
            this.$v.fields[change.name].$reset()
          }
        })
      }
    },
    validateField(data) {
      this.isDoublebyte = false
      this.setflag = false
      this.$v[data.name].$touch()

      // data.name is the input name that validateField is being called on
      if (
        data.name === 'card_number' &&
        this.$v.card_number.$error == false &&
        !this.isLoanAccount
      ) {
        if (
          !(
            this.productDetails.additionalAuth === undefined ||
            (this.productDetails.additionalAuth === null &&
              this.productDetails.parts.length > 0)
          )
        ) {
          return this.$store
            .dispatch('triggerCardtypecheck', {
              ccNumber: this.conjoinCardNumber(this.fields.card_number),
              transactionId: this.detailsInfo.id,
              transactionType: this.detailsInfo.type,
              country: this.country,
              token: this.$route.params.token
            })
            .then(() => {
              if (store.state.formStore.isDebitcard == 'true') {
                this.debitModal.open()
                this.isModalClose = false
              }
            })
        }
      }
    },
    validateDetailsField(data) {
      this.isDoublebyte = false
      this.setflag = false
      if (this.$v.fields[data.name]) {
        this.$v.fields[data.name].$touch()
        if (data.name === 'postalcode' && this.shouldZipCodeLookUp) {
          if (data.value === '') {
            return
          }
          if (this.selectedCountry === 'JPN') {
            let postCodeVal = ''

            if (this.fields.postalcode !== null) {
              postCodeVal = this.fields.postalcode
                .replace('−', '-')
                .replace('ー', '-')
            }
            if (
              postCodeVal.indexOf('-') === -1 &&
              this.fields.postalcode !== null
            ) {
              var postCodeVal1 = postCodeVal.slice(0, 3)
              var postCodeVal2 = postCodeVal.slice(3, 7)
              postCodeVal = `${postCodeVal1}-${postCodeVal2}`
            }

            if (
              postCodeVal.indexOf('-') !== -1 &&
              this.fields.postalcode !== null
            ) {
              if (this.fields.postalcode !== null) {
                this.$store
                  .dispatch('fetchStates', {
                    zipCode: postCodeVal,
                    country: this.selectedCountry,
                    field: data.name
                  })
                  .then(() => {
                    if (this.errorField !== 'postalcode') {
                      this.$store.dispatch('setZipCodeLookup')
                    }
                  })
              }
            }
          } else {
            this.$store
              .dispatch('fetchStates', {
                zipCode: data.value,
                country: this.selectedCountry,
                field: data.name
              })
              .then(() => {
                if (this.errorField !== 'postalcode') {
                  this.$store.dispatch('setZipCodeLookup')
                }
              })
          }
        }
      }
    },
    onKeyupfield(data) {
      if (isit.doubleByte(data) && this.setflag === false) {
        this.isDoublebyte = false
        this.setflag = true
      } else {
        this.isDoublebyte = true
        this.setflag = false
      }
      if (
        (this.$v.$invalid === false &&
          this.error === false &&
          this.detailsInfo.country_3 !== 'JPN') ||
        (this.$v.$invalid === false &&
          this.error === false &&
          this.detailsInfo.country_3 === 'JPN' &&
          this.isDoublebyte === true)
      ) {
        this.payment()
      }

      if (
        this.$v.$invalid === false &&
        this.error === false &&
        this.detailsInfo.country_3 === 'JPN'
      ) {
        this.isDoublebyte = true
      }
    }
  },
  onToggle() {
    if (this.showed == true) {
      this.showed = false
      document.getElementById('popover').setAttribute('aria-expanded', 'false')
    }
  },
  hidedisplay() {
    this.firstFlag = false
    this.showed = false
  },
  hidedisplayonesc(e) {
    if (e.keyCode == 27) {
      this.showed = false
      document.getElementById('popover').focus()
    }
  },
  showdisplay(e) {
    if (e.keyCode == 32 || e.keyCode == 13) {
      this.showed = !this.showed
    }
    if (e.keyCode == 27) {
      this.showed = false
    }
    if (this.showed == true) {
      document.getElementById('popover').setAttribute('aria-expanded', 'true')
      helperMethods.hideAria('#popover', true)
    } else {
      document.getElementById('popover').setAttribute('aria-expanded', 'false')
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'ac-sasskit/core';
@import 'ac-sasskit/base';
@import 'ac-forms/core';
@import 'ac-forms/base';
@import 'ac-forms/modules/form-icons';
@import 'ac-forms/modules/form-textbox';
@import '@/styles/variables.scss';
@import 'ac-forms/modules/form-tooltip';
@import 'ac-forms/modules/form-selector';

.checkbox__label {
  color: swatch(glyph-gray);
  @include typography(body);
  @include direction(
    padding-left,
    $app-margin-xxx-xs,
    padding-right,
    $app-margin-xxx-xs
  );
  margin-bottom: $app-margin-xx-xs;
}

.checkout {
  color: swatch(glyph-gray);
  text-align: left;
}

.payment__selection {
  @include direction(margin-left, 0px, margin-right, 0px);
  @include viewport(large) {
    border-bottom: 1px solid swatch(fill-gray-tertiary);
  }
  @include viewport(small) {
    border-bottom: none;
  }
}
h1:focus {
  outline: none;
}
.checkout__header-label {
  h1 {
    margin-block-start: 0;
    margin-block-end: 0;
    @include typography(eyebrow);
  }
  @include typography(eyebrow);
  padding-bottom: $app-margin-xxx-sm;
  border-bottom: 1px solid swatch(fill-gray-tertiary);
}

.checkout__header-price {
  padding-top: $app-padding-x-sm;
  @include typography(body-reduced);
  color: black;
  float: right;
  text-align: right;
  border-bottom: 1px solid swatch(fill-gray-tertiary);
}
.checkout__header-price:lang(ar-AE) {
  padding-top: $app-padding-x-sm;
  @include typography(body-reduced);
  color: black;
  float: right;
  text-align: left;
}

.checkout__header-text {
  color: black;
}

.checkout__header-howtopay,
h2 {
  margin-top: $app-margin-lg;
  margin-bottom: $app-margin-lg;
  @include typography(headline-reduced);
  @include viewport(medium) {
    @include typography(eyebrow-super);
  }
  color: swatch(glyph-gray);
}
h2:focus {
  outline: none;
}
.checkout_button {
  padding-top: $app-margin-xx-xs;
  padding-bottom: $app-margin-xx-xs;
}
.form-label-small {
  margin-top: 4px;
  letter-spacing: unset;
  @include typography(body);
  @include viewport(medium) {
    @include typography(caption);
  }
  @include viewport(small) {
    @include typography(caption);
  }
}
.checkout_button--creditline1 {
  @include typography(body);
  font-weight: 600;
  color: swatch(glyph-gray);
}
.checkout_button--creditline2 {
  @include typography(body-reduced);
  color: swatch(glyph-gray);
}

.card {
  border: none;
}
.card-body {
  margin-bottom: -50px;
  padding: 0;
  @include viewport(small) {
    border-bottom: none;
    margin-bottom: -50px !important;
  }
  @include viewport(large) {
    margin-bottom: -50px;
  }
  @include viewport(medium) {
    margin-bottom: -40px;
  }
}

.creditcard__section-details {
  margin-top: $app-margin-x-sm;
  margin-bottom: $app-margin-lg;
}

.creditcard__section-label {
  @include typography(body);
  color: swatch(glyph-gray);
  margin-bottom: $app-margin-xx-xs;
}
.creditcard__section--cardinfo {
  padding-bottom: $app-margin-xx-xs;
  border-bottom: 1px solid swatch(fill-gray-tertiary);
}

.creditcard__section--field {
  padding-bottom: 0;
}

.creditcard__section--expire {
  @include viewport(small) {
    padding-bottom: 0;
  }
}
.creditcard__section--expireARE {
  @include viewport(small) {
    padding-bottom: $app-margin-xx-xs;
  }
  padding-left: 10px !important;
}

.creditcard__section--email {
  @include viewport(small) {
    padding-bottom: 0;
  }
}
.creditcard__section--emailARE {
  @include viewport(small) {
    padding-bottom: $app-margin-xx-xs;
  }
  padding-left: 10px !important;
}
.creditcard__section--cvvARE {
  @include viewport(small) {
    padding-left: 0px;
  }
  @include viewport(medium) {
    margin-right: $app-padding-x-sm;
  }
  @include viewport(large) {
    padding-right: $app-margin-xx-xs;
  }
}
.creditcard__section-addressheading {
  display: block;
  margin: $app-margin-xxxx-sm 0;
  @include typography(body);
  font-weight: 600;
  color: swatch(glyph-gray);
}

.form-selector-group {
  margin-bottom: 36px;
  margin-top: 0px;
  @include viewport(small) {
    margin-bottom: $app-padding-xxxx-lg;
  }
}
.form-selector .form-selector-label {
  margin-bottom: $app-margin-xx-xs;
}

.form-selector-label .form-selector-left-col {
  @include direction(text-align, left, text-align, right);
  @include direction(padding-left, 0, padding-right, 0);
  position: relative;
  align-self: center;
}

.form-selector-label {
  min-height: 4.88235rem;
  @include viewport(medium) {
    min-height: 4.77rem;
  }
}
.form-selector {
  @include direction(padding-right, 0px, padding-left, 0px);
}
.form-selector-input {
  padding: $app-padding-xxx-sm;
}
.form-tooltip-content {
  width: auto;
  height: auto;
}
.continue__review {
  padding-top: $app-margin-lg;
  @include viewport(small) {
    padding-top: 0px;
  }

  @include direction(padding-right, 10px, padding-left, 0px);
  @include direction(text-align, right, text-align, left);
}
.row {
  padding-left: 0px;
  padding-right: 0px;
}
// #debit_Modal {
//   display: none;
// }

.debitModal-close__icons {
  width: 10px;
  height: 10px;
  margin-bottom: 3px;
}
.debitModal__heading {
  text-align: center;
  @include typography(headline-reduced);
  padding-bottom: $app-padding-sm;
  @include viewport(small) {
    padding-bottom: $app-margin-xxxx-sm;
  }
}
.debitModal__content {
  @include direction(text-align, center, text-align, center);
  @include typography(body-tight);
  padding-top: $app-padding-zero;
  margin-top: 0 !important;
}

.continue__review .button {
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
}

.debitModal__action {
  margin-top: $app-margin-xxxx-sm;
  text-align: -webkit-center;

  .button {
    display: block;
    margin-top: $app-margin-x-xss;
    @include viewport(small) {
      width: 70%;
    }
  }
  .button--link {
    margin-left: $app-margin-x-xss;
    margin-right: $app-margin-x-xss;
  }
}

.termsFailureModal__heading {
  text-align: center;
  @include typography(headline-reduced);
  padding-bottom: $app-padding-xxxx-sm;
  @include viewport(small) {
    padding-bottom: $app-margin-xxxx-sm;
  }
}
.termsFailureModal__content {
  @include direction(text-align, center, text-align, center);
  @include typography(body-tight);
  padding-top: $app-padding-zero;
  margin-top: 0 !important;
  p {
    margin: 0px;
  }
}

.termsFailureModal__action {
  margin-top: $app-margin-xxxx-sm;
  text-align: -webkit-center;
  .button {
    display: block;
    margin-top: $app-margin-xxxx-xs;
    @include viewport(small) {
      width: 70%;
    }
  }
}
.form-textbox-righticon {
  @include direction(text-align, right, text-align, left);
}
.displayNone,
.modal-close {
  display: none;
  @include direction(left, 24px, right, 24px);
}
.column {
  @include direction(padding-right, 0px, padding-left, 0px);
}

img {
  width: 70px;
  height: 28px;
  display: block;
}
.creditcard__section--cvv {
  @include viewport(small) {
    padding-left: 0px;
  }
  @media only screen and (min-width: 750px) {
    padding-left: $app-padding-x-sm;
  }
  @include viewport(large) {
    padding-left: $app-margin-xx-xs;
  }
}
.button-spinner {
  top: rem(10);
  position: relative;
  text-align: -webkit-center;
}

#loading-text {
  display: none;
}

.debit_info {
  position: absolute;
  top: $app-margin-bottom-mmd;
  right: $app-right-mg;
  @include typography(caption);
  @include viewport(medium) {
    position: absolute;
    top: $app-margin-bottom-mmd;
    right: $app-right-mg;
    @include typography(caption);
  }
  @include viewport(small) {
    position: relative;
    top: $app-top-xxxx-xs;
    padding-top: $app-margin-xx-md;
    right: 0px;
    @include typography(caption);
  }
}

.error-message {
  margin: $app-margin-xx-xds 0;
  border-color: rgba(227, 0, 0, 0.4);
  background: #fff2f4;
  border-radius: 5px;
  border: 1px solid swatch(glyph-red);
}
.error-message-form {
  padding-left: $app-padding-xx-sm;
  vertical-align: middle;
}
.error-message__text {
  display: flex;
  color: #e30000;
  font-weight: $app-font-weight-lg;
  font-size: 0.9375em;
  padding: $app-margin-xxx-sm $app-padding-x-sm $app-padding-xx-m
    $app-padding-xx-m;
  img {
    width: 14px;
    height: 20px;
  }
}
.confirmModal__action {
  margin-top: $app-margin-xxxx-sm;
  text-align: -webkit-center;
  .button {
    display: block;
    margin-top: $app-margin-x-xss;
    @include viewport(small) {
      width: 70%;
    }
  }
}
.image-shoppepay {
  width: auto;
  height: 55px;
  display: block;
}
.image-atmpay {
  width: auto;
  height: 55px;
  display: block;
}
.image-momo {
  width: auto;
  height: 45px;
  display: block;
}
.image-tng {
  width: auto;
  height: 45px;
  display: block;
}
.image-banktransfer {
  width: auto;
  height: 45px;
  display: block;
}
.payment__form-displayAddressinfo {
  span {
    display: block;
    @include typography(body);
    padding-bottom: 0;
  }
}
.address-title__info {
  @include typography(body-tight);
}

.checkbox-container {
  display: flex;
  align-items: baseline;
}

.hr {
  border-bottom: 1px solid #d2d2d7;
  margin: 28px 0;
}

.new-section {
  margin: 28px 0;
}
</style>
