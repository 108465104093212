<template>
  <div>
    <div
      :class="
        hasQuestionMarkIcon
          ? 'input form-tooltip-textbox-wrapper form-textbox form-icons-focusable pattern-tooltip-after-textbox'
          : 'input form-tooltip-textbox-wrapper form-textbox'
      "
    >
      <div v-if="label && !hasQuestionMarkIcon" class="input-label">
        <label
          class="input-label__text"
          :id="`${name}_mainlabel`"
          :for="name"
          v-html="label"
        ></label>
      </div>

      <div class="column large-12 small-12" :class="classInputWrapper">
        <select
          class="form-dropdown-select form-dropdown-selectnone"
          autocomplete="nofill"
          :name="name"
          :required="required"
          v-if="name === 'controlKey'"
          :placeholder="`${confirmation} ${
            locale[`pp.payment.refund.${textKey}`]
          }`"
          :type="type"
          :aria-labelledby="getLabelId"
          v-model="enteredVal"
          v-on:blur="onBlur"
        >
          <option disabled="" selected="">
            {{ locale['pp.payment.refund.bank.please.select'] }}
          </option>

          <option
            id="form-textbox-dropdown"
            v-for="item in [
              {
                value: '01',
                text: locale['pp.payment.refund.bank.saving.account']
              },
              {
                value: '02',
                text: locale['pp.payment.refund.bank.checking.account']
              }
            ]"
            :key="item.text"
            :value="item.value"
          >
            {{ item.text }}
          </option>
        </select>
        <span
          v-if="name === 'controlKey'"
          class="form-dropdown-chevron"
          aria-hidden="true"
        >
          <span class="a11y">{{
            locale['cryptozen.dropdown.dropdownButton']
          }}</span>
        </span>
        <input
          v-if="name !== 'controlKey'"
          class="form-textbox-input"
          autocomplete="nofill"
          :name="name"
          :required="required"
          :type="type"
          :aria-labelledby="`${name}_label` + confirmation"
          :aria-invalid="isErrorDisplayed ? 'true' : 'false'"
          :aria-describedby="
            error || errorMatched ? `${name}_error` + confirmation : null
          "
          v-mask="mask"
          v-model="value"
          v-on:blur="onBlur"
          @keyup="resetObfuscationOfInput"
        />
        <span
          class="column large-12 small-12 form-textbox-label"
          :class="{
            'form-textbox-label-filled': value,
            'form-textbox-label-filled-invaild': value && error,
            'form-textbox-label-empty': !value
          }"
          :id="`${name}_label` + confirmation"
          aria-hidden="true"
          >{{
            `${confirmation} ${locale[`pp.payment.refund.${textKey}`]}`
          }}</span
        >

        <div class="form-textbox-righticon" role="alert">
          <template v-if="isLoading">
            <div class="form-loading-icon">
              <vn-loading-spinner :isLoading="true"></vn-loading-spinner>
            </div>
          </template>
        </div>

        <div aria-live="polite" :id="`${name}_error` + confirmation">
          <div v-if="isErrorDisplayed" class="error-container row">
            <div class="form-message-wrapper">
              <span class="form-message">
                {{ errorMessage }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="hasQuestionMarkIcon"
        class="form-tooltip form-tooltip-after"
        :class="{ 'form-tooltip-show': isTooltipDisplayed }"
      >
        <button
          v-on:keydown.prevent.enter="toggleTooltip"
          v-on:keyup.prevent.space="toggleTooltip"
          v-on:focus="showTooltip"
          v-on:keyup.esc="hideTooltip"
          v-on:blur="hideTooltip"
          v-on:click="showTooltip"
          v-click-outside="hideTooltip"
          class="form-tooltip-button"
          :aria-label="`${locale[`pp.tooltip.info`]}${
            locale[`pp.payment.refund.${textKey}`]
          }`"
          :aria-controls="`${this.textKey}_tooltip`"
          :aria-expanded="isTooltipDisplayed ? 'true' : 'false'"
        >
          <span class="form-icons form-icons-info19" aria-hidden="true"> </span>
        </button>
        <div
          :id="`${this.textKey}_tooltip`"
          :class="isThisRtl"
          :aria-label="`${locale[`pp.tooltip.info`]}${
            locale[`pp.payment.refund.${textKey}`]
          }`"
          class="form-tooltip-info form-tooltip-pointer-left"
          role="tooltip" 
        >
          <div class="form-tooltip-content">
            <p>
              {{ locale[`acrt.tooltip.${textKey}`] }}
            </p>
            <img
              v-if="textKey === 'bank.bank_key'"
              :src="require('@/assets/routingNumber_2x.png')"
              alt=""
              id="tooltip-pic"
            />
            <img
              v-if="textKey === 'bank.bank_account'"
              :src="require('@/assets/accountNumber_2x.png')"
              alt=""
              id="tooltip-pic"
            />
          </div>

          <p
            v-if="textKey === 'bank.account_holder_name'"
            class="form-tooltip-content-account"
            v-html="
              locale[`pp.payment.refund.tooltip.bank.account_holder_name`]
            "
          />
          <p
            v-else-if="textKey === 'bank.bank_branch'"
            class="form-tooltip-content"
          >
            {{ locale[`pp.payment.refund.tooltip.${textKey}`] }}
          </p>
          <p v-else class="form-tooltip-content">
            {{ locale[`pp.payment.refund.${textKey}`] }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CheckMarkIcon from '@/images/CheckMarkIcon.vue'
import ErrorCircleIcon from '@/images/ErrorCircleIcon.vue'
import { polishInput } from '@/utilities/utils'
import { mapState } from 'vuex'

export default {
  name: 'app-input-base-bank-field',
  description: 'Refund bank input',
  components: {
    ErrorCircleIcon,
    CheckMarkIcon
  },
  data() {
    return {
      maskValue: '',
      enteredVal: '',
      isTooShort: false,
      isDuplicate: false,
      isFirstInputOfDuplicate: false,
      isTooltipDisplayed: false,
      userTabbedIntoInput: false,
      errorMessage: ''
    }
  },
  props: {
    label: {
      type: String,
      default: null
    },
    hasQuestionMarkIcon: {
      type: Boolean,
      default: false
    },
    textKey: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: null
    },
    fieldConstraints: {
      type: Array,
      default: ''
    },
    minLength: {
      type: Number,
      default: ''
    },
    maxLength: {
      type: Number,
      default: ''
    },
    vMaskPlaceholder: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    error: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    hasRightIcon: {
      type: Boolean,
      default: false
    },
    confirmationRequired: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    firstInput: {
      type: Boolean,
      default: false
    },
    secondInput: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    enteredVal: function (newVal) {
      this.$emit('onChange', {
        name: this.name,
        value: newVal,
        first: this.firstInput,
        second: this.secondInput,
        confirmationRequired: this.confirmationRequired
      })
    }
  },
  mounted() {
    if (
      this.bankClientInfoConfirmationRequiredList[this.name] &&
      this.bankClientInfoConfirmationRequiredList[this.name].inputObfuscated
        .length > 1
    ) {
      this.enteredVal =
        this.bankClientInfoConfirmationRequiredList[this.name].inputObfuscated
    } else this.enteredVal = this.bankClientInfo[this.name]
  },
  computed: {
    ...mapState({
      locale: (state) => state.mainStore.localization,
      errorField: (state) => state.refundStore.errorDetails.errorField,
      bankClientInfoConfirmationRequiredList: (state) =>
        state.refundStore.bankClientInfoConfirmationRequiredList,
      bankClientInfo: (state) => state.refundStore.bankClientInfo,
      errorFieldsArray: (state) => state.refundStore.errorFieldsArray,
      isRtl: (state) => state.mainStore.isRtl
    }),
    classInputWrapper: function () {
      return {
        'is-error': this.isErrorDisplayed
        // 'form-icons-focusable form-textbox form-textbox-with-righticon': !this
        //   .hasQuestionMarkIcon
      }
    },
    isThisRtl() {
      if (this.isRtl) {
        return 'question-mark-tooltip-button-rtl'
      }
      return `${this.textKey}_tooltip`
    },

    mask() {
      if (
        this.bankClientInfoConfirmationRequiredList[this.name] &&
        this.bankClientInfoConfirmationRequiredList[this.name].inputObfuscated
          .length > 1
      ) {
        this.maskValue = 'X'.repeat(this.maxLength)
        this.maxLength =
          this.bankClientInfoConfirmationRequiredList[this.name].inputObfuscated
            .length * 3
        return this.maskValue
      }
      if (this.vMaskPlaceholder !== null) {
        this.maskValue = Array(this.maxLength).fill(this.vMaskPlaceholder)
        return this.maskValue
      }
    },
    confirmation() {
      if (this.secondInput) {
        return this.locale[`pp.payment.refund.account.confirm`]
      } else return ''
    },

    value: {
      get() {
        return this.enteredVal
      },
      set(data) {
        this.enteredVal = ''
        let polishedinput = () => {
          return polishInput(
            data,
            this.maxLength,
            this.maxLength,
            null,
            this.name
          )
        }
        let newfield = polishedinput()
        this.enteredVal = data && newfield
        this.$emit('onChange', {
          name: this.name,
          value: this.enteredVal,
          first: this.firstInput,
          second: this.secondInput,
          confirmationRequired: this.confirmationRequired
        })
      }
    },
    describedbyValue() {
      if (this.error) {
        return `${this.name}_error`
      } else if (this.hasRightIcon && this.isInputValid) {
        return `${this.name}_positive_validation`
      } else {
        return null
      }
    },
    isErrorDisplayed() {
      this.isTooShort = false
      if (this.userTabbedIntoInput === false && this.secondInput) {
        return false
      }
      if (this.errorFieldsArray.includes(this.name)) {
        if (
          this.firstInput &&
          this.confirmationRequired &&
          this.bankClientInfoConfirmationRequiredList[this.name].firstInputOfCR
            .length < this.minLength
        ) {
          return true
        }
        if (
          this.firstInput &&
          this.confirmationRequired &&
          this.bankClientInfoConfirmationRequiredList[this.name].firstInputOfCR
            .length >= this.minLength
        ) {
          return false
        }
        if (
          this.secondInput &&
          this.bankClientInfoConfirmationRequiredList[this.name]
            .secondInputOfCR === ''
        ) {
          this.isTooShort = true
          return true
        }

        if (
          this.secondInput &&
          this.bankClientInfoConfirmationRequiredList[this.name].secondInputOfCR
            .length < this.minLength
        ) {
          this.isTooShort = true
          return true
        }
        if (this.secondInput) {
          return true
        }
        return true
      }
    },
    getLabelId() {
      if (this.label && this.placeholder && this.label !== this.placeholder) {
        return `${this.name}_mainlabel ${this.name}_label`
      } else if (this.label) {
        return `${this.name}_mainlabel`
      } else if (this.name == 'card_number') {
        return `${this.name}_label + debitCardMessage`
      } else {
        return `${this.name}_label`
      }
    },
    errorMatched() {
      if (this.errorFieldsArray.includes(this.name)) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    toggleTooltip() {
      this.isTooltipDisplayed = !this.isTooltipDisplayed
    },
    errorMsg() {
      if (this.name == 'bankAccount') {
        if (this.secondInput && !this.isTooShort) {
          this.errorMessage = this.locale['pp.payment.error.actnum.mismatch']
        } else {
          this.errorMessage =
            this.locale['pp.payment.error.highlight.default'] +
            ' ' +
            this.locale[`pp.payment.refund.${this.textKey}`]
        }
      } else if (this.name == 'iban') {
        if (this.secondInput && !this.isTooShort) {
          this.errorMessage = this.locale['pp.payment.error.iban.mismatch']
        } else {
          this.errorMessage =
            this.locale['pp.payment.error.highlight.default'] +
            ' ' +
            this.locale[`pp.payment.refund.${this.textKey}`]
        }
      } else {
        this.errorMessage =
          this.locale['pp.payment.error.highlight.default'] +
          ' ' +
          this.locale[`pp.payment.refund.${this.textKey}`]
      }
    },

    onBlur(data) {
      this.userTabbedIntoInput = true
      this.$store
        .dispatch('onBlurCheck', {
          valueLength: data.target._value.length,
          minLength: this.minLength,
          name: this.name,
          confirmationRequired: this.confirmationRequired,
          isFirstInputAndCR: this.isFirstInputOfDuplicate
        })
        .then(() => {
          this.errorMsg()
        })
    },
    hideTooltip() {
      this.isTooltipDisplayed = false
    },

    showTooltip() {
      this.isTooltipDisplayed = true
    },
    resetObfuscationOfInput() {
      if (
        this.bankClientInfoConfirmationRequiredList[this.name] &&
        this.bankClientInfoConfirmationRequiredList[this.name].inputObfuscated
          .length > 1
      ) {
        this.$store.dispatch('resetObfuscation', this.name)
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import 'ac-sasskit/core';
@import 'ac-sasskit/base';
@import 'ac-forms/core';
@import 'ac-forms/base';
@import 'ac-forms/modules/form-icons';
@import 'ac-forms/modules/form-dropdown';
@import 'ac-forms/modules/form-textbox';
@import '@/styles/variables.scss';
@import 'ac-forms/modules/form-tooltip';
@import 'ac-forms/modules/form-textbox';
.input {
  width: 100%;
  margin: 0 auto;
  .input-label {
    margin-top: $app-margin-reduced-reg;
    margin-bottom: $app-margin-xs;
    text-align: left;
  }
  .input-label__text {
    @include input-label-text;
    max-width: 100%;
    font-weight: $app-font-weight-x-lg;
  }
  @include viewport(small) {
    max-width: 85%;
  }
}

.form-selector-label .form-selector-left-col {
  @include direction(text-align, left, text-align, right);
}

.form-textbox-input:valid[required] ~ .form-textbox-righticon .icon-checkmark {
  display: block;
}
.form-textbox-with-righticon .form-textbox-righticon {
  top: rem(13);
  .icon-checkmark {
    display: none;
  }
}
.form-textbox-input {
  font-size: $app-font-size-reg;
}
.form-textbox-label {
  @include direction(left, 1rem, right, 0);
  color: swatch(glyph-gray-secondary) !important;
}
.trigger-input-disabled {
  .form-textbox-label {
    font-size: 1.0625rem;
    line-height: 1.33337;
    font-weight: 400;
    letter-spacing: -0.01em;
    top: 0.58824rem;
  }
}
.form-textbox-input {
  min-height: 3.5rem;
  margin-bottom: 14px;
  &:focus {
    outline: 0;
    outline-offset: 1px;
    border-color: swatch(fill-gray-tertiary);
    box-shadow: none;
  }
}
.form-textbox-righticon {
  top: 12px !important;
}
.is-error .form-textbox-input {
  margin-bottom: 0 !important;
}

.creditcard-section-addressheading {
  @include direction(margin-left, 0px, margin-right, 0px);
}
.form-tooltip-after {
  &:focus {
    outline: 4px solid red !important;
    outline-offset: 1px;
    box-shadow: none;
  }
}
.form-textbox {
  margin-bottom: 0;
  text-align: left;
}
.form-dropdown-chevron {
  top: 15px;
  bottom: 30px;
}

.form-textbox-label-filled {
  position: absolute;
  pointer-events: none;
  max-width: calc(100% - 1.5rem);
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
  -webkit-transition-duration: 0.125s;
  transition-duration: 0.125s;
  left: 1rem;
  white-space: nowrap;
  overflow: unset;
  font-size: rem(12) !important;
  line-height: 1.33337 !important;
  font-weight: 400 !important;
  letter-spacing: -0.01em !important;
  font-family: $default-family;
  top: 0.58824rem !important;
}
.form-tooltip-info::after {
  position: relative;
  right: 5px;
  content: '';
  width: 0.88235rem;
  height: 0.88235rem;
  border-bottom-right-radius: 2px;
  transform: rotate(45deg);
  bottom: -0.23529rem;
}
.form-textbox-label-empty {
  position: absolute;
  pointer-events: none;
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
  -webkit-transition-duration: 0.125s;
  transition-duration: 0.125s;
  top: 1.05882rem;
  white-space: nowrap;
  overflow: hidden;
  max-width: calc(100% - 1.5rem);
  left: 1rem;
  right: 0.5rem;
  bottom: 1rem;
  font-size: rem(17);
  line-height: 1.23536;
  font-weight: 400;
  letter-spacing: -0.022em;
  font-family: $default-family;
}
.form-textbox-label-filled-invaild {
  color: swatch(glyph-red);
}

.form-tooltip-content {
  width: auto;
  margin-top: 0px !important;
  @include typography(body-reduced);

  p {
    width: 200px;
    @include typography(body-reduced);
    @include font-weight(semibold);
    margin-bottom: 0px;
  }
}
.form-tooltip-content-account {
  @include typography(caption);
  width: 300px;
}
.form-loading-icon {
  top: rem(10);
  right: rem(10);
  position: relative;
}
.form-textbox-input::-webkit-autofill,
.form-textbox-input::-webkit-contacts-auto-fill-button,
.form-textbox-input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
}
.form-message {
  margin-left: $app-padding-x-xs;
}
.form-message-wrapper {
  margin-top: 8px;
  margin-bottom: 12px;
  padding-left: 0px;
}
.input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='url'],
select,
textarea {
  color: swatch(glyph-gray-secondary-alt);
  border: 1px solid;
  border-radius: rem(12);
  border-color: swatch(fill-gray-tertiary);
  box-shadow: none;
  box-sizing: border-box;
  padding-left: $app-padding-elevated-sm;
  padding-top: $app-padding-md;
  direction: ltr;
}

.form-textbox-righticon {
  top: 15px !important;
  @include direction(right, 14px, left, inherit);
}
.form-icons {
  .form-textbox .is-error {
    padding-bottom: 0px;
  }
  img {
    width: 40px;
    height: 25px;
  }
  .card-type--visa {
    background-image: url(../images/visa.png);
  }

  &.card-type--mastercard {
    background-image: url(../images/mastercard.png);
  }

  &.card-type--amex {
    background-image: url(../images/amex.png);
  }

  &.card-type--discover {
    background-image: url(../images/discover.png);
  }

  &.card-type--maestro {
    background-image: url(../images/maestro.png);
  }
  &.card-type--mnp {
    background-image: url(../images/mnp.png);
  }
  &.card-type--rupay {
    background-image: url(../images/rupay.png);
  }
  &.card-type--jcb {
    background-image: url(../images/jcb.png);
  }
}
@media only screen and (max-width: 734px) {
  .input {
    max-width: 100% !important ;
  }
}

.light-theme-label {
  opacity: 0.4;
}
.form-icons-default {
  pointer-events: none;
  transition: 0.5s ease;
  transition-property: opacity, transform;
  img {
    width: 40px;
    height: 25px;
  }
  @include viewport(small) {
    display: none;
  }
  @include viewport(medium) {
    display: none;
  }
}
.form-tooltip-title {
  @include typography(body-reduced);
  @include font-weight(semibold);
  padding-top: 14px;
  padding-bottom: 6px;
  margin-bottom: 0px !important;
}
.form-tooltip-button {
  margin-top: 20px;
  margin-left: 8px;
  display: block;
  &:focus,
  &.form-tooltip-show {
    .form-tooltip-info {
      display: block;
    }
  }
}
#tooltip-pic {
  width: 150px;
  height: 80px;
}
.form-tooltip-info {
  width: auto;
  padding: 16px;
  bottom: 25px;
  right: 0px;
  padding-right: 30px;
  img {
    width: auto;
    height: auto;
  }
}

.form-dropdown-select {
  margin: 0;
  margin-bottom: 14px;
}

.question-mark-tooltip-button-rtl {
  margin-right: -300px !important;
}
</style>
