<template>
  <component :is="initialComponent" />
</template>

<script>
import PaymentProduct from '@/features/payment/PaymentProduct'
import AccountDetail from '@/features/payment/AccountDetail'
import AccountRefund from '@/features/payment/AccountRefund'
import store from '../store/mainStore'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      accountType: (state) => state.mainStore.details.accountType,
      refundType: (state) => state.mainStore.details.refundType
    }),
    initialComponent() {
      if (
        this.accountType === 'SWT' ||
        this.accountType === 'MCF'
      ) {
        return AccountDetail
      } else if (this.refundType !== undefined) {
        return AccountRefund
      } else {
        return PaymentProduct
      }
    }
  }
}
</script>
