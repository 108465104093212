import axios from 'axios'
import { apiHost } from './endPointConfig'
const axiosInterceptor = axios.create({
  baseURL: apiHost
})
var token = null
var locale = null

// Add a request interceptor
axiosInterceptor.interceptors.request.use(
  function (config) {
    if (!token) {
      token = localStorage.getItem('token')
      locale = localStorage.getItem('locale')
    }

    if (
      (config.method === 'post' && token) ||
      (config.method === 'put' && token) ||
      (config.method === 'delete' && token) ||
      (config.method === 'get' && token)
    ) {
      config.headers['X-GWS-LANG-CODE'] = locale
      config.headers['X-GWS-MSG-SEQ-NUM'] = token
    }
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// // Add a response interceptor
// axiosInterceptor.interceptors.response.use(
//   response => {
//     // Do something with response data
//     debugger
//     // if (response.headers['x-apple-csrf-token']) {
//     //   localStorage.setItem('token', response.headers['x-apple-csrf-token'])
//     // }
//     return response
//   },
//   error => {
//     // If an error occurs, make the init call to refetch CSRF token since we are not able to set the csrf token got in the error reponse
//     // if (
//     //   error.response &&
//     //   (error.response.status === 500 || error.response.status === 403)
//     // ) {
//     //   axiosInterceptor.get(`${apiHost}/init`)
//     // }
//     // // axiosInterceptor.get(`test`)

//     // // Do something with response error
//     // if (error.response.headers['x-apple-csrf-token']) {
//     //   localStorage.setItem(
//     //     'token',
//     //     error.response.headers['x-apple-csrf-token']
//     //   )
//     // }
//     return Promise.reject(error)
//   }
// )

export const indiaPayment = axios.create()

export default axiosInterceptor
