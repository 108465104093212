<template>
  <div>
    <div>
      <div class="title_heading">
        <h1>{{ locale['pp.message.notfound'] }}</h1>
      </div>
      <p
        class="title_heading-description"
        v-html="locale['pp.message.action.notfound']"
      />
    </div>
    <div class="contact-button">
      <app-button @onClick="goToSite">
        {{ locale['pp.message.viewcase.button'] }}
      </app-button>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import AppButton from '@/components/app-button'
import { focusOnSelector } from '@/utilities/utils'

export default {
  components: {
    AppButton
  },
  title() {
    return `${this.locale['pp.token.notfound.pagetitle']}`
  },
  computed: {
    ...mapState({
      locale: (state) => state.mainStore.localization
    })
  },
  mounted() {
    focusOnSelector('h1')
  },
  beforeRouteLeave(to, from, next) {},
  methods: {
    goToSite() {
      window.open('https://getsupport.apple.com/', '_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/custom.scss';
@import 'ac-sasskit/core';
@import 'ac-sasskit/base';
@import '@/styles/variables.scss';

.title_heading,
h1 {
  text-align: center;
  @include typography(headline);
  padding-top: $app-margin-top-lg;
  color: swatch(glyph-gray);
  &:focus {
    outline: 0;
  }
}

.title_heading-description {
  text-align: center;
  @include typography(intro);
  color: swatch(glyph-gray);
  margin-top: 0px !important;
  padding-top: $app-margin-xx-xds;
  // font-weight: $app-font-weight-xr-lg;
  &:focus {
    outline: 0;
  }
}

.contact-button {
  padding-top: $app-padding-lg;
  text-align: center;
}
</style>
