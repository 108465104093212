<template>
  <app-global-loader v-if="isLocaleFetching" />
  <div v-else>
    <div>
      <div class="title_heading">
        <h1>{{ locale['pp.summary.error.appleid.mismatch'] }}</h1>
      </div>
      <p
        class="column large-10 large-centered small-uncentered title_heading-description"
      >
        {{ locale['pp.summary.error.idmismatch.message'] }}
      </p>
    </div>
    <div class="column large-7 large-centered small-uncentered contact-button">
      <div class="anotherId-button">
        <app-button @onClick="redirectToLogin">
          <span dir="ltr">
            {{ locale['pp.summary.error.another.appleid'] }}
          </span>
        </app-button>
      </div>
      <div class="contact-gray-button">
        <app-button @onClick="getSupport">
          <span dir="ltr">
            {{ locale['pp.message.contact.button'] }}
          </span>
        </app-button>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from '@/components/app-button'
import AppGlobalLoader from '@/components/app-global-loader'
import { focusOnSelector } from '@/utilities/utils'
import { mapState } from 'vuex'

export default {
  components: {
    AppButton,
    AppGlobalLoader
  },
  title() {
    return `${this.locale['pp.error.appleid.mismatch.pagetitle']}`
  },
  data() {
    return {
      isLocaleFetching: true
    }
  },

  computed: {
    ...mapState({
      locale: (state) => state.mainStore.localization
    })
  },

  async created() {
    await this.$store
      .dispatch('getLocales', {
        locale: this.getLocale()
      })
      .then(() => {
        document.title = this.locale['pp.error.appleid.mismatch.pagetitle']
      })
    this.isLocaleFetching = false
  },

  mounted() {
    focusOnSelector('h1')
  },

  methods: {
    getSupport() {
      window.open('https://getsupport.apple.com/', '_blank')
    },

    redirectToLogin() {
      const token = localStorage.getItem('token')
      const locale = localStorage.getItem('locale')
      window.location.href = `/${token}/${locale}/authenticate`
    },

    getLocale() {
      if (this.$route.params.locale) {
        return this.$route.params.locale
      }

      if (localStorage.getItem('locale')) {
        return localStorage.getItem('locale')
      }

      // Fallback locale
      return 'en_US'
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/custom.scss';
@import 'ac-sasskit/core';
@import 'ac-sasskit/base';
@import '@/styles/variables.scss';

.title_heading,
h1 {
  text-align: center;
  @include typography(headline);
  padding-top: $app-margin-top-lg;
  color: swatch(glyph-gray);
  &:focus {
    outline: 0;
  }
}

.title_heading-description {
  text-align: center;
  @include typography(intro);
  color: swatch(glyph-gray);
  margin-top: 0px !important;
  padding-top: $app-margin-xx-xds;
  // font-weight: $app-font-weight-xr-lg;
  &:focus {
    outline: 0;
  }
}

.contact-button {
  padding-top: $app-padding-lg;
  text-align: center;
}
.button {
  width: 25em;
}
.anotherId-button {
  padding-bottom: 20px;
}
.contact-gray-button {
  .button {
    background-color: #e8e8ed;
    color: #000;
  }
}
</style>
