<template>
  <div>
    <div>
      <div class="title_heading">
        <h1>{{ locale['pp.message.expired'] }}</h1>
      </div>
      <p class="title_heading-description">
        {{ locale['pp.message.action.expired'] }}
      </p>
    </div>
    <div class="contact-button">
      <app-button @onClick="goToSite">
        <span dir="ltr">
          {{ locale['pp.message.contact.button'] }}
        </span>
      </app-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AppButton from '@/components/app-button'
import store from '../../store'
import router from '../../router'
import { focusOnSelector } from '@/utilities/utils'

export default {
  components: {
    AppButton
  },
  computed: {
    ...mapState({
      locale: (state) => state.mainStore.localization
    })
  },
  mounted() {
    focusOnSelector('h1')
  },
  title() {
    return `${this.locale['pp.expired.pagetitle']}`
  },
  beforeRouteEnter(to, from, next) {
    const status = store.state.mainStore.details.transactionStatus
    const currentPath = `/${router.currentRoute.params.token}/${router.currentRoute.params.locale}`
    if (
      (from.name === 'Payment' ||
        from.name === 'PaymentWizardTerms' ||
        from.name === 'PaymentWizardBilling') &&
      to.name == 'Expired' &&
      status === 'PEND'
    ) {
      router.push(currentPath)
    } else {
      next()
    }
    if (from.path === '/') {
      let s = window.location.href
      let testpath = s.replace('expired', '').split('/')
      let path = `/${testpath[4]}/${testpath[5]}/`
      router.push(path)
    }
  },
  beforeRouteLeave(to, from, next) {},
  methods: {
    goToSite() {
      window.open('https://getsupport.apple.com/', '_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/custom.scss';
@import 'ac-sasskit/core';
@import 'ac-sasskit/base';
@import '@/styles/variables.scss';

.title_heading,
h1 {
  text-align: center;
  @include typography(headline);
  padding-top: $app-margin-top-lg;
  color: swatch(glyph-gray);
  &:focus {
    outline: 0;
  }
}

.title_heading-description {
  text-align: center;
  @include typography(intro);
  color: swatch(glyph-gray);
  margin-top: 0px !important;
  padding-top: $app-margin-xx-xds;
  // font-weight: $app-font-weight-xr-lg;
  &:focus {
    outline: 0;
  }
}

.contact-button {
  padding-top: $app-padding-lg;
  text-align: center;
}
</style>
