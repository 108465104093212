<template>
  <div>
    <div
      :class="
        isCVV
          ? 'input form-tooltip-textbox-wrapper form-textbox form-icons-focusable pattern-tooltip-after-textbox'
          : 'input'
      "
    >
      <div v-if="label && !isCVV" class="input-label">
        <label
          class="input-label__text"
          :id="`${name}_mainlabel`"
          :for="name"
          v-html="label"
        ></label>
      </div>

      <div
        class="form-textbox form-textbox-with-counter"
        :class="{
          'form-textbox-with-righticon': this.hasRightIcon,
          'is-error': this.error
        }"
      >
        <input
          class="form-textbox-input"
          :autocomplete="autocomplete"
          :id="name"
          :type="type"
          :name="name"
          :state="error || errorField === name ? false : null"
          v-mask="mask"
          :disabled="readonly"
          :required="required"
          :maxLength="maxLength"
          :aria-labelledby="getLabelId"
          :aria-describedby="getErrorMessage"
          :aria-invalid="error"
          :data-testid="name"
          v-model="value"
          v-on:keydown.space="onSpace"
          :formatter="format"
          @input="input"
          @blur="blur"
          v-on:keyup="keyup"
        />
        <span
          class="column large-12 small-12 form-textbox-label"
          :class="{
            'form-textbox-label-filled': value,
            'form-textbox-label-filled-invaild': value && error,
            'form-textbox-label-empty': !value
          }"
          :id="`${name}_label`"
          aria-hidden="true"
          >{{ placeholder }}</span
        >
        <div
          v-if="characterCountEnabled"
          :id="`${name}_counter`"
          class="form-textbox-counter-wrapper"
          aria-live="polite"
        >
          <span class="visuallyhidden">{{
            locale['pp.payment.character_length']
          }}</span>
          <span class="form-textbox-counter visuallyhidden">{{
            this.characterCount
          }}</span>
        </div>
        <div class="form-textbox-righticon" aria-live="polite">
          <template v-if="isLoading">
            <div class="form-loading-icon">
              <vn-loading-spinner :isLoading="true"></vn-loading-spinner>
            </div>
          </template>
          <template v-else-if="hasRightIcon">
            <div class="form-icons-default">
              <img
                v-if="cardType === 'visa'"
                src="../images/visa.png"
                alt="Visa Card"
              />
              <img
                v-else-if="cardType === 'mastercard'"
                src="../images/mastercard.png"
                alt="MasterCard"
              />
              <img
                v-else-if="cardType === 'amex'"
                src="../images/amex.png"
                alt="Amex Card"
              />
              <img
                v-else-if="cardType === 'discover'"
                src="../images/discover.png"
                alt="Discover Card"
              />
              <img
                v-else-if="cardType === 'diners'"
                src="../images/diners.png"
                alt="Diners Card"
              />
              <img
                v-else-if="cardType === 'jcb'"
                src="../images/jcb.png"
                alt="JCB Card"
              />
              <img
                v-else-if="cardType === 'rupay'"
                src="../images/rupay.png"
                alt="Rupay Card"
              />
              <img
                v-else-if="cardType === 'unionpay'"
                src="../images/unionpay.png"
                alt="UnionPay"
              />
              <img
                v-else-if="cardType === 'mnp'"
                src="../images/mnp.png"
                alt="MNP Card"
              />
            </div>
          </template>
        </div>

        <div aria-live="polite" :id="`${name}_error`">
          <div v-if="error || errorField === name" class="error-container row">
            <div class="form-message-wrapper">
              <!-- error message never visually hidden, never delayed, and never read by voiceover-->
              <span class="form-message" aria-hidden="true">
                {{ this.displayedErrorMessage }}
              </span>
              <!-- error message visually hidden and delayed then removed -->
              <span v-if="this.displayDelayedError" class="visuallyhidden">
                {{ this.displayedErrorMessage }}</span
              >
            </div>
          </div>
          <div
            v-if="multiByteMessage && multiByteCountries"
            class="form-layout-row-message"
            :id="`${name}_fieldMessage`"
          >
            {{ multiByteMessage }}
          </div>
          <div
            v-if="displayPOBoxDisclaimer"
            class="form-layout-row-message"
            :id="`${name}_poBoxMessage`"
          >
            {{ this.locale['Webpay.shipping.pobxaddress.Alert'] }}
          </div>
        </div>
      </div>
      <div
        v-if="isCVV"
        class="form-tooltip form-tooltip-after"
        :class="{ 'form-tooltip-show': isTooltipDisplayed }"
      >
        <button
          v-on:keydown.prevent.enter="toggleTooltip"
          v-on:keyup.prevent.space="toggleTooltip"
          v-on:focus="showTooltip"
          v-on:keyup.esc="hideTooltip"
          v-on:blur="hideTooltip"
          v-on:click="showTooltip"
          v-click-outside="hideTooltip"
          class="form-tooltip-button"
          aria-describedby="cvv-button"
          :aria-label="locale[`pp.payment.info.help`]"
          :aria-expanded="isTooltipDisplayed ? 'true' : 'false'"
        >
          <span class="form-icons form-icons-info19" aria-hidden="true"> </span>
          <span class="visuallyhidden">{{
            locale[`pp.payment.cvv.help`]
          }}</span>
        </button>
        <div
          v-bind:id="isThisRtl"
          aria-hidden="true"
          class="form-tooltip-info form-tooltip-pointer-left"
          role="tooltip"
        >
          <div class="form-tooltip-content">
            <img :src="require('@/assets/card_amex_visa_cvv.png')" alt="" />
          </div>

          <p class="form-tooltip-title">
            {{ locale[`pp.payment.info.help`] }}
          </p>
          <p class="form-tooltip-content">
            {{ locale[`pp.payment.cvv.helptext`] }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import ErrorCircleIcon from '@/images/ErrorCircleIcon.vue'
import CheckMarkIcon from '@/images/CheckMarkIcon.vue'
import isit from 'isitjs'
import { isNumber } from 'util'
import { polishInput } from '@/utilities/utils'

export default {
  name: 'app-input-new',
  description: 'Reusable regular input',
  components: {
    ErrorCircleIcon,
    CheckMarkIcon
  },
  data() {
    return {
      masked: true,
      enteredVal: '',
      isTooltipDisplayed: false,
      displayDelayedError: false,
      displayedErrorMessage: ''
      // displayedErrorMessage is the first error message visually displayed to the user
      // displayDelayedError is the same message as errorMessageDisplayed first but it delayed and visually hidden
    }
  },
  mounted: function () {
    this.updateErrormessage()
  },
  props: {
    label: {
      type: String,
      default: null
    },
    cardNumberMasked: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    name: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    fieldValue: {
      type: String,
      default: ''
    },
    error: {
      type: Boolean,
      default: false
    },
    isCVV: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    mask: {
      type: String,
      default: null
    },
    maxLength: {
      type: Number,
      default: 35
    },
    minilength: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    hasRightIcon: {
      type: Boolean,
      default: false
    },
    isInputValid: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    lookup: {
      type: Boolean,
      default: false
    },
    isLoanAccount: {
      type: Boolean,
      default: false
    },
    multiByteMessage: {
      type: String,
      default: null
    },
    multiByteCountries: {
      type: String,
      default: null
    },
    characterCountEnabled: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: null
    },
    validationMessage: {
      type: String,
      default: ''
    },
    displayPOBoxDisclaimer: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: function (val, oldVal) {
      this.value = this.format(val)
    },
    errorMessage: function () {
      this.updateErrormessage()
    },
    validationMessage: function () {
      this.updateErrormessage()
    }
  },
  computed: {
    ...mapState({
      locale: (state) => state.mainStore.localization,
      cardType: (state) => state.formStore.cardType,
      errorField: (state) => state.formStore.errorDetails.errorField,
      cardDetails: (state) => state.formStore.cardDetails,
      cardNotSupported: (state) => state.formStore.cardNotSupported,
      selectedCountry: (state) => state.mainStore.selectedCountry,
      isRtl: (state) => state.mainStore.isRtl
    }),
    value: {
      get() {
        this.enteredVal = this.fieldValue
        return this.enteredVal
      },
      set(data) {
        this.enteredVal = ''
        let polishedinput = () => {
          return polishInput(
            this.fullWidthNumConvert(data),
            this.maxLength,
            this.cardNumberMasked
          )
        }
        let newfield = polishedinput()
        if (this.fieldValue != data) {
          this.$emit('onChange', {
            name: this.name,
            value: data && newfield
          })
          this.enteredVal = data && newfield
        }
      }
    },
    isThisRtl() {
      if (this.isRtl) {
        return 'cvv-button-rtl'
      }
      return 'cvv-button'
    },
    errorMessage() {
      return this.error || this.errorField === this.name ? true : false
    },
    describedbyValue() {
      if (this.error) {
        return `${this.name}_error`
      } else if (this.hasRightIcon && this.isInputValid) {
        return `${this.name}_positive_validation`
      } else {
        return null
      }
    },
    characterCount() {
      return this.fieldValue
        ? this.maxLength - this.fieldValue.length
        : this.maxLength
    },
    getLabelId() {
      if (this.label && this.placeholder && this.label !== this.placeholder) {
        return `${this.name}_mainlabel ${this.name}_label`
      } else if (this.label) {
        return `${this.name}_mainlabel`
      } else if (this.name == 'card_number') {
        return `${this.name}_label + debitCardMessage`
      } else {
        return `${this.name}_label`
      }
    },
    getErrorMessage() {
      if (this.error || this.errorField === this.name) {
        return `${this.name}_error`
      } else {
        return `${this.name}_fieldMessage`
      }
    }
  },

  methods: {
    toggleTooltip() {
      this.isTooltipDisplayed = !this.isTooltipDisplayed
    },

    fullWidthNumConvert(fullWidthNum) {
      return fullWidthNum.replace(/[\uFF10-\uFF19]/g, function (m) {
        return String.fromCharCode(m.charCodeAt(0) - 0xfee0)
      })
    },

    hideTooltip() {
      this.isTooltipDisplayed = false
    },

    showTooltip() {
      this.isTooltipDisplayed = true
    },
    blur() {
      const payload = { name: this.name, value: this.value }
      if (this.lookup) {
        payload.ziplookup = this.lookup
      }
      this.$emit('onBlur', payload)
    },
    input() {
      this.$emit('onInput')
    },
    onSpace(e) {
      if (this.selectedCountry === 'JPN' && this.name == 'postalcode') {
        e.preventDefault()
      }
    },
    keyup(e) {
      if (e.keyCode === 13) {
        const payload = {
          name: this.name,
          value: this.value
        }
        this.$emit('onInputApply', payload)
      }
    },
    format(value, event) {
      if (this.selectedCountry === 'JPN' || isit.doubleByte(value)) {
        if (this.name === 'expires') {
          if (this.value.length === 4) {
            if (this.value.indexOf('/') === -1) {
              let firstval = this.value.substring(0, 2)
              var secondval = this.value.substring(2, 4)
              if (
                isNumber(parseInt(firstval)) &&
                isNumber(parseInt(secondval))
              ) {
                var completeval = firstval + '/' + secondval
                return completeval
              }
            }
          }
        }
        if (this.name === 'postalcode' && value && value.length >= 0) {
          let pp = value.split(' ').join('')
          pp = pp.replace(/\D/g, '')
          if (pp.length < 4) {
            return pp
          }
          var postCodeVal1 = pp.slice(0, 3)
          var postCodeVal2 = pp.slice(3, 7)
          let codevalue = `${postCodeVal1}-${postCodeVal2}`
          return codevalue
        }
      }
      if (this.name === 'card_number' && value && value.length > 0) {
        let cc = value.split(' ').join('')
        cc = cc.replace(/\D/g, '')
        let delim = ' '
        let offset = 0
        let length = 0
        let grouping = []
        const cardNumberGrouping = this.cardDetails.cardNumberGrouping
          ? this.cardDetails.cardNumberGrouping
          : [4, 4, 4, 4]
        for (let i = 0; i < cardNumberGrouping.length; i++) {
          length = Number(cardNumberGrouping[i])
          let numberPart = cc.substr(offset, length)
          if (numberPart !== '') grouping.push(numberPart)
          offset = offset + length
        }
        return grouping.length > 0 ? grouping.join(delim) : null
      } else {
        return value
      }
    },
    updateErrormessage() {
      if (this.validationMessage) {
        this.displayedErrorMessage = this.validationMessage
      } else if (this.name == 'expires') {
        this.displayedErrorMessage =
          this.locale[`pp.payment.error.highlight.expirationdate`]
      } else if (this.name == 'postalcode') {
        this.displayedErrorMessage =
          this.locale[`pp.payment.error.highlight.zipcode`]
      } else if (this.name == 'suburb') {
        this.displayedErrorMessage =
          this.locale[`pp.payment.error.highlight.street1`]
      } else if (this.name == 'card_number') {
        if (this.isLoanAccount) {
          this.displayedErrorMessage = this.locale[`pp.payment.error.debitcard`]
        }
        if (this.cardNotSupported === true) {
          this.displayedErrorMessage = this.locale[`pp.error.cardnotsupported`]
        } else {
          this.displayedErrorMessage =
            this.locale[`pp.payment.error.highlight.cardnumber`]
        }
      } else {
        this.displayedErrorMessage =
          this.locale[
            `pp.payment.error.highlight.${this.name.replace('_', '')}`
          ]
      }
      setTimeout(() => {
        this.displayDelayedError = true
      }, 6000)
    }
  }
}
</script>
<style scoped lang="scss">
@import 'ac-sasskit/core';
@import 'ac-sasskit/base';
@import 'ac-forms/core';
@import 'ac-forms/base';
@import 'ac-forms/modules/form-icons';
@import 'ac-forms/modules/form-textbox';
@import '@/styles/variables.scss';
@import 'ac-forms/modules/form-tooltip';
.input {
  width: 100%;
  margin: 0 auto;
  .input-label {
    margin-top: $app-margin-reduced-reg;
    margin-bottom: $app-margin-xs;
    text-align: left;
  }
  .input-label__text {
    @include input-label-text;
    max-width: 100%;
    font-weight: $app-font-weight-x-lg;
  }
  @include viewport(small) {
    max-width: 85%;
  }
}

.form-textbox-input:valid[required] ~ .form-textbox-righticon .icon-checkmark {
  display: block;
}

.form-textbox-with-righticon .form-textbox-righticon {
  top: rem(13);
  .icon-checkmark {
    display: none;
  }
}

.form-textbox-label {
  @include direction(left, 1rem, right, 0);
  color: swatch(glyph-gray-secondary);
}

.trigger-input-disabled {
  .form-textbox-label {
    font-size: 1.0625rem;
    line-height: 1.33337;
    font-weight: 400;
    letter-spacing: -0.01em;
    top: 0.58824rem;
  }
}

.form-textbox-input {
  font-size: $app-font-size-reg;
  direction: ltr !important;
  @include direction(padding-left, 1rem, padding-right, 1rem);
  @include direction(text-align, left, text-align, right);
  min-height: 3.5rem;
  margin-bottom: 14px;
  &:focus {
    outline: 0;
    outline-offset: 1px;
    border-color: swatch(fill-gray-tertiary);
    box-shadow: none;
  }
}

.form-textbox-righticon {
  top: 12px !important;
}
.is-error .form-textbox-input {
  margin-bottom: 0 !important;
}
.form-tooltip {
  @include direction(left, 6px, right, 6px);
}

.creditcard-section-addressheading {
  @include direction(margin-left, 0px, margin-right, 0px);
}
.form-tooltip-after {
  &:focus {
    outline: 4px solid red !important;
    outline-offset: 1px;
    box-shadow: none;
  }
}

.form-tooltip-pointer-left {
  @include viewport(small) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.form-textbox {
  margin-bottom: 0;
  text-align: left;
}

.form-textbox-label-filled {
  position: absolute;
  pointer-events: none;
  max-width: calc(100% - 1.5rem);
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
  -webkit-transition-duration: 0.125s;
  transition-duration: 0.125s;
  left: 1rem;
  white-space: nowrap;
  overflow: unset;
  font-size: rem(12) !important;
  line-height: 1.33337 !important;
  font-weight: 400 !important;
  letter-spacing: -0.01em !important;
  font-family: $default-family;
  top: 0.58824rem !important;
}
.form-tooltip-info::after {
  position: relative;
  right: 5px;
  content: '';
  width: 0.88235rem;
  height: 0.88235rem;
  border-bottom-right-radius: 2px;
  transform: rotate(45deg);
  bottom: -0.23529rem;
}
.form-textbox-label-empty {
  position: absolute;
  pointer-events: none;
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
  -webkit-transition-duration: 0.125s;
  transition-duration: 0.125s;
  top: 1.05882rem;
  white-space: nowrap;
  overflow: hidden;
  max-width: calc(100% - 1.5rem);
  left: 1rem;
  right: 0.5rem;
  bottom: 1rem;
  font-size: rem(17);
  line-height: 1.23536;
  font-weight: 400;
  letter-spacing: -0.022em;
  font-family: $default-family;
}
.form-textbox-label-filled-invaild {
  color: swatch(glyph-red);
}
.form-tooltip-content {
  margin-top: 0px !important;
  @include typography(body-reduced);
  text-align: justify;
}
.form-loading-icon {
  top: rem(10);
  right: rem(10);
  position: relative;
}
.form-textbox-input::-webkit-autofill,
.form-textbox-input::-webkit-contacts-auto-fill-button,
.form-textbox-input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
}

.form-message {
  @include direction(
    padding-left,
    $app-padding-x-xs,
    padding-right,
    $app-padding-x-xs
  );
}

.form-message-wrapper {
  margin-top: 8px;
  margin-bottom: 12px;
  @include direction(padding-left, 0, padding-right, 0);
}

.input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='url'],
select,
textarea {
  height: 3.53rem;
  color: swatch(fill-gray-tertiary);
  border: 1px solid;
  border-radius: rem(12);
  box-shadow: none;
  box-sizing: border-box;
  padding-left: $app-padding-elevated-sm;
  padding-top: $app-padding-md;
  direction: ltr;
}

.form-tooltip {
  top: 20px !important;
  left: 6px;
}

.form-textbox-righticon {
  top: 15px !important;
  @include direction(right, 14px, left, inherit);
}
.form-icons {
  .form-textbox .is-error {
    padding-bottom: 0px !important;
  }
  img {
    width: 40px;
    height: 25px;
  }
  .card-type--visa {
    background-image: url(../images/visa.png);
  }

  &.card-type--mastercard {
    background-image: url(../images/mastercard.png);
  }
  &.card-type--unionpay {
    background-image: url(../images/unionpay.png);
  }

  &.card-type--amex {
    background-image: url(../images/amex.png);
  }

  &.card-type--discover {
    background-image: url(../images/discover.png);
  }

  &.card-type--maestro {
    background-image: url(../images/maestro.png);
  }
  &.card-type--mnp {
    background-image: url(../images/mnp.png);
  }
  &.card-type--rupay {
    background-image: url(../images/rupay.png);
  }
  &.card-type--jcb {
    background-image: url(../images/jcb.png);
  }
}
@media only screen and (max-width: 734px) {
  .input {
    max-width: 100% !important ;
  }
}

.light-theme-label {
  opacity: 0.4;
}
.form-icons-default {
  pointer-events: none;
  transition: 0.5s ease;
  transition-property: opacity, transform;
  img {
    width: 40px;
    height: 25px;
  }
}
.form-tooltip-title {
  @include typography(body-reduced);
  padding-top: 14px;
  padding-bottom: 6px;
  margin-bottom: 0px !important;
}
.form-tooltip-button {
  display: block;
  &:focus,
  &.form-tooltip-show {
    .form-tooltip-info {
      display: block;
    }
  }
}
.form-tooltip-info {
  margin-bottom: 10px;
  width: 320px;
  padding: 16px;
  bottom: 25px;
  right: 0px;
  img {
    width: auto;
    height: auto;
  }
}
#cvv-button-rtl {
  margin-right: -300px !important;
}

.form-layout-row-message {
  margin-bottom: 15px;
  @include typography(caption);
}

.form-textbox-counter-wrapper {
  @include direction(left, unset, right, 1rem);
}
</style>
