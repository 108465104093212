import BootstrapVue from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.css'
import vClickOutside from 'v-click-outside'
import VueMask from 'v-mask'
import Vue from 'vue'
import VueScrollTo from 'vue-scrollto'
import App from './App.vue'
import titleMixin from './mixins/titleMixin'
import router from './router'
import store from './store'

Vue.use(VueMask)

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(VueScrollTo)
Vue.use(vClickOutside)
Vue.mixin(titleMixin)

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
