<template>
  <div>
    <div v-if="!isUnderReview && !isPaymentComplete" class="product_heading">
      <h1>{{ locale['pp.application.heading'] }}</h1>
      <!-- <p class="product_heading-description">
              {{locale[`pp.summary.description.${productInfo.ordertype}`]}}
        </p> -->
    </div>

    <div id="checkUPIModal" :class="{ displayNone: isModalClose }">
      <div v-if="isFetched && error" class="upistatusModal__headingicon">
        <span class="icon icon-exclamationtrianglesolid"></span>
      </div>
      <h2 class="upistatusModal__heading">
        {{ locale['pp.message.payment.authorization.status.upititle'] }}
      </h2>
      <p v-if="!isFetched && !error" class="upistatusModal__content">
        {{ locale['pp.message.payment.authorization.status.processing'] }}
      </p>
      <!-- <div role="alert" class= 'sr-only' aria-live="polite">processing</div> -->
      <div class="upistatusModal__Icon" v-if="!isFetched && !error">
        <app-moon-loader color="black" size="25px" />
      </div>
      <p v-if="isFetched && error" class="upistatusModal__content">
        {{ errorMessage }}
      </p>
      <div v-if="isFetched && error" class="upistatusModal__action">
        <app-button type="primary" ariaLabel="Ok" @onClick="goHome">
          Ok
        </app-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import AppButton from '@/components/app-button'
import Modal from '@marcom/ac-modal'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'

export default {
  components: {
    AppButton,
    appMoonLoader: MoonLoader
  },
  data() {
    return {
      // showed: true
      upilookup: '',
      isModalClose: true
    }
  },
  mounted() {
    this.upilookup = Modal.createStandardModal()
    this.upilookup.modalElement.setAttribute(
      'aria-label',
      this.locale['pp.message.payment.authorization.status.upititle']
    )
    const upiel = document.getElementById('checkUPIModal')

    this.upilookup.appendContent(upiel)
    this.upilookup.modalElement
      .getElementsByTagName('div')[0]
      .getElementsByTagName('div')[0]
      .removeAttribute('data-modal-close')
    this.upilookup.modalElement
      .getElementsByTagName('div')[0]
      .getElementsByTagName('div')[0]
      .getElementsByTagName('div')[0]
      .removeAttribute('data-modal-close')
    this.upilookup.modalElement.getElementsByTagName('button')[0].remove()
    this.upilookup.open()
    this.isModalClose = false
  },
  created() {
    this.$store.dispatch('checkUPIPaymentStatus', {
      token: this.$route.params.token,
      locale: this.$route.params.locale
    })
  },
  computed: {
    ...mapState({
      locale: (state) => state.mainStore.localization,
      isFetched: (state) => state.upiredirectPageStore.status.isFetched,
      error: (state) => state.upiredirectPageStore.status.error,
      message: (state) => state.upiredirectPageStore.status.errorMessage,
      showed: (state) => state.upiredirectPageStore.showed,
      isUnderReview: (state) => state.mainStore.isUnderReview,
      isPaymentComplete: (state) => state.mainStore.isPaymentComplete,
      productInfo: (state) => state.productStore,
      isUPIPaymentpend: (state) => state.mainStore.isUPIPaymentpend,
      upiModal: (state) => state.upiredirectPageStore.upiModal
    }),
    errorMessage() {
      return this.message === true
        ? this.locale['pp.message.payment.authorization.status.error']
        : this.locale['pp.payment.error.auth.fail']
    }
  },
  beforeRouteLeave(to, from, next) {
    if (
      to.name === 'PaymentProduct' ||
      to.name === 'PaymentReview' ||
      to.name === 'Overview'
    ) {
      this.upilookup.close()
      this.isModalClose = true
      next()
    }
  },
  methods: {
    goHome() {
      this.upilookup.close()
      this.isModalClose = true
      const { token, locale } = this.$route.params
      this.$router.push(`/${token}/${locale}`)
      this.$store.dispatch('resetUpi')
      this.$store.dispatch('init', this.$route.params)
    },
    addBlankAttr() {
      document.getElementById('checkUpiModal').focus()
      document
        .getElementById('checkUpiModal')
        .setAttribute('role', 'alertdialog')
      document
        .getElementById('checkUpiModal___BV_modal_content_')
        .removeAttribute('role')

      document
        .getElementById('checkUpiModal')
        .setAttribute('aria-describedby', 'checkUpiModal_body')
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'ac-sasskit/core';
@import 'ac-sasskit/base';
@import '@/styles/variables.scss';

.product_heading,
h1 {
  @include typography(headline-reduced);
  padding-top: $app-padding-lg;
  color: swatch(glyph-gray);
  &:focus {
    outline: 0;
  }
}

.product_heading-description {
  @include typography(body);
  color: swatch(glyph-gray);
  margin-top: 0px !important;
  padding-top: $app-margin-x-xs;
  font-weight: $app-font-weight-xr-lg;
  &:focus {
    outline: 0;
  }
}
.upistatusModal__headingicon {
  text-align: center;
  margin-bottom: 22px;
  font-size: 3rem;
}

.upistatusModal__heading {
  text-align: center;
  @include typography(headline-reduced);
  padding-bottom: $app-padding-xxxx-sm;
  @include viewport(small) {
    padding-bottom: $app-margin-xxxx-sm;
  }
}
.upistatusModal__content {
  @include direction(text-align, center, text-align, center);
  @include typography(body-tight);
  padding-top: $app-padding-zero;
  margin-top: 0 !important;
  p {
    margin: 0px;
  }
}

.upistatusModal__Icon {
  display: flex;
  justify-content: center;
  margin-top: 28px;
}

.upistatusModal__action {
  margin-top: $app-margin-xxxx-sm;
  text-align: -webkit-center;
  .button {
    display: block;
    margin-top: $app-margin-xxxx-xs;
    @include viewport(small) {
      width: 70%;
    }
  }
}
.displayNone,
.modal-close {
  display: none;
}
</style>
