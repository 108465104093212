<template>
  <div>
    <div class="title_heading">
      <h1>{{ locale['pp.application.heading'] }}</h1>
    </div>
    <p class="title_heading-description">
      {{ locale['pp.message.autherror'] }}
    </p>
    <p class="title_heading-description">
      {{ locale['pp.message.action.autherror'] }}
    </p>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import AppButton from '@/components/app-button'

export default {
  components: {},
  computed: {
    ...mapState({
      locale: (state) => state.mainStore.localization
    })
  },
  title() {
    return `${this.locale['pp.page_title']}`
  },
  beforeRouteLeave(to, from, next) {}
}
</script>
<style lang="scss" scoped>
@import '@/styles/custom.scss';
@import '@/styles/custom.scss';
@import 'ac-sasskit/core';
@import 'ac-sasskit/base';
@import '@/styles/variables.scss';

.title_heading,
h1 {
  text-align: center;
  @include typography(headline);
  padding-top: $app-margin-top-lg;
  color: swatch(glyph-gray);
  &:focus {
    outline: 0;
  }
}

.title_heading-description {
  text-align: center;
  @include typography(intro);
  color: swatch(glyph-gray);
  margin-top: 0px !important;
  padding-top: $app-margin-xx-xds;
  // font-weight: $app-font-weight-xr-lg;
  &:focus {
    outline: 0;
  }
}
</style>
