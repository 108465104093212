<template>
  <div class="form-selector">
    <input
      :id="`forms-${type}`"
      class="form-selector-input"
      :aria-labelledby="`forms-${type}`"
      name="paymentType"
      type="radio"
      :value="`${type}`"
      v-on:click="$emit('onClick', type, $event)"
    />
    <label :for="`forms-${type}`" class="form-selector-label">
      <div class="row">
        <span class="form-selector-left-col column large-12">
          <div>
            <slot name="image"></slot>
          </div>
          <div v-if="title" class="form-selector-title">
            {{ title }}
          </div>
          <div v-if="this.$slots.default && !title">
            <slot></slot>
          </div>
        </span>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: ''
    },
    logoImage: {
      type: String,
      default: ''
    },
    styleClass: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'ac-sasskit/core';
@import 'ac-forms/core';
@import 'ac-forms/base';
@import '@/styles/variables.scss';
@import 'ac-forms/modules/form-selector';

.form-selector .form-selector-label {
  margin-bottom: $app-margin-xx-xs;
}

.form-selector-label .form-selector-left-col {
  position: relative;
  align-self: center;
  text-align: left;
  padding-left: 0;
  display: flex;
  align-items: center;
}

.form-selector-label {
  min-height: 4.88235rem;
  @include viewport(medium) {
    min-height: 4.77rem;
  }
}

.form-selector-input {
  padding: $app-padding-xxx-sm;
}
.form-selector-title {
  margin-left: 1rem;
}

.form-selector-label .form-selector-image ~ .form-selector-title {
  margin-left: 2.88235rem;
}

.form-selector-image img {
  height: 1.64706rem;
  width: 1.64706rem;
}
</style>
